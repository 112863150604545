import React, { useCallback } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useAppDispatch, setIsPremiumFinishModalOpen } from "@shared/redux";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { usePremiumFinishData } from "./usePremiumFinishData";
import { FlexibilityOptionPanel } from "../common/components/FlexibilityOptionPanel";
import { FlexibilityOptionPanelProvider } from "../common/components/FlexibilityOptionPanelProvider";
import { FlexibilityDesignAttributes } from "../constants";

export const messages = defineMessages({
    modalCloseButton: {
        id: "studio.components.changePremiumFinish.modalCloseButton",
        defaultMessage: "Close",
        description: {
            note: "Text message for change product premium finish modal close button"
        }
    },
    modalHeader: {
        id: "studio.components.changePremiumFinish.modalHeader",
        defaultMessage: "Choose a premium finish",
        description: {
            note: "Text message for change product premium finish modal header"
        }
    },
    modalDescription: {
        id: "studio.components.changePremiumFinish.description",
        defaultMessage: "Go from standard to stand-out with a premium print finish",
        description: {
            note: "Description text message for change product premium finish modal"
        }
    },
    modalChangeText: {
        id: "studio.components.changePremiumFinish.changeText",
        defaultMessage: "Continue",
        description: {
            note: "Change product premium finish button text message in modal"
        }
    },
    modalLoadingText: {
        id: "studio.components.changePremiumFinish.loadingText",
        defaultMessage: "Changing premium finish...",
        description: {
            note: "Change product premium finish button loading text message in modal while it is updating the premium finish on canvas"
        }
    }
});

export function ChangePremiumFinishModal() {
    const dispatch = useAppDispatch();
    const { t } = useTranslationSSR();
    const getPremiumFinishData = usePremiumFinishData();
    const { setCurrentActiveDialog } = useActiveDialog();
    const onContinueClick = useCallback(() => {
        setCurrentActiveDialog(DialogType.PremiumFinishes);
        dispatch(
            setIsPremiumFinishModalOpen({
                isPremiumFinishModalOpen: true,
                premiumFinishModalOpenedContext: "flexibilityPanel"
            })
        );
    }, [setCurrentActiveDialog, dispatch]);

    return (
        <FlexibilityOptionPanelProvider
            designAttributeName={FlexibilityDesignAttributes.PremiumFinish}
            getOptionsData={getPremiumFinishData}
        >
            <FlexibilityOptionPanel
                onContinueClick={onContinueClick}
                closeButtonLabel={t(messages.modalCloseButton.id)}
                headerText={t(messages.modalHeader.id)}
                descriptionText={t(messages.modalDescription.id)}
                changeOptionText={t(messages.modalChangeText.id)}
                loadingText={t(messages.modalLoadingText.id)}
                changeAlertKey="studio.components.Toast.changeFinishToast"
            />
        </FlexibilityOptionPanelProvider>
    );
}
ChangePremiumFinishModal.displayName = "ChangePremiumFinishModal";
