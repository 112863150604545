import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { Typography, Link, Box } from "@vp/swan";
import { AcrobatIcon, DownArrowIcon, SvgIcon, AdobeIllustratorIcon, LoadingSpinner, SpinnerVariant } from "@shared/features/StudioChrome";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { downloadFile } from "@shared/utils/WebBrowser";
import * as styles from "./UploadDesignsTemplates.module.scss";

const messages = defineMessages({
    templatesText: {
        id: "studio.components.specsAndTemplates.templatesText",
        defaultMessage: "Templates",
        description: {
            note: "Text for Templates component heading"
        }
    },
    templatesHelperText: {
        id: "studio.components.specsAndTemplates.templatesHelperText",
        defaultMessage: "Create a print-ready file by downloading a template.",
        description: {
            note: "Helper text for templates panel"
        }
    },
    defaultPdfText: {
        id: "studio.components.specsAndTemplates.defaultPdfText",
        defaultMessage: "PDF",
        description: {
            note: "Link text for PDF file"
        }
    },
    defaultSvgText: {
        id: "studio.components.specsAndTemplates.defaultSvgText",
        defaultMessage: "SVG",
        description: {
            note: "Link text for SVG file"
        }
    },
    defaultAiText: {
        id: "studio.components.specsAndTemplates.defaultAiText",
        defaultMessage: "Adobe Illustrator (.ai).",
        description: {
            note: "Link text for Adobe Illustrator (.ai) file"
        }
    }
});

interface Props {
    pdfUrl: string;
    svgUrl: string;
    aiUrl: string;
}

export const UploadDesignsTemplates = (props: Props) => {
    const { pdfUrl, svgUrl, aiUrl } = props;
    const { t } = useTranslationSSR();
    const fileData = {
        PDF: {
            url: pdfUrl,
            fileName: "pdf-template.pdf"
        },
        SVG: {
            url: svgUrl,
            fileName: "svg-template.zip"
        },
        Ai: {
            url: aiUrl,
            fileName: "illustrator-template.ai"
        }
    };
    const onLinkClick = (fileType: string) => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.DOWNLOAD_SPECS_AND_TEMPLATES_FILE.replace("[FILE TYPE]", fileType),
            label: `Specs and Templates Download`,
            extraData: () => ({ fileType })
        });
        if (fileData[fileType].url) {
            downloadFile(fileData[fileType]);
        }
    };

    return (
        <Box marginTop={8}>
            <Typography fontSize="4" fontWeight="bold" mb={4}>
                {t(messages.templatesText.id)}
            </Typography>
            <Typography fontSize="1" mb={5}>
                {t(messages.templatesHelperText.id)}
            </Typography>
            <div>
                {!(pdfUrl && svgUrl && aiUrl) && (
                    <LoadingSpinner variant={SpinnerVariant.Small} centering />
                )}
                {pdfUrl && (
                    <Link className={styles.templateItem} component="button" onClick={() => onLinkClick("PDF")}>
                        <AcrobatIcon aria-hidden="true" />
                        <div className={styles.templateInnerItem}>
                            <Typography fontSize="1" fontWeight="bold">
                                {t(messages.defaultPdfText.id)}
                            </Typography>
                            <span>
                                <DownArrowIcon aria-hidden="true" />
                            </span>
                        </div>
                    </Link>
                )}
                {svgUrl && (
                    <Link className={styles.templateItem} component="button" onClick={() => onLinkClick("SVG")}>
                        <SvgIcon aria-hidden="true" />
                        <div className={styles.templateInnerItem}>
                            <Typography fontSize="1" fontWeight="bold">
                                {t(messages.defaultSvgText.id)}
                            </Typography>
                            <span>
                                <DownArrowIcon aria-hidden="true" />
                            </span>
                        </div>
                    </Link>
                )}
                {aiUrl && (
                    <Link className={styles.templateItem} component="button" onClick={() => onLinkClick("Ai")}>
                        <AdobeIllustratorIcon aria-hidden="true" />
                        <div className={styles.templateInnerItem}>
                            <Typography fontSize="1" fontWeight="bold">
                                {t(messages.defaultAiText.id)}
                            </Typography>
                            <span>
                                <DownArrowIcon aria-hidden="true" />
                            </span>
                        </div>
                    </Link>
                )}
            </div>
        </Box>
    );
};

UploadDesignsTemplates.displayName = "UploadDesignsTemplates";
