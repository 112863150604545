import { fontBrowserMessages } from "./fontBrowserMessages";

export const fontWeightTranslation = (weight: string) => {
    switch (weight) {
        case "bolditalic":
        case "italicbold":
            return fontBrowserMessages.weightBoldItalic.id;
        case "bold":
            return fontBrowserMessages.weightBold.id;
        case "italic":
            return fontBrowserMessages.weightItalic.id;
        case "normal":
        case "":
            return fontBrowserMessages.weightRegular.id;
        default:
            return "";
    }
};
