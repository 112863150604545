import React from "react";

interface Props extends React.HTMLAttributes<SVGSVGElement> {}

export function WarningErrorIcon(props: Props) {
    return (
        <svg viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M10.5946 1.56818C10.997 0.871212 12.003 0.871212 12.4054 1.56818L20.5539 15.6818C20.9563 16.3788 
                20.4533 17.25 19.6485 17.25H3.35149C2.5467 17.25 2.0437 16.3788 2.4461 15.6818L10.5946 1.56818Z"
                fill="#D24345"
            />
            <path
                d="M11.4667 14.502C11.9772 14.502 12.4002 14.0935 12.4002 13.5684C12.4002 13.0433 11.9772 12.6349 
                11.4667 12.6349C10.9562 12.6349 10.5332 13.0433 10.5332 13.5684C10.5332 14.0935 10.9562 14.502 
                11.4667 14.502ZM10.9933 11.0164C11.0052 11.2772 11.2201 11.4826 11.4813 11.4826C11.7424 11.4826 
                11.9574 11.2772 11.9693 11.0164L12.1217 7.67026C12.1384 7.30513 11.8468 7 11.4813 7C11.1158 7 
                10.8242 7.30513 10.8409 7.67026L10.9933 11.0164Z"
                fill="white"
            />
        </svg>
    );
}
WarningErrorIcon.displayName = "WarningErrorIcon";
