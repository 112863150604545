import React from "react";
import { useDragLayer } from "react-dnd";
import { itemIsImagePlaceholder } from "../../../easel";
import { useDocumentImages } from "../../../easel/designer-suite/PanelComponents/Uploads/useDocumentImages";
import { DroppableTargetLayerCanvasElement } from "./DroppableTargetLayerCanvasElement";
import { DroppableTargetLayerImageElement } from "./DroppableTargetLayerImageElement";

export const DroppableTargetLayerContents = () => {
    const imagesOnPanel = useDocumentImages(true);
    const { isDragging } = useDragLayer(monitor => ({
        isDragging: monitor.isDragging()
    }));
    if (!isDragging) {
        return null;
    }
    return (
        <div className="drop-target">
            <DroppableTargetLayerCanvasElement>
                {imagesOnPanel.filter(itemIsImagePlaceholder).map(image => (
                    <DroppableTargetLayerImageElement key={`droppable-target-${image.id}`} image={image as ImageItem} />
                ))}
            </DroppableTargetLayerCanvasElement>
        </div>
    );
};

DroppableTargetLayerContents.displayName = "DroppableTargetLayerContents";
