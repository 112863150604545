import React, { useEffect } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import {
    Typography,
    Button,
    Link,
    AlertBox,
    LegacyModalDialog,
    LegacyModalDialogBody,
    LegacyModalDialogCloseButton,
    LegacyModalDialogHeader,
    LegacyModalDialogTitle
} from "@vp/swan";
import { isCareAgent } from "@shared/utils/Care";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { fireDesignToolTrackingEvent } from "@shared/utils/Tracking";
import { usePageContext } from "@shared/features/StudioConfiguration";
import { useAppSelector, useAppDispatch, setTrackModalTransitionEvent, setShowPDFProofModal } from "@shared/redux";
import { GetDocument } from "@shared/utils/CimDoc";
import { StudioModalDialogContent } from "@shared/features/StudioChrome";
import { useGeneratePdf } from "./useGeneratePdf";
import * as styles from "./PdfProofModal.module.scss";

const messages = defineMessages({
    proofDescription: {
        id: "studio.pdfProofModal.proofDescription",
        defaultMessage:
            // eslint-disable-next-line max-len
            "To make sure a document comes out just the way you want it, get a PDF proof to print and review. Please remember that we don’t review your documents so double-check your spelling, text size and alignment before placing your order. You can also expect a degree of color variation as the precision and quality of our commercial printers is much higher than that of typical home/office printers.",
        description: {
            note: "Description of what a proof is in a studio proof modal"
        }
    },
    mobilePanelTitle: {
        id: "studio.pdfProofModal.mobilePanelTitle",
        defaultMessage: "Get Your PDF Proof"
    },
    proof: {
        id: "studio.pdfProofModal.proof",
        defaultMessage: "Proof",
        description: {
            note: "Button to launch the PDF Proof modal.  A PDF proof is the customer's design on a PDF that they can print out."
        }
    },
    proofButton: {
        id: "studio.pdfProofModal.proofButton",
        defaultMessage: "Get PDF Proof",
        description: {
            note: "Button text for shown to customers, for generating a pdf proof"
        }
    },
    pdfButton: {
        id: "studio.pdfProofModal.pdfButton",
        defaultMessage: "Create PDF",
        description: {
            note: "Button text for shown to CARE agents, for generating a pdf"
        }
    },
    pdfFileName: {
        id: "studio.pdfProofModal.pdfFileName",
        defaultMessage: "proof",
        description: {
            note: "The name of the downloaded file"
        }
    },
    pdfTimeToFinishMessage: {
        id: "studio.pdfTimeToFinishMessage",
        defaultMessage: "PDF proof generation can take up to a few minutes to complete.",
        description: {
            note: "message explaining how long proof processing may take"
        }
    },
    pdfTimeToFinishMessageRefreshed: {
        // TODO: Remove above and rename this to pdfTimeToFinishMessage when releasing increment 1b
        id: "studio.pdfTimeToFinishMessageRefreshed",
        defaultMessage:
            "We are creating a PDF proof of your design. This can take a few minutes to complete. You can continue to edit your design.",
        description: {
            note: "message explaining how long proof processing may take"
        }
    },
    pdfErrorMessage: {
        id: "studio.pdfErrorMessage",
        defaultMessage: "Generation of your PDF has failed.  Please try again or ",
        description: {
            note: "message explaining how long proof processing may take"
        }
    },
    contactUs: {
        id: "studio.pdfErrorMessage.contactUs",
        defaultMessage: "give us a call",
        description: {
            note: "The 'g' in give us a call is intentionally lowercase. This bit of text is meant to be a link that will be added inside other sentences."
        }
    },
    ariaCloseLabel: {
        id: "studio.pdfProofModal.ariaCloseLabel",
        defaultMessage: "Close PDF proof dialog"
    },
    continueEditing: {
        id: "studio.pdfProofModal.continueEditing",
        defaultMessage: "Continue editing",
        description: {
            note: "Text for button to continue editing project and close dialog"
        }
    }
});

interface Props {
    getDocument: GetDocument;
}

export const PdfProofModal = ({ getDocument }: Props) => {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const filename = `${t(messages.pdfFileName.id)}.pdf`;
    const { careUrl } = usePageContext();
    const { onClickGenerate, hasError } = useGeneratePdf({ filename, getDocument });
    const showPDFProofModal = useAppSelector(state => state.showPDFProofModal);
    const processingPDF = useAppSelector(state => state.processingPDF);
    const dispatch = useAppDispatch();

    useEffect(() => {
        hasError && dispatch(setShowPDFProofModal(true));
    }, [hasError, dispatch]);

    const onClickClose = () => {
        fireDesignToolTrackingEvent({
            eventDetail: "PDF Proof Modal Close Button Clicked",
            label: "PDF Proof Modal Close Button",
            pageNameBase: undefined,
            extraData: undefined
        });
        dispatch(setShowPDFProofModal(false));
    };

    const onClickContinueEditing = () => {
        fireDesignToolTrackingEvent({
            eventDetail: "PDF Proof Continue Editing Button Clicked",
            label: "PDF Proof Continue Editing Button",
            pageNameBase: undefined,
            extraData: undefined
        });
        dispatch(setShowPDFProofModal(false));
    };

    const handleTransitionEnd = () => {
        dispatch(setTrackModalTransitionEvent({ isEventActive: true, isTransitionComplete: !showPDFProofModal }));
    };

    return (
        <LegacyModalDialog
            isOpen={showPDFProofModal}
            onRequestDismiss={onClickClose}
            onTransitionEnd={handleTransitionEnd}
            variant={isSmall ? "panel-bottom" : "standard"}
        >
            <StudioModalDialogContent
                fullBleed={false}
                aria-labelledby="pdfProofModal"
                data-dcl-prevent-canvas-items-deselection
            >
                <LegacyModalDialogCloseButton visuallyHiddenLabel={t(messages.ariaCloseLabel.id)} />
                <LegacyModalDialogHeader>
                    <LegacyModalDialogTitle>{t(messages.mobilePanelTitle.id)}</LegacyModalDialogTitle>
                </LegacyModalDialogHeader>
                <LegacyModalDialogBody>
                    <div className={styles.descriptionText} id="pdfProofModal">
                        <p>{t(messages.proofDescription.id)}</p>
                    </div>
                    <div className={styles.generatePDF}>
                        <div>
                            <Button skin="primary" size="mini" onClick={onClickGenerate} disabled={processingPDF}>
                                {isCareAgent() ? t(messages.pdfButton.id) : t(messages.proofButton.id)}
                            </Button>
                            {processingPDF && (
                                <Button skin="primary" size="mini" onClick={onClickContinueEditing}>
                                    {t(messages.continueEditing.id)}
                                </Button>
                            )}
                        </div>
                        {processingPDF && (
                            <Typography fontSize="1" marginY={0} fontWeight="normal">
                                {t(messages.pdfTimeToFinishMessageRefreshed.id)}
                            </Typography>
                        )}
                        {hasError && (
                            <AlertBox skin="error">
                                {t(messages.pdfErrorMessage.id)}
                                <Link href={careUrl}>{t(messages.contactUs.id)}</Link>.
                            </AlertBox>
                        )}
                    </div>
                </LegacyModalDialogBody>
            </StudioModalDialogContent>
        </LegacyModalDialog>
    );
};
PdfProofModal.displayName = "PdfProofModal";
