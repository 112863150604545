import React from "react";
import ReactDOM from "react-dom";
import { useActiveCanvas } from "@easel";
import { useUploadComponents } from "@shared/features/UploadsAndAssets";
import { DroppableTargetLayerContents } from "./DroppableTargetLayerContents";
import "./draganddrop.scss";


export const DroppableTargetLayer = () => {
    const activeCanvas = useActiveCanvas();
    const uploadComponents = useUploadComponents();

    if (!activeCanvas || !uploadComponents) {
        return null;
    }
    const portalElement = document.getElementById(activeCanvas.id);
    if (!portalElement) {
        return null;
    }
    return <>{ReactDOM.createPortal(<DroppableTargetLayerContents />, portalElement)}</>;
};

DroppableTargetLayer.displayName = "DroppableTargetLayer";
