import React from "react";
import classNames from "classnames";
import { Typography, Divider, GridContainer, Row, Column } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import * as styles from "./SpecsAndTemplatesDimensionRow.module.scss";

const messages = defineMessages({
    surfaceHeightInInch: {
        id: "studio.components.specsAndTemplatePanel.surfaceheightInInch",
        defaultMessage: `[[lengthDimentionsInch]]"`,
        description: {
            note: "length of product dimensions in inch"
        }
    },
    surfaceWidthInInch: {
        id: "studio.components.specsAndTemplatePanel.surfaceWidthInInch",
        defaultMessage: `[[widthDimentionsInch]]"`,
        description: {
            note: "width dimensions of product in inch"
        }
    },
    surfaceHeightInMm: {
        id: "studio.components.specsAndTemplatePanel.surfaceheightInMm",
        defaultMessage: "[[lengthDimentionsMm]]mm",
        description: {
            note: "length of product dimensions in mm"
        }
    },

    surfaceWidthInMm: {
        id: "studio.components.specsAndTemplatePanel.surfaceWidthInMm",
        defaultMessage: "[[widthDimentionsMm]]mm",
        description: {
            note: "width dimensions of product in mm"
        }
    }
});

interface Props {
    className?: string;
    label?: string;
    columnLabel1?: string;
    columnLabel2?: string;
    icon?: React.ReactNode;
    lengthDimentionsInch?: String;
    lengthDimentionsMm?: String;
    widthDimentionsInch?: String;
    widthDimentionsMm?: String;
}

export function SpecsAndTemplatesDimensionRow({
    icon,
    columnLabel1,
    columnLabel2,
    label,
    className,
    lengthDimentionsInch,
    lengthDimentionsMm,
    widthDimentionsInch,
    widthDimentionsMm
}: Props) {
    const { t } = useTranslationSSR();
    return (
        <GridContainer>
            <Row className={classNames(styles.tableDataRow, className)}>
                <Column span={4} className={styles.panelDimensionsColumns}>
                    <Row>
                        <span className={styles.surfaceAreaIndicatorIcon}>{icon}</span>
                        <Typography fontWeight="bold" ml={4} fontSize="1">
                            {label}
                        </Typography>
                    </Row>
                </Column>
                <Column span={4}>
                    {columnLabel1 ? (
                        <Typography fontWeight="bold" fontSize="1">
                            {columnLabel1}
                        </Typography>
                    ) : (
                        <>
                            <Typography fontSize="2" mb={1} fontWeight="bold">
                                {lengthDimentionsInch
                                    ? t(messages.surfaceHeightInInch.id, { lengthDimentionsInch })
                                    : t(messages.surfaceHeightInMm.id, { lengthDimentionsMm })}
                            </Typography>
                            {lengthDimentionsInch && (
                                <Typography fontSize="-1" className={styles.dimensionsMmText}>
                                    {t(messages.surfaceHeightInMm.id, { lengthDimentionsMm })}
                                </Typography>
                            )}
                        </>
                    )}
                </Column>
                <Column span={4}>
                    {columnLabel2 ? (
                        <Typography fontWeight="bold" fontSize="1">
                            {columnLabel2}
                        </Typography>
                    ) : (
                        <>
                            <Typography fontSize="2" mb={1} fontWeight="bold">
                                {widthDimentionsInch
                                    ? t(messages.surfaceWidthInInch.id, { widthDimentionsInch })
                                    : t(messages.surfaceWidthInMm.id, { widthDimentionsMm })}
                            </Typography>
                            {widthDimentionsInch && (
                                <Typography fontSize="-1" className={styles.dimensionsMmText}>
                                    {t(messages.surfaceWidthInMm.id, { widthDimentionsMm })}
                                </Typography>
                            )}
                        </>
                    )}
                </Column>
            </Row>
            <Divider marginY={3} />
        </GridContainer>
    );
}
SpecsAndTemplatesDimensionRow.displayName = "SpecsAndTemplatesDimensionRow";
