import React from "react";
import classnames from "classnames";
import { Typography } from "@vp/swan";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { SelectedCheckIcon } from "@shared/features/StudioChrome";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import loadable from "@loadable/component";
import { loadableRetry } from "@shared/utils/Network";
import { type DifferentialPricing, type DifferentialPricingProps } from "@shared/features/Pricing";
import { useFlexibilityOptionPanel } from "./FlexibilityOptionPanelProvider";
import * as styles from "./FlexibilityOption.module.scss";
import { useFlexibilityOptionCost } from "../hooks/useFlexibilityOptionCost";
import { useFlexibilityMerchandisingData } from "../hooks/useFlexibilityMerchandisingData";

const DifferentialPricingDisplay = loadable(() => loadableRetry(() => import("@shared/features/Pricing")), {
    resolveComponent: components => components.DifferentialPricingDisplay as React.FC<DifferentialPricingProps>
});

export interface FlexibilityOptionData {
    designAttributeValue: string;
    description?: string;
    useIcon?: boolean;
    previousDesignAttribute?: string;
}

interface FlexibilityOptionsProps extends FlexibilityOptionData {
    selected: boolean;
    onClick: (designAttributeValue: string) => void;
    setPreviousDesignAttribute: (previousDesignAttribute: string) => void;
    className?: string;
}

export const FlexibilityOption = (props: FlexibilityOptionsProps) => {
    const {
        selected,
        description,
        useIcon,
        onClick,
        designAttributeValue,
        previousDesignAttribute,
        setPreviousDesignAttribute,
        className
    } = props;

    const { designAttributeName } = useFlexibilityOptionPanel();
    const getFlexibilityMerchandisingData = useFlexibilityMerchandisingData();
    const { isSmall } = useStudioLayout();
    const { getCost } = useFlexibilityOptionCost();
    const cost: DifferentialPricing | undefined = getCost(designAttributeName, designAttributeValue);

    const attributesAndValues = getFlexibilityMerchandisingData(designAttributeName, designAttributeValue);
    let translatedOptionName = designAttributeValue;
    let imageName;
    if (attributesAndValues) {
        translatedOptionName = attributesAndValues.merchandisingName;
        if (useIcon) {
            [imageName] = attributesAndValues.images;
        }
    }
    return (
        <button
            className={classnames(
                styles.flexibilityOption,
                {
                    [styles.optionSelected]: selected
                },
                className
            )}
            onClick={() => {
                fireDesignToolTrackingEvent({
                    eventDetail: STUDIO_TRACKING_EVENTS.CLICK_VALUE_ON_CHANGE_DESIGN_ATTRIBUTE_MODAL,
                    label: "click on a value in the change design attribute modal",
                    extraData: () => ({
                        designAttributeName,
                        designAttributeValue,
                        oldDesignAttributeValue: previousDesignAttribute
                    })
                } as any);
                onClick(designAttributeValue);
                setPreviousDesignAttribute(designAttributeValue);
            }}
            tabIndex={0}
        >
            {useIcon && imageName && <img src={imageName} alt="" />}
            <div className={styles.optionData}>
                <div className={styles.optionDataTitleRow}>
                    <Typography fontWeight="bold" fontSize="1" textColor="black">
                        {translatedOptionName}
                    </Typography>
                </div>
                {description && !isSmall && (
                    <Typography fontSize="-1" textColor="dark-grey">
                        {description}
                    </Typography>
                )}
                {cost && (
                    <DifferentialPricingDisplay
                        pricing={{
                            listPrice: cost.listPrice,
                            discountPrice: cost.discountPrice
                        }}
                        hideQuantity={true}
                        hideVatMessage={true}
                        negatePricingValues={false}
                        className={styles.differentialPricing}
                        priceIncludedClassname={styles.priceIncluded}
                    />
                )}
                {selected && (
                    <div className={styles.optionDataSelectedIcon}>
                        <SelectedCheckIcon />
                    </div>
                )}
            </div>
        </button>
    );
};

FlexibilityOption.displayName = "FlexibilityOption";
