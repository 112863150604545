import React from "react";
import { Typography, Box, FlexBox } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useStudioLayout, DesktopExperience, MobileExperience } from "@shared/features/ResponsiveDesign";
import { changeTemplateMessages } from "./messages";
import * as styles from "./ChangeTemplateHeader.module.scss";
import { TemplateSearchBar } from "./TemplateSearchBar";

export const ChangeTemplateHeader = () => {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();

    return (
        <FlexBox flexDirection="column" className={styles.container}>
            <Typography
                textSize={isSmall ? 4 : 3}
                textAlign="left"
                mt={isSmall ? 6 : 8}
                ml={isSmall ? 0 : 5}
                weight={isSmall ? "bold" : "standard"}
            >
                <DesktopExperience>{t(changeTemplateMessages.desktopModalHeader.id)}</DesktopExperience>
                <MobileExperience>{t(changeTemplateMessages.mobileModalHeader.id)}</MobileExperience>
            </Typography>
            <FlexBox className={styles.subHeaderContainer}>
                <div className={styles.subHeader}>
                    <Typography
                        className="modal-description-text"
                        mt={isSmall ? 2 : 3}
                        mx={isSmall ? 0 : 5}
                        fontSize="2"
                        fontWeight="normal"
                    >
                        {t(changeTemplateMessages.modalSubheader.id)}
                    </Typography>
                </div>
                <Box className={styles.templateSearchBar} mx={isSmall ? 0 : 5}>
                    <TemplateSearchBar />
                </Box>
            </FlexBox>
        </FlexBox>
    );
};

ChangeTemplateHeader.displayName = "ChangeTemplateHeader";
