import React, { useState, useEffect } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Typography, Button } from "@vp/swan";
import { getDesignDocumentFromDesigner, switchContext, useDesigner } from "@easel";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { newRelicWrapper } from "@shared/utils/Errors";
import { Store, useAppSelector, useAppDispatch, setForceWorkRecovery } from "@shared/redux";
import { useIsAnonymousUser } from "@shared/features/Auth";
import { useSessionState } from "@shared/features/WebBrowser";
import { MobilePanel } from "@shared/features/StudioChrome";
import { rehydrateDesignData } from "@shared/utils/Work";
import useIsWorkRecoverable from "../hooks/useIsWorkRecoverable";

const messages = defineMessages({
    buttonCTA: {
        id: "studio.components.recoverUnsavedWorkStep.buttonCTA",
        defaultMessage: "Sign in"
    },
    headline: {
        id: "studio.components.recoverUnsavedWorkStep.headline",
        defaultMessage: "Please sign back in"
    },
    subcopyText: {
        id: "studio.components.recoverUnsavedWorkStep.subcopyText",
        defaultMessage:
            "Still designing? Your session timed out, but we'll get you back to your project and we've saved your work."
    },
    ariaCloseLabel: {
        id: "studio.components.recoverUnsavedWorkStep.ariaCloseLabel",
        defaultMessage: "Close work recovery dialog"
    }
});

export default function RecoverUnsavedWorkDialog(props: Parameters<typeof Button>[0]) {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const dispatch = useAppDispatch();
    const { identity, auth } = useIdentityContext();
    const isWorkRecoverable = useIsWorkRecoverable();
    const isAnonymousUser = useIsAnonymousUser();
    const [showRecoveryModal, setShowRecoveryModal] = useState(false);
    const [sessionDesignData, setSessionDesignData] = useSessionState(`wipDesignData`, "");
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const forceWorkRecovery = useAppSelector(state => state.forceWorkRecovery);

    useEffect(() => {
        if ((forceWorkRecovery || isAnonymousUser) && isWorkRecoverable && !showRecoveryModal && designer) {
            newRelicWrapper.logPageAction("studio-recover-work-flow-displayed");
            // Trigger the documentSaved event to prevent the NavigationWarning in cimpress designer from firing.
            designer.eventBus.trigger(designer.eventBus.events.documentSaved);
            setShowRecoveryModal(true);
        } else if (forceWorkRecovery && !isWorkRecoverable) {
            dispatch(setForceWorkRecovery(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnonymousUser, designer, isWorkRecoverable, showRecoveryModal, forceWorkRecovery]);

    useEffect(() => {
        // only recover work after a user logged back in
        if (easelLoaded && sessionDesignData && !isAnonymousUser && !forceWorkRecovery) {
            rehydrateDesignData(sessionDesignData, auth.getToken(), identity, switchContext);
            setSessionDesignData("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnonymousUser, easelLoaded, sessionDesignData, forceWorkRecovery]);

    const saveDesignDataToSessionStorage = () => {
        const designDocument = getDesignDocumentFromDesigner();
        const state = Store.getState();
        const wipData = { state, designDocument };
        setSessionDesignData(JSON.stringify(wipData));
    };

    const handleRedirectToLoginAndRecoverFlow = async () => {
        newRelicWrapper.logPageAction("studio-recover-work-flow-accepted");
        saveDesignDataToSessionStorage();
        // redirect to login, with appropriate return url to studio (should be workId)
        // unless we are forcing a recovery due to some sort of auth issue, then we have
        // to logout the user
        if (forceWorkRecovery) {
            await auth.signOut();
        } else {
            await auth.signIn();
        }
    };

    const handleMobilePanelCloseClick = () => {
        setShowRecoveryModal(false);
        setSessionDesignData("");
        newRelicWrapper.logPageAction("studio-recover-work-flow-cancelled");
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    };

    return (
        <React.Fragment>
            <MobilePanel
                show={showRecoveryModal}
                onRequestDismiss={handleMobilePanelCloseClick}
                ariaLabelledBy="recoverUnsavedWorkModalHeader"
                ariaCloseLabel={t(messages.ariaCloseLabel.id)}
                title={t(messages.headline.id)}
            >
                <Typography
                    component="p"
                    marginBottom={6}
                    id="recoverUnsavedWorkModalSubcopy"
                    data-translationid={messages.subcopyText.id}
                >
                    {t(messages.subcopyText.id)}
                </Typography>
                <Button
                    skin="primary"
                    {...props}
                    onClick={handleRedirectToLoginAndRecoverFlow}
                    data-translationid={messages.buttonCTA.id}
                >
                    {t(messages.buttonCTA.id)}
                </Button>
            </MobilePanel>
        </React.Fragment>
    );
}
RecoverUnsavedWorkDialog.displayName = "RecoverUnsavedWorkDialog";
