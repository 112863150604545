import React, { FunctionComponent } from "react";
import classnames from "classnames";
import { getTrackingDataForSelection, selectedItemsAreOfTypes } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { StrikethroughButton as ToolbarButton } from "@shared/features/ContextualToolbar";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { useOnKeyDownRTFFocus } from "@shared/features/Accessibility";
import { useRichTextAttribute } from "../designer/useRichTextAttribute";
import { useDesigner } from "../designer/DesignerProvider";
import { useSelection } from "../designer/useSelection";
import { addModelsToSelection } from "../util";
import { useRichTextSet } from "../designer/useRichTextSet";
import type { Designer, ItemSelection } from "../@types/designer";

type ComponentWithRef = typeof ToolbarButton;
type FontStyleComponentProps = React.ComponentPropsWithoutRef<ComponentWithRef>;

interface Props {
    /** The key of the attribute that needs to be listened to and changed */
    styleName: string;
    /** The font style button component. Should accept isDisabled, isSelected, and onClick. */
    FontStyleComponent: FunctionComponent<FontStyleComponentProps> | ComponentWithRef;
    /** For overriding styles */
    className?: string;
}

const FONTBOLD = "bold";
const FONTITALIC = "italic";

const disableableVariantMapping = {
    [FONTBOLD]: {
        variant: "Bold",
        oppositeStyleName: FONTITALIC
    },
    [FONTITALIC]: {
        variant: "Italic",
        oppositeStyleName: FONTBOLD
    }
};

export function getIsCompatible(designer: Designer | undefined, selection: ItemSelection): designer is Designer {
    return designer !== undefined && selectedItemsAreOfTypes(selection, [ItemTypes.TEXT]);
}

export function useIsActive(styleName: string) {
    return useRichTextAttribute<boolean>("common", styleName, false);
}

export function getIsDisabled(designer: Designer, selection: CanvasItem[], styleName: string) {
    if (!selection.length) return true;
    const items = addModelsToSelection(selection);
    const attributes = designer.richTextManager.getAllAttributes(items);
    if (!attributes.fontFamily) {
        return true;
    }

    const variantMapping = disableableVariantMapping[styleName];
    return (
        variantMapping &&
        attributes.fontFamily.some(value => {
            const fontOption = designer.clients.font.fontMapping[value.toLowerCase()];
            if (!fontOption || !fontOption.Variants[variantMapping.variant]) {
                return true;
            }

            const secondaryAttribute = attributes[variantMapping.oppositeStyleName];
            const secondaryAttributeActive = secondaryAttribute && secondaryAttribute.includes(true);
            return !fontOption.Variants.BoldItalic && secondaryAttributeActive;
        })
    );
}

export function useIsDisabled(designer: Designer | undefined, selection: CanvasItem[], styleName: string) {
    const alternateVariant = disableableVariantMapping[styleName];
    // for bold or italic, cause this component to re-render when the other attribute changes
    // so that it can become enabled or disabled if the font calls for it
    // If alternateVariant is not defined, this will have no effect
    useRichTextAttribute<boolean>("all", alternateVariant && alternateVariant.oppositeStyleName, false);
    if (designer && selection) {
        return getIsDisabled(designer, selection, styleName);
    }
    // if either designer or selection are undefined, default to turning off functionality
    return true;
}

/**
 * The core functionality for a font style button
 */
export function FontStyleButton({ styleName, FontStyleComponent, className }: Props) {
    const designer = useDesigner();
    const attributeValue = useRichTextAttribute<boolean>("common", styleName, false);
    const selection = useSelection();
    const { setWithSideEffects } = useRichTextSet();

    const alternateVariant = disableableVariantMapping[styleName];
    // for bold or italic, cause this component to re-render when the other attribute changes
    // so that it can become enabled or disabled if the font calls for it
    // If alternateVariant is not defined, this will have no effect
    useRichTextAttribute<boolean>("all", alternateVariant && alternateVariant.oppositeStyleName, false);

    function onClick() {
        switch (styleName) {
            case "underline":
                designer &&
                    designer.eventBus.trigger(
                        STUDIO_TRACKING_EVENTS.CLICK_UNDERLINE,
                        getTrackingDataForSelection(selection)
                    );
                break;
            case "strikeout":
                designer &&
                    designer.eventBus.trigger(
                        STUDIO_TRACKING_EVENTS.CLICK_STRIKETHROUGH,
                        getTrackingDataForSelection(selection)
                    );
                break;
            default:
                break;
        }
        setWithSideEffects(addModelsToSelection(selection), styleName, !attributeValue);
    }

    const keyDownClick = useOnKeyDownRTFFocus(onClick, undefined);

    if (!selection.length || !getIsCompatible(designer, selection)) {
        return null;
    }

    return (
        <FontStyleComponent
            className={classnames(className, "easel-font-style-button")}
            onKeyDownCapture={keyDownClick}
            isSelected={attributeValue}
            onClick={onClick}
            isDisabled={getIsDisabled(designer, selection, styleName)}
        />
    );
}
FontStyleButton.displayName = "FontStyleButton";
