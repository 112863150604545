import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
}
export function RightArrowIcon(props: Props) {
    return (
        <svg viewBox="0 0 14 10" fill="none" {...props}>
            <path
                // eslint-disable-next-line max-len
                d="M13.0601 4.99658C13.0601 4.81429 12.9894 4.65479 12.8481 4.51807L8.58252 0.252441C8.50505 0.179525 8.42529 0.127116 8.34326 0.0952148C8.26579 0.0587565 8.18604 0.0405273 8.104 0.0405273C7.92171 0.0405273 7.76904 0.0997721 7.646 0.218262C7.52751 0.336751 7.46826 0.484863 7.46826 0.662598C7.46826 0.749186 7.48421 0.833496 7.51611 0.915527C7.54801 0.997559 7.59131 1.06592 7.646 1.12061L9.09521 2.59033L11.5835 4.85986L11.7681 4.48389L9.60107 4.354H1.58936C1.39795 4.354 1.24072 4.41553 1.11768 4.53857C0.999186 4.65706 0.939941 4.80973 0.939941 4.99658C0.939941 5.18799 0.999186 5.34521 1.11768 5.46826C1.24072 5.58675 1.39795 5.646 1.58936 5.646H9.60107L11.7681 5.50928L11.5835 5.14697L9.09521 7.40283L7.646 8.87939C7.59131 8.93408 7.54801 9.00244 7.51611 9.08447C7.48421 9.16195 7.46826 9.24626 7.46826 9.3374C7.46826 9.51514 7.52751 9.66325 7.646 9.78174C7.76904 9.90023 7.92171 9.95947 8.104 9.95947C8.27262 9.95947 8.42529 9.89111 8.56201 9.75439L12.8481 5.48193C12.9894 5.34521 13.0601 5.18343 13.0601 4.99658Z"
                fill="currentColor"
            />
        </svg>
    );
}
RightArrowIcon.displayName = "RightArrowIcon";
