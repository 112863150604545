import React, { ComponentProps, useState } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { MeasurementUnit } from "@design-stack-ct/utility-core";
import { SafetyLineIndicatorIcon, TrimLineIndicatorIcon, BleedLineIndicatorIcon } from "@shared/features/StudioChrome";
import { useSurfaceSpecs } from "@shared/features/SurfaceSpecifications";
import { useAppSelector } from "@shared/redux/Store";
import { localesWithInches } from "@shared/utils/Locales";
import { convertCmToMm, convertCmToInch } from "./utilities";
import { SpecsAndTemplatesDimensionRow } from "./SpecsAndTemplatesDimensionRow";
import { SpecsAndTemplatesLoader } from "./SpecsAndTemplatesLoader";

const messages = defineMessages({
    surfaceHeight: {
        id: "studio.components.specsAndTemplatePanel.surfaceHeight",
        defaultMessage: "Height",
        description: {
            note: "specs and templates modal height column title"
        }
    },
    surfaceWidth: {
        id: "studio.components.specsAndTemplatePanel.surfaceWidth",
        defaultMessage: "Width",
        description: {
            note: "specs and templates modal width column title"
        }
    },
    surfaceBleedLine: {
        id: "studio.components.specsAndTemplatePanel.surfaceBleedLine",
        defaultMessage: "Bleed",
        description: {
            note: "specs and templates modal bleed specification row title"
        }
    },
    surfaceTrimLine: {
        id: "studio.components.specsAndTemplatePanel.surfaceTrimLine",
        defaultMessage: "Trim",
        description: {
            note: "specs and templates modal trim specification row title"
        }
    },
    surfaceSafetyLine: {
        id: "studio.components.specsAndTemplatePanel.surfaceSafetyLine",
        defaultMessage: "Safety",
        description: {
            note: "specs and templates modal safety specification row title"
        }
    },
    loaderText: {
        id: "studio.components.specsAndTemplates.loaderText",
        defaultMessage: "loading...",
        description: {
            note: "Text message will display when dimension table is loading data"
        }
    },
    ariaLabelOfLoader: {
        id: "studio.components.specsAndTemplates.ariaLabelOfLoader",
        defaultMessage: "specs & templates data loader",
        description: {
            note: "Aria label for loader of specs and template dimension table"
        }
    }
});

export function SpecsAndTemplatesSurfaceData() {
    const { t } = useTranslationSSR();
    const { surfaceSpecs, isSurfaceSpecsLoading } = useSurfaceSpecs();
    const locale = useAppSelector(state => state.locale);
    const unit = localesWithInches.includes(locale) ? MeasurementUnit.IN : MeasurementUnit.CM;
    const [mmFormatter] = useState(() => {
        return new Intl.NumberFormat(locale, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0
        });
    });
    const [inFormatter] = useState(() => {
        return new Intl.NumberFormat(locale, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
        });
    });
    const surfaceDimensions = surfaceSpecs?.surfaceGroups[0]?.surfaces[0]?.docAdditionalData?.masks;

    return (
        <>
            <SpecsAndTemplatesDimensionRow
                columnLabel1={t(messages.surfaceHeight.id)}
                columnLabel2={t(messages.surfaceWidth.id)}
            />
            {isSurfaceSpecsLoading ? (
                <SpecsAndTemplatesLoader
                    accessibilityLabel={t(messages.ariaLabelOfLoader.id)}
                    loaderMessage={t(messages.loaderText.id)}
                />
            ) : (
                surfaceDimensions?.map(({ pathType: pathName, boundingArea: myData }: any, index: number) => {
                    let label: string | undefined;

                    if (pathName === "BLEED") {
                        label = t(messages.surfaceBleedLine.id);
                    } else if (pathName === "TRIM") {
                        label = t(messages.surfaceTrimLine.id);
                    } else if (pathName === "SAFE") {
                        label = t(messages.surfaceSafetyLine.id);
                    }

                    let props: Partial<ComponentProps<typeof SpecsAndTemplatesDimensionRow>> = {
                        lengthDimentionsMm: mmFormatter.format(convertCmToMm(myData.position.height)),
                        widthDimentionsMm: mmFormatter.format(convertCmToMm(myData.position.width))
                    };
                    if (unit === MeasurementUnit.IN) {
                        props = {
                            ...props,
                            lengthDimentionsInch: inFormatter.format(convertCmToInch(myData.position.height)),
                            widthDimentionsInch: inFormatter.format(convertCmToInch(myData.position.width))
                        };
                    }

                    return (
                        <SpecsAndTemplatesDimensionRow
                            key={index}
                            icon={
                                (pathName === "BLEED" && <BleedLineIndicatorIcon />) ||
                                (pathName === "TRIM" && <TrimLineIndicatorIcon />) ||
                                (pathName === "SAFE" && <SafetyLineIndicatorIcon />)
                            }
                            label={label}
                            {...props}
                        />
                    );
                })
            )}
        </>
    );
}
SpecsAndTemplatesSurfaceData.displayName = "SpecsAndTemplatesSurfaceData";
