import { defineMessages } from "@vp/i18n-helper";

// keyboard shortcuts modal tabs content text.

export const keyboardShortcutMessages = defineMessages({
    modalAriaLabel: {
        id: "studio.components.keyboardShorcuts.modalAriaLabel",
        defaultMessage: "keyboard shortcuts dialog",
        description: {
            note: "aria label for keyboard shortcuts modal"
        }
    },
    keyboardShortcutsLinkButton: {
        id: "studio.components.keyboardShorcuts.keyboardShortcutsLinkButton",
        defaultMessage: "See keyboard shortcuts",
        description: {
            note: "text for view modal keyboard shortcuts button"
        }
    },
    keyboardShortcutModalHeaderOneTitle: {
        id: "studio.components.keyboardShortcuts.keyboardShortcutModalHeaderOneTitle",
        defaultMessage: "Select operating system",
        description: {
            note: "Text for keyboard shortcut modal header one "
        }
    },
    keyboardShortcutModalHeaderTwoTitle: {
        id: "studio.components.keyboardShortcuts.keyboardShortcutModalHeaderTwoTitle",
        defaultMessage: "Keyboard shortcuts",
        description: {
            note: "Text for keyboard shortcut modal header two"
        }
    },
    viewAndZoomTabTitle: {
        id: "studio.components.keyboardShortcuts.viewAndZoomTabTitle",
        defaultMessage: "View & Zoom",
        description: {
            note: "Text for keyboard shortcut modal tab"
        }
    },
    textTabTitle: {
        id: "studio.components.keyboardShortcuts.textTabTitle",
        defaultMessage: "Text",
        description: {
            note: "Text for keyboard shortcut modal tab"
        }
    },
    shapeTabTitle: {
        id: "studio.components.keyboardShortcuts.shapeTabTitle",
        defaultMessage: "Shape",
        description: {
            note: "Text for keyboard shortcut modal tab"
        }
    },
    editTabTitle: {
        id: "studio.components.keyboardShortcuts.editTabTitle",
        defaultMessage: "Edit",
        description: {
            note: "Text for keyboard shortcut modal tab"
        }
    },
    transformTabTitle: {
        id: "studio.components.keyboardShortcuts.transformTabTitle",
        defaultMessage: "Transform",
        description: {
            note: "Text for keyboard shortcut modal tab"
        }
    },
    arrangeTabTitle: {
        id: "studio.components.keyboardShortcuts.arrangeTabTitle",
        defaultMessage: "Arrange",
        description: {
            note: "Text for keyboard shortcut modal tab"
        }
    },
    selectionTabTitle: {
        id: "studio.components.keyboardShortcuts.selectionTabTitle",
        defaultMessage: "Selection",
        description: {
            note: "Text for keyboard shortcut modal tab"
        }
    },
    guide: {
        id: "studio.components.keyboardShortcuts.guide",
        defaultMessage: "Show guides",
        description: {
            note: "Text that appears in keyboard shortcuts view and zoom panel"
        }
    },
    rulers: {
        id: "studio.components.keyboardShortcuts.rulers",
        defaultMessage: "Show rulers"
    },
    safety: {
        id: "studio.components.keyboardShortcuts.safety",
        defaultMessage: "Show safety area and bleeds"
    },
    preview: {
        id: "studio.components.keyboardShortcuts.preview",
        defaultMessage: "Preview"
    },
    zoomIn: {
        id: "studio.components.keyboardShortcuts.zoomIn",
        defaultMessage: "Zoom in",
        description: {
            note: "Text that appears in keyboard shortcuts view and zoom panel"
        }
    },
    zoomOut: {
        id: "studio.components.keyboardShortcuts.zoomOut",
        defaultMessage: "Zoom out"
    },
    zoomToFit: {
        id: "studio.components.keyboardShortcuts.zoomToFit",
        defaultMessage: "Zoom to fit"
    },
    leftAlignText: {
        id: "studio.components.keyboardShortcuts.leftAlignText",
        defaultMessage: "Left align text",
        description: {
            note: "Text that appears in keyboard shortcuts text panel"
        }
    },
    centerAlignText: {
        id: "studio.components.keyboardShortcuts.centerAlignText",
        defaultMessage: "Center align text"
    },
    rightAlignText: {
        id: "studio.components.keyboardShortcuts.rightAlignText",
        defaultMessage: "Right align text"
    },
    bold: {
        id: "studio.components.keyboardShortcuts.bold",
        defaultMessage: "Bold"
    },
    italic: {
        id: "studio.components.keyboardShortcuts.italic",
        defaultMessage: "Italic"
    },
    underline: {
        id: "studio.components.keyboardShortcuts.underline",
        defaultMessage: "Underline"
    },
    strikethrough: {
        id: "studio.components.keyboardShortcuts.strikethrough",
        defaultMessage: "Strikethrough"
    },
    increaseFont: {
        id: "studio.components.keyboardShortcuts.increaseFont",
        defaultMessage: "Increase font size by 1 point"
    },
    decreaseFont: {
        id: "studio.components.keyboardShortcuts.decreaseFont",
        defaultMessage: "Decrease font size by 1 point"
    },
    openFontBrowser: {
        id: "studio.components.keyboardShortcuts.openFontBrowser",
        defaultMessage: "Open font browser"
    },
    addText: {
        id: "studio.components.keyboardShortcuts.addText",
        defaultMessage: "Add text",
        description: {
            note: "Text that appears in keyboard shortcuts shape panel"
        }
    },
    addRectangle: {
        id: "studio.components.keyboardShortcuts.addRectangle",
        defaultMessage: "Add rectangle"
    },
    addLine: {
        id: "studio.components.keyboardShortcuts.addLine",
        defaultMessage: "Add line"
    },
    addCircle: {
        id: "studio.components.keyboardShortcuts.addCircle",
        defaultMessage: "Add circle"
    },
    copyStyle: {
        id: "studio.components.keyboardShortcuts.copyStyle",
        defaultMessage: "Copy style",
        description: {
            note: "Text that appears in keyboard shortcuts edit panel"
        }
    },
    pasteStyle: {
        id: "studio.components.keyboardShortcuts.pasteStyle",
        defaultMessage: "Paste style"
    },
    toggleLock: {
        id: "studio.components.keyboardShortcuts.toggleLock",
        defaultMessage: "Toggle lock"
    },
    groupElements: {
        id: "studio.components.keyboardShortcuts.groupElements",
        defaultMessage: "Group elements"
    },
    ungroupElements: {
        id: "studio.components.keyboardShortcuts.ungroupElements",
        defaultMessage: "Ungroup elements"
    },
    save: {
        id: "studio.components.keyboardShortcuts.save",
        defaultMessage: "Save"
    },
    deleteSelectedElements: {
        id: "studio.components.keyboardShortcuts.deleteSelectedElements",
        defaultMessage: "Delete selected elements"
    },
    selectAllElements: {
        id: "studio.components.keyboardShortcuts.selectAllElements",
        defaultMessage: "Select all elements"
    },
    deselectAllElements: {
        id: "studio.components.keyboardShortcuts.deselectAllElements",
        defaultMessage: "Deselect all elements"
    },
    duplicateSelectedElements: {
        id: "studio.components.keyboardShortcuts.duplicateSelectedElements",
        defaultMessage: "Duplicate selected elements"
    },
    undo: {
        id: "studio.components.keyboardShortcuts.undo",
        defaultMessage: "Undo"
    },
    redo: {
        id: "studio.components.keyboardShortcuts.redo",
        defaultMessage: "Redo"
    },
    colorPicker: {
        id: "studio.components.keyboardShortcuts.colorPicker",
        defaultMessage: "Color picker"
    },
    copy: {
        id: "studio.components.keyboardShortcuts.copy",
        defaultMessage: "Copy"
    },
    paste: {
        id: "studio.components.keyboardShortcuts.paste",
        defaultMessage: "Paste"
    },
    cut: {
        id: "studio.components.keyboardShortcuts.cut",
        defaultMessage: "Cut"
    },
    flipHorizontally: {
        id: "studio.components.keyboardShortcuts.flipHorizontally",
        defaultMessage: "Flip horizontally",
        description: {
            note: "Text that appears in keyboard shortcuts transform panel"
        }
    },
    flipVertically: {
        id: "studio.components.keyboardShortcuts.flipVertically",
        defaultMessage: "Flip vertically"
    },
    alignElementsToTop: {
        id: "studio.components.keyboardShortcuts.alignElementsToTop",
        defaultMessage: "Align elements to top"
    },
    alignElementsToRight: {
        id: "studio.components.keyboardShortcuts.alignElementsToRight",
        defaultMessage: "Align elements to right"
    },
    alignElementsToBottom: {
        id: "studio.components.keyboardShortcuts.alignElementsToBottom",
        defaultMessage: "Align elements to bottom"
    },
    alignElementsToLeft: {
        id: "studio.components.keyboardShortcuts.alignElementsToLeft",
        defaultMessage: "Align elements to left"
    },
    alignElementsVertically: {
        id: "studio.components.keyboardShortcuts.alignElementsVertically",
        defaultMessage: "Align elements vertically"
    },
    alignElementsHoriztonally: {
        id: "studio.components.keyboardShortcuts.alignElementsHoriztonally",
        defaultMessage: "Align elements horiztonally"
    },
    moveSelectionUpBy10px: {
        id: "studio.components.keyboardShortcuts.moveSelectionUpBy10px",
        defaultMessage: "Move selection up by 10px"
    },
    moveSelectionRightBy10px: {
        id: "studio.components.keyboardShortcuts.moveSelectionRightBy10px",
        defaultMessage: "Move selection right by 10px"
    },
    moveSelectionDownBy10px: {
        id: "studio.components.keyboardShortcuts.moveSelectionDownBy10px",
        defaultMessage: "Move selection down by 10px"
    },
    moveSelectionLeftBy10px: {
        id: "studio.components.keyboardShortcuts.moveSelectionLeftBy10px",
        defaultMessage: "Move selection left by 10px"
    },
    arrangeElementsForward: {
        id: "studio.components.keyboardShortcuts.arrangeElementsForward",
        defaultMessage: "Arrange elements forward",
        description: {
            note: "Text that appears in keyboard shortcuts arrange panel"
        }
    },
    arrangeElementsBackward: {
        id: "studio.components.keyboardShortcuts.arrangeElementsBackward",
        defaultMessage: "Arrange elements backward"
    },
    arrangeElementsToFront: {
        id: "studio.components.keyboardShortcuts.arrangeElementsToFront",
        defaultMessage: "Arrange elements to front"
    },
    arrangeElementsToBack: {
        id: "studio.components.keyboardShortcuts.arrangeElementsToBack",
        defaultMessage: "Arrange elements to back"
    },
    duplicateSelection: {
        id: "studio.components.keyboardShortcuts.duplicateSelection",
        defaultMessage: "Duplicate selection",
        description: {
            note: "Text that appears in keyboard shortcuts selection panel"
        }
    },
    incrementalRotationOnly: {
        id: "studio.components.keyboardShortcuts.incrementalRotationOnly",
        defaultMessage: "Incremental rotation only"
    },
    ignoreSnapping: {
        id: "studio.components.keyboardShortcuts.ignoreSnapping",
        defaultMessage: "Ignore snapping"
    },
    ignoreResizeSnapping: {
        id: "studio.components.keyboardShortcuts.ignoreResizeSnapping",
        defaultMessage: "Ignore snapping"
    },
    ignoreDragSnapping: {
        id: "studio.components.keyboardShortcuts.ignoreDragSnapping",
        defaultMessage: "Ignore snapping"
    },
    resizeFromMiddle: {
        id: "studio.components.keyboardShortcuts.resizeFromMiddle",
        defaultMessage: "Resize from middle, lock ratio"
    },
    resizeFromOpposite: {
        id: "studio.components.keyboardShortcuts.resizeFromOpposite",
        defaultMessage: "Resize from opposite corner, lock ratio"
    },
    pan: {
        id: "studio.components.keyboardShortcuts.pan",
        defaultMessage: "Pan"
    },
    holdHorizontalAndVerticalPosition: {
        id: "studio.components.keyboardShortcuts.holdHorizontalAndVerticalPosition",
        defaultMessage: "Hold horizontal/vertical position"
    },
    closeModalAriaLabel: {
        id: "studio.components.keyboardShortcuts.closeModalAriaLabel",
        defaultMessage: "Close",
        description: {
            note: "Aria label for a close modal"
        }
    }
});
