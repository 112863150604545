import { defineMessages } from "@vp/i18n-helper";

export const designPanelMessages = defineMessages({
    addingSide: {
        id: "studio.components.spinners.addingSide",
        defaultMessage: "Adding the [[sideName]]...",
        description: {
            note: "Spinner text shown over the entire canvas when changing sides (back/front/inside, etc) via canvas selector"
        }
    },

    designPanelAddTitle: {
        id: "studio.components.design.panel.add.title.updated",
        defaultMessage: "Add the [[sideName]]",
        description: {
            note: "Title of a dialog / panel that allows adding a side"
        }
    },
    designPanelAddInstructions: {
        id: "studio.components.design.panel.add.instructions.updated",
        defaultMessage: "Choose an option below or use a template to add the [[sideName]] to your design.",
        description: {
            note: "instruction text of a dialog / panel that allows adding a side"
        }
    },
    designPanelChangeTitle: {
        id: "studio.components.design.panel.change.title",
        defaultMessage: "Change the [[sideName]]",
        description: {
            note: "Title of a dialog / panel that allows changing the template / design of a side"
        }
    },
    changingSide: {
        id: "studio.components.spinners.changingSide",
        defaultMessage: "Changing the [[sideName]]...",
        description: {
            note: "Title of a dialog / panel that allows changing the template / design of a side"
        }
    },
    designPanelChangeInstructions: {
        id: "studio.components.design.panel.change.instructions.noDelete",
        defaultMessage: "Choose an option below or use a template to change the [[sideName]] of your design.",
        description: {
            note: "instructions of a dialog / panel that allows changing the template / design of a side"
        }
    },
    designPanelChangeInstructionsWithDelete: {
        id: "studio.components.design.panel.change.instructions.withDelete",
        defaultMessage: "Delete [[sideName]] or use a template to replace your design.",
        description: {
            note: "instructions of a dialog / panel that allows changing the template / design of a side with delete as an option"
        }
    },
    designPanelCancel: {
        id: "studio.components.design.panel.button.cancel",
        defaultMessage: "Cancel",
        description: {
            note: "Close dialog"
        }
    },
    designTileUpload: {
        id: "studio.components.design.panel.tile.upload",
        defaultMessage: "Upload your design",
        description: {
            note: "Text for a tile that triggers selecting a full bleed upload to be the design"
        }
    },
    designStartFromScratch: {
        id: "studio.components.design.panel.start.from.scratch",
        defaultMessage: "Design from scratch",
        description: {
            note: "Text for a tile that triggers selecting a blank design to start designing on"
        }
    },
    designStartFromBlank: {
        id: "studio.components.design.panel.start.from.blank",
        defaultMessage: "Start from blank design",
        description: {
            note: "Text for a tile that triggers selecting a blank design to start designing on, when adding a surface"
        }
    },
    designChangeToBlank: {
        id: "studio.components.design.panel.change.to.blank",
        defaultMessage: "Change to blank design",
        description: {
            note: "Text for a tile that triggers selecting a blank design to start designing on for a surface that's already been added"
        }
    },
    designTileDuplicate: {
        id: "studio.components.design.panel.tile.duplicate",
        defaultMessage: "Duplicate the front design",
        description: {
            note: "Text for a tile that triggers copying the front design onto the current surface"
        }
    },
    designTileDuplicateResync: {
        id: "studio.components.design.panel.tile.duplicateResync.tooltip",
        defaultMessage: "Re-sync with front",
        description: {
            note: "Text for a tile that triggers re-copying the front onto the current surface"
        }
    },
    designButtonDelete: {
        id: "studio.components.design.panel.tile.delete",
        defaultMessage: "Delete the [[sideName]] ",
        description: {
            note: "Instructions for a tile that triggers removing the current side"
        }
    },
    designButtonDeleteInstructions: {
        id: "studio.components.design.panel.tile.delete.instructions",
        defaultMessage: "Delete the [[sideName]] design to keep it blank on your product.",
        description: {
            note: "Text for a tile/button that triggers removing the current side"
        }
    },
    designChangeTileBlank: {
        id: "studio.components.design.panel.change.tile.blank",
        defaultMessage: "Change to blank",
        description: {
            note: "Text for a tile that triggers selecting a blank design"
        }
    },
    designConfirmDeleteTitle: {
        id: "studio.components.design.dialog.confirm.delete.title",
        defaultMessage: "Delete the [[sideName]]?",
        description: {
            note: "Title for a dialog for confirming if you want to delete a side"
        }
    },
    designConfirmDeleteText: {
        id: "studio.components.design.dialog.confirm.delete.text",
        defaultMessage: "Are you sure you want to delete the [[sideName]] of your design? This cannot be undone.",
        description: {
            note: "Text for a dialog for confirming if you want to delete a side"
        }
    },
    designConfirmationMailingText: {
        id: "studio.components.design.dialog.designConfirmationMailingText",
        defaultMessage: " The mailing address area will still be printed on the [[sideName]] of your postcard.",
        description: {
            note: "instructions of a dialog / panel that allows changing the template / design of a side with delete as an option for mailing postcard."
        }
    },
    designConfirmDeleteCancel: {
        id: "studio.components.design.dialog.confirm.delete.cancel",
        defaultMessage: "Cancel",
        description: {
            note: "Cancel button text for a dialog for confirming if you want to delete a side"
        }
    },
    designConfirmDeleteConfirm: {
        id: "studio.components.design.dialog.confirm.delete.confirm",
        defaultMessage: "Delete",
        description: {
            note: "Delete button text for a dialog for confirming if you want to delete a side"
        }
    },
    deletingSide: {
        id: "studio.components.spinners.deletingSide",
        defaultMessage: "Deleting the [[sideName]]...",
        description: {
            note: "Spinner text shown over the entire canvas when deleting sides (back/front/inside, etc) via canvas selector"
        }
    },
    designConfirmChangeTitle: {
        id: "studio.components.design.dialog.confirm.change.title",
        defaultMessage: "Change the [[sideName]]?",
        description: {
            note: "Title for a dialog for confirming if you want to change a side"
        }
    },
    designConfirmChangeText: {
        id: "studio.components.design.dialog.confirm.change.text",
        defaultMessage:
            "Are you sure you want to change the [[sideName]] of your design? Your current design will be deleted.",
        description: {
            note: "Text for a dialog for confirming if you want to change a side"
        }
    },
    designConfirmChangeCancel: {
        id: "studio.components.design.dialog.confirm.change.cancel",
        defaultMessage: "Cancel",
        description: {
            note: "Cancel button text for a dialog for confirming if you want to change a side"
        }
    },
    designConfirmChangeConfirm: {
        id: "studio.components.design.dialog.confirm.change.confirm",
        defaultMessage: "Change",
        description: {
            note: "Change button text for a dialog for confirming if you want to change a side"
        }
    },
    designNoSideTitle: {
        id: "studio.components.design.dialog.warn.noside.title",
        defaultMessage: "No [[sideName]] added",
        description: {
            note: "Title for a dialog warning that an optional side has not been added"
        }
    },
    designNoSideText: {
        id: "studio.components.design.dialog.noside.text",
        defaultMessage:
            "Your design is missing the [[sideName]]. Add the [[sideName]], or leave it blank by clicking Continue to checkout.",
        description: {
            note: "Text for a dialog warning that an optional side has not been added"
        }
    },
    designNoSideAdd: {
        id: "studio.components.design.dialog.noside.addside",
        defaultMessage: "Add [[sideName]]",
        description: {
            note: "Cancel button for a dialog warning that an optional side has not been added"
        }
    },
    designNoSideConfirm: {
        id: "studio.components.design.dialog.noside.confirm",
        defaultMessage: "Continue to checkout",
        description: {
            note: "Continue button for a dialog warning that an optional side has not been added"
        }
    },
    designSideSelectionClose: {
        id: "studio.components.design.dialog.side.selection.close",
        defaultMessage: "Close",
        description: {
            note: "accessability text for an x button for closing a dialog for selecting a side"
        }
    },
    title: {
        id: "studio.ui.component.templatesPanel.title",
        defaultMessage: "Templates",
        description: {
            note: "Title for the templates panel"
        }
    },
    changeTemplateAriaLabel: {
        id: "studio.components.design.dialog.side.selection.template.ariaLabel",
        defaultMessage: "Choose Template",
        description: {
            note: "Aria label for the choosing a template on the panel"
        }
    },
    designDialogClose: {
        id: "studio.components.design.dialog.close",
        defaultMessage: "Close",
        description: {
            note: "accessability text for an x button for closing a dialog"
        }
    },
    selectSideDialogOpen: {
        id: "studio.components.open.side.selection.dialog",
        defaultMessage: "Open side selection dialog",
        description: {
            note: "accessability text for a button that opens a side selection dialog on mobile"
        }
    },
    designPanelContinueNoSide: {
        id: "studio.components.design.dialog.continue",
        defaultMessage: "Continue without [[sideName]]",
        description: {
            note: "label for continue button on add panel"
        }
    },

    designPanelTileSelection: {
        id: "studio.components.design.panel.tile.selection.option",
        defaultMessage: "Choose back design",
        description: {
            note: "label for choosing back design on design panel tile"
        }
    },
    changeConfirmationAriaLabel: {
        id: "studio.components.design.panel.tile.selection.changeConfirmationAriaLabel",
        defaultMessage: "Change confirmation modal",
        description: {
            note: "aria label for change confirmation modal"
        }
    },
    deleteConfirmationAriaLabel: {
        id: "studio.components.design.panel.tile.selection.deleteConfirmationAriaLabel",
        defaultMessage: "Delete confirmation modal",
        description: {
            note: "aria label for delete confirmation modal"
        }
    },
    designPanelChangeInstructionsWithoutTemplate: {
        id: "studio.components.design.dialog.instructions.withoutTemplate",
        defaultMessage: "Choose an option below to change the [[sideName]] to your design.",
        description: {
            note: "instructions on the panel that allows changing the design of a side"
        }
    },
    designPanelContinueWithBlank: {
        id: "studio.components.design.dialog.continueWithBlank",
        defaultMessage: "Continue with blank [[sideName]]",
        description: {
            note: "label for the continue button when using a blank side"
        }
    }
});
