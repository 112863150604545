import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import {
    Typography,
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton,
    Dropdown,
    DropdownOption
} from "@vp/swan";
import { useIsFlagEnabled } from "@shared/features/Flags";
import { DesktopExperience } from "@shared/features/ResponsiveDesign";
import { keyboardShortcutMessages } from "./messages";
import { KeyboardShortcutsTabContent } from "./KeyboardShortcutsTabContent";
import { OSName } from "./usePlatformName";
import { useKeyboardShortcutContext } from "./KeyboardShortcutsContext";
import * as styles from "./KeyboardShortcutsInformationModal.module.scss";

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function KeyBoardShortcutsInformationModal() {
    const { t } = useTranslationSSR();
    const advancedTools = useIsFlagEnabled("advancedTools");
    const { isKeyboardShortcutsGuideModalOpen, setIsKeyboardShortcutsGuideModalOpen, setSelectedOS, selectedOS } =
        useKeyboardShortcutContext();

    const KeyboardShortcutsGuideModalClose = () => {
        setIsKeyboardShortcutsGuideModalOpen(false);
    };

    return (
        <DesktopExperience>
            <LegacyModalDialog
                isOpen={isKeyboardShortcutsGuideModalOpen}
                onRequestDismiss={KeyboardShortcutsGuideModalClose}
            >
                <LegacyModalDialogContent
                    aria-label={t(keyboardShortcutMessages.modalAriaLabel.id)}
                    className={styles.keyboardShortcutModal}
                >
                    <LegacyModalDialogHeader>
                        {advancedTools && (
                            <div className={styles.osSelectionHeader}>
                                <Typography className={styles.advanceOptionHeader}>
                                    {t(keyboardShortcutMessages.keyboardShortcutModalHeaderOneTitle.id)}
                                </Typography>
                                <Dropdown
                                    size="mini"
                                    value={selectedOS}
                                    onChange={event => setSelectedOS(event.target.value)}
                                    className={styles.swanDropdown}
                                >
                                    {Object.values(OSName).map(key => {
                                        return (
                                            <DropdownOption key={OSName[key]} value={OSName[key]}>
                                                {capitalizeFirstLetter(OSName[key])}
                                            </DropdownOption>
                                        );
                                    })}
                                </Dropdown>
                            </div>
                        )}
                    </LegacyModalDialogHeader>
                    <div className={styles.closeButtonContainer}>
                        <LegacyModalDialogCloseButton
                            visuallyHiddenLabel={t(keyboardShortcutMessages.closeModalAriaLabel.id)}
                            className={styles.closeButton}
                        />
                    </div>
                    <Typography className={styles.header}>
                        {t(keyboardShortcutMessages.keyboardShortcutModalHeaderTwoTitle.id)}
                    </Typography>
                    <KeyboardShortcutsTabContent />
                </LegacyModalDialogContent>
            </LegacyModalDialog>
        </DesktopExperience>
    );
}

KeyBoardShortcutsInformationModal.displayName = "KeyBoardShortcutsInformationModal";
