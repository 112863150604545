import React from "react";
import { MailingConfirmationModal } from "./MailingConformationModal";
import { MailingServicesFAQModal } from "./MailingServicesFAQModal";
import { MailingServicesGuideModal } from "./MailingServicesGuideModal";
import { MailingServicesOptionsModal } from "./MailingServicesOptionsModal";
import RelocateItemsAlert from "./RelocateItemsAlert";

export const MailingServicesContainer = () => (
    <>
        <MailingServicesOptionsModal />
        <MailingServicesGuideModal />
        <MailingServicesFAQModal />
        <MailingConfirmationModal />
        <RelocateItemsAlert />
    </>
);

MailingServicesContainer.displayName = "MailingServicesContainer";
