import React, { useContext, createContext, ReactNode, useState, useMemo, useEffect } from "react";
import { PriceQuantityData } from "@shared/utils/Calcifer";
import { useAppSelector } from "@shared/redux";
import { mapDesignAttributeToProductOption, mapProductOptionValueToDesignAttributeValue } from "@shared/utils/Product";
import { FlexibilityOptionData } from "./FlexibilityOption";
import { useActiveFlexibilityOptions } from "../../ActiveFlexibilityProvider";

export type PreviewSceneInfo = {
    designAttributeValue: string;
    sceneUrls: string[];
};

export interface FlexibilityOptionPanelProviderData {
    designAttributeName: string;
    getOptionsData?: (optionsValues: string[]) => FlexibilityOptionData[];
    forcePreviewFallback?: boolean;
    previewScenes?: PreviewSceneInfo[];
    quantityAttributes?: PriceQuantityData;
    displayPreview?: boolean;
}

interface FlexibilityOptionPanelProviderProps extends FlexibilityOptionPanelProviderData {
    children: ReactNode | ReactNode[];
}

type ContextData = {
    designAttributeName: string;
    currentDesignAttributeValue: string;
    setCurrentDesignAttributeValue: React.Dispatch<React.SetStateAction<string>>;
    optionData: FlexibilityOptionData[] | undefined;
    availableDesignAttributes: string[];
    forcePreviewFallback: boolean;
    previewScenes?: PreviewSceneInfo[];
    quantityAttributes?: PriceQuantityData;
};

const Context = createContext<ContextData | undefined>(undefined);

export const useFlexibilityOptionPanel = () => {
    const result = useContext(Context);
    if (!result) {
        throw Error("Please call this within an FlexibilityOptionPanelProvider");
    }
    return result;
};
const { Provider } = Context;

export function FlexibilityOptionPanelProvider({
    children,
    designAttributeName,
    getOptionsData,
    forcePreviewFallback,
    previewScenes,
    quantityAttributes,
    displayPreview
}: FlexibilityOptionPanelProviderProps) {
    const { getDesignAttributeValues, displayedDesignAttributeName } = useActiveFlexibilityOptions();
    const availableDesignAttributes = getDesignAttributeValues(designAttributeName);
    const designAttributeMappings = useAppSelector(state => state.designAttributeMappings);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const [currentDesignAttributeValue, setCurrentDesignAttributeValue] = useState<string>("");

    const productOptionName = mapDesignAttributeToProductOption(designAttributeMappings, designAttributeName);
    const productOptionValue = studioSelectedProductOptions[productOptionName];
    const designAttributeValue = mapProductOptionValueToDesignAttributeValue(
        designAttributeMappings,
        productOptionName,
        productOptionValue
    );
    const optionData = useMemo(
        () => availableDesignAttributes && getOptionsData?.(availableDesignAttributes),
        [getOptionsData, availableDesignAttributes]
    );

    const contextObject = useMemo(
        () => ({
            currentDesignAttributeValue,
            setCurrentDesignAttributeValue,
            optionData,
            designAttributeName,
            availableDesignAttributes,
            forcePreviewFallback: forcePreviewFallback || false,
            previewScenes,
            quantityAttributes,
            displayPreview
        }),
        [
            currentDesignAttributeValue,
            optionData,
            designAttributeName,
            availableDesignAttributes,
            forcePreviewFallback,
            previewScenes,
            quantityAttributes,
            displayPreview
        ]
    );

    useEffect(() => {
        if (displayedDesignAttributeName) {
            setCurrentDesignAttributeValue(designAttributeValue);
        }
    }, [designAttributeValue, displayedDesignAttributeName]);

    return <Provider value={contextObject}>{children}</Provider>;
}

FlexibilityOptionPanelProvider.displayName = "FlexibilityOptionPanelProvider";
