import React, { useEffect, useState } from "react";
import { LegacyModalDialog } from "@vp/swan";
import { useAppSelector, useAppDispatch, setShowFontBrowser } from "@shared/redux";
import { FontFlavor } from "@design-stack-ct/font-sdk";
import { FontBrowserModalContent } from "./FontBrowserModalContent";
import "./FontBrowserModal.scss";
import { FontSimpleStyle } from "./FontSimpleStyle";

export interface FontBrowserModalProps {
    onDismiss?: (openTime: number | null) => void;
    onUpdateRecentFonts: (fontFamily: string | undefined) => void;
    updateLastSelectedFont: (fontFamily: string) => void;
    lastSelectedFontFamily?: string;
    onCategoryClick?: (categoryValue: string) => void;
    recentFonts: string[];
    getFontVariant: (fontFamily: string) =>
        | {
              Bold: boolean;
              Italic: boolean;
              BoldItalic: boolean;
          }
        | undefined;
    getSelectedText: (pangram: string) => string | undefined;
    appliedWeights: {
        bold: boolean;
        italic: boolean;
    };
    onUpdateFont: (fontFamily: string, style?: FontSimpleStyle) => void;
    selectedFontFamily?: string;
    fontFlavor: FontFlavor;
}

export function FontBrowserModal(props: FontBrowserModalProps) {
    const { onDismiss, onUpdateRecentFonts, lastSelectedFontFamily, ...rest } = props;
    const isOpen = useAppSelector(state => state.showFontBrowser);
    const [openTime, setOpenTime] = useState<number | null>(null); // used to track time spent in browser
    const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isOpen) {
            setOpenTime(performance.now());
        }
    }, [isOpen]);

    const handleDismiss = () => {
        dispatch(setShowFontBrowser(false));
        onUpdateRecentFonts(lastSelectedFontFamily);
        setFocusedIndex(null);
        onDismiss?.(openTime);
        setOpenTime(null);
    };

    return (
        <LegacyModalDialog isOpen={isOpen} onRequestDismiss={handleDismiss} data-dcl-prevent-canvas-items-deselection>
            <FontBrowserModalContent {...rest} focusedIndex={focusedIndex} setFocusedIndex={setFocusedIndex} />
        </LegacyModalDialog>
    );
}

FontBrowserModal.displayName = "FontBrowserModal";
