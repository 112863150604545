import type { MCP } from "@vp/types-ddif";
import { useEffect, useState } from "react";
import { getStudioConfigFromCalciferV2 } from "@shared/utils/Calcifer";
import { useAppSelector } from "@shared/redux";
import { useIsMailingPostCard } from "@shared/features/MailingServices";

export const useSurfaceDataCalcifer = () => {
    const [surface, setSurface] = useState<MCP.SurfaceSpecificationModelsV3CalculatedPathGroup | undefined | null>(
        null
    );
    const isMailingPostCard = useIsMailingPostCard();

    const locale = useAppSelector(state => state.locale);
    const mpvId = useAppSelector(state => state.mpvId);
    const productVersion = useAppSelector(state => state.productVersion);
    const customerSelectedProductOptions = useAppSelector(state => state.customerSelectedProductOptions);
    const quantity = useAppSelector(state => state.quantity);
    const productKey = useAppSelector(state => state.productKey);
    const studioSelectedProductOptions = useAppSelector(state => state.customerSelectedProductOptions);

    useEffect(() => {
        const getMaskData = async () => {
            const productConfig = await getStudioConfigFromCalciferV2(
                productKey,
                mpvId,
                productVersion,
                customerSelectedProductOptions,
                studioSelectedProductOptions,
                quantity,
                locale,
                null,
                false,
                null,
                null
            );
            const surface = productConfig.designViews.designViews[1]?.docAdditionalData?.masks?.find(
                (mask: any) => mask.pathType === "BLEED"
            );
            setSurface(surface);
        };
        if (productKey && isMailingPostCard) {
            getMaskData();
        }
    }, [
        productKey,
        locale,
        customerSelectedProductOptions,
        mpvId,
        productVersion,
        quantity,
        studioSelectedProductOptions,
        isMailingPostCard
    ]);
    return surface;
};
