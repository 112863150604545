import React, { useCallback, useState } from "react";
import { Typography, Button } from "@vp/swan";
import { LoadingSpinner, SpinnerVariant, StickyActionBar } from "@shared/features/StudioChrome";
import {
    useStudioFlexibility,
    useActiveFlexibilityOptions,
    useFlexibilityMerchandisingData
} from "@shared/features/Flexibility";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import {
    fireDesignToolTrackingEvent,
    fireUserInteractionTrackingEvent,
    STUDIO_TRACKING_EVENTS
} from "@shared/utils/Tracking";
import { useAppSelector, useAppDispatch, autoClosableAlert, setAlerts, setTemplate } from "@shared/redux";
import {
    mapDesignAttributeToProductOption,
    mapDesignAttributeValueToProductOptionValue,
    mapProductOptionValueToDesignAttributeValue
} from "@shared/utils/Product";
import { formatPrice } from "@shared/utils/Pricing";
import { usePricing } from "@shared/features/Pricing";
import classNames from "classnames";
import { FlexibilityOptionSet } from "./FlexibilityOptionSet";
import { useFlexibilityOptionPanel } from "./FlexibilityOptionPanelProvider";
import { FlexibilityBackButton } from "./FlexibilityBackButton";
import { useFlexibilityDesignDocuments } from "../hooks/useFlexibilityDesignDocuments";
import { useFlexibilityOptionCost } from "../hooks/useFlexibilityOptionCost";
import * as styles from "./FlexibilityOptionPanelContent.module.scss";

interface Props {
    onContinueClick?: () => void;
    headerText: string;
    descriptionText: string;
    loadingText: string;
    changeOptionText: string;
    changeAlertKey?: string;
    onSaveOrCancel?: () => void;
    backButtonLabel?: string;
}

export function FlexibilityOptionPanelContent(props: Props) {
    const {
        headerText,
        descriptionText,
        loadingText,
        changeOptionText,
        changeAlertKey,
        onContinueClick,
        onSaveOrCancel,
        backButtonLabel
    } = props;
    const { isMileStone2Enabled } = useStudioFlexibility();
    const { currentDesignAttributeValue, designAttributeName } = useFlexibilityOptionPanel();
    const { designDocuments } = useFlexibilityDesignDocuments();
    const { targetDocument } = designDocuments[currentDesignAttributeValue] || {};
    const { setDisplayedDesignAttributeName, loadNewProductForFlexibility } = useActiveFlexibilityOptions();
    const getFlexibilityMerchandisingData = useFlexibilityMerchandisingData();
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const locale = useAppSelector(state => state.locale);
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const quantity = useAppSelector(state => state.quantity);
    const template = useAppSelector(state => state.template);
    const designAttributeMappings = useAppSelector(state => state.designAttributeMappings);
    const isFullBleed = useAppSelector(state => state.isFullBleed);
    const { pricing } = usePricing();
    const { getCost } = useFlexibilityOptionCost();
    const { isSmall } = useStudioLayout();
    const dispatch = useAppDispatch();
    const isBackButtonEnabled = isSmall && isMileStone2Enabled && backButtonLabel;

    const [loader, setLoader] = useState(false);

    const closePanel = useCallback(() => {
        setDisplayedDesignAttributeName(undefined);
    }, [setDisplayedDesignAttributeName]);

    const onContinue = useCallback(async () => {
        const startTime = performance.now();
        const productOptionName = mapDesignAttributeToProductOption(designAttributeMappings, designAttributeName);
        const productOptionValue = mapDesignAttributeValueToProductOptionValue(
            designAttributeMappings,
            designAttributeName,
            currentDesignAttributeValue
        );
        if (studioSelectedProductOptions[productOptionName] !== productOptionValue) {
            const previousDesignAttribute = mapProductOptionValueToDesignAttributeValue(
                designAttributeMappings,
                designAttributeName,
                studioSelectedProductOptions[productOptionName]
            );
            setLoader(true);
            if (targetDocument) {
                let updatedTemplate = template;
                const {
                    metadata: {
                        documentSources: { panels }
                    }
                } = targetDocument as any;
                const templateId = panels.length > 0 && panels[0]?.data;
                if (templateId && templateId !== template) {
                    dispatch(setTemplate(templateId));
                    updatedTemplate = templateId;
                }
                const newProductOptions = {
                    productKey,
                    locale,
                    customerSelectedProductOptions: {
                        ...studioSelectedProductOptions,
                        [productOptionName]: productOptionValue
                    },
                    productVersion: productVersion ?? undefined,
                    quantity,
                    template: updatedTemplate ?? undefined,
                    targetDocument,
                    isFullBleed,
                    saveWork: true
                };
                await Promise.resolve(loadNewProductForFlexibility(newProductOptions));
            }
            setLoader(false);

            const attributesAndValues = getFlexibilityMerchandisingData(designAttributeName, productOptionValue);
            let translatedValue = productOptionValue;
            if (attributesAndValues) {
                translatedValue = attributesAndValues.merchandisingName;
            }

            let price = "";
            const cost = getCost(designAttributeName, currentDesignAttributeValue);
            if (pricing && cost) {
                price = formatPrice(cost.discountPrice, locale, pricing.currency);
            }

            if (changeAlertKey) {
                // show toast having price info only when pricing available
                const alertKey = price ? `${changeAlertKey}WithPrice` : changeAlertKey;
                dispatch(
                    setAlerts({
                        alerts: [
                            {
                                key: alertKey,
                                variables: { [designAttributeName]: translatedValue, price },
                                skin: "positive"
                            }
                        ]
                    })
                );
                dispatch(autoClosableAlert());
            }

            fireDesignToolTrackingEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.CLICK_CTA_BUTTON_ON_CHANGE_DESIGN_ATTRIBUTE_MODAL,
                label: "clicked cta button on the change design attribute modal",
                extraData: () => ({
                    designAttributeName,
                    designAttributeValue: currentDesignAttributeValue,
                    oldDesignAttributeValue: previousDesignAttribute
                })
            } as any);
        }

        onContinueClick?.();
        onSaveOrCancel?.();
        closePanel();

        const endTime = performance.now();
        fireUserInteractionTrackingEvent(`ChangeFlexibility:${designAttributeName}`, endTime - startTime);
    }, [
        designAttributeMappings,
        designAttributeName,
        currentDesignAttributeValue,
        studioSelectedProductOptions,
        onContinueClick,
        onSaveOrCancel,
        closePanel,
        targetDocument,
        getFlexibilityMerchandisingData,
        getCost,
        pricing,
        changeAlertKey,
        template,
        productKey,
        locale,
        productVersion,
        quantity,
        isFullBleed,
        loadNewProductForFlexibility,
        dispatch
    ]);
    return (
        <>
            <div className={classNames("flexibility-option-panel-right-content", styles.optionPanelRightContent)}>
                <div className="flexibility-option-panel-header">
                    {isBackButtonEnabled && (
                        <FlexibilityBackButton
                            className={styles.backButton}
                            label={backButtonLabel}
                            onClick={closePanel}
                        />
                    )}
                    <Typography className={styles.modalHeaderText} fontSize="4" textAlign="left" fontWeight="normal">
                        {headerText}
                    </Typography>
                    <Typography className={isSmall ? styles.smallFont : styles.modalDescriptionText} mt={2}>
                        {descriptionText}
                    </Typography>
                </div>
                <FlexibilityOptionSet />
            </div>
            <StickyActionBar>
                <Button
                    skin="primary"
                    size={isSmall ? "mini" : "standard"}
                    width="full-width"
                    onClick={onContinue}
                    disabled={!targetDocument || loader}
                >
                    <span className={styles.ctaLoadingSpinner}>
                        {loader && (
                            <LoadingSpinner variant={SpinnerVariant.Button} centering={true} aria-label="Loading" />
                        )}
                    </span>
                    {loader ? loadingText : changeOptionText}
                </Button>
            </StickyActionBar>
        </>
    );
}
FlexibilityOptionPanelContent.displayName = "FlexibilityOptionPanelContent";
