import React, { useCallback, useEffect } from "react";
import { LegacyModalDialog, LegacyModalDialogContent } from "@vp/swan";
import { useTrackEvents } from "@shared/features/Tracking";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useAppSelector } from "@shared/redux";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useDesignRequirementsContext } from "@shared/features/Product";
import type { TriggerCanvasChange } from "@shared/utils/DesignPanel";
import { usePreviewsContext } from "@shared/features/Previews";
import { DesignPanelType, useDesignDialog } from "@shared/features/DesignPanel";
import { NextStepButtonProps } from "@shared/features/GlobalHeader";
import { ChangeConfirmationModal } from "./ChangeConfirmationDialog";
import { DesignPanelDialogBody } from "./DesignPanelDialogBody";
import { DesignPanelAction, getDesignPanelAction } from "../utils";
import * as styles from "./DesignPanelDialog.module.scss";

interface Props {
    triggerCanvasChange: TriggerCanvasChange;
    NextStepButton: (props: NextStepButtonProps) => JSX.Element;
}

/**
 * Dialog that shows all the options for changing a canvas.
 */
export const DesignPanelDialog = ({ triggerCanvasChange, NextStepButton }: Props) => {
    const { designPanelType, setDesignPanelType, dialogCanvasName, setDialogCanvas, isAddDialog } = useDesignDialog();
    const { openFirstFullColorSurface } = useAppSelector(state => state.studioConfiguration);
    const { canvasSelectorUrls } = usePreviewsContext();
    const { trackEvent } = useTrackEvents();
    const isAdd = isAddDialog();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const designRequirements = useDesignRequirementsContext();
    const showPreviewState = useAppSelector(state => state.previewOpen);

    const { isSmall } = useStudioLayout();

    const closePanel = useCallback(() => {
        setDesignPanelType(DesignPanelType.None);
        setDialogCanvas(undefined);
    }, [setDesignPanelType, setDialogCanvas]);

    useEffect(() => {
        if (easelLoaded && designRequirements) {
            const { firstFullColorCanvasIndex, isEveryPanelBlank } = designRequirements;

            const canvasPreviewUrl = canvasSelectorUrls[0];

            const action = getDesignPanelAction(
                openFirstFullColorSurface,
                firstFullColorCanvasIndex,
                isEveryPanelBlank,
                canvasPreviewUrl
            );

            if (action === DesignPanelAction.ShowFirstFullColor) {
                // load studio with the first full color canvas
                triggerCanvasChange(designRequirements.panels[firstFullColorCanvasIndex].name);
            }

            if (action === DesignPanelAction.OpenUpsellModal) {
                // open upsell modal right away, with active canvas info
                setDesignPanelType(DesignPanelType.CheckoutAdd);
                setDialogCanvas(canvasPreviewUrl);
            }
        }
        // remove canvasSelectorUrls dep to prevent unwanted canvas switching
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [designRequirements, setDesignPanelType, setDialogCanvas, easelLoaded, openFirstFullColorSurface]);

    useEffect(() => {
        // Log which mode the panel was opened in + how it was opened
        if (designPanelType === DesignPanelType.None || !trackEvent) {
            return;
        }
        const selector = designPanelType === DesignPanelType.CheckoutAdd ? "NextButton" : "SideSelector";
        trackEvent({
            eventDetail: `${isAdd ? STUDIO_TRACKING_EVENTS.ADD_SIDE : STUDIO_TRACKING_EVENTS.CHANGE_SIDE}:${selector}`,
            extraData: () => ({
                dialogCanvasName
            })
        });
    }, [designPanelType, dialogCanvasName, isAdd, trackEvent]);

    return (
        <>
            <LegacyModalDialog
                className="design-panel-dialog"
                isOpen={designPanelType !== DesignPanelType.None && !showPreviewState}
                variant={isSmall ? "panel-bottom" : "panel-right"}
                onRequestDismiss={closePanel}
                takeOver={isSmall}
            >
                <LegacyModalDialogContent
                    className={styles.dialogContent}
                    padding={0}
                    data-dcl-prevent-canvas-items-deselection
                    aria-labelledby="design-panel-dialog-title"
                >
                    <DesignPanelDialogBody triggerCanvasChange={triggerCanvasChange} NextStepButton={NextStepButton} />
                </LegacyModalDialogContent>
            </LegacyModalDialog>
            <ChangeConfirmationModal />
        </>
    );
};

DesignPanelDialog.displayName = "DesignPanelDialog";
