import { addModelsToSelection } from "@designer-suite";
import { updateSelectedItems } from "@utilities";
import type { Designer, ItemSelection } from "../@types/designer";
import { useRichTextSet } from "../designer/useRichTextSet";

export function useOnFontSelect() {
    const { setWithSideEffects } = useRichTextSet();
    return (selection: ItemSelection, fontFamily: string) => {
        setWithSideEffects(addModelsToSelection(selection), "fontFamily", fontFamily);
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function onSelectWordArt(designer: Designer | undefined, selection: WordArtItem[], fontFamily: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateSelectedItems(designer, selection, (item: { setData: (arg0: any) => any; data: any }) =>
        item.setData({
            ...item.data,
            fontFamily
        })
    );
}
