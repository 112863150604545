import classNames from "classnames";
import React, { ReactNode } from "react";
import { useIsFlagEnabled } from "@shared/features/Flags";
import { WindowsOptionIcon, WindowsControlIcon, MacOptionIcon, CommandIcon } from "@shared/features/StudioChrome";
import { modifierKeyName } from "./ContentDataStructure";
import { OSName, usePlatformName } from "./usePlatformName";
import { useKeyboardShortcutContext } from "./KeyboardShortcutsContext";
import * as styles from "./KeyboardShortcutsIcon.module.scss";

interface IconProps {
    className?: string;
    alphabet?: string;
    children?: ReactNode | ReactNode[];
    iconName?: string; // use this prop if the icon is a modifier and should get updated based on OS.
}
/**
 * A component that renders icons passed as children that can be alphabets, numbers or modifieres.
 * If icon is a modifier then iconName must be passed so that it gets updated based on OS.
 */
export function KeyBoardShortcutsIcon({ alphabet, children, className, iconName }: IconProps) {
    const { OS } = usePlatformName();
    const { selectedOS } = useKeyboardShortcutContext();
    let OSBasedChildKey = children;
    const advancedTools = useIsFlagEnabled("advancedTools");
    const finalalisedOS = advancedTools ? selectedOS : OS;

    if (finalalisedOS === OSName.windows) {
        switch (iconName) {
            case modifierKeyName.alt:
                OSBasedChildKey = <WindowsOptionIcon />;
                break;
            case modifierKeyName.control:
                OSBasedChildKey = <WindowsControlIcon />;
                break;
            case modifierKeyName.option:
                OSBasedChildKey = <MacOptionIcon />;
                break;
            case modifierKeyName.command:
                OSBasedChildKey = <CommandIcon />;
                break;
            default:
                break;
        }
    }

    return (
        <div className={classNames(styles.keyboardShortcutsIconAlphabetContent, className)}>
            {alphabet || OSBasedChildKey}
        </div>
    );
}
KeyBoardShortcutsIcon.displayName = "KeyBoardShortcutsIcon";
