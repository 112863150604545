import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
}
export function LeftArrowIcon(props: Props) {
    return (
        <svg viewBox="0 0 14 10" fill="none" {...props}>
            <path
                // eslint-disable-next-line max-len
                d="M0.939941 4.99658C0.939941 5.18343 1.01286 5.34521 1.15869 5.48193L5.43799 9.75439C5.57471 9.89111 5.72965 9.95947 5.90283 9.95947C6.08512 9.95947 6.23551 9.90023 6.354 9.78174C6.47705 9.66325 6.53857 9.51514 6.53857 9.3374C6.53857 9.24626 6.52035 9.16195 6.48389 9.08447C6.45199 9.00244 6.40869 8.93408 6.354 8.87939L4.90479 7.40283L2.42334 5.14697L2.23877 5.50928L4.40576 5.646H12.4175C12.6043 5.646 12.757 5.58675 12.8755 5.46826C12.9985 5.34521 13.0601 5.18799 13.0601 4.99658C13.0601 4.80973 12.9985 4.65706 12.8755 4.53857C12.757 4.41553 12.6043 4.354 12.4175 4.354H4.40576L2.23877 4.48389L2.42334 4.85986L4.90479 2.59033L6.354 1.12061C6.40869 1.06592 6.45199 0.997559 6.48389 0.915527C6.52035 0.833496 6.53857 0.749186 6.53857 0.662598C6.53857 0.484863 6.47705 0.336751 6.354 0.218262C6.23551 0.0997721 6.08512 0.0405273 5.90283 0.0405273C5.81624 0.0405273 5.73421 0.0587565 5.65674 0.0952148C5.57926 0.127116 5.49951 0.179525 5.41748 0.252441L1.15869 4.51807C1.01286 4.65479 0.939941 4.81429 0.939941 4.99658Z"
                fill="currentColor"
            />
        </svg>
    );
}
LeftArrowIcon.displayName = "LeftArrowIcon";
