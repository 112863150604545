import { FlexBox } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import React, { useRef } from "react";
import { ExpandingButton, HorizontalScroller } from "@shared/features/StudioChrome";
import type { ExpandingButtonHandles } from "@shared/features/StudioChrome";
import {
    ColorPalette,
    ColorSwatch,
    type SelectableColor,
    type EmbroiderySelectableColor
} from "@shared/features/ColorPicker";
import { ResetButton } from "./ResetButton";
import { embroideryMessages } from "./embroideryMessages";
import "./embroideryImageColorModal.scss";

interface Props {
    recentColors: SelectableColor[];
    paletteColors: SelectableColor[];
    currentColors: EmbroiderySelectableColor[] | undefined;
    onChange: (color: string) => void;
    resetColors: () => void;
    setOrdinalToUpdate: (ordinal: number) => void;
    ordinalToUpdate: number;
}

export function DesktopEmbroideryColorPalette({
    recentColors,
    paletteColors,
    currentColors,
    onChange,
    resetColors,
    setOrdinalToUpdate,
    ordinalToUpdate
}: Props) {
    const { t } = useTranslationSSR();
    const buttonRef = useRef<ExpandingButtonHandles>(null);

    const handleColorSelection = (color: string) => {
        onChange(color);
        if (buttonRef.current) {
            buttonRef.current.close();
        }
    };

    return (
        <FlexBox
            // eslint-disable-next-line max-len
            className="embroidery-image-color-modal__container embroidery-image-color-modal__container--short embroidery-image-color-modal__container--stacked embroidery-image-color-modal__desktop-container"
            alignItems="center"
            justifyContent="space-between"
        >
            <HorizontalScroller>
                <FlexBox>
                    {currentColors?.map(color => {
                        return (
                            <ExpandingButton
                                className="embroidery-image-color-modal__desktop-current-color"
                                key={color.ordinal}
                                content={
                                    <ColorPalette
                                        paletteColors={paletteColors}
                                        onSelectColor={handleColorSelection}
                                        recentColors={recentColors}
                                        selectedColor={color.value}
                                    />
                                }
                                showArrow={true}
                                overridePopperAlignment="start"
                                title={t(embroideryMessages.chooseColorAriaLabel.id)}
                                onOpen={() => setOrdinalToUpdate(color.ordinal)}
                                ref={ordinalToUpdate === color.ordinal ? buttonRef : null}
                            >
                                <ColorSwatch
                                    component="span"
                                    className="embroidery-image-color-modal__desktop-color-swatch"
                                    color={color}
                                    toggleColor={() => {}}
                                />
                            </ExpandingButton>
                        );
                    })}
                </FlexBox>
            </HorizontalScroller>
            <div className="embroidery-image-color-modal__reset-button-container">
                <ResetButton resetColors={resetColors} />
            </div>
        </FlexBox>
    );
}

DesktopEmbroideryColorPalette.displayName = "DesktopEmbroideryColorPalette";
