import React from "react";
import classNames from "classnames";
import {
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogBody,
    LegacyModalDialogTitle,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton,
    AlertBox
} from "@vp/swan";
import * as styles from "./MobilePanel.module.scss";

type modalDialogProps = React.ComponentProps<typeof LegacyModalDialog>;
type modalDialogContentProps = React.ComponentProps<typeof LegacyModalDialogContent>;
type modalDialogBodyProps = React.ComponentProps<typeof LegacyModalDialogBody>;
type modalDialogCloseButtonProps = React.ComponentProps<typeof LegacyModalDialogCloseButton>;
export interface MobilePanelProps extends modalDialogProps {
    show?: modalDialogProps["isOpen"];
    title: string;
    customHeader?: JSX.Element | null;
    children: modalDialogBodyProps["children"];
    fullBleed?: modalDialogContentProps["fullBleed"];
    noCloseButton?: boolean;
    onClose?: modalDialogProps["onRequestDismiss"];
    ariaLabel?: modalDialogContentProps["aria-label"];
    ariaLabelledBy?: modalDialogContentProps["aria-labelledby"];
    ariaCloseLabel?: modalDialogCloseButtonProps["aria-label"];
    testId?: string;
    warning?: string;
    modalDialogBodyClass?: string;
}

export function MobilePanel({
    show,
    title,
    customHeader = null,
    children,
    fullBleed = false,
    noCloseButton = false,
    onClose,
    ariaLabel,
    ariaLabelledBy,
    ariaCloseLabel,
    testId,
    warning,
    modalDialogBodyClass,
    ...rest
}: MobilePanelProps) {
    return (
        <LegacyModalDialog {...rest} isOpen={show || rest.isOpen} onRequestDismiss={onClose || rest.onRequestDismiss}>
            <LegacyModalDialogContent
                fullBleed={fullBleed}
                aria-label={ariaLabelledBy ? undefined : title || ariaLabel}
                aria-labelledby={ariaLabelledBy}
                data-testid={testId}
                className={"mobile-panel-content modal-dialog-content"}
                data-dcl-prevent-canvas-items-deselection
            >
                {!noCloseButton && <LegacyModalDialogCloseButton visuallyHiddenLabel={ariaCloseLabel} />}

                {warning && (
                    <AlertBox className={"modal-warning"} skin="warning">
                        {warning}
                    </AlertBox>
                )}
                {customHeader ||
                    (title && (
                        <LegacyModalDialogHeader className={styles.mobilePanelHeader}>
                            <LegacyModalDialogTitle>{title}</LegacyModalDialogTitle>
                        </LegacyModalDialogHeader>
                    ))}
                <LegacyModalDialogBody className={classNames("modal-dialog-body", modalDialogBodyClass)}>
                    {children}
                </LegacyModalDialogBody>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}
MobilePanel.displayName = "MobilePanel";
