import React, { useState, useEffect } from "react";
import { Button, AlertBox, ButtonProps } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { isCareAgent, isCareAgentEditingCareAgentDocument } from "@shared/utils/Care";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { Dispatch } from "redux";
import { newRelicWrapper, handleError, ERROR_CODES } from "@shared/utils/Errors";
import { Store, useAppDispatch, useAppSelector, showLoader, setError, setDocument } from "@shared/redux";
import { getNextStep, fireImpressionsForContexts } from "@shared/utils/SiteFlow";
import {
    fireDesignToolTrackingEvent,
    fireGenericTrackingEvent,
    fireUserInteractionTrackingEvent,
    fireAddToCartPageTrackingEvent,
    STUDIO_TRACKING_EVENTS
} from "@shared/utils/Tracking";
import { Identity } from "@shared/utils/Identity";
import { usePageContext } from "@shared/features/StudioConfiguration";
import { saveWorkEntity, saveWorkInSession } from "@shared/utils/Work";
import { useCartContext } from "@shared/features/Cart";
import { callUpsertCart, updateCart } from "@shared/utils/Cart";
import { getQueryParams, addQueryParams } from "@shared/utils/WebBrowser";
import { UdsResponse } from "@shared/utils/DocumentStorage";
import type { SaveDesignWithSaveDocumentOmitted } from "@shared/features/Save";
import { isNavigateToReturnUrlEnabled } from "@shared/features/Navigation";

const params = getQueryParams();

const messages = defineMessages({
    addToCartApprovalAlert: {
        id: "studio.components.addToCartButton.addToCartApprovalAlert",
        defaultMessage: "Please check the approval box to indicate that you approve this design.",
        description: {
            note: "The alert if the user tries to add to cart without first approving"
        }
    },
    continueButton: {
        id: "studio.components.addToCartButton.continueButton",
        defaultMessage: "Continue"
    },
    gettingYourDesignReady: {
        id: "studio.components.spinners.gettingYourDesignReady",
        defaultMessage: "Getting your design ready...",
        description: {
            note: "Spinner text shown when hitting continue button on studio review page"
        }
    }
});

async function handleContinue(
    saveDesign: SaveDesignWithSaveDocumentOmitted,
    locale: string,
    productKey: string,
    productVersion: number | null,
    studioSelectedProductOptions: any,
    identity: Identity,
    authToken: any,
    documentPromise: undefined | Promise<any>,
    dispatch: Dispatch<any>,
    cartUrl: string | undefined,
    isItemInCart: boolean,
    isQuantityPageEnabled: any,
    isConnectedCard: boolean,
    loadingMessage: any,
    mpvId: string | null
) {
    try {
        const startTime = performance.now();

        // @ts-ignore type inference from Action is incorrect
        dispatch(setError({}));
        dispatch(showLoader(loadingMessage));

        const existingDocument = await documentPromise;

        const work = await (existingDocument
            ? saveWorkEntity({
                  authToken,
                  identity,
                  udsResponse: existingDocument,
                  savedProjectTrackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_CONTINUE_BUTTON
              })
            : saveDesign({
                  authToken,
                  identity,
                  savedProjectTrackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_CONTINUE_BUTTON
              }));
        saveWorkInSession(work);

        const quantity = work.merchandising.quantity || params.qty;

        if (isCareAgentEditingCareAgentDocument()) {
            // eslint-disable-next-line no-alert
            alert("Document has been saved successfully");
            return;
        }

        // If user is care agent tell them that work has been added to cart and they should use care tools
        // to look at users cart
        if (isCareAgent()) {
            // Only track if the item is not already in cart
            if (!isItemInCart) {
                fireAddToCartPageTrackingEvent();
            }

            const ownerId = identity.shopperId || identity.anonymousUserId;
            await callUpsertCart(authToken, locale, ownerId, work, studioSelectedProductOptions, mpvId);

            // eslint-disable-next-line no-alert
            alert("The Item has been saved to the customer's cart");
            return;
        }

        newRelicWrapper.logPageAction("studio-exit");
        fireGenericTrackingEvent({
            event: "Studio Continue",
            eventDetail: "Continue Button Clicked",
            label: "Continue Button",
            extraData: () => ({
                workId: work.workId,
                editFromCartFlow: isItemInCart
            })
        });

        const { nextStepUrl, document } = Store.getState();

        // if item is already in cart, they have already gone through pdc etc
        // so take them straight to cart
        if (isItemInCart && cartUrl) {
            try {
                await updateCart({
                    authToken,
                    locale,
                    work,
                    shopperId: identity.shopperId,
                    anonymousId: identity.anonymousUserId,
                    isQuantityPageEnabled,
                    isConnectedCard,
                    studioSelectedProductOptions,
                    mpvId
                });
            } catch (error) {
                handleError(error, ERROR_CODES.ADD_TO_CART_ERROR);
            }
            window.location.href = cartUrl;
        } else if (isNavigateToReturnUrlEnabled() && params.returnUrl) {
            const selectedOptions = { ...params.selectedOptions };
            if (typeof quantity !== "undefined") {
                selectedOptions.Quantity = quantity;
            }
            const newRetUrl = addQueryParams(params.returnUrl, {
                key: productKey,
                version: productVersion,
                selectedAttributes: JSON.stringify(selectedOptions),
                qty: typeof quantity !== "undefined" ? quantity : null,
                docRef: `${existingDocument.url || work.design.designUrl || document.documentUrl}/docRef`,
                workId: work.workId
            });
            window.location.href = newRetUrl;
        } else if (!nextStepUrl?.url) {
            if (productVersion === null) {
                throw Error("Product version is not defined");
            }
            const result = await getNextStep(productKey, productVersion, studioSelectedProductOptions, locale);
            if (!result.url) {
                newRelicWrapper.logPageAction("studio-no-next-step-url", { nextStep: JSON.stringify(result) });
            }
            await fireImpressionsForContexts(result.context);
            window.location.href = result?.url?.replace("{workId}", work.workId);
        } else {
            await fireImpressionsForContexts(nextStepUrl.context);
            window.location.href = nextStepUrl.url.replace("{workId}", work.workId);
        }
        const endTime = performance.now();
        const timeElapsed = endTime - startTime;
        fireUserInteractionTrackingEvent("Click Continue to Cart", timeElapsed, { editFromCartFlow: isItemInCart });
    } catch (e) {
        newRelicWrapper.logPageAction("studio-fail-add-to-cart", { cartError: JSON.stringify(e) });
        handleError(e, ERROR_CODES.ADD_TO_CART_ERROR);
    }
}

type ContinueButtonProps = Omit<
    ButtonProps,
    "data-testid" | "onClick" | "id" | "data-translationid" | "skin" | "ref"
> & {
    hasBeenReviewed: boolean;
    hasSpecsAndTemplate?: boolean;
    saveDocument: (authToken: string, updateStore?: boolean | undefined) => Promise<UdsResponse>;
    saveDesign: SaveDesignWithSaveDocumentOmitted;
};

export function ContinueButton({
    hasBeenReviewed,
    hasSpecsAndTemplate,
    saveDocument,
    saveDesign,
    ...rest
}: ContinueButtonProps) {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [documentPromise, setDocumentPromise] = useState<Promise<any> | undefined>();
    const { identity, auth } = useIdentityContext();
    // @ts-ignore type inference is incorrect
    const { cartUrl } = usePageContext();
    const { isItemInCart } = useCartContext();
    const { t } = useTranslationSSR();
    const locale = useAppSelector(state => state.locale);
    const mpvId = useAppSelector(state => state.mpvId);
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const isQuantityPageEnabled = useAppSelector(state => state.isQuantityPageEnabled);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const specsAndTemplateModalViewed = useAppSelector(state => state.specsAndTemplateModalViewed);
    const dispatch = useAppDispatch();

    // Preload a document save when the Review Step is rendered
    useEffect(() => {
        let isCurrent = true;
        async function saveToUds() {
            const udsDocumentPromise = saveDocument(auth.getToken());
            // set the promise immediately so that we can use it if the user clicks continue before this call is finished
            setDocumentPromise(udsDocumentPromise);
            const udsDocument = await udsDocumentPromise;
            // wait 3 seconds before updating state - fido will do a bunch of crap once we update, and we want to make sure
            // the previews have no competition
            setTimeout(() => {
                if (isCurrent) {
                    dispatch(setDocument(udsDocument));
                }
            }, 3000);
        }
        saveToUds();
        return () => {
            isCurrent = false;
        };
    }, [dispatch, auth, saveDocument]);

    useEffect(() => {
        if (showErrorMessage) {
            setShowErrorMessage(!hasBeenReviewed);
        }
    }, [hasBeenReviewed, showErrorMessage]);

    const handleClick = () => {
        if (hasBeenReviewed) {
            newRelicWrapper.logPageAction("studio-design-approved");
            fireDesignToolTrackingEvent({
                eventDetail: "DesignApproved",
                label: "General",
                extraData: () => ({
                    specsAndTemplateModalViewed: hasSpecsAndTemplate ? specsAndTemplateModalViewed : undefined
                })
            });
        }
        if (hasBeenReviewed || sessionStorage.getItem("was_inside_next_dialog")) {
            const isConnectedCard = studioSelectedProductOptions.Connection === "NFC";
            handleContinue(
                saveDesign,
                locale,
                productKey,
                productVersion,
                studioSelectedProductOptions,
                identity,
                auth.getToken(),
                documentPromise,
                dispatch,
                cartUrl,
                isItemInCart,
                isQuantityPageEnabled,
                isConnectedCard,
                t(messages.gettingYourDesignReady.id),
                mpvId
            );
        } else {
            setShowErrorMessage(true);
        }
    };

    return (
        <>
            {showErrorMessage && (
                <AlertBox
                    data-testid="approvalAlert"
                    skin="error"
                    marginBottom={4}
                    data-translationid={messages.addToCartApprovalAlert.id}
                >
                    {t(messages.addToCartApprovalAlert.id)}
                </AlertBox>
            )}
            <Button
                data-testid="continueButton"
                onClick={handleClick}
                id="approveAndContinueButton"
                data-translationid={messages.continueButton.id}
                skin="primary"
                width="full-width"
                {...rest}
            >
                {t(messages.continueButton.id)}
            </Button>
        </>
    );
}
ContinueButton.displayName = "ContinueButton";
