import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { SelectedCheckIcon } from "@shared/features/StudioChrome";
import { Typography, Button, FlexBox } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { FontPreview } from "@shared/features/ContextualToolbar";
import { fontWeightTranslation } from "./fontWeightTranslation";
import { type FontSimpleStyle } from "./FontSimpleStyle";

interface Props {
    selectedText: string;
    fontFamily: string;
    fontStyle?: FontSimpleStyle;
    appliedWeights?: { bold: boolean; italic: boolean };
    handleClick: (fontFamily: string, index: number, style?: FontSimpleStyle) => void;
    isSelected: boolean;
    handleKeyDown: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
    isFocused: boolean;
    index: number;
    recentFonts: string[];
}

export function FontBrowserButton(props: Props) {
    const {
        selectedText,
        fontFamily,
        fontStyle,
        appliedWeights,
        handleClick,
        isSelected,
        handleKeyDown,
        isFocused,
        index,
        recentFonts
    } = props;
    const buttonRef = useRef<HTMLButtonElement>(null);
    const cimDocStyle = fontStyle === "bolditalic" ? "bold,italic" : fontStyle;
    const { t } = useTranslationSSR();

    const selectedStyle = () => {
        if (isSelected && appliedWeights) {
            const { bold, italic } = appliedWeights;
            switch (fontStyle) {
                case "bolditalic":
                    return bold && italic;
                    break;
                case "bold":
                    return bold && !italic;
                case "italic":
                    return italic && !bold;
                case "normal":
                    return !italic && !bold;
                default:
                    return false;
                    break;
            }
        }
        return false;
    };

    useEffect(() => {
        if (isFocused) {
            buttonRef.current?.focus();
        }
    }, [isFocused]);

    useEffect(() => {
        if (
            isSelected &&
            !recentFonts.includes(fontFamily) &&
            buttonRef.current?.parentElement?.parentElement?.scrollTo
        ) {
            // Remove 45 from the scroll to account for category label covering the button
            buttonRef.current.parentElement.parentElement.scrollTo({
                top: buttonRef.current?.offsetTop - 45
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Button
            ref={buttonRef}
            skin="unstyled"
            className={classNames("font-browser__button", {
                "font-browser__button--selected": fontStyle ? isSelected && selectedStyle() : isSelected
            })}
            mb={2}
            onClick={() => handleClick(fontFamily, index, fontStyle)}
            onKeyDown={handleKeyDown}
        >
            <FlexBox>
                <Typography fontSize="-1" textColor="dark-grey" mb={2}>
                    {fontStyle ? t(fontWeightTranslation(fontStyle)) : fontFamily}
                </Typography>
            </FlexBox>
            <FlexBox alignItems="center" justifyContent="space-between">
                <FontPreview text={selectedText || ""} fontFamily={fontFamily} altText="" fontStyle={cimDocStyle} />
                <FlexBox alignItems="center" className="font-browser__right-aligned-items">
                    <div className="font-browser__preview-image-blur" />
                    <SelectedCheckIcon className="font-browser__icon" />
                </FlexBox>
            </FlexBox>
        </Button>
    );
}

FontBrowserButton.displayName = "FontBrowserButton";
