import React, { useCallback } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { FlexBox, Hidden, H3 } from "@vp/swan";
import classNames from "classnames";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { StartFromScratchDesignTile } from "./Tiles/StartFromScratchDesignTile";
import { DuplicateDesignTile } from "./Tiles/DuplicateDesignTile";
import { designPanelMessages } from "../designPanelMessages";
import { TemplateDesignTile } from "./Tiles/TemplateDesignTile";
import { FullbleedDesignTile } from "./Tiles/FullbleedDesignTile";
import { useDesignDialog } from "../DesignDialogProvider";
import { useShouldShowDuplicateFirstCanvasButton } from "../hooks/useShouldShowDuplicateFirstCanvasButton";
import * as styles from "./DesignTilesPanel.module.scss";
import { useStudioFlexibility } from "../../Flexibility";
import { useGetDesignTileTemplates } from "../hooks/useGetDesignTileTemplates";

/**
 * List of all the design tile options that appears on the design tile dialog.
 */

export function DesignTilesPanel() {
    const { dialogCanvasName } = useDesignDialog();
    const { title } = designPanelMessages;
    const { t } = useTranslationSSR();
    const designRequirements = useDesignRequirementsContext();
    const panel = designRequirements?.getPanelByName(dialogCanvasName);
    const { validTemplates, fullBleed } = useGetDesignTileTemplates();

    const isWideCanvas = useCallback(() => {
        return panel && panel.width >= panel.height;
    }, [panel]);

    const size = isWideCanvas() ? styles.wide : styles.tall;
    const heightRatio = (panel ? panel.height / panel.width : 1) * 100;

    const hasDuplicateFirstCanvasButton = useShouldShowDuplicateFirstCanvasButton(dialogCanvasName);
    const { isXerox } = useXerox();
    const { isMileStone3Enabled } = useStudioFlexibility();

    return (
        <>
            <FlexBox
                flexWrap="wrap"
                className={classNames(styles.options, "design-panel-tile-section-options", {
                    wide: !hasDuplicateFirstCanvasButton
                })}
                aria-label={t(designPanelMessages.designPanelTileSelection.id)}
                role="radiogroup"
            >
                <DuplicateDesignTile className={styles.designButton} />
                <FullbleedDesignTile imageHeight={heightRatio} className={styles.designButton} />
                <StartFromScratchDesignTile imageHeight={heightRatio} className={styles.designButton} />
            </FlexBox>
            {validTemplates.length > 0 && (
                <>
                    <Hidden xs>
                        <H3
                            paddingY={4}
                            marginBottom={0}
                            fontWeight={isXerox || isMileStone3Enabled ? "normal" : "bold"}
                            className={styles.sectionHeading}
                        >
                            {t(title.id)}
                        </H3>
                    </Hidden>
                    <FlexBox
                        className={classNames(styles.templates, "design-panel-tile-section-templates", size)}
                        flexWrap="wrap"
                        role="radiogroup"
                    >
                        {validTemplates.map((template: any) =>
                            template !== fullBleed ? (
                                <TemplateDesignTile
                                    key={template.templateToken}
                                    template={template}
                                    className={styles.designButton}
                                />
                            ) : null
                        )}
                    </FlexBox>
                </>
            )}
        </>
    );
}
DesignTilesPanel.displayName = "DesignTilesPanel";
