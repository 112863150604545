import { keyboardShortcutEvents, useKeyboardShortcuts } from "@shared/features/KeyboardShortcuts";
import { useCallback, useEffect } from "react";
import { useAddShape, SHAPES } from "../../hooks/useAddShape";
import useAddText from "../../hooks/useAddText";

function KeyboardShortcutContainer() {
    const { on, off } = useKeyboardShortcuts();
    const { addText } = useAddText();
    const { addShape } = useAddShape({ openGraphicsPanel: true, shouldInitIcons: true });
    const addRectangle = useCallback(() => addShape({ shapes: SHAPES.RECTANGLE_SHAPE }), [addShape]);
    const addLine = useCallback(() => addShape({ shapes: SHAPES.LINE_SHAPE }), [addShape]);
    const addCircle = useCallback(() => addShape({ shapes: SHAPES.ELLIPSE_SHAPE }), [addShape]);

    useEffect(() => {
        on(keyboardShortcutEvents.addText, addText);
        on(keyboardShortcutEvents.addRectangle, addRectangle);
        on(keyboardShortcutEvents.addLine, addLine);
        on(keyboardShortcutEvents.addCircle, addCircle);
        return () => {
            off(keyboardShortcutEvents.addText, addText);
            off(keyboardShortcutEvents.addRectangle, addRectangle);
            off(keyboardShortcutEvents.addLine, addLine);
            off(keyboardShortcutEvents.addCircle, addCircle);
        };
    }, [on, off, addShape, addRectangle, addLine, addCircle, addText]);
    return null;
}

KeyboardShortcutContainer.displayName = "KeyboardShortcutContainer";

export default KeyboardShortcutContainer;
