import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { withPrefix } from "gatsby";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Typography, FlexBox, Divider, Link } from "@vp/swan";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";
import {
    FINISH_OPTION,
    useAvailablePremiumFinishesForSurfaces,
    PremiumFinishSelectionSet
} from "@shared/features/PremiumFinish";
import {
    useAppSelector,
    useAppDispatch,
    showDesignReview,
    setShowFullBleedPremiumFinishErrorModal
} from "@shared/redux";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useDesignRequirementsContext } from "@shared/features/Product";
import type { TriggerCanvasChange } from "@shared/utils/DesignPanel";
import * as styles from "./FullBleedPremiumFinishReview.module.scss";

const messages = defineMessages({
    applyMetallic: {
        id: "studio.components.fullbleedpremiumfinishreview.apply.metallic",
        defaultMessage: "Apply foil accent finish",
        description: {
            note:
                "North America (NA) brands as Metallic as `Foil Accent`." +
                " Non NA does not use that branding - usually 'metallic' should be used instead of 'foil accent'"
        }
    },
    applyRaisedInk: {
        id: "studio.components.fullbleedpremiumfinishreview.apply.raisedink",
        defaultMessage: "Apply embossed gloss finish",
        description: {
            note:
                "North America (NA) brands raised ink as `Embossed Gloss`." +
                "Non NA does not use that branding - 'raised, glossy' or 'Spot UV' should be used instead."
        }
    },
    applyRaisedFoilGold: {
        id: "studio.components.fullbleedpremiumfinishreview.apply.raisedfoilgold",
        defaultMessage: "Apply gold embossed foil finish",
        description: {
            note:
                " North America (NA) brands Gold foil as 'Gold Embossed Foil'." +
                " Non NA does not use that branding. 'Gold Foil' should be used instead."
        }
    },
    applyRaisedFoilSilver: {
        id: "studio.components.fullbleedpremiumfinishreview.apply.raisedfoilsilver",
        defaultMessage: "Apply silver embossed foil finish",
        description: {
            note:
                " North America (NA) brands Silver foil as 'Silver Embossed Foil'." +
                " Non NA does not use that branding. 'Silver Foil' should be used instead."
        }
    },
    applyRaisedFoilGlitterSilver: {
        id: "studio.components.fullbleedpremiumfinishreview.apply.raisedfoilglittersilver",
        defaultMessage: "Apply embossed glitter finish"
    },
    finishSubheader: {
        id: "studio.components.fullbleedpremiumfinishreview.finishSubheader",
        defaultMessage: "A designer will apply the finish after checkout."
    },
    textOnly: {
        id: "studio.components.fullbleedpremiumfinishreview.option.textOnly",
        defaultMessage: "Text Only"
    },
    imageOnly: {
        id: "studio.components.fullbleedpremiumfinishreview.option.imageOnly",
        defaultMessage: "Image Only"
    },
    textAndImage: {
        id: "studio.components.fullbleedpremiumfinishreview.option.textAndImage",
        defaultMessage: "Text and Images"
    },
    changeOptions: {
        id: "studio.components.fullbleedpremiumfinishreview.changeOptions",
        defaultMessage: "Change options"
    },
    instructions: {
        id: "studio.components.fullbleedpremiumfinishreview.instructions",
        defaultMessage: "Instructions"
    },
    readMore: {
        id: "studio.components.fullbleedpremiumfinishreview.readMore",
        defaultMessage: "Read more..."
    },
    noInstructions: {
        id: "studio.components.fullbleedpremiumfinishreview.noInstructions",
        defaultMessage: "No special instructions provided."
    },
    reviewPremiumFinish: {
        id: "studio.components.fullbleedpremiumfinishreview.review",
        defaultMessage: "Full bleed premium finish review"
    }
});

const finishOptionMessages = {
    [FINISH_OPTION.TextOnly]: messages.textOnly,
    [FINISH_OPTION.ImageOnly]: messages.imageOnly,
    [FINISH_OPTION.TextAndImage]: messages.textAndImage
};

interface Props {
    hasPremiumFinishesCurrentCanvas: boolean;
    triggerCanvasChange: TriggerCanvasChange;
}

export function FullBleedPremiumFinishReview({ hasPremiumFinishesCurrentCanvas, triggerCanvasChange }: Props) {
    const dispatch = useAppDispatch();
    const { t } = useTranslationSSR();
    const { availableFinishes } = useAvailablePremiumFinishesForSurfaces();
    const finishType = availableFinishes[0];
    const { setCurrentActiveDialog } = useActiveDialog();
    const designRequirements = useDesignRequirementsContext();

    const reviewInstructions = useAppSelector(state => state.reviewInstructions);

    const instructionsRef = useRef<HTMLDivElement>(null);
    const [showReadMoreButton, setShowReadMoreButton] = useState(false);
    const [showAllText, setShowAllText] = useState(false);
    const { isSmall } = useStudioLayout();

    useEffect(() => {
        if (instructionsRef.current) {
            const visibleHeight = instructionsRef.current.clientHeight;
            const textHeight = instructionsRef.current.scrollHeight;
            if (textHeight > visibleHeight) {
                setShowReadMoreButton(true);
            }
        }
    }, [finishType]);

    if (!finishType) {
        return null;
    }

    const handleChangeOptionsClick = () => {
        if (!hasPremiumFinishesCurrentCanvas && designRequirements) {
            triggerCanvasChange(designRequirements?.getDefaultPanelName());
        }
        dispatch(showDesignReview({ show: false }));
        setCurrentActiveDialog(DialogType.FullBleedPremiumFinish, true);
    };

    if (!reviewInstructions || reviewInstructions.length === 0) {
        if (designRequirements) {
            triggerCanvasChange(designRequirements?.getDefaultPanelName());
        }
        dispatch(showDesignReview({ show: false }));
        dispatch(setShowFullBleedPremiumFinishErrorModal(true));
        return null;
    }

    const { finishOption, optionalNotes } = reviewInstructions[0];

    const { img } = PremiumFinishSelectionSet[finishType].find(
        (pfOption: { option: string }) => pfOption.option === finishOption
    );
    const image = (
        <div
            className={styles.premiumFinishReviewImage}
            style={{
                backgroundImage: `url(${ASSET_PREFIX + withPrefix(`/fullBleedPremiumFinishModal/${img}`)})`
            }}
        ></div>
    );

    return (
        <div className={styles.premiumFinishReview}>
            <Divider mt={isSmall ? 5 : 7} mb={5} />
            <FlexBox justifyContent="space-between" marginBottom={1}>
                {finishOption ? (
                    <Typography fontSize="2" fontWeight="bold">
                        {`${t(messages[`apply${finishType}`].id)}: ${t(finishOptionMessages[finishOption].id)}`}
                    </Typography>
                ) : (
                    <></>
                )}
                <Link
                    as="button"
                    paddingLeft={1}
                    className={styles.premiumFinishReviewButton}
                    onClick={handleChangeOptionsClick}
                >
                    {t(messages.changeOptions.id)}
                </Link>
            </FlexBox>
            <Typography textColor="dark-grey" marginBottom={5}>
                {t(messages.finishSubheader.id)}
            </Typography>
            <FlexBox>
                {image}
                <div className={styles.premiumFinishReviewInstructions}>
                    <Typography fontSize="1" textColor="dark-grey" fontWeight="bold">
                        {t(messages.instructions.id)}
                    </Typography>
                    {optionalNotes ? (
                        <>
                            <Typography
                                ref={instructionsRef}
                                className={classNames(styles.premiumFinishReviewInstructionsText, {
                                    [styles.premiumFinishReviewInstructionsTextUntruncated]: showAllText
                                })}
                            >
                                {optionalNotes}
                            </Typography>
                            {showReadMoreButton ? (
                                <Link
                                    as="button"
                                    onClick={() => {
                                        setShowReadMoreButton(false);
                                        setShowAllText(true);
                                    }}
                                >
                                    {t(messages.readMore.id)}
                                </Link>
                            ) : null}
                        </>
                    ) : (
                        <Typography>{t(messages.noInstructions.id)}</Typography>
                    )}
                </div>
            </FlexBox>
            <Divider marginTop={5} />
        </div>
    );
}

FullBleedPremiumFinishReview.displayName = "FullBleedPremiumFinishReview";
