import React, { useState } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";

import type { PriceQuantityData } from "@shared/utils/Calcifer";
import { SaveDesignWithSaveDocumentOmitted } from "@shared/features/Save";
import { useQuantityData } from "./useQuantityData";
import { QuantityOptionPanel } from "./QuantityOptionPanel";
import { FlexibilityOptionPanelProvider } from "../common/components/FlexibilityOptionPanelProvider";
import { FlexibilityDesignAttributes } from "../constants";
import { useGetQuantityAttributes } from "./useGetQuantityAttributes";

export const messages = defineMessages({
    modalCloseButton: {
        id: "studio.components.changeQuantity.modalCloseButton",
        defaultMessage: "Close",
        description: {
            note: "Text message for change product quantity modal close button"
        }
    },
    modalHeader: {
        id: "studio.components.changeQuantity.modalHeader",
        defaultMessage: "Choose your quantity",
        description: {
            note: "Text message for change product quantity modal header"
        }
    },
    modalChangeText: {
        id: "studio.components.changeQuantity.changeText",
        defaultMessage: "Select this quantity",
        description: {
            note: "Change product Quantity button text message in modal"
        }
    }
});

interface Props {
    saveDesign: SaveDesignWithSaveDocumentOmitted;
}

export function ChangeQuantityModal({ saveDesign }: Props) {
    const { t } = useTranslationSSR();
    const getQuantityData = useQuantityData();
    const quantityAttributes = useGetQuantityAttributes();
    const [quantityAttributesData, setQuantityAttributesData] = useState<PriceQuantityData | null>();
    quantityAttributes.then(setQuantityAttributesData);

    return (
        <FlexibilityOptionPanelProvider
            designAttributeName={FlexibilityDesignAttributes.Quantity}
            getOptionsData={getQuantityData}
            quantityAttributes={quantityAttributesData ?? undefined}
        >
            <QuantityOptionPanel
                closeButtonLabel={t(messages.modalCloseButton.id)}
                headerText={t(messages.modalHeader.id)}
                changeOptionText={t(messages.modalChangeText.id)}
                saveDesign={saveDesign}
            />
        </FlexibilityOptionPanelProvider>
    );
}
ChangeQuantityModal.displayName = "ChangeQuantityModal";
