import { useCallback } from "react";
import { DesignTileCallback } from "@shared/utils/DesignPanel";
import { documentSourceTypeMap } from "@shared/utils/DSS";
import { useDesignDialog } from "../DesignDialogProvider";
import { applyDesignOption } from "../utils";

export const useFullbleedCallback = () => {
    const { getDocument, loadNewDesign, fireInteractionTimedEvent } = useDesignDialog();

    /**
     * This function is a callback that when called, will convert the panel with panelName to a fullbleed canvas.
     * @param panelName - panel to be converted
     * @param resetting - are we resetting the panel (meaning are we going to ignore the saved panel) meaning the user would be able to start from fullbleed
     * @returns callback
     */
    return useCallback<DesignTileCallback>(
        ({ panelName, resetting }) => {
            return async (dispatch: any, getState: any) => {
                const { surfaceUpsells } = getState();
                const surfaceUpsell = surfaceUpsells[panelName];
                return applyDesignOption({
                    dispatch,
                    getState,
                    panelName,
                    resetting,
                    newDocumentSourceType: documentSourceTypeMap.FULLBLEED,
                    newDocumentSourceData: "",
                    newOptions: surfaceUpsell
                        ? {
                              [surfaceUpsell.optionName]: surfaceUpsell.colorOption
                          }
                        : undefined,
                    fireInteractionTimedEvent,
                    existingDocument: await getDocument(),
                    loadNewDesign
                });
            };
        },
        [getDocument, loadNewDesign, fireInteractionTimedEvent]
    );
};
