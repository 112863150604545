import React from "react";
import classNames from "classnames";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Button } from "@vp/swan";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { StickyComponent } from "./StickyComponent";

import * as styles from "./DialogButtons.module.scss";

const messages = defineMessages({
    apply: {
        id: "easel.components.tables.tableDialog.apply",
        defaultMessage: "Apply",
        description: {
            note: "Apply table changes"
        }
    },
    cancel: {
        id: "easel.components.tables.tableDialog.cancel",
        defaultMessage: "Cancel",
        description: {
            note: "Close dialog without applying changes"
        }
    }
});

interface DialogButtonsProps {
    onCancel: () => void;
    onApply: () => void;
}

export function DialogButtons({ onCancel, onApply }: DialogButtonsProps) {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();

    if (isSmall) {
        return (
            <StickyComponent className={classNames("easel-table-editor-sticky-footer-component", styles.dialogButtons)}>
                <Button width="full-width" size="mini" onClick={onCancel} data-button-type="cancel">
                    {t(messages.cancel.id)}
                </Button>
                <Button width="full-width" size="mini" onClick={onApply} data-button-type="apply" skin="primary">
                    {t(messages.apply.id)}
                </Button>
            </StickyComponent>
        );
    }

    return (
        <StickyComponent className={classNames("easel-table-editor-sticky-footer-component", styles.dialogButtons)}>
            <Button width="full-width" size="mini" onClick={onCancel} marginX={4} data-button-type="cancel">
                {t(messages.cancel.id)}
            </Button>
            <Button
                width="full-width"
                onClick={onApply}
                marginX={4}
                data-button-type="apply"
                skin="primary"
                size="mini"
            >
                {t(messages.apply.id)}
            </Button>
        </StickyComponent>
    );
}

DialogButtons.displayName = "DialogButtons";
