import React from "react";

export function ResetArrowIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 12 12" fill="none" {...props}>
            <path d="M6.00003 4.4476L3.84753 2.4976L6.00003 0.547596" stroke="black" strokeLinecap="round" />
            <path
                // eslint-disable-next-line max-len
                d="M1.73242 6.76514C1.73242 7.61264 1.97992 8.43764 2.45242 9.13514C2.92492 9.84014 3.58492 10.3801 4.36492 10.7101C5.14492 11.0326 5.99992 11.1151 6.83242 10.9501C7.65742 10.7851 8.42242 10.3801 9.01492 9.78014C9.61492 9.18014 10.0199 8.42264 10.1849 7.59764C10.3499 6.77264 10.2674 5.91014 9.94492 5.13014C9.62242 4.35014 9.07492 3.68264 8.36992 3.21764C7.67242 2.74514 6.84742 2.49764 5.99992 2.49764H3.84742"
                stroke="black"
                strokeLinecap="round"
            />
        </svg>
    );
}
ResetArrowIcon.displayName = "ResetArrowIcon";
