/* eslint-disable no-param-reassign */
import cloneDeep from "lodash/cloneDeep";
import type { DSS, DTR } from "@vp/types-ddif";
import { PremiumFinishSpotColors } from "@shared/features/PremiumFinish";
import { ItemReferenceTypes, loopOverItems, mapTextContent } from "@shared/utils/CimDoc";
import { ImageItem, ItemReference, TextAreaItem } from "@design-stack-ct/cdif-types";

const fixUpPremiumFinishImage = (
    image: ImageItem,
    designAttributeValue: string,
    urls: { printUrl: string; previewUrl: string }
) => {
    image.overlays = [
        {
            color: `spot(${designAttributeValue})`,
            ...urls
        }
    ];
};

const convertPremiumFinishImage = (image: ImageItem, metadata: DTR.TemplateMetadata, designAttributeValue: string) => {
    // available finishes not defined on all template metadata in types-ddif
    // @ts-ignore
    const { availableFinishes, variations } = metadata;
    if (!availableFinishes?.length) {
        return;
    }

    let urls;
    switch (designAttributeValue) {
        case PremiumFinishSpotColors.RaisedFoilGold:
        case PremiumFinishSpotColors.RaisedFoilSilver:
        case PremiumFinishSpotColors.RaisedFoilGlitterSilver:
            urls = variations.raisedFoil;
            break;
        case PremiumFinishSpotColors.Metallic:
            urls = variations.metallic;
            break;
        case PremiumFinishSpotColors.RaisedInk:
            urls = variations.raisedInk;
            break;
        default:
            return;
    }

    // Either we're converting a non-finish product
    // Or this image supports a finish but not the one we've chosen
    if (!urls) {
        delete image.overlays;
        const {
            rgbCmyk: { printUrl, previewUrl }
        } = variations;
        image.printUrl = printUrl;
        image.previewUrl = previewUrl;
    } else {
        fixUpPremiumFinishImage(image, designAttributeValue, urls);
    }
};

const textHasPremiumFinish = (item: TextAreaItem) => {
    if (!item) {
        return false;
    }
    let result = false;
    mapTextContent(item.content, itemContent => {
        if (itemContent.overprints?.some((overprint: string) => overprint.includes("spot("))) {
            result = result || true;
        }
        return itemContent;
    });
    return result;
};

const getCommonTextColor = (items?: TextAreaItem[]) => {
    const result: Record<string, number> = {};
    let mostCommonColor: string = "";
    items?.forEach(item => {
        mapTextContent(item.content, itemContent => {
            const { color } = itemContent;
            if (color) {
                const count = result[color] || 0;
                result[color] = count + 1;
                if (!mostCommonColor || result[color] > result[mostCommonColor]) {
                    mostCommonColor = color;
                }
            }
            return itemContent;
        });
    });
    return mostCommonColor || "rgb(#101820)";
};

const convertPremiumFinishText = (
    textArea: TextAreaItem,
    metadata: DTR.TemplateMetadata,
    designAttributeValue: string,
    commonColor: string
) => {
    // available finishes not defined on all template metadata in types-ddif
    // @ts-ignore
    const { availableFinishes } = metadata;
    if (!availableFinishes?.length && !textHasPremiumFinish(textArea)) {
        return;
    }

    if (designAttributeValue === PremiumFinishSpotColors.None) {
        mapTextContent(textArea.content, itemContent => {
            itemContent.overprints = [];
            itemContent.color = commonColor;
            return itemContent;
        });
    } else {
        mapTextContent(textArea.content, itemContent => {
            itemContent.overprints = [`spot(${designAttributeValue})`];
            if (designAttributeValue.includes("RaisedFoil")) {
                itemContent.color = `spot(${designAttributeValue})`;
            }
            return itemContent;
        });
    }
};

const convertPremiumFinishItemReference = (
    itemReference: ItemReference,
    metadata: DTR.TemplateMetadata,
    designAttributeValue: string,
    commonColor: string
) => {
    // available finishes not defined on all template metadata in types-ddif
    // @ts-ignore
    const { availableFinishes } = metadata;
    if (
        (!availableFinishes?.fill?.length && !availableFinishes?.stroke?.length) ||
        itemReference.type !== ItemReferenceTypes.WORD_ART
    ) {
        return;
    }

    if (designAttributeValue === PremiumFinishSpotColors.None) {
        itemReference.data.overprints = [];
        if (itemReference.data.stroke) {
            itemReference.data.stroke.overprints = [];
        }
        if (itemReference.data.fill) {
            itemReference.data.fill.overprints = [];
        }
        itemReference.data.color = commonColor;
        if (itemReference.data.shadow) {
            itemReference.data.shadow.overprints = [];
        }
    } else {
        itemReference.data.overprints = [`spot(${designAttributeValue})`];
        if (availableFinishes?.stroke?.length && itemReference?.data?.stroke) {
            itemReference.data.stroke.overprints = [`spot(${designAttributeValue})`];
        }
        if (availableFinishes?.fill?.length && itemReference?.data?.fill) {
            itemReference.data.fill.overprints = [`spot(${designAttributeValue})`];
        }
        if (itemReference.data.shadow) {
            itemReference.data.shadow.overprints = [`spot(${designAttributeValue})`];
        }
    }
};

export const convertPremiumFinishInDocument = (
    sourceDocument: DSS.DesignDocument,
    designAttributeValue: string
): DSS.DesignDocument => {
    const targetDocument = cloneDeep(sourceDocument);
    const { images, textAreas, itemReferences } = targetDocument.document.panels[0];
    const commonColor = getCommonTextColor(textAreas);
    loopOverItems<ImageItem>(targetDocument, images, designAttributeValue, convertPremiumFinishImage);
    loopOverItems<TextAreaItem>(
        targetDocument,
        textAreas,
        designAttributeValue,
        (item: TextAreaItem, metadata: DTR.TemplateMetadata, designAttributeValue: string) =>
            convertPremiumFinishText(item, metadata, designAttributeValue, commonColor)
    );
    loopOverItems<ItemReference>(
        targetDocument,
        itemReferences,
        designAttributeValue,
        (item: ItemReference, metadata: DTR.TemplateMetadata, designAttributeValue: string) =>
            convertPremiumFinishItemReference(item, metadata, designAttributeValue, commonColor)
    );

    return targetDocument;
};
