import { HorizontalScroller } from "@shared/features/StudioChrome";
import React, { useCallback, useState } from "react";
import { FlexibilityOption } from "./FlexibilityOption";
import { useFlexibilityOptionPanel } from "./FlexibilityOptionPanelProvider";

import * as styles from "./FlexibilityOptionSet.module.scss";

export function FlexibilityOptionSet() {
    const { currentDesignAttributeValue, setCurrentDesignAttributeValue, optionData } = useFlexibilityOptionPanel();
    const [previousDesignAttribute, setPreviousDesignAttribute] = useState(currentDesignAttributeValue);

    const onClick = useCallback(
        (option: string) => {
            setCurrentDesignAttributeValue(option);
        },
        [setCurrentDesignAttributeValue]
    );

    return (
        <HorizontalScroller className={styles.horizontalScroller}>
            <div className={styles.flexibilityOptionSet}>
                {optionData?.map(option => (
                    <FlexibilityOption
                        key={option.designAttributeValue}
                        {...option}
                        selected={currentDesignAttributeValue === option.designAttributeValue}
                        onClick={onClick}
                        previousDesignAttribute={previousDesignAttribute}
                        setPreviousDesignAttribute={setPreviousDesignAttribute}
                        className={styles.flexibilityOption}
                    />
                ))}
            </div>
        </HorizontalScroller>
    );
}
FlexibilityOptionSet.displayName = "FlexibilityOptionSet";
