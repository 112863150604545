import React, { useState } from "react";
import { Button, SearchIcon, SearchResetIcon } from "@shared/features/StudioChrome";

import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { TextInput, TextInputWithButtonInset } from "@vp/swan";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import classNames from "classnames";
import { useGallery } from "./GalleryProvider";
import * as styles from "./TemplateSearchBar.module.scss";

export const messages = defineMessages({
    modalSearchbarText: {
        id: "studio.components.changeTemplate.modalSearchbarText",
        defaultMessage: "Search designs",
        description: {
            note: "Default text in the search bar for searching templates"
        }
    },
    templateSearchButtonLabel: {
        id: "studio.components.changeTemplate.templateSearchButtonLabel",
        defaultMessage: "Search template",
        description: {
            note: "Label for a search gallery templates button"
        }
    },
    templateSearchResetButtonLabel: {
        id: "studio.components.changeTemplate.templateSearchResetButtonLabel",
        defaultMessage: "Reset Search",
        description: {
            note: "Label for a reset search gallery templates button"
        }
    }
});

type Props = {
    className?: string;
};

export const TemplateSearchBar = (props: Props) => {
    const { className } = props;
    const { t } = useTranslationSSR();

    const { changeTemplateSearchTerm, updateSearchTerm } = useGallery();
    const [searchTerm, setSearchTerm] = useState(changeTemplateSearchTerm);

    const resetSearchInputField = () => {
        setSearchTerm("");
        updateSearchTerm("");
    };

    const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        const { value } = event.target;
        setSearchTerm(value);
    };

    const handleSearch = () => {
        if (searchTerm) {
            fireDesignToolTrackingEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.CHANGE_TEMPLATE_SEARCH,
                label: "Change template search",
                extraData: () => ({ searchTerm })
            });
            updateSearchTerm(searchTerm);
        } else {
            resetSearchInputField();
        }
    };

    return (
        <TextInputWithButtonInset skin="searchbar" mb={5}>
            <TextInput
                value={searchTerm}
                onChange={handleOnChange}
                placeholder={t(messages.modalSearchbarText.id)}
                title={t(messages.modalSearchbarText.id)}
                onKeyPress={(event: { key: string }) => {
                    if (event.key === "Enter") handleSearch();
                }}
                className={classNames("search-input", "search-input-text", styles.textInput)}
            />
            {searchTerm && (
                <Button
                    onClick={resetSearchInputField}
                    className={className}
                    aria-label={t(messages.templateSearchResetButtonLabel.id)}
                >
                    <SearchResetIcon className="search-reset-icon" />
                </Button>
            )}
            <Button
                onClick={() => handleSearch()}
                className={classNames("search-image-button", styles.searchButton)}
                aria-label={t(messages.templateSearchButtonLabel.id)}
            >
                <SearchIcon />
            </Button>
        </TextInputWithButtonInset>
    );
};

TemplateSearchBar.displayName = "TemplateSearchBar";
