import { useCallback } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { type FlexibilityOptionData } from "../common/components/FlexibilityOption";

const messages = defineMessages({
    RaisedFoilGoldDescription: {
        id: "studio.components.RaisedFoilGold.description",
        defaultMessage: "Premium shine on your text or logo for added texture and depth",
        description: {
            note: "Description for gold finish"
        }
    },
    RaisedFoilSilverDescription: {
        id: "studio.components.RaisedFoilSilver.description",
        defaultMessage: "Shiny Metalic accent that adds shimmer to text, logos and more.",
        description: {
            note: "Description for silver finish"
        }
    },
    // TODO: Get copy for this
    RaisedFoilGlitterSilverDescription: {
        id: "studio.components.RaisedFoilGlitterSilver.description",
        defaultMessage: "Raised polymer gloss (0.05 mm thick) pops off the front of your card",
        description: {
            note: "Description for RaisedFoilGlitter"
        }
    },
    // TODO: Get copy for this
    MetallicDescription: {
        id: "studio.components.Metallic.description",
        defaultMessage: "Shiny, metallic accent that adds foil-printed shimmer",
        description: {
            note: "Description for Metallic"
        }
    },
    // TODO: Get copy for this
    RaisedInkDescription: {
        id: "studio.components.RaisedInk.description",
        defaultMessage: "Raised polymer gloss (0.05 mm thick) pops off the front of your card",
        description: {
            note: "Description for RaisedInk"
        }
    },
    NoneTitle: {
        id: "studio.components.None.title",
        defaultMessage: "No premium finish",
        description: {
            note: "Title for no finish"
        }
    },
    NoneDescription: {
        id: "studio.components.None.description",
        defaultMessage: "14pt paper without the frills for an understated look",
        description: {
            note: "Description for none finish"
        }
    },
    included: {
        id: "studio.components.None.included ",
        defaultMessage: "Included",
        description: {
            note: "Description for none cost"
        }
    }
});

export const usePremiumFinishData = () => {
    const { t } = useTranslationSSR();

    const constructData = useCallback(
        (designAttributeValue: string): FlexibilityOptionData => {
            return {
                description: t(messages[`${designAttributeValue}Description`].id),
                useIcon: true,
                designAttributeValue
            };
        },
        [t]
    );

    const getPremiumFinishData = useCallback(
        (finishes: string[]) => {
            return finishes.map(constructData);
        },
        [constructData]
    );

    return getPremiumFinishData;
};
