export interface Rectangle extends Point, Dimensions {}

export interface Vertices {
    topLeft: Point;
    topRight: Point;
    bottomLeft: Point;
    bottomRight: Point;
    topMiddle: Point;
    bottomMiddle: Point;
}

export interface VerticesOnCanvas {
    topLeftOnCanvas: boolean;
    topRightOnCanvas: boolean;
    bottomLeftOnCanvas: boolean;
    bottomRightOnCanvas: boolean;
    topMiddleOnCanvas: boolean;
    bottomMiddleOnCanvas: boolean;
}

export type ItemPosition = {
    rotation: number;
} & Dimensions &
    CanvasPosition;

/**
 * Converts degrees to Radians
 * @param degrees number of degrees using the 360 degrees to a circle
 * @returns radians number representation of the angle
 */
export function degreesToRadians(degrees: number) {
    return degrees * (Math.PI / 180);
}

/**
 * Calculate the longest side of a right triangle, opposite the right angle.
 * @param x length of a side
 * @param y length of a side
 * @returns return the length of the hypotenuse
 */
export function hypotenuse(x: number, y: number) {
    return Math.sqrt(x * x + y * y);
}

/**
 * Get the Points representing the corners and the important points on the Rectangle
 * @param rectangle
 * @returns Vertices
 */
export function getRectangleVertices(rectangle: Rectangle): Vertices {
    const { x, y, width, height } = rectangle;
    return {
        // Corners
        topLeft: { x, y },
        topRight: { x: x + width, y },
        bottomLeft: { x, y: y + height },
        bottomRight: { x: x + width, y: y + height },
        // Mid points
        topMiddle: { x: x + width / 2, y },
        bottomMiddle: { x: x + width / 2, y: y + height }
    };
}

/**
 * Rotate a Point around an origin point, maintaining the same distance from origin point
 * @param point Point that should be rotated
 * @param origin Origin Point (center Point of item)
 * @param rotationInDegrees degrees to rotate
 * @returns rotated Point
 */
export function rotatePointAroundOrigin(point: Point, origin: Point, rotationInDegrees: number): Point {
    const deltaX = point.x - origin.x;
    const deltaY = point.y - origin.y;
    const distance = hypotenuse(deltaX, deltaY);
    const angleInRadians = Math.atan2(deltaY, deltaX);
    const newAngle = angleInRadians - degreesToRadians(rotationInDegrees);
    return {
        x: origin.x + distance * Math.cos(newAngle),
        y: origin.y + distance * Math.sin(newAngle)
    };
}

/**
 * Rotate a rectangle arount an origin point,
 * and provide a key set of points and return
 * key points of resulting rectangle
 * @param rectangle
 * @param origin
 * @param r
 * @returns Vertices
 */
export function rotateRectangleAroundOrigin(rectangle: Rectangle, origin: Point, r: number): Vertices {
    const vertices = getRectangleVertices(rectangle);
    // @ts-ignore
    return Object.fromEntries(
        Object.entries(vertices).map(([key, point]) => [key, rotatePointAroundOrigin(point, origin, r)])
    );
}

/**
 * Find the center Point of a rectangle based on knowing its top and left coorinates and its height & width
 * @param left
 * @param top
 * @param width
 * @param height
 * @returns Point
 */
export function centerOfRectangle(left: number, top: number, width: number, height: number): Point {
    return {
        x: left + width / 2,
        y: top + height / 2
    };
}

/**
 * Get the key points of interest for an rectangle rotated on the canvas
 * @param itemPosition Items position
 * @param rotation amount to rotate
 * @returns Set out points that have been rotated
 */
export function getRotatedPoints(itemPosition: ItemPosition) {
    const { left, top, width, height, rotation } = itemPosition;
    const center = centerOfRectangle(left, top, width, height);
    return rotateRectangleAroundOrigin({ y: top, x: left, width, height }, center, rotation);
}

/**
 * Check if point is on the canvas
 * @param point Point to check
 * @param dimensionsOfCanvas Dimensions of the canvas (assuming a top left of 0,0)
 * @returns boolean - true if on canvas, false otherwise
 */
export function isPointOnCanvas(point: Point, dimensionsOfCanvas: Dimensions) {
    const leftBorder = point.x >= 0;
    const topBorder = point.y >= 0;
    const rightBorder = point.x <= dimensionsOfCanvas.width;
    const bottomBorder = point.y <= dimensionsOfCanvas.height;
    return leftBorder && topBorder && rightBorder && bottomBorder;
}

/**
 * Returns an evaluation of if the points provided are on the canvas
 * @param vertices
 * @param dimensions
 * @returns an object with booleans indicating if the points appeared on canvas
 */
export function areVerticesOnCanvas(vertices: Vertices, dimensions: Dimensions): any {
    const onCanvasPoints = {};
    Object.entries(vertices).forEach(([key, point]) => {
        onCanvasPoints[`${key}OnCanvas`] = isPointOnCanvas(point, dimensions);
    });
    return onCanvasPoints;
}
