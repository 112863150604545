import qs from "qs";
import { tryFetch } from "@shared/utils/Network";

interface FetchTemplateUrlBody {
    calculatedSurfaceSetUrl: string;
}

const getSurfaceURL = (productKey: string, productOptions: Record<string, string>, productVersion: number) => {
    const surfacesQueryString = qs.stringify({
        optionSelections: productOptions,
        requestor: REQUESTOR
    });

    return `${SURFACE_PRODUCT_URL}/surfaces/${productKey}${
        productVersion ? `/${productVersion}` : ""
    }?${surfacesQueryString}`;
};

async function fetchSVGTemplateUrl(headers: HeadersInit, body: FetchTemplateUrlBody): Promise<string> {
    try {
        const response = await tryFetch({
            url: `${SURFACE_HELPER_URL}/v0/svg:zip`,
            options: {
                method: "POST",
                headers,
                body: JSON.stringify(body)
            },
            moduleFunction: "uploadPrintClient:fetchSVGTemplateUrl",
            friendlyDescription: "fetch SVG template url"
        });
        if (response.message === "success") {
            return response.file;
        }
        return "";
    } catch {
        return "";
    }
}

async function fetchPDFTemplateUrl(headers: HeadersInit, body: FetchTemplateUrlBody): Promise<string> {
    try {
        const response = await tryFetch({
            url: `${DESIGN_TEMPLATE_GENERATOR_URL}/v1/designTemplateGenerator`,
            options: {
                method: "POST",
                headers,
                body: JSON.stringify(body)
            },
            moduleFunction: "uploadPrintClient:fetchPDFTemplateUrl",
            friendlyDescription: "fetch PDF template url"
        });
        if (response.Status === "Completed") {
            return response.PdfUrl;
        }
        return "";
    } catch {
        return "";
    }
}

async function fetchAITemplateUrl(headers: HeadersInit, body: FetchTemplateUrlBody): Promise<string> {
    try {
        const response = await tryFetch({
            url: `${DESIGN_TEMPLATE_GENERATOR_URL}/v0/generateAi?asynchronous=true`,
            options: {
                method: "POST",
                headers,
                body: JSON.stringify(body)
            },
            moduleFunction: "uploadPrintClient:fetchAITemplateUrl",
            friendlyDescription: "fetch AI template url"
        });
        if (response.Status === "Completed") {
            return response.ResultUrl;
        }
        return "";
    } catch {
        return "";
    }
}

export async function fetchTemplateUrls(
    productKey: string,
    productOptions: Record<string, string>,
    productVersion: number,
    authToken: string
) {
    const calculatedSurfaceSetUrl = getSurfaceURL(productKey, productOptions, productVersion);
    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`
    };
    const body = { calculatedSurfaceSetUrl };

    const urlPromises = [
        fetchSVGTemplateUrl(headers, body),
        fetchPDFTemplateUrl(headers, body),
        fetchAITemplateUrl(headers, body)
    ];

    const [svgUrl, pdfUrl, aiUrl] = await Promise.all(urlPromises);

    return {
        svgUrl,
        pdfUrl,
        aiUrl
    };
}
