import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { UploadIcon } from "@shared/features/StudioChrome";
import { FULLBLEED_SELECTED_TEMPLATE } from "@shared/utils/Templates";
import { DesignTileButton } from "../DesignTileButton";
import { DesignTileButtonLabel } from "../DesignTileButtonLabel";
import { designPanelMessages } from "../../designPanelMessages";
import * as styles from "./FullbleedDesignTile.module.scss";

interface Props {
    imageHeight?: number;
    className?: string;
}

/**
 * Tile to add to the Design Tiles Panel that allows the user to change their canvas to a full bleed design.
 */
export function FullbleedDesignTile({ imageHeight, className }: Props) {
    const { t } = useTranslationSSR();
    const label = t(designPanelMessages.designTileUpload.id);

    return (
        <DesignTileButton
            selectedOption={FULLBLEED_SELECTED_TEMPLATE}
            key={`${FULLBLEED_SELECTED_TEMPLATE}`}
            ariaLabel={label}
            className={className}
        >
            <div className={styles.fullbleedIcon} style={{ paddingBottom: `${imageHeight}%` }}>
                <UploadIcon aria-hidden="true" />
            </div>
            <DesignTileButtonLabel label={label} />
        </DesignTileButton>
    );
}
FullbleedDesignTile.displayName = "FullbleedDesignTile";
