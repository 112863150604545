import React, { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useStudioLayout, BOTTOM_BAR } from "@shared/features/ResponsiveDesign";
import { MailingWarningIcon } from "@shared/features/StudioChrome";
import debounce from "lodash/debounce";
import { Typography, Box, Link, AlertBox } from "@vp/swan";
import { useActiveCanvas, useDesigner } from "@designer-suite";
import { newRelicWrapper } from "@shared/utils/Errors";
import { useAppSelector } from "@shared/redux";
import { useIsMailingPostCard, useMailingServicesContext } from "@shared/features/MailingServices";
import { mailingServicesMessages } from "./messages";
import { isWithinPostalArea, moveItemsOnCanvas } from "./Utilities/util";
import { useSurfaceDataCalcifer } from "../../hooks/useSurfaceDataCalcifer";

import "./RelocateItemsAlert.scss";

const MAILING_POSTCARD_BACK_SIDE = 2;
export default function RelocateItemsAlert() {
    const { t } = useTranslationSSR();
    const productKey = useAppSelector(state => state.productKey);
    const { isMailingServicesGuideModalOpen } = useMailingServicesContext();
    const [itemsInUSPSArea, setItemsInUSPSArea] = useState<null | Item[]>(null);
    const isMailingPostCard = useIsMailingPostCard();
    const { isSmall, isMedium } = useStudioLayout();
    const isMailingServicesGuideModalOpenRef = useRef(isMailingServicesGuideModalOpen);

    const canvas = useActiveCanvas();
    const canvasBackPanel = canvas?._canvasViewModel?.attributes?.ordinal === MAILING_POSTCARD_BACK_SIDE;

    const designer = useDesigner();
    const surface = useSurfaceDataCalcifer();
    const showAlert = isMailingPostCard && canvasBackPanel && (itemsInUSPSArea ? itemsInUSPSArea.length > 0 : false);

    const moveCanvasItems = useCallback(() => {
        if (!designer || !itemsInUSPSArea) {
            return;
        }
        // adding little spacing from the edges of the canvas to provide some breathing area there.
        moveItemsOnCanvas(designer, itemsInUSPSArea, "left", 7);
    }, [designer, itemsInUSPSArea]);

    const findItemsInUSPSArea = useCallback(() => {
        if (!designer || !surface || !isMailingPostCard) {
            return;
        }
        // wrapping it in try catch isWithinPostalArea fails sometime when projects are switched.
        // few designer attributes are missing or not in correct shape during project transition.
        try {
            const allItems = designer.api.design.canvases.reduce(
                (acc, curr, index) => (index === 1 ? [...acc, ...curr.items] : [...acc]),
                []
            );
            const items = allItems.filter(item => {
                return isWithinPostalArea(designer, surface, item) === true;
            });

            setItemsInUSPSArea(items);
        } catch (e) {
            newRelicWrapper.noticeError(e, {
                productKey
            });
        }
    }, [designer, surface, productKey, isMailingPostCard]);

    // iterating through all the canvas items and passing them one by one to checkvalidation function.
    const debouncedFindItemsInUSPSArea = debounce(findItemsInUSPSArea, 500);

    const bottomSpacing = useMemo(() => {
        if (isSmall) {
            return { bottom: BOTTOM_BAR.MOBILE.MIN_HEIGHT_WITH_MULTI_PANEL };
        }
        if (isMedium) {
            return { bottom: BOTTOM_BAR.TABLET.MIN_HEIGHT_WITH_MULTI_PANEL };
        }
        return { bottom: "55px" };
    }, [isMedium, isSmall]);

    // attaching a listener to listen every time an item moves on canvas,
    useEffect(() => {
        // do not attach item changed listeners to non mailing products
        if (!designer || !isMailingPostCard) {
            return;
        }

        designer?.eventBus.on(designer.eventBus.events.historyChanged, debouncedFindItemsInUSPSArea);
        /* eslint-disable consistent-return */
        return () => {
            designer?.eventBus.off(designer.eventBus.events.historyChanged, debouncedFindItemsInUSPSArea);
        };
    }, [designer, debouncedFindItemsInUSPSArea, isMailingPostCard]);

    // when backpanel is loaded for the first time, items are checked for validation.
    useEffect(() => {
        if (!designer || !isMailingPostCard) {
            return;
        }
        // when guide modal is closed we need to relocate items.
        if (isMailingServicesGuideModalOpenRef.current !== isMailingServicesGuideModalOpen) {
            isMailingServicesGuideModalOpenRef.current = isMailingServicesGuideModalOpen;
            findItemsInUSPSArea();
        }
        findItemsInUSPSArea();
    }, [
        canvas,
        findItemsInUSPSArea,
        designer,
        isMailingPostCard,
        isMailingServicesGuideModalOpen,
        isMailingServicesGuideModalOpenRef
    ]);

    return (
        <>
            {showAlert && (
                <Box className="mailing-services-relocate-item-alert-box" style={bottomSpacing}>
                    <AlertBox skin="warning" width="narrow" className="studio-alert-box-message-container">
                        <span className="alert-box-icon">
                            <MailingWarningIcon aria-hidden="true" />
                        </span>

                        <div>
                            <Typography className="alert-box-message">
                                {t(mailingServicesMessages.behindUSPSAreaItemsRelocatingText.id)}
                            </Typography>
                            <span className="alert-box-link-button">
                                <Link component="button" onClick={moveCanvasItems}>
                                    <Typography className="alert-box-message__link">
                                        {t(mailingServicesMessages.bringIntoViewButtonText.id)}
                                    </Typography>
                                </Link>
                            </span>
                        </div>
                    </AlertBox>
                </Box>
            )}
        </>
    );
}
RelocateItemsAlert.displayName = "RelocateItemsAlert";
