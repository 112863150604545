import { useEffect, useState } from "react";

type LoadingState = {
    isLoading: boolean;
};

export const useLoadingState = (imageViewModel: ImageViewModel) => {
    const [loadingState, setLoadingState] = useState<LoadingState>({ isLoading: false });

    useEffect(() => {
        const setLoadingStateFromDesigner = (vm: ImageViewModel) => {
            const isLoading = vm.get("loading");
            setLoadingState({ isLoading });
        };

        // need to do this to capture cases when image apears on canvas being in a loading state already
        if (imageViewModel.get("loading")) {
            setLoadingStateFromDesigner(imageViewModel);
        }

        imageViewModel.on("change:loading", setLoadingStateFromDesigner);
        return () => {
            imageViewModel.off("change:loading", setLoadingStateFromDesigner);
        };
    }, [imageViewModel]);
    return loadingState;
};
