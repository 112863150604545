import { useCallback } from "react";
import { isHorizontalArrow } from "@shared/utils/Shapes";
import { DesignTileCallback } from "@shared/utils/DesignPanel";
import { documentSourceTypeMap } from "@shared/utils/DSS";
import { useDesignDialog } from "../DesignDialogProvider";
import { applyDesignOption } from "../utils";

export const useSelectDuplicateCallback = () => {
    const { getDocument, loadNewDesign, fireInteractionTimedEvent } = useDesignDialog();

    /**
     * This function is a callback that when called, will duplicate the front panel to  the panel with panelName.
     * @param panelName - panel to receive the duplicated front
     * @param resetting - are we resetting the panel (meaning are we going to ignore the saved panel) in essence reduplicating the front, in this case
     * @returns callback
     */
    return useCallback<DesignTileCallback>(
        ({ panelName, resetting }) => {
            return async (dispatch: any, getState: any) => {
                const existingDocument = await getDocument();
                const firstPanel = existingDocument.document.panels[0];
                if (!firstPanel) {
                    return undefined;
                }
                const { surfaceUpsells } = getState();
                const surfaceUpsell = surfaceUpsells[panelName];
                const arrows = firstPanel?.shapes?.filter((shape: any) => isHorizontalArrow(shape, existingDocument));
                const transformations = arrows?.map((shape: any) => ({ id: shape.id, type: "FlipHorizontal" })) ?? [];

                return applyDesignOption({
                    dispatch,
                    getState,
                    panelName,
                    resetting,
                    newDocumentSourceType: documentSourceTypeMap.DUPLICATE,
                    newDocumentSourceData: firstPanel.id,
                    transformations,
                    newOptions: surfaceUpsell
                        ? {
                              [surfaceUpsell.optionName]: surfaceUpsell.colorOption
                          }
                        : undefined,
                    fireInteractionTimedEvent,
                    existingDocument,
                    loadNewDesign
                });
            };
        },
        [getDocument, loadNewDesign, fireInteractionTimedEvent]
    );
};
