import { useCallback, useMemo, useState } from "react";
import { FAKE_INSTRUCTIONS, getAllViews } from "@shared/utils/Previews";
import { useAppSelector } from "@shared/redux";
import { mapDesignAttributeToProductOption, mapDesignAttributeValueToProductOptionValue } from "@shared/utils/Product";
import { useActiveFlexibilityOptions } from "@shared/features/Flexibility";
import { type PreviewSceneInfo } from "../components/FlexibilityOptionPanelProvider";

export const useFlexibilityPreviewScenes = (designOptionName: string) => {
    const { getDesignAttributeValues } = useActiveFlexibilityOptions();
    const designOptionValues = getDesignAttributeValues(designOptionName);
    const designAttributeMappings = useAppSelector(state => state.designAttributeMappings);
    const locale = useAppSelector(state => state.locale);
    const productOptionName = mapDesignAttributeToProductOption(designAttributeMappings, designOptionName);

    const [scenes, setScenes] = useState<PreviewSceneInfo[]>();

    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);

    const getScenes = useCallback(
        async (purpose: string, productOptionValue: string) => {
            const newSelectedProductOptions = {
                ...studioSelectedProductOptions,
                [productOptionName]: productOptionValue
            };
            const allViews = await getAllViews({
                purpose,
                previewInstructionsUri: FAKE_INSTRUCTIONS,
                width: 1000,
                studioSelectedProductOptions: newSelectedProductOptions,
                product: { key: productKey, version: productVersion ?? undefined },
                locale
            });
            //  eslint-disable-next-line no-underscore-dangle
            const scenes = allViews?.views.map((view: any) => view?._embedded?.scene);
            return scenes;
        },
        [productKey, productVersion, studioSelectedProductOptions, productOptionName, locale]
    );

    const getScenesWithBackup = useCallback(
        async (designAttributeValue: string) => {
            const productOptionValue = mapDesignAttributeValueToProductOptionValue(
                designAttributeMappings,
                designOptionName,
                designAttributeValue
            );

            let scenes = await getScenes("studio.referential", productOptionValue);
            if (!scenes[0]?.id) {
                scenes = await getScenes("design.studio.review", productOptionValue);
            }
            //  eslint-disable-next-line no-underscore-dangle
            return { designAttributeValue, sceneUrls: scenes?.map((scene: any) => scene._links.content.href) };
        },
        [designAttributeMappings, getScenes, designOptionName]
    );

    useMemo(() => {
        if (!designOptionValues) {
            return;
        }
        Promise.all(designOptionValues.map(getScenesWithBackup)).then(setScenes);
    }, [designOptionValues, getScenesWithBackup]);

    return scenes?.length ? scenes : undefined;
};
