import { useEffect, useState } from "react";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useAppSelector } from "@shared/redux";
import { getProductSurfacePreview } from "./surfacePreviewImage";

export const useSurfaceImage = () => {
    const [surfaceImage, setSurfaceImage] = useState("");
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const productOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const { auth } = useIdentityContext();

    useEffect(() => {
        if (!productKey || !productOptions || !productVersion || !auth) {
            return;
        }

        const authToken = auth.getToken();
        async function productSurfacePreview() {
            const surfacePreviewImage = await getProductSurfacePreview(
                productKey,
                productVersion ?? undefined,
                productOptions,
                authToken
            );
            setSurfaceImage(surfacePreviewImage?.url);
        }
        productSurfacePreview();
    }, [productKey, productOptions, auth, productVersion]);

    return surfaceImage;
};
