import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { Typography, Button } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import classNames from "classnames";
import React from "react";
import { ResetArrowIcon } from "@shared/features/StudioChrome";
import { embroideryMessages } from "./embroideryMessages";

interface Props {
    resetColors: () => void;
}
export function ResetButton({ resetColors }: Props) {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();

    return (
        <Button
            size="mini"
            className={classNames("embroidery-image-color-modal__button", {
                "embroidery-image-color-modal__button--link-styled": isSmall
            })}
            paddingX={isSmall ? 0 : 4}
            paddingY={isSmall ? 0 : 1}
            onClick={resetColors}
        >
            <ResetArrowIcon />
            <Typography fontSize="-1" marginLeft={2}>
                {t(embroideryMessages.resetColors.id)}
            </Typography>
        </Button>
    );
}
ResetButton.displayName = "ResetButton";
