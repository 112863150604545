import React from "react";
import { MailingIcon } from "@shared/features/StudioChrome";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { Box, Link, AlertBox } from "@vp/swan";
import { useIsMailingPostCard, useMailingServicesContext } from "@shared/features/MailingServices";
import * as styles from "./MailingProductAlert.module.scss";

const messages = defineMessages({
    mailingProductBacksideTemplateAlertPart1: {
        id: "studio.components.mailingServices.mailingProductBacksideTemplateAlertPart1",
        defaultMessage: "The back of your postcard has an area reserved for the mailing address.",
        description: {
            note: `initial part of alert message that comes before 'Update postcard mailing options.' that appears
            at the top of the modal on selecting backside of the canvas when a mailing postcard is selected`
        }
    },
    mailingProductBacksideTemplateAlertPart2: {
        id: "studio.components.mailingServices.mailingProductBacksideTemplateAlertPart2",
        defaultMessage: "Update postcard mailing options.",
        description: {
            note: `message for "Update postcard mailing options" link that appears at the top of the
            modal on selecting backside of the canvas when a mailing postcard is selected`
        }
    }
});

export function MailingProductAlert() {
    const { t } = useTranslationSSR();
    // if mailing services is enabled and it is a mailing postcard then only show the alert.
    const { setIsMailingServicesFAQModalOpen } = useMailingServicesContext();
    const isMailingPostCard = useIsMailingPostCard();

    const handleInfoClick = () => {
        setIsMailingServicesFAQModalOpen(true);
    };

    return (
        <>
            {isMailingPostCard && (
                <Box className={styles.alertBox}>
                    <AlertBox skin="warning" width="narrow" className={styles.alertBoxInfo}>
                        <p className={styles.alertBoxIcon}>
                            <MailingIcon aria-hidden="true" />
                        </p>
                        <div className={styles.alertBoxMessage}>
                            {t(messages.mailingProductBacksideTemplateAlertPart1.id)}&nbsp;
                            <p className={styles.alertBoxLink}>
                                <Link component="button" onClick={handleInfoClick}>
                                    {t(messages.mailingProductBacksideTemplateAlertPart2.id)}
                                </Link>
                            </p>
                        </div>
                    </AlertBox>
                </Box>
            )}
        </>
    );
}

MailingProductAlert.displayName = "MailingProductAlert";
