import { defineMessages } from "@vp/i18n-helper";

export const ClipartMessages = defineMessages({
    clipartPreviewModalAriaLabel: {
        id: "studio.components.panels.clipartPanel.previewModal.ariaLabel",
        defaultMessage: "Graphics preview modal",
        description: {
            note: "Graphics preview modal aria label"
        }
    },
    closeModalAriaLabel: {
        id: "studio.components.panels.clipartPanel.previewModal.closeModalAriaLabel",
        defaultMessage: "Close",
        description: {
            note: "Graphics preview modal close button aria label"
        }
    },
    addToDesignButtonLabel: {
        id: "studio.components.panels.clipartPanel.previewModal.addToDesignButtonLabel",
        defaultMessage: "Add to design",
        description: {
            note: "Label for the add to design button in the graphics preview modal"
        }
    }
});
