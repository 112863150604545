import React, { useCallback, useEffect } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import {
    Typography,
    Callout,
    Box,
    Divider,
    LegacyModalDialogHeader,
    LegacyModalDialogTitle,
    LegacyModalDialogCloseButton,
    LegacyModalDialogFooter,
    LegacyModalDialogNav
} from "@vp/swan";
import { loadableRetry } from "@shared/utils/Network";
import loadable from "@loadable/component";
import { useAppSelector } from "@shared/redux";
import { TriggerCanvasChange } from "@shared/utils/DesignPanel";
import { type DifferentialPricingProps } from "@shared/features/Pricing";
import { MailingProductAlert, useIsMailingPostCard } from "@shared/features/MailingServices";
import { useGetCanvasUpsellContext, useIsPaidUpsellContext } from "@shared/features/Product";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import classNames from "classnames";
import { type NextStepButtonProps } from "@shared/features/GlobalHeader";
import { CanvasUpdateStatus, DesignPanelType, useDesignDialog } from "../DesignDialogProvider";
import { DeleteSideButton } from "./DeleteSideButton";
import { DesignTilesPanel } from "./DesignTilesPanel";
import { designPanelMessages } from "../designPanelMessages";
import { useStudioFlexibility } from "../../Flexibility";
import * as styles from "./DesignPanelDialogBody.module.scss";
import { useGetDesignPanelVariableContents } from "../hooks/useGetDesignPanelVariableContents";

const DifferentialPricingDisplay = loadable(() => loadableRetry(() => import("@shared/features/Pricing")), {
    resolveComponent: components => components.DifferentialPricingDisplay as React.FC<DifferentialPricingProps>
});

const { designSideSelectionClose } = designPanelMessages;

interface Props {
    onSaveOrCancel?: () => void;
    triggerCanvasChange: TriggerCanvasChange;
    NextStepButton: (props: NextStepButtonProps) => JSX.Element;
}

/**
 * Dialog that shows all the options for changing a canvas.
 */
export const DesignPanelDialogBody = ({ onSaveOrCancel, triggerCanvasChange, NextStepButton }: Props) => {
    const {
        designPanelType,
        setDesignPanelType,
        dialogCanvasName,
        setDialogCanvas,
        isAddDialog,
        setCanvasUpdateStatus
    } = useDesignDialog();
    const { t } = useTranslationSSR();
    const isPaidUpsell = useIsPaidUpsellContext();
    const showDelete = designPanelType === DesignPanelType.Change && isPaidUpsell(dialogCanvasName);
    const isAdd = isAddDialog();
    const isMailingPostCard = useIsMailingPostCard();
    const showPreviewState = useAppSelector(state => state.previewOpen);
    const getUpsellsByCanvasName = useGetCanvasUpsellContext();
    const dialogPricing = getUpsellsByCanvasName(dialogCanvasName);
    const { isXerox } = useXerox();
    const { isMileStone3Enabled } = useStudioFlexibility();

    useEffect(() => {
        // reset canvas update status on unmount
        return () => {
            setCanvasUpdateStatus(CanvasUpdateStatus.RESET);
        };
    }, [setCanvasUpdateStatus]);

    const closePanel = useCallback(() => {
        setDesignPanelType(DesignPanelType.None);
        setDialogCanvas(undefined);
        onSaveOrCancel?.();
    }, [setDesignPanelType, setDialogCanvas, onSaveOrCancel]);

    const { FooterComponent, title, instructions } = useGetDesignPanelVariableContents({
        NextStepButton,
        closePanel
    });

    return (
        <>
            <LegacyModalDialogNav className={classNames(styles.designPanelNav, "design-panel-nav")}>
                {!showPreviewState && (
                    <LegacyModalDialogCloseButton visuallyHiddenLabel={t(designSideSelectionClose.id)} />
                )}
                <LegacyModalDialogHeader className={styles.dialogHeader} marginBottom={0}>
                    <LegacyModalDialogTitle
                        id="design-panel-dialog-title"
                        fontWeight={isXerox || isMileStone3Enabled ? "normal" : undefined}
                        marginBottom={1}
                    >
                        {title}
                    </LegacyModalDialogTitle>
                    {isAdd && (
                        <Box marginBottom={3}>
                            <Callout
                                skin="announcement"
                                variant="inverse"
                                marginBottom={0}
                                className={classNames(styles.upsellPrice, "upsell-side-price")}
                            >
                                <DifferentialPricingDisplay
                                    pricing={dialogPricing && dialogPricing.listPrice !== 0 ? dialogPricing : undefined}
                                />
                            </Callout>
                        </Box>
                    )}
                </LegacyModalDialogHeader>
            </LegacyModalDialogNav>
            <div className={classNames(styles.designPanelTiles, "design-panel-tiles")}>
                <Typography pb={2} mt={2} as="p" fontSize="1" textColor="dark-grey">
                    {instructions}
                </Typography>
                {showDelete && <DeleteSideButton triggerCanvasChange={triggerCanvasChange} />}
                {!showDelete && isMailingPostCard && <Divider className={styles.alertDivider} />}
                {isMailingPostCard && <MailingProductAlert />}
                <DesignTilesPanel />
            </div>
            <LegacyModalDialogFooter pinned margin={0}>
                {FooterComponent}
            </LegacyModalDialogFooter>
        </>
    );
};

DesignPanelDialogBody.displayName = "DesignPanelDialogBody";
