import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useAppSelector } from "@shared/redux";
import { useSizeData } from "./useSizeData";
import { changeProductSizeMessages } from "./messages";
import { FlexibilityOptionPanel } from "../common/components/FlexibilityOptionPanel";
import { FlexibilityOptionPanelProvider } from "../common/components/FlexibilityOptionPanelProvider";
import { useFlexibilityPreviewScenes } from "../common/hooks/useFlexibilityPreviewScenes";
import { FlexibilityDesignAttributes } from "../constants";
import * as styles from "./ChangeSizeModal.module.scss";

export function ChangeSizeModal() {
    const sizeScenes = useFlexibilityPreviewScenes(FlexibilityDesignAttributes.Size);
    const productName = useAppSelector(state => state.productName);

    const { t } = useTranslationSSR();
    const getSizeData = useSizeData();

    return (
        <FlexibilityOptionPanelProvider
            designAttributeName={FlexibilityDesignAttributes.Size}
            getOptionsData={getSizeData}
            forcePreviewFallback={true}
            previewScenes={sizeScenes}
        >
            <FlexibilityOptionPanel
                className={styles.flexibilityOptionPanel}
                closeButtonLabel={t(changeProductSizeMessages.modalCloseButton.id)}
                headerText={t(changeProductSizeMessages.changeProductSizeModalHeader.id, { productName })}
                descriptionText={t(changeProductSizeMessages.changeProductSizeModalDescription.id)}
                changeOptionText={t(changeProductSizeMessages.changeProductSizeModalButtonText.id)}
                loadingText={t(changeProductSizeMessages.changeSizeButtonLoadingText.id)}
                changeAlertKey="studio.components.Toast.changeSizeToast"
            />
        </FlexibilityOptionPanelProvider>
    );
}
ChangeSizeModal.displayName = "ChangeTrimModal";
