import React, { HTMLAttributes, useEffect, useState } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { windowExists } from "@shared/utils/WebBrowser";
import { FocusLock } from "@shared/features/Accessibility";
import "./modelessDialog.scss";

interface Props extends HTMLAttributes<HTMLDivElement> {
    isOpen: boolean;
    dialogAriaLabel: string;
    handleClose: () => void;
}

const getFirstFocusableToolbarElement = () => {
    if (!windowExists()) {
        return null;
    }
    const globalToolbar = document.getElementsByClassName("global-toolbar-V2")[0] as HTMLElement;
    const focusableElementsInToolbar = Array.from(
        globalToolbar?.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), video'
        )
    ).filter(element => element.clientWidth > 0);

    return focusableElementsInToolbar[0] as HTMLElement;
};

export function ModelessDialog({ isOpen, className, children, dialogAriaLabel, handleClose, ...otherProps }: Props) {
    const { isSmall } = useStudioLayout();
    const initialPosition = isSmall ? { y: "100%" } : { x: "100%" };
    const animatedPosition = isSmall ? { y: 0 } : { x: 0 };
    const transition = { duration: 0.25, ease: "linear" };
    const [lockFocus, setLockFocus] = useState(false);
    const [focusToggleElement, setFocusToggleElement] = useState<HTMLElement | null>(null);

    useEffect(() => {
        let returnFn;
        if (isOpen) {
            // delay focus lock until panel has animated in
            const timer = setTimeout(() => {
                setLockFocus(true);
            }, 250);
            returnFn = () => clearTimeout(timer);
        } else {
            setLockFocus(false);
        }
        return returnFn;
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            // To allow togging focus in and out of the modeless dialog, we need a focusable element outside of the dialog
            // For now, I'm using the first focusable element in the global toolbar
            // (the button that opens the dialog is hidden behind the dialog for smart validations panel)
            // Ideally, we'd be able to move focus to an item on the canvas but that's not possible with designer
            const firstFocusableToolbarElement = getFirstFocusableToolbarElement();
            setFocusToggleElement(firstFocusableToolbarElement);
        }
    }, [isOpen]);

    return (
        <FocusLock
            isLocked={lockFocus}
            handleClose={handleClose}
            focusToggleElement={focusToggleElement}
            {...otherProps}
        >
            {/* @ts-ignore */}
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        role="dialog"
                        aria-modal="false"
                        aria-label={dialogAriaLabel}
                        className={classNames("modeless-dialog", className)}
                        initial={initialPosition}
                        animate={{ ...animatedPosition, transition }}
                        exit={{ ...initialPosition, transition }}
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </FocusLock>
    );
}

ModelessDialog.displayName = "ModelessDialog";
