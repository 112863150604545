import { useMemo } from "react";
import { useAppSelector } from "@shared/redux";
import { FULLBLEED_SELECTED_TEMPLATE } from "@shared/utils/Templates";
import { useDesignDialog } from "../DesignDialogProvider";

export const useGetDesignTileTemplates = () => {
    const matchingTemplates = useAppSelector(state => state.matchingTemplates);
    const { dialogCanvasName } = useDesignDialog();

    const { validTemplates, fullBleed } = useMemo(() => {
        const allTemplateForThisPanel = (matchingTemplates ?? []).filter(
            (matchingTemplate: any) => matchingTemplate.panelName.toLowerCase() === dialogCanvasName.toLowerCase()
        );

        const fullBleed = allTemplateForThisPanel.find(
            (template: any) => template.templateToken === FULLBLEED_SELECTED_TEMPLATE
        );
        const validTemplates = allTemplateForThisPanel.filter((template: any) => template !== fullBleed);
        return { validTemplates, fullBleed };
    }, [matchingTemplates, dialogCanvasName]);

    return { validTemplates, fullBleed };
};
