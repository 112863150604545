import React, { useCallback } from "react";
import { Typography, Button, Box, SelectionSet, LegacyModalDialogButtons } from "@vp/swan";
import { PriceForQtyUnits } from "@vp/vp-tokenized-fragment";
import { SaveDesignWithSaveDocumentOmitted } from "@shared/features/Save";
import { RootStateOrAny, useSelector } from "react-redux";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { removeQueryParam, addQueryParam } from "@shared/utils/WebBrowser";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import type { TaxAwarePrice } from "@shared/utils/Calcifer";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { isVatInclusive, DEFAULT_FRACTION_DIGITS } from "@shared/utils/Pricing";
import { useAppSelector, setQuantity, useAppDispatch } from "@shared/redux";
import { StickyActionBar } from "@shared/features/StudioChrome";
import classnames from "classnames";
import { QuantityCard } from "./QuantityCard";
import { FlexibilityBackButton } from "../common/components/FlexibilityBackButton";
import { useFlexibilityOptionPanel } from "../common/components/FlexibilityOptionPanelProvider";
import * as styles from "./QuantityOptionPanelContent.module.scss";

const messages = defineMessages({
    quantityLabel: {
        id: "studio.components.quantityOptionPanel.quantityLabel",
        defaultMessage: "Quantity ",
        description: {
            note: "Text message for change product quantity"
        }
    },
    quantityPriceErrorMessage: {
        id: "studio.components.quantityOptionPanel.quantityPriceErrorMessage",
        defaultMessage: "Something went wrong. We could not fetch Quantity and Price information.",
        description: {
            note: "Error text for product price and quantity"
        }
    },
    priceForQtyUnitsLabel: {
        id: "studio.components.quantityOptionPanel.priceForQtyUnitsLabel",
        defaultMessage: "Price for quantity",
        description: {
            note: "Placeholder text for price for each product quantity"
        }
    }
});

interface Props {
    headerText: string;
    changeOptionText: string;
    currentQuantitySelection: any;
    setCurrentQuantitySelection: any;
    className?: string;
    onClose?: () => void;
    onSaveOrCancel?: () => void;
    isBackButtonEnabled?: boolean;
    saveDesign: SaveDesignWithSaveDocumentOmitted;
}

function getPrice(price: TaxAwarePrice, vatInc: boolean) {
    return vatInc ? price.taxed : price.untaxed;
}

export function QuantityOptionPanelContent(props: Props) {
    const {
        headerText,
        changeOptionText,
        currentQuantitySelection,
        setCurrentQuantitySelection,
        className,
        onClose,
        onSaveOrCancel,
        isBackButtonEnabled,
        saveDesign
    } = props;
    const { designAttributeName, quantityAttributes: priceQuantityData } = useFlexibilityOptionPanel();
    const locale = useSelector((state: RootStateOrAny) => state.locale);
    const { currency } = useSelector((state: { currency: string }) => state);
    const productQuantity = useSelector((state: RootStateOrAny) => state.quantity);
    const dispatch = useAppDispatch();
    const vatInc = isVatInclusive();
    const { t } = useTranslationSSR();
    const { auth, identity } = useIdentityContext();
    const authToken = auth?.getToken();
    const workId = useAppSelector(state => state.workId);

    const estimatedPriceQuantity = priceQuantityData?.estimatedPrices?.[currentQuantitySelection];

    function onChangeHandler(selectedQuantity: string) {
        if (selectedQuantity) {
            const parsedQuantity = parseInt(selectedQuantity, 10) || 0;
            setCurrentQuantitySelection(parsedQuantity);
        }
    }

    const onContinue = useCallback(async () => {
        if (productQuantity !== currentQuantitySelection) {
            fireDesignToolTrackingEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.CLICK_CTA_BUTTON_ON_CHANGE_DESIGN_ATTRIBUTE_MODAL,
                label: "clicked continue button on the change design attribute modal",
                extraData: () => ({
                    designAttributeName,
                    designAttributeValue: currentQuantitySelection,
                    oldDesignAttributeValue: productQuantity
                })
            });

            dispatch(setQuantity({ quantity: currentQuantitySelection }));

            // To save quantity in workid, we added this. We are only going to save quantity to workid when workid is present.
            if (workId && authToken && identity) {
                await saveDesign({ authToken, identity });
            }

            // Update the quantity in the URL
            window.history.replaceState("update-url", "", removeQueryParam(window.location.href, "qty"));
            window.history.replaceState(
                "update-url",
                "",
                addQueryParam(window.location.href, "qty", currentQuantitySelection)
            );
        }

        onClose?.();
        onSaveOrCancel?.();
    }, [
        productQuantity,
        currentQuantitySelection,
        onClose,
        onSaveOrCancel,
        dispatch,
        workId,
        authToken,
        identity,
        designAttributeName,
        saveDesign
    ]);

    return (
        <>
            <div
                className={classnames(
                    className,
                    "flexibility-option-panel-right-content",
                    styles.quantityOptionPanelContent
                )}
            >
                <div className={styles.panelHeader}>
                    {isBackButtonEnabled && <FlexibilityBackButton onClick={onClose} className={styles.backButton} />}
                    <Typography fontSize={4} textAlign="left" fontWeight="normal">
                        {headerText}
                    </Typography>
                </div>
                <Box mt={5}>
                    <SelectionSet
                        skin="standard"
                        variant="single-select"
                        selectedValue={currentQuantitySelection.toString()}
                        onSelectedValueChange={onChangeHandler}
                        className={styles.quantitySelectionSet}
                        aria-labelledby="quantity-selection-label"
                    >
                        {priceQuantityData?.estimatedPrices &&
                            Object.keys(priceQuantityData?.estimatedPrices).map((quantity: string) => {
                                return (
                                    <QuantityCard
                                        key={`quantity-${quantity}`}
                                        priceQuantityData={priceQuantityData}
                                        quantity={quantity}
                                        vatInc={vatInc}
                                        defaultFractionDigits={DEFAULT_FRACTION_DIGITS}
                                    />
                                );
                            })}
                    </SelectionSet>
                </Box>
            </div>
            <StickyActionBar className={classnames("quantity-update-bar", styles.quantityFooter)}>
                <LegacyModalDialogButtons className={classnames("quantity-option-buttons", styles.quantityButtons)}>
                    <div className={classnames("quantity-price-block", styles.priceBlock)}>
                        {estimatedPriceQuantity ? (
                            <PriceForQtyUnits
                                placeHolderText={t(messages.priceForQtyUnitsLabel.id)}
                                pricingInfo={{
                                    quantity: currentQuantitySelection,
                                    discountPrice: getPrice(estimatedPriceQuantity.totalDiscountedPrice, vatInc),
                                    listPrice: getPrice(estimatedPriceQuantity.totalListPrice, vatInc),
                                    vatInc,
                                    minimumFractionDigits: priceQuantityData.fractionDigits || DEFAULT_FRACTION_DIGITS,
                                    culture: locale,
                                    currency: priceQuantityData.currency || currency
                                }}
                            />
                        ) : (
                            t(messages.quantityPriceErrorMessage.id)
                        )}
                    </div>
                    {priceQuantityData?.estimatedPrices && (
                        <Button
                            className={styles.confirmButton}
                            skin="primary"
                            width="full-width"
                            size="mini"
                            onClick={onContinue}
                        >
                            {changeOptionText}
                        </Button>
                    )}
                </LegacyModalDialogButtons>
            </StickyActionBar>
        </>
    );
}
QuantityOptionPanelContent.displayName = "QuantityOptionPanelContent";
