import React, { useRef, useState, useEffect } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import {
    Typography,
    TextInput,
    Box,
    TextInputWithFloatingLabel,
    TextInputFloatingLabel,
    LegacyModalDialog,
    LegacyModalDialogBody,
    LegacyModalDialogCloseButton,
    LegacyModalDialogHeader,
    LegacyModalDialogTitle,
    H3
} from "@vp/swan";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { fireUserInteractionTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppSelector, useAppDispatch, setTrackModalTransitionEvent, setShowSaveAs } from "@shared/redux";
import { duplicateProjectName } from "@shared/utils/Work";
import { projectNamingRelatedMessages } from "@shared/features/MyProjects";
import { savingMessages } from "@shared/utils/Save";
import { StudioModalDialogContent } from "@shared/features/StudioChrome";
import { SaveButton } from "./SaveButton";
import { renamingMessages } from "./RenameProjectButton";
import * as styles from "./SaveAsDialog.module.scss";
import { Save, UseSaveParams } from "./useSave";

export const saveAsMessages = defineMessages({
    title: {
        id: "studio.duplicateDesignDialog.title",
        defaultMessage: "Name your project",
        description: {
            note: "Title for the duplicate design dialog"
        }
    },
    instructions: {
        id: "studio.duplicateDesignDialog.instructions",
        defaultMessage: "Name your design and we'll save it in My Projects.",
        description: {
            note: "Instructions for the duplicate design dialog"
        }
    },
    inputLabel: {
        id: "studio.duplicateDesignDialog.inputLabel",
        defaultMessage: "Design name",
        description: {
            note: "Label for the input to name a design"
        }
    },
    closeLabel: {
        id: "studio.duplicateDesignDialog.closeLabel",
        defaultMessage: "Close",
        description: {
            note: "Aria label to close the dialog"
        }
    }
});

interface Props {
    useSave: (params: Omit<UseSaveParams, "saveDesign">) => Save;
}

export function SaveAsDialog({ useSave }: Props) {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const showDialog = useAppSelector(state => state.showSaveAs);
    const isDuplicatingDesign = useAppSelector(state => state.isDuplicatingDesign);
    const isRenamingProject = useAppSelector(state => state.isRenamingProject);
    const productname = useAppSelector(state => state.productName);
    const workName = useAppSelector(state => state.workName);
    const dispatch = useAppDispatch();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [newWorkName, setNewWorkName] = useState("");
    const startTimeRef = useRef<number | null>(null);
    const setStartTime = (startTime: number) => {
        startTimeRef.current = startTime;
    };

    useEffect(() => {
        if (isDuplicatingDesign) {
            if (workName && workName.length > 0) {
                const duplicatedProjectName = duplicateProjectName(workName, `${t(savingMessages.appendToName.id)}`);
                setNewWorkName(duplicatedProjectName);
            } else {
                setNewWorkName(t(projectNamingRelatedMessages.projectName.id, { productname }));
            }
        }
        // exclude workName to avoid resetting name in input after submitting
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDuplicatingDesign, productname, t]);

    useEffect(() => {
        if (isRenamingProject) {
            setNewWorkName(workName ?? "");
        }
        // exclude workName to avoid resetting name in input after submitting
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRenamingProject]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget) {
            setNewWorkName(event.currentTarget.value);
        }
    };

    const handleSubmit = (event: any) => {
        if (buttonRef.current !== null) {
            buttonRef.current.click();
        }
        event.preventDefault();
    };

    const closeSaveAs = () => {
        dispatch(setShowSaveAs({ showSaveAs: false, isDuplicatingDesign: false, isRenamingProject }));
        setNewWorkName("");
        const startTime = startTimeRef.current;
        if (startTime !== null) {
            const endTime = performance.now();
            // eslint-disable-next-line no-nested-ternary
            const eventName = isRenamingProject
                ? "Rename Project"
                : isDuplicatingDesign
                ? "Duplicate Project"
                : "Save as Copy";

            fireUserInteractionTrackingEvent(eventName, endTime - startTime);
            startTimeRef.current = null;
        }
        // Delay state change to prevent title from changing while fading out
        if (isRenamingProject) {
            setTimeout(
                () =>
                    dispatch(
                        setShowSaveAs({ showSaveAs: false, isDuplicatingDesign: false, isRenamingProject: false })
                    ),
                100
            );
        }
    };

    const save = useSave({ onSaveCallback: closeSaveAs, trackingEventData: STUDIO_TRACKING_EVENTS.SAVE_BUTTON });

    const handleTransitionEnd = () => {
        dispatch(setTrackModalTransitionEvent({ isEventActive: true, isTransitionComplete: !showDialog }));
    };

    return (
        <LegacyModalDialog
            isOpen={showDialog}
            onRequestDismiss={closeSaveAs}
            onTransitionEnd={handleTransitionEnd}
            onClick={event => {
                event.preventDefault();
            }}
        >
            <StudioModalDialogContent
                fullBleed={isSmall}
                aria-labelledby="saveAsProjectModal"
                data-dcl-prevent-canvas-items-deselection
            >
                <LegacyModalDialogCloseButton visuallyHiddenLabel={t(saveAsMessages.closeLabel.id)} />
                {isSmall ? (
                    <div className="m-m">
                        <H3 p={2} textAlign="center" className="swan-m-0">
                            {t(saveAsMessages.title.id)}
                        </H3>
                    </div>
                ) : (
                    <LegacyModalDialogHeader>
                        <LegacyModalDialogTitle>
                            {isRenamingProject ? t(renamingMessages.rename.id) : t(saveAsMessages.title.id)}
                        </LegacyModalDialogTitle>
                    </LegacyModalDialogHeader>
                )}
                <LegacyModalDialogBody>
                    <form onSubmit={handleSubmit} className={styles.saveAsContainer}>
                        <Typography fontSize="1" marginBottom={4} id="saveAsProjectModal">
                            {t(saveAsMessages.instructions.id)}
                        </Typography>
                        <TextInputWithFloatingLabel marginBottom={6} className={styles.nameInput}>
                            <TextInput
                                className={styles.nameInput}
                                type="text"
                                value={newWorkName}
                                onChange={handleInputChange}
                            />
                            <TextInputFloatingLabel>{t(saveAsMessages.inputLabel.id)}</TextInputFloatingLabel>
                        </TextInputWithFloatingLabel>
                        <Box paddingX={isSmall ? 4 : 0} paddingY={isSmall ? 3 : 0} className={styles.saveAsFooter}>
                            <SaveButton
                                disabled={!newWorkName}
                                newWorkName={newWorkName}
                                save={save}
                                ref={buttonRef}
                                setStartTime={setStartTime}
                                className={styles.saveButton}
                            />
                        </Box>
                    </form>
                </LegacyModalDialogBody>
            </StudioModalDialogContent>
        </LegacyModalDialog>
    );
}
SaveAsDialog.displayName = "SaveAsDialog";
