import React from "react";
import classNames from "classnames";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useAppDispatch, useAppSelector, hidePreview } from "@shared/redux";
import { FlexBox } from "@vp/swan";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useIsMailingPostCard } from "@shared/features/MailingServices";
import loadable from "@loadable/component";
import { loadableRetry } from "@shared/utils/Network";
import { type ReviewPrevewProps } from "@shared/features/Previews";
import { useActiveCanvas } from "@designer-suite";
import { DownloadButton } from "@shared/features/GlobalHeader";
import { MobilePanel } from "@shared/features/StudioChrome";
import "./previewPage.scss";

const ReviewPreviews = loadable<ReviewPrevewProps, any>(
    () => loadableRetry(() => import("@shared/features/Previews")),
    {
        resolveComponent: components => components.ReviewPreviews
    }
);

const messages = defineMessages({
    closeAriaLabel: {
        id: "studio.components.preview.closeAriaLabel",
        defaultMessage: "Close preview dialog"
    },
    ariaHeaderLabel: {
        id: "studio.components.preview.ariaHeaderLabel",
        defaultMessage: "Preview Design"
    },
    preview: {
        id: "studio.components.preview.label",
        defaultMessage: "View",
        description: {
            note: "Label for the add sheet preview button"
        }
    }
});

export const PreviewPage = () => {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const dispatch = useAppDispatch();
    const showPreviewState = useAppSelector(state => state.previewOpen);
    const isMailingPostCard = useIsMailingPostCard();
    const activeCanvas = useActiveCanvas();
    const title = isSmall ? " " : "";
    const handleCloseClick = () => {
        dispatch(hidePreview());
    };

    return (
        <MobilePanel
            onRequestDismiss={handleCloseClick}
            isOpen={showPreviewState}
            title={title}
            ariaLabel={t(messages.ariaHeaderLabel.id)}
            ariaCloseLabel={t(messages.closeAriaLabel.id)}
            show={undefined}
            customHeader={undefined}
            fullBleed={true}
            noCloseButton={false}
            onClose={undefined}
            ariaLabelledBy="previewPageModal"
            testId={undefined}
            variant={isSmall ? "panel-bottom" : "standard"}
            className={classNames("studio-preview-modal", "studio-wide-modal", {
                "review-preview-modal": isMailingPostCard
            })}
        >
            <div className="preview-window" id="previewPageModal">
                {!isSmall ? (
                    <FlexBox justifyContent="center">
                        <DownloadButton className="pdfproof-download-btn" />
                    </FlexBox>
                ) : null}
                <ReviewPreviews showPreview={showPreviewState} activeCanvasName={activeCanvas?.name} />
            </div>
        </MobilePanel>
    );
};
PreviewPage.displayName = "Preview";
