/* eslint-disable max-len */
import React from "react";

export function SvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 100 100" {...props}>
            <rect fill="#F90" width={100} height={100} rx={4} ry={4} />
            <rect fill="#FFB13B" width={50} height={50} rx={4} ry={4} />
            <rect x={50} y={50} fill="#DE8500" width={50} height={50} rx={4} ry={4} />
            <g fill="#F90">
                <circle cx={50} cy={18.4} r={18.4} />
                <circle cx={72.4} cy={27.6} r={18.4} />
                <circle cx={81.6} cy={50} r={18.4} />
                <circle cx={72.4} cy={72.4} r={18.4} />
                <circle cx={50} cy={81.6} r={18.4} />
                <circle cx={27.6} cy={72.4} r={18.4} />
                <circle cx={18.4} cy={50} r={18.4} />
                <circle cx={27.6} cy={27.6} r={18.4} />
            </g>
            <path d="M63.086 18.385c0-7.227-5.859-13.086-13.1-13.086-7.235 0-13.096 5.859-13.096 13.086-5.1-5.11-13.395-5.11-18.497 0-5.119 5.12-5.119 13.408 0 18.524-7.234 0-13.103 5.859-13.103 13.085 0 7.23 5.87 13.098 13.103 13.098-5.119 5.11-5.119 13.395 0 18.515 5.102 5.104 13.397 5.104 18.497 0 0 7.228 5.86 13.083 13.096 13.083 7.24 0 13.1-5.855 13.1-13.083 5.118 5.104 13.416 5.104 18.513 0 5.101-5.12 5.101-13.41 0-18.515 7.216 0 13.081-5.869 13.081-13.098 0-7.227-5.865-13.085-13.081-13.085 5.101-5.119 5.101-13.406 0-18.524-5.097-5.11-13.393-5.11-18.513 0z" />
            <path
                fill="#FFF"
                d="M55.003 23.405v14.488L65.26 27.64c0-1.812.691-3.618 2.066-5.005 2.78-2.771 7.275-2.771 10.024 0a7.086 7.086 0 0 1-5.015 12.099L62.101 44.982H76.59a7.088 7.088 0 0 1 5.011-2.076c3.9 0 7.078 3.179 7.078 7.087 0 3.906-3.178 7.088-7.078 7.088a7.11 7.11 0 0 1-5.011-2.072H62.1l10.229 10.244c1.824 0 3.642.694 5.015 2.086a7.06 7.06 0 0 1 0 10.01c-2.75 2.774-7.239 2.774-10.025 0a7.068 7.068 0 0 1-2.064-5.003L55 62.094v14.499a7.11 7.11 0 0 1 2.084 5.013c0 3.906-3.177 7.077-7.098 7.077-3.919 0-7.094-3.167-7.094-7.077a7.12 7.12 0 0 1 2.081-5.013V62.094L34.738 72.346a7.065 7.065 0 0 1-2.084 5.003 7.08 7.08 0 0 1-10.024 0 7.061 7.061 0 0 1 0-10.012 7.067 7.067 0 0 1 5.012-2.086l10.257-10.242H23.414a7.134 7.134 0 0 1-5.015 2.072c-3.917 0-7.096-3.18-7.096-7.088s3.177-7.087 7.096-7.087c1.94 0 3.725.796 5.015 2.076h14.488L27.646 34.736a7.125 7.125 0 0 1-5.012-2.071 7.08 7.08 0 0 1 0-10.027 7.086 7.086 0 0 1 10.027 0 7.106 7.106 0 0 1 2.083 5.005l10.235 10.252V23.407c-1.27-1.287-2.082-3.053-2.082-5.023 0-3.908 3.175-7.079 7.096-7.079 3.919 0 7.097 3.168 7.097 7.079-.002 1.972-.816 3.735-2.087 5.021z"
            />
            <path d="M5.3 50h89.38v40q0 5-5 5H10.3q-5 0-5-5Z" />
            <path
                fill="#3F3F3F"
                d="M14.657 54.211h71.394c2.908 0 5.312 2.385 5.312 5.315v17.91c-27.584-3.403-54.926-8.125-82.011-7.683V59.526c.001-2.93 2.391-5.315 5.305-5.315z"
            />
            <title>{"SVG"}</title>
            <path
                fill="#FFF"
                stroke="#000"
                strokeWidth={0.503}
                d="M18.312 72.927a11.65 11.65 0 0 1-3.407-8.253c0-6.445 5.223-11.672 11.666-11.672 6.446 0 11.667 5.225 11.667 11.672h-6.832a4.836 4.836 0 1 0-9.673 0 4.87 4.87 0 0 0 1.415 3.42 4.861 4.861 0 0 0 3.423 1.405v.012a11.59 11.59 0 0 1 8.243 3.416 11.617 11.617 0 0 1 3.424 8.248c0 6.454-5.221 11.68-11.667 11.68-6.442 0-11.666-5.222-11.666-11.68h6.828a4.837 4.837 0 0 0 9.673 0 4.786 4.786 0 0 0-1.429-3.407 4.77 4.77 0 0 0-3.406-1.418c-3.23 0-6.142-1.314-8.259-3.423zM61.588 53.005l-8.244 39.849h-6.85l-8.258-39.849h6.846l4.838 23.337 4.835-23.337zM73.255 69.513h11.683v11.664c0 6.452-5.226 11.678-11.669 11.678-6.441 0-11.666-5.226-11.666-11.678V64.676h-.017C61.586 58.229 66.827 53 73.253 53c6.459 0 11.683 5.225 11.683 11.676h-6.849a4.826 4.826 0 0 0-4.834-4.837c-2.647 0-4.82 2.163-4.82 4.837v16.501c0 2.675 2.173 4.837 4.82 4.837a4.823 4.823 0 0 0 4.834-4.827V76.348h-4.834l.002-6.835z"
            />
        </svg>
    );
}

SvgIcon.displayName = "SvgIcon";
