import React, { ReactNode, useEffect, useState } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Drawer, InfiniteScrollContainer, useUploadManager } from "@design-stack-vista/upload-components";
import { useQRUrlGenerator, UploadQRModal, UploadQRButton, useUploadQRABTest } from "@shared/features/QRUrlUploads";
import { Divider, H6 } from "@vp/swan";
import { VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { RedesignedUploadsPanelMessages, UploadsPanelMessages } from "@shared/features/UploadsAndAssets";
import { useIsAnonymousUser } from "@shared/features/Auth";
import { STUDIO_TRACKING_EVENTS, fireDesignToolTrackingEvent } from "@shared/utils/Tracking";

interface Props {
    /**
     * Optional. Class name for the div
     */
    className?: string;

    sideName?: string;

    onAssetDoubleClick: (asset: VistaAsset, pageNumber: number) => void;
    onAssetClick: (asset: VistaAsset, pageNumber: number) => void;

    uploadButton: ReactNode;

    /**
     * To get active canvas name for Replace Drawer Panel
     */

    activeCanvasName?: string;

    usedAssets: {
        assetId: string;
        pages: number[];
    }[];
}

export function ReplaceDrawerPanelDisplay({
    className,
    onAssetClick,
    onAssetDoubleClick,
    uploadButton,
    usedAssets,
    activeCanvasName
}: Props) {
    const [showQRModal, setShowQRModal] = useState(false);
    const isAnonymousUser = useIsAnonymousUser();
    const { hasAssets, refreshAssets, assetStore } = useUploadManager();
    const { isQRCodeImageUploadABEnabled, trackImpression } = useUploadQRABTest();

    const { isSmall } = useStudioLayout();

    const { t } = useTranslationSSR();

    const { requestGenerateQR, qrCodeData, assetsRefreshed } = useQRUrlGenerator({
        refreshAssetsCallback: refreshAssets,
        assetStore,
        activeCanvasName
    });

    useEffect(() => {
        if (assetsRefreshed) {
            setShowQRModal(false);
        }
    }, [assetsRefreshed]);

    useEffect(() => {
        if (trackImpression) trackImpression();
    }, [trackImpression]);

    return (
        <InfiniteScrollContainer infiniteScrollId="replace-drawer-infinite-scroll-component">
            {uploadButton}
            {isQRCodeImageUploadABEnabled && !isSmall && (
                <UploadQRButton
                    aria-describedby="open-qr-modal-for-upload"
                    onClick={() => {
                        setShowQRModal(true);
                        activeCanvasName &&
                            fireDesignToolTrackingEvent({
                                eventDetail: STUDIO_TRACKING_EVENTS.CLICK_QR_MOBILE_UPLOAD_BUTTON.replace(
                                    "[CanvasSide]",
                                    activeCanvasName
                                ),
                                label: "General"
                            } as any);
                    }}
                >
                    {t(UploadsPanelMessages.uploadFromMobileButton.id)}
                </UploadQRButton>
            )}
            {hasAssets && <Divider mb={4} />}
            <>
                {hasAssets && (
                    <H6 className="recent-upload-title-text" textColor="dark-grey" fontSize="-1" marginBottom={2}>
                        {t(RedesignedUploadsPanelMessages.recentlyUploaded.id)}
                    </H6>
                )}
                <Drawer
                    emptyResultsElement={
                        !isAnonymousUser ? <>{t(RedesignedUploadsPanelMessages.noImagesFound.id)}</> : undefined
                    }
                    onClick={onAssetClick}
                    onDoubleClick={onAssetDoubleClick}
                    usedAssets={usedAssets}
                    deleteOption="confirm"
                    styleOptions={{ thumbnailSize: isSmall ? 105 : 132, flexboxGap: 10 }}
                />
            </>
            <UploadQRModal
                requestGenerateQR={requestGenerateQR}
                qrCodeData={qrCodeData}
                showUploadQRModal={showQRModal}
                requestCloseModal={() => {
                    setShowQRModal(false);
                }}
            />
        </InfiniteScrollContainer>
    );
}
ReplaceDrawerPanelDisplay.displayName = "ReplaceDrawerPanel";
