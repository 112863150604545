import React, { useEffect, useState } from "react";
import { Column as ReactTableColumn } from "react-table";
import classNames from "classnames";
import loadable from "@loadable/component";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import {
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton
} from "@vp/swan";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { loadableRetry } from "@shared/utils/Network";
import { TableItemReferenceData } from "@shared/utils/Tables";
import { StickyComponent } from "./StickyComponent";
import { DialogButtons } from "./DialogButtons";
import type { TableContainerProps } from "./TableContainer";
import { ReactTableRowData } from "./types";
import { convertFromReactTable, convertToReactTable } from "./converters";

import * as styles from "./TableEditorDialog.module.scss";

const messages = defineMessages({
    dialogHeader: {
        id: "easel.components.tables.tableDialog.header",
        defaultMessage: "Edit Table",
        description: {
            note: "Edit Table Dialog Title"
        }
    },
    ariaCloseButton: {
        id: "easel.components.tables.ariaCloseButton",
        defaultMessage: "Close table dialog"
    }
});

const TableContainer = loadable(() => loadableRetry(() => import("./TableContainer")), {
    resolveComponent: components => components.TableContainer as React.FC<TableContainerProps>
});

interface TableEditorDialogProps {
    isOpen: boolean;
    tableData: TableItemReferenceData;
    onApplyChanges: (newData: TableItemReferenceData) => void;
    onClose: () => void;
}

export function TableEditorDialog({ isOpen, tableData, onApplyChanges, onClose }: TableEditorDialogProps) {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const [reactTableData, setReactTableData] = useState<ReactTableRowData[]>([]);
    const [reactTableColumns, setReactTableColumns] = useState<ReactTableColumn<ReactTableRowData>[]>([]);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        const { reactTableColumns: newReactTableColumns, reactTableData: newReactTableData } =
            convertToReactTable(tableData);

        setReactTableData(newReactTableData);
        setReactTableColumns(newReactTableColumns);
    }, [tableData, isOpen]);

    const updateTableItemData = (rowIndex: number, columnId: string | undefined, cellText: string) => {
        const newReactTableData = [...reactTableData];
        const row = newReactTableData[rowIndex];

        if (columnId) {
            row[columnId] = cellText;
        }

        setReactTableData(newReactTableData);
    };

    const onDeleteRow = (rowIndex: number) => {
        const newData = [...reactTableData];

        if (newData.length === 1) {
            return;
        }

        newData.splice(rowIndex, 1);
        setReactTableData(newData);
    };

    const onAddRow = (rowIndex: number) => {
        const newReactTableData = [...reactTableData];
        const newRowData: ReactTableRowData = {};

        reactTableColumns.forEach(cell => {
            newRowData[cell.accessor as string] = "";
        });

        newReactTableData.splice(rowIndex + 1, 0, newRowData);
        setReactTableData(newReactTableData);
    };

    const onApply = () => {
        const newData: TableItemReferenceData = convertFromReactTable(reactTableData, reactTableColumns);
        onApplyChanges(newData);
    };

    return (
        <LegacyModalDialog
            isOpen={isOpen}
            onRequestDismiss={onClose}
            paddingTop={isSmall ? undefined : 4}
            variant="standard"
            data-dialog-enable-browser-history="xs-only"
            onClick={(event: React.MouseEvent) => {
                event.preventDefault();
            }}
            data-dcl-prevent-canvas-items-deselection
        >
            <LegacyModalDialogContent
                aria-label={t(messages.dialogHeader.id)}
                fullBleed={true}
                className={classNames("easel-table-dialog-content", styles.dialogContent)}
            >
                <StickyComponent
                    className={classNames("easel-table-editor-sticky-header-component", styles.stickyHeader)}
                >
                    <LegacyModalDialogCloseButton visuallyHiddenLabel={t(messages.ariaCloseButton.id)} />
                    <LegacyModalDialogHeader className="easel-table-dialog-header">
                        {t(messages.dialogHeader.id)}
                    </LegacyModalDialogHeader>
                </StickyComponent>
                <TableContainer
                    reactTableData={reactTableData}
                    reactTableColumns={reactTableColumns}
                    updateTableItemData={updateTableItemData}
                    onAddRow={onAddRow}
                    onDeleteRow={onDeleteRow}
                />
                <DialogButtons onCancel={onClose} onApply={onApply} />
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}

TableEditorDialog.displayName = "TableEditorDialog";
