import { newRelicWrapper } from "@shared/utils/Errors";
import { V2 } from "@shared/utils/DSS";
import { getWorkEntity } from "@shared/utils/Work";
import { Identity } from "@shared/utils/Identity";
import { Store, setDefaultState } from "@shared/redux";
import { DSS, MCP } from "@vp/types-ddif";
import { StudioConfiguration } from "@shared/utils/StudioConfiguration";

export async function rehydrateDesignData(
    sessionDesignData: string,
    authToken: string,
    identity: Identity,
    onComplete: (params: {
        productKey: string;
        productOptions?: Record<string, string>;
        designDocument: DSS.DesignDocument;
        views: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface[];
        studioConfiguration: StudioConfiguration;
    }) => Promise<void>
) {
    let success = false;
    let error = {};
    try {
        const recoveryData = sessionDesignData && JSON.parse(sessionDesignData);
        if (!recoveryData) {
            throw new Error("Recovery Data not found");
        }
        const { designDocument, state } = recoveryData;
        const { workId, productKey, studioSelectedProductOptions, productVersion, studioConfiguration, locale } = state;

        // get most recent copy of the work from WES. If this errors then they don't own the work oops.
        const remoteWork = getWorkEntity(authToken, identity, workId);
        if (!remoteWork) {
            throw new Error("Could not access work entity");
        }
        // Restore the entire state as if it's a fresh load
        Store.dispatch(
            setDefaultState({
                ...state
            })
        );
        // retrieve the design spec
        const views = (
            await V2.getDesignViewsForProduct(productKey, productVersion, studioSelectedProductOptions, locale)
        ).designViews;
        // switch EASEL context
        await onComplete({
            productKey,
            productOptions: studioSelectedProductOptions,
            designDocument,
            views,
            studioConfiguration
        });
        success = true;
    } catch (err) {
        success = false;
        error = err;
    }

    newRelicWrapper.logPageAction("studio-work-restored", { success: success.toString(), error });
    return success;
}
