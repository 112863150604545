import { useEffect, useRef } from "react";
import { useActiveCanvas, useDesigner } from "@designer-suite";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppDispatch, setShowReplaceImageModal, useAppSelector } from "@shared/redux";
import { isImageUnreplacedPlaceholder } from "@utilities";
import { useIsAITemplatesEnabled } from "@five/hooks/useIsAITemplatesEnabled";

export const useIsUnreplacedFullbleedPlaceholder = () => {
    const activeCanvas = useActiveCanvas();
    const designer = useDesigner();
    const isFullBleed = useAppSelector(state => state.isFullBleed);
    const isUploadModalAutoLoaded = useAppSelector(state => state.isUploadModalAutoLoaded);
    const isAITemplatesEnabled = useIsAITemplatesEnabled();
    const dispatch = useAppDispatch();
    const autoLoad = useRef(true);

    useEffect(() => {
        if (
            designer &&
            activeCanvas &&
            activeCanvas.items &&
            activeCanvas.items.length > 0 &&
            isFullBleed &&
            !isAITemplatesEnabled
        ) {
            const canvasItem = activeCanvas.items[0];
            const itemBoolean = isImageUnreplacedPlaceholder(canvasItem);
            autoLoad.current &&
                fireDesignToolTrackingEvent({
                    eventDetail: STUDIO_TRACKING_EVENTS.SHOW_UPLOAD_MODAL,
                    label: "General",
                    extraData: () => ({ from: "Auto load studio" })
                } as any);
            dispatch(
                setShowReplaceImageModal({
                    showReplaceImageModal: itemBoolean,
                    isReplaceOpenOnCanvasChange: true,
                    isUploadModalAutoLoaded: autoLoad.current
                })
            );
        } else {
            dispatch(
                setShowReplaceImageModal({
                    showReplaceImageModal: false,
                    isReplaceOpenOnCanvasChange: false,
                    isUploadModalAutoLoaded: false
                })
            );
        }
    }, [activeCanvas, designer, dispatch, isFullBleed, isAITemplatesEnabled, autoLoad]);

    useEffect(() => {
        if (isUploadModalAutoLoaded) {
            autoLoad.current = false;
        }
    }, [autoLoad, isUploadModalAutoLoaded]);
};
