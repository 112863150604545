import React from "react";
import classNames from "classnames";
import { FlexBox } from "@vp/swan";

import * as styles from "./StickyComponent.module.scss";

interface StickyComponentProps {
    className: string;
}

export function StickyComponent({ className, children }: React.PropsWithChildren<StickyComponentProps>) {
    const classnames = classNames(
        "easel-table-editor-sticky-component",
        "easel-table-editor-sticky-component-breakout",
        styles.stickyComponent,
        className
    );

    return <FlexBox className={classnames}>{children}</FlexBox>;
}

StickyComponent.displayName = "StickyComponent";
