import { usePricing } from "@shared/features/Pricing";
import { useCallback } from "react";

export function useFlexibilityOptionCost() {
    const { pricing } = usePricing();

    const getCost = useCallback(
        (
            designAttributeName: string,
            designAttributeValue: string
        ): { listPrice: number; discountPrice: number } | undefined => {
            if (!pricing || !pricing.choiceGroups) {
                return undefined;
            }
            const { choiceGroups } = pricing;
            const choiceGroup = choiceGroups[`${designAttributeName}-${designAttributeValue}`];
            if (!choiceGroup) {
                return undefined;
            }
            const { differentialListPrice: listPrice, differentialDiscountPrice: discountPrice } = choiceGroup;
            return { listPrice, discountPrice };
        },
        [pricing]
    );

    return {
        getCost
    };
}
