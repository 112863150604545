import React, { useMemo, useRef, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useAppSelector } from "@shared/redux";
import { useClickOutside } from "@shared/features/StudioChrome";
import type { DSS } from "@vp/types-ddif";
import { useValidations } from "./ValidationProvider";
import { ValidationOverlay } from "./ValidationOverlay";

export const ValidationLayer = () => {
    const { itemValidations } = useValidations();
    const [selectedItemIdClicked, setSelectedItemIdClicked] = useState<string>("");
    const [selectedItemIdHovered, setSelectedItemIdHovered] = useState<string>("");
    const ref = useRef<HTMLDivElement>(null);
    const isSmartValidationPanelOpen = useAppSelector(state => state.showValidations.showPanel);

    useClickOutside(
        [ref],
        async () => {
            setSelectedItemIdClicked("");
        },
        []
    );

    const unfixedItemValidations = useMemo(() => {
        if (!itemValidations) {
            return undefined;
        }
        const copy = cloneDeep(itemValidations);

        // Remove smartFixed or dismissed validations so they don't show
        Object.entries(copy).forEach(([key, validations]) => {
            // Loop through backwards so you're not shifting array elements up in index while deleting
            for (let i = validations.length - 1; i >= 0; i--) {
                const validation = validations[i];
                if (validation.smartFixed || validation.dismissed) {
                    validations.splice(i, 1);
                }
            }

            if (validations.length === 0) {
                delete copy[key];
            }
        });

        return copy;
    }, [itemValidations]);

    if (!itemValidations || !unfixedItemValidations) {
        return null;
    }

    return (
        <>
            {Object.entries(unfixedItemValidations).map(([id, data]: [string, DSS.StudioValidation[]]) => (
                <ValidationOverlay
                    key={`validation-${id}`}
                    ref={id === selectedItemIdClicked ? ref : undefined}
                    data={data}
                    setSelectedItemIdHovered={setSelectedItemIdHovered}
                    setSelectedItemIdClicked={setSelectedItemIdClicked}
                    showBrick={
                        !isSmartValidationPanelOpen && (selectedItemIdClicked === id || selectedItemIdHovered === id)
                    }
                />
            ))}
        </>
    );
};

ValidationLayer.displayName = "ValidationLayer";
