import React from "react";
import { useTranslation } from "@vp/i18n-helper";
import {
    Typography,
    Button,
    Divider,
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogButtons,
    LegacyModalDialogTitle,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton,
    LegacyModalDialogFooter,
    LegacyModalDialogNav
} from "@vp/swan";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { POSTCARD_PRDS, useMailingServicesContext } from "@shared/features/MailingServices";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { mailingServicesMessages } from "./messages";
import { useSwitchProducts } from "../contexts/SwitchProductsContext";
import { useProductOptions, getDefaultProductOptions } from "../../hooks/useProductOptions";
import "./mailingServicesOptionsModal.scss";

export type SelectionOptions = {
    option: string;
    title: string;
    text: string;
};
export function MailingServicesOptionsModal() {
    const { t } = useTranslation();
    const { isSmall } = useStudioLayout();
    const {
        isMailingServicesModalOpen,
        setIsMailingServicesModalOpen,
        setIsMailingServicesConfirmationModalOpen,
        setIsMailingServicesGuideModalOpen,
        setIsShowingGuideModalFromOptionsModal
    } = useMailingServicesContext();
    const { switchMSProduct } = useSwitchProducts();
    const { customerSelectedProductOptions, locale } = useProductOptions();
    const { isMailingPostCard } = useMailingServicesContext();

    const mailingServicesModalClose = () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_NO_THANK_BUTTON,
            label: "No thanks mailing services modal closed"
        } as any);
        setIsMailingServicesModalOpen(false);
    };

    const addMailingServices = async () => {
        setIsMailingServicesModalOpen(false);
        const productOptions = await getDefaultProductOptions({
            productKey: POSTCARD_PRDS.MAILING,
            customerSelectedProductOptions,
            locale
        });
        const { views } = await switchMSProduct({ productOptions, isMailingPostCard });
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_YES_MAIL_THEM_FOR_ME_BUTTON,
            label: "Yes mailing services is selected"
        } as any);
        if (views.length === 2 && views[1].colorType && views[1].colorType === "color") {
            setIsShowingGuideModalFromOptionsModal(true);
            setIsMailingServicesGuideModalOpen(true);
        } else {
            setIsMailingServicesConfirmationModalOpen(true);
        }
    };

    return (
        <LegacyModalDialog
            isOpen={isMailingServicesModalOpen}
            onRequestDismiss={mailingServicesModalClose}
            bodyWidth="standard"
            variant={isSmall ? "panel-bottom" : "panel-right"}
            className="mailing-services-options-modal"
            takeOver={isSmall}
        >
            <LegacyModalDialogContent aria-label={t(mailingServicesMessages.optionModalAriaLabel.id)}>
                <LegacyModalDialogNav>
                    <LegacyModalDialogCloseButton visuallyHiddenLabel={t(mailingServicesMessages.close.id)} />
                </LegacyModalDialogNav>

                <LegacyModalDialogHeader>
                    <LegacyModalDialogTitle>
                        <Typography>{t(mailingServicesMessages.mailingServicesModalHeader.id)}</Typography>
                    </LegacyModalDialogTitle>
                    <Typography className="mailing-pricing-text">
                        {t(mailingServicesMessages.mailingPostcardPrizingTag.id)}
                    </Typography>
                </LegacyModalDialogHeader>

                <Divider className="option-modal-divider" />
                {/* TODO: use Swan `List` component here */}
                <ul className="swan-list">
                    <li>{t(mailingServicesMessages.checkListItem_1.id)}</li>
                    <li>
                        <span className="list-save-text">{t(mailingServicesMessages.checkListItem_2.id)}</span>
                        {t(mailingServicesMessages.checkListItem_3.id)}
                    </li>
                    <li>{t(mailingServicesMessages.checkListItem_4.id)}</li>
                </ul>

                <LegacyModalDialogFooter pinned className="options-modal-footer">
                    <LegacyModalDialogButtons className="mailing-services-options-button">
                        <Button
                            className="mailing-add-button"
                            skin="primary"
                            width="full-width"
                            onClick={addMailingServices}
                        >
                            {t(mailingServicesMessages.mailingOptions.id)}
                        </Button>
                        <div className="mailing-button-below-text">
                            {t(mailingServicesMessages.mailingButtonBelowText.id)}
                        </div>
                        <Button skin="secondary" width="full-width" onClick={mailingServicesModalClose}>
                            {t(mailingServicesMessages.nonMailingOption.id)}
                        </Button>
                    </LegacyModalDialogButtons>
                </LegacyModalDialogFooter>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}
MailingServicesOptionsModal.displayName = "MailingServicesOptionsModal";
