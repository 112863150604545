import { useCallback } from "react";
import { FlexibilityOptionData } from "../common/components/FlexibilityOption";

export const useQuantityData = () => {
    const constructData = useCallback(
        (designAttributeValue: string): FlexibilityOptionData => ({
            designAttributeValue,
            useIcon: true
        }),
        []
    );

    const getQuantityData = useCallback(
        (quantities: string[]) => {
            return quantities.map(constructData);
        },
        [constructData]
    );

    return getQuantityData;
};
