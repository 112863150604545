import React from "react";
import { Typography } from "@vp/swan";
import { LoadingSpinner, SpinnerVariant } from "@shared/features/StudioChrome";
import * as styles from "./SpecsAndTemplatesLoader.module.scss";

interface Props {
    accessibilityLabel: string;
    loaderMessage: string;
    className?: string;
}

export function SpecsAndTemplatesLoader(props: Props) {
    const { accessibilityLabel = "Loading...", loaderMessage, ...rest } = props;

    return (
        <div className={styles.spinnerContainer}>
            <LoadingSpinner variant={SpinnerVariant.Small} centering aria-label={accessibilityLabel} {...rest} />
            <Typography className={styles.spinnerText}>{loaderMessage}</Typography>
        </div>
    );
}

SpecsAndTemplatesLoader.displayName = "SpecsAndTemplatesLoader";
