/* eslint-disable max-len */
import React from "react";

export function AdobeIllustratorIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 256 256" data-name="Layer 1" {...props}>
            <g data-name="Photo Surface">
                <rect width={240} height={232} x={8} y={12} fill="#300" data-name="Outline no shadow" rx={42} />
            </g>
            <path
                fill="#ff9a00"
                d="M128.79 156H93l-9.24 26.68Q83.6 184 82.09 184h-16.8q-1.68 0-1.17-1.65l32.42-96.29A29.5 29.5 0 0 0 97.88 77a.89.89 0 0 1 1-1h23.52c.68 0 1.07.28 1.18.82l36.28 105.7c.34 1 .06 1.48-.84 1.48h-18.81a1.74 1.74 0 0 1-1.68-1zM97 138h27.88c-.67-1.47-13.21-41.93-14.11-45.34h-.16zm82.91-42a11.81 11.81 0 0 1-8.61-3.21 11.55 11.55 0 0 1-3.3-8.7 11.71 11.71 0 0 1 3.39-8.79 12 12 0 0 1 8.7-3.3 11.55 11.55 0 0 1 8.7 3.3 12.07 12.07 0 0 1 3.21 8.79 11.55 11.55 0 0 1-3.3 8.7 12.07 12.07 0 0 1-8.79 3.21zM170 182.5v-77.16a1.18 1.18 0 0 1 1.34-1.34h17.32a1.19 1.19 0 0 1 1.34 1.34v77.32a1.18 1.18 0 0 1-1.34 1.34H171.5a1.33 1.33 0 0 1-1.5-1.5z"
            />
        </svg>
    );
}

AdobeIllustratorIcon.displayName = "AdobeIllustratorIcon";
