import { useEffect, useState } from "react";

export function useSessionState(key: string, defaultValue: string): [string, (newValue: string) => void] {
    const scopedKey = `studio__${key}`;
    const [value, setValue] = useState(sessionStorage.getItem(scopedKey) || defaultValue);

    // Update state and update session storage
    function updateValue(newValue: string) {
        setValue(newValue);
        sessionStorage.setItem(scopedKey, newValue);
    }

    // Set inital value in session storage if scopedKey does not exit
    useEffect(() => {
        if (!sessionStorage.getItem(scopedKey)) {
            sessionStorage.setItem(scopedKey, defaultValue);
        }
    }, [defaultValue, scopedKey]);

    return [value, updateValue];
}
