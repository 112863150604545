import React, { ReactNode } from "react";
import { Typography } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { MobilePanel } from "@shared/features/StudioChrome";
import { AcceptedUploadFormats } from "@shared/features/UploadsAndAssets";
import * as styles from "./MobileReplaceDrawerPanelDisplay.module.scss";

const messages = defineMessages({
    closeAriaLabel: {
        id: "easel.components.replacePanel.closeAriaLabel",
        defaultMessage: "Close replace image dialog"
    },
    replacePanelInfo: {
        id: "easel.components.replacePanel.replacePanelInfo",
        defaultMessage: "Please ensure that your image uses an accepted format and has high-quality resolution."
    },
    ariaCloseLabel: {
        id: "studio.replaceImagePanel.ariaCloseLabel",
        defaultMessage: "Close replace image dialog"
    },
    replaceImage: {
        id: "easel.ui.toolbarComponents.replaceImages.replaceButton.replacePanelHeader",
        defaultMessage: "Replace Image"
    },
    replaceImagePanelInfo: {
        id: "easel.components.replacePanel.replaceImagePanelInfo",
        defaultMessage:
            "Please make sure that your image resolution is at least 300 dots per inch and uses an accepted format:",
        description: {
            note: "Text displayed for suggested and accepted format of image to be uploaded"
        }
    },
    uploadDesignText: {
        id: "easel.components.replacePanel.uploadDesignText",
        defaultMessage: "Select an image"
    }
});

interface MobileReplaceDrawerPanelDisplayProps {
    handleModalClose: () => void;
    children?: ReactNode | ReactNode[];
    isReplaceOpenOnCanvasChange?: boolean;
    showReplaceImageModal?: boolean;
}

export const MobileReplaceDrawerPanelDisplay = ({
    children,
    handleModalClose,
    isReplaceOpenOnCanvasChange,
    showReplaceImageModal
}: MobileReplaceDrawerPanelDisplayProps) => {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();

    const uploadDesignText = !isReplaceOpenOnCanvasChange
        ? t(messages.replaceImage.id)
        : t(messages.uploadDesignText.id);

    return (
        <MobilePanel
            onRequestDismiss={handleModalClose}
            isOpen={showReplaceImageModal}
            title={uploadDesignText}
            ariaCloseLabel={t(messages.ariaCloseLabel.id)}
            modalDialogBodyClass={styles.replaceImagePanel}
            variant={isSmall ? "panel-bottom" : "standard"}
        >
            <Typography component="p" textSize={isSmall ? 5 : 6} mb={1} fontWeight={isSmall ? "normal" : "bold"}>
                {t(messages.replaceImagePanelInfo.id)}
            </Typography>
            <AcceptedUploadFormats isImageUpload={false} />
            {children}
        </MobilePanel>
    );
};
MobileReplaceDrawerPanelDisplay.displayName = "MobileReplaceDrawerPanelDisplay";
