import React, { useCallback } from "react";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { getDesignDocumentFromDesigner } from "@utilities";
import { LastPremiumFinishWarningModal, EmbroideryImageColorModal } from "@designer-suite";
import { FontBrowserModalStudio5 } from "@presentational";
import { useAppSelector } from "@shared/redux";
import {
    useStudioFlexibility,
    ChangePremiumFinishModal,
    ChangeQuantityModal,
    ChangeTrimModal
} from "@shared/features/Flexibility";
import { useSmartValidations } from "@shared/features/SmartValidations";
import loadable from "@loadable/component";
import { loadableRetry } from "@shared/utils/Network";
import { ChangeTemplateExperienceContainer } from "@shared/features/ChangeTemplate";
import { DesignPanelDialog } from "@shared/features/DesignPanel";
import { EditFromCartModal } from "@shared/features/EditFromCart";
import { ChangeSizeModal } from "@shared/features/Flexibility/Size/ChangeSizeModal";
import { saveStudio5Design } from "@five/clients/saveClient";
import { PdfProofModal } from "@shared/features/GlobalHeader";
import { SaveAsDialog } from "@shared/features/Save";
import { useBeforeAfterHydrationCheck } from "@shared/features/ResponsiveDesign/hooks/useBeforeAfterHydrationCheck";
import { SpecsAndTemplatesModal } from "@shared/features/SpecsAndTemplates";
import { KeyBoardShortcutsInformationModal } from "@shared/features/KeyboardShortcuts";
import { LoadingSpinnerLayer } from "../LoadingSpinners/LoadingSpinnerLayer";
import { ReviewPanel, BlankDocumentAlertPanel } from "../Panels";
import RecoverUnsavedWorkDialog from "../RecoverUnsavedWorkDialog";
import { PreviewPage } from "../Redesign";
import StudioDesignErrorModalWrapper from "../StudioDesignErrorModalWrapper";
import { MailingServicesContainer } from "../MailingServices";
import KeyboardShortcutContainer from "../KeyboardShortcuts/KeyboardShortcutContainer";
import { ImageGalleryPreview } from "../Panels/Images/ImageGalleryPreview";
import { SmartValidationsPanel } from "../Validation/SmartValidationsPanel";
import { ValidationLayer } from "../Validation/ValidationLayer";
import { ClipartPreview } from "../Panels/Clipart/ClipartPreview";
import { ReplacePanel } from "../../../easel/designer-suite/ToolbarComponents/ReplaceImages/ReplacePanel";
import { DroppableTargetLayer } from "../DragAndDrop/DroppableTargetLayer";
import { MyProjectsPanelWrapper } from "../MyProjects/MyProjectsPanelWrapper";
import { useLoadNewDesignForChangeTemplate } from "../../hooks/useLoadNewDesignForChangeTemplate";
import { useStudio5Save } from "../../hooks/useStudio5Save";
import { useTriggerCanvasChange } from "../../hooks/useTriggerCanvasChange";
import { NextStepButtonStudio5 } from "../NextStepButton";
import { TableEditorModal } from "../../../easel/designer-suite/Modals/TableEditorModal";

const CropDialog = loadable(
    () => loadableRetry(() => import("../../../easel/designer-suite/ToolbarComponents/crop/CropDialog")),
    {
        resolveComponent: components => components.CropDialog
    }
);

export const EaselContentsDialogs = () => {
    const productDataLoadSuccessful = useAppSelector(state => state.productDataLoadSuccessful);
    const { isSmall } = useStudioLayout();
    const isAfterHydration = useBeforeAfterHydrationCheck();
    const triggerCanvasChange = useTriggerCanvasChange();
    const loadNewDesign = useLoadNewDesignForChangeTemplate();
    const getDocument = useCallback(async () => getDesignDocumentFromDesigner(false), []);
    const getDocumentWithoutPlaceholders = useCallback(async () => getDesignDocumentFromDesigner(), []);
    const saveForChangeTemplate = useStudio5Save({
        allowAnonymousUser: true,
        hideSaveToast: true,
        showLoader: false,
        trackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_CHANGE_TEMPLATE
    });

    const { isMileStone1Enabled, isMileStone2Enabled } = useStudioFlexibility();
    const { isSmartValidationsEnabled } = useSmartValidations();

    return (
        <>
            <LastPremiumFinishWarningModal />
            {isAfterHydration && <RecoverUnsavedWorkDialog />}
            {productDataLoadSuccessful && <CropDialog />}
            <StudioDesignErrorModalWrapper />
            <SaveAsDialog useSave={useStudio5Save} />
            <BlankDocumentAlertPanel />
            <ReviewPanel />
            <DesignPanelDialog triggerCanvasChange={triggerCanvasChange} NextStepButton={NextStepButtonStudio5} />
            <PdfProofModal getDocument={getDocumentWithoutPlaceholders} />
            <MyProjectsPanelWrapper />
            <FontBrowserModalStudio5 />
            <MailingServicesContainer />
            <EmbroideryImageColorModal />
            <KeyBoardShortcutsInformationModal />
            <KeyboardShortcutContainer />
            <ImageGalleryPreview />
            <SpecsAndTemplatesModal />
            <EditFromCartModal />
            <LoadingSpinnerLayer />
            <DroppableTargetLayer />
            <ChangeTemplateExperienceContainer
                getDocument={getDocument}
                loadNewDesign={loadNewDesign}
                save={saveForChangeTemplate}
            />
            <SmartValidationsPanel />
            {isSmartValidationsEnabled && <ValidationLayer />}
            <ChangeSizeModal />
            {(isMileStone1Enabled || isMileStone2Enabled) && <ChangePremiumFinishModal />}
            {(isMileStone1Enabled || isMileStone2Enabled) && <ChangeTrimModal />}
            {isMileStone2Enabled && <ChangeQuantityModal saveDesign={saveStudio5Design} />}
            <ReplacePanel openOnPlaceholderSelected={isSmall} />
            <ClipartPreview />
            <PreviewPage />
            <div id="teams-guide-modal" />
            <TableEditorModal />
        </>
    );
};

EaselContentsDialogs.displayName = "EaselContentsDialogs";
