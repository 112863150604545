import React from "react";

interface DropTargetIconProps {
    className: string;
}

export const DropTargetIcon = (props: DropTargetIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 276.8 212.05" {...props}>
            <g>
                <g>
                    <rect x="128.4" width="20" height="113.23" fill="currentColor" />
                    <path
                        /* eslint-disable max-len */
                        d="M138.45,212a5.1,5.1,0,0,1-3.29-1.21L2.06,114a5,5,0,0,1,.13-8.18L107,34.4a5,5,0,0,1,5.63,8.27l-99,67.42L138.4,200.83l124.72-90.74-99-67.42a5,5,0,1,1,5.63-8.27l104.85,71.42a5,5,0,0,1,.13,8.18L142,210.61A5,5,0,0,1,138.45,212Z"
                        fill="currentColor"
                    />
                    <polygon points="138.66 141.05 97.35 98.01 179.44 98.01 138.66 141.05" fill="currentColor" />
                </g>
            </g>
        </svg>
    );
};

DropTargetIcon.displayName = "DropTargetIcon";
