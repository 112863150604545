import { useAppDispatch, useAppSelector, setShowBlankDocumentAlert } from "@shared/redux";
import {
    Button,
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogHeader,
    ModalDialogNav,
    ModalDialogTitle
} from "@vp/swan";
import React, { useEffect } from "react";
import "./blankDocumentAlertPanel.scss";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useTrackingClient } from "@shared/features/Tracking";
import { Events } from "@shared/utils/Tracking";

const messages = defineMessages({
    blankDocumentAlertPanelTitle: {
        id: "studio.ui.component.blankDocumentAlertPanel.title",
        defaultMessage: "This product can’t be printed blank.",
        description: {
            note: "Title for blank document alert panel"
        }
    },
    blankDocumentAlertPanelBody: {
        id: "studio.ui.component.blankDocumentAlertPanel.body",
        defaultMessage: "To continue, please add elements to the canvas.",
        description: {
            note: "Description for blank document alert panel"
        }
    },
    blankDocumentAlertPanelButton: {
        id: "studio.ui.component.blankDocumentAlertPanel.button",
        defaultMessage: "Back to editor",
        description: {
            note: "Button text for blank document alert panel"
        }
    }
});

export const BlankDocumentAlertPanel = () => {
    const showBlankDocumentAlert = useAppSelector(state => state.showBlankDocumentAlert);
    const dispatch = useAppDispatch();
    const { isSmall } = useStudioLayout();
    const { t } = useTranslationSSR();
    const trackingClient = useTrackingClient();

    useEffect(() => {
        if (showBlankDocumentAlert) {
            trackingClient.track(Events.StudioTracking, {
                event: "Message Displayed",
                eventDetail: "view_blankValidationBox",
                label: "Warning"
            });
        }
    }, [showBlankDocumentAlert, trackingClient]);

    const close = () => {
        trackingClient.track(Events.StudioTracking, {
            event: "Message Displayed",
            eventDetail: "close_blankValidationBox",
            label: "Warning"
        });
        dispatch(setShowBlankDocumentAlert(false));
    };

    return (
        <>
            <ModalDialog
                variant={isSmall ? "panel-bottom" : "standard"}
                isOpen={showBlankDocumentAlert}
                onRequestDismiss={close}
            >
                <ModalDialogContent
                    className="blank-document-alert-dialog-content"
                    aria-labelledby="blank document alert dialog"
                >
                    <ModalDialogNav>
                        <ModalDialogCloseButton accessibleText="Close" />
                    </ModalDialogNav>
                    <ModalDialogHeader {...(isSmall ? { mb: 4 } : {})}>
                        <ModalDialogTitle>{t(messages.blankDocumentAlertPanelTitle.id)}</ModalDialogTitle>
                    </ModalDialogHeader>
                    <ModalDialogBody {...(isSmall ? { mt: 1 } : {})}>
                        {t(messages.blankDocumentAlertPanelBody.id)}
                    </ModalDialogBody>
                    <Button
                        mt={isSmall ? 5 : 6}
                        mb={isSmall ? 2 : 0}
                        skin="primary"
                        widthVariant={isSmall ? "full-width" : "standard"}
                        onClick={close}
                    >
                        {t(messages.blankDocumentAlertPanelButton.id)}
                    </Button>
                </ModalDialogContent>
            </ModalDialog>
        </>
    );
};

BlankDocumentAlertPanel.displayName = "BlankDocumentAlertPanel";
