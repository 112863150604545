import React, { useState } from "react";
import { useTranslation } from "@vp/i18n-helper";
import classNames from "classnames";
import { Typography, TabContent, TabHeader, Tabs, TabsContents, TabsHeaders } from "@vp/swan";
import { keyboardShortcutMessages } from "./messages";
import { KeyboardShortcutsContent } from "./KeyboardShortcutsContent";
import { KeyboardShortcutsTabs } from "./ContentDataStructure";
import * as styles from "./KeyboardShortcutsTabContent.module.scss";

export function KeyboardShortcutsTabContent() {
    const { t } = useTranslation();
    const initialTab = t(keyboardShortcutMessages.viewAndZoomTabTitle.id);
    const [selectedTabId, setSelectedTabId] = useState<string | null>(initialTab);
    const KeyboardShortcutsTabsContent = Object.values(KeyboardShortcutsTabs);
    return (
        <Tabs
            defaultSelectedTabId={selectedTabId}
            selectedTabId={selectedTabId}
            onRequestTabChange={requestedTabId => setSelectedTabId(requestedTabId)}
            className={styles.keyboardShortcutsTabContainer}
        >
            <TabsHeaders className={styles.keyboardShortcutsTabTitle}>
                {KeyboardShortcutsTabsContent.map(({ tabTitle: keyboardShortcutsTitle }, index) => {
                    return (
                        <TabHeader
                            key={index}
                            tabId={t(keyboardShortcutsTitle)}
                            className={styles.keyboardShortcutsTabsHeaders}
                        >
                            {t(keyboardShortcutsTitle)}
                        </TabHeader>
                    );
                })}
            </TabsHeaders>
            {/* Will improve the implementation later, will be a part of DT-9352 ticket */}
            <TabsContents className={styles.keyboardShortcutsTabContents}>
                {KeyboardShortcutsTabsContent.map(
                    ({ tabTitle: keyboardShortcutsTitle, columns: keyboardShortcutsColumns }, index) => {
                        return (
                            <React.Fragment key={`${index}-${t(keyboardShortcutsTitle)}-viewAndZoomTabTitle-tab-title`}>
                                <TabContent
                                    tabId={t(keyboardShortcutsTitle)}
                                    key={`${index}-${t(keyboardShortcutsTitle)}-zoomIn-title`}
                                >
                                    <div className={styles.tabsColumnContent}>
                                        {keyboardShortcutsColumns.map(
                                            ({ header: keyboardTabsHeader, content: keyboardTabsContent }, index) => {
                                                return (
                                                    <div
                                                        className={classNames(styles.keyAndIconsContent, {
                                                            "zoom-tab-width": keyboardTabsHeader
                                                        })}
                                                        key={`${index}-${t(keyboardTabsHeader)}-title`}
                                                    >
                                                        <Typography fontSize="1" fontWeight="bold" mb={4}>
                                                            {t(keyboardTabsHeader)}
                                                        </Typography>
                                                        {keyboardTabsContent.map(
                                                            (
                                                                {
                                                                    title: keyboardTabsContentTitle,
                                                                    icons: keyboardTabsContentIcons
                                                                },
                                                                index
                                                            ) =>
                                                                keyboardTabsHeader && (
                                                                    <KeyboardShortcutsContent
                                                                        key={`${index}-${t(
                                                                            keyboardTabsContentTitle
                                                                        )}-view-zoom-title`}
                                                                        keyboardShortcutsTitle={t(
                                                                            keyboardTabsContentTitle
                                                                        )}
                                                                        keyboardShortcutsKeysIcons={
                                                                            keyboardTabsContentIcons
                                                                        }
                                                                    />
                                                                )
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </TabContent>
                                <TabContent
                                    tabId={t(keyboardShortcutsTitle)}
                                    key={`${index}-${t(keyboardShortcutsTitle)}-bold-title`}
                                >
                                    <div className={styles.tabsColumnContent}>
                                        <div className={styles.keyAndIconsContent}>
                                            {keyboardShortcutsColumns.map(
                                                ({ header: keyboardTabsHeader, content: keyboardTabsContent }) =>
                                                    keyboardTabsContent
                                                        .slice(0, 8)
                                                        .map(
                                                            (
                                                                {
                                                                    title: keyboardTabsContentTitle,
                                                                    icons: keyboardTabsContentIcons
                                                                },
                                                                index
                                                            ) =>
                                                                !keyboardTabsHeader && (
                                                                    <KeyboardShortcutsContent
                                                                        key={`${index}-${t(
                                                                            keyboardTabsContentTitle
                                                                        )}-title`}
                                                                        keyboardShortcutsTitle={t(
                                                                            keyboardTabsContentTitle
                                                                        )}
                                                                        keyboardShortcutsKeysIcons={
                                                                            keyboardTabsContentIcons
                                                                        }
                                                                    />
                                                                )
                                                        )
                                            )}
                                        </div>
                                        <div className={styles.keyAndIconsContent}>
                                            {keyboardShortcutsColumns.map(
                                                ({ header: keyboardTabsHeader, content: keyboardTabsContent }) =>
                                                    keyboardTabsContent
                                                        .slice(8)
                                                        .map(
                                                            (
                                                                {
                                                                    title: keyboardTabsContentTitle,
                                                                    icons: keyboardTabsContentIcons
                                                                },
                                                                index
                                                            ) =>
                                                                !keyboardTabsHeader && (
                                                                    <KeyboardShortcutsContent
                                                                        key={`${index}-${t(
                                                                            keyboardTabsContentTitle
                                                                        )}-title`}
                                                                        keyboardShortcutsTitle={t(
                                                                            keyboardTabsContentTitle
                                                                        )}
                                                                        keyboardShortcutsKeysIcons={
                                                                            keyboardTabsContentIcons
                                                                        }
                                                                    />
                                                                )
                                                        )
                                            )}
                                        </div>
                                    </div>
                                </TabContent>
                            </React.Fragment>
                        );
                    }
                )}
            </TabsContents>
        </Tabs>
    );
}
KeyboardShortcutsTabContent.displayName = "KeyboardShortcutsTabContent";
