import React, { useEffect } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Divider, SelectionSet, SelectionSetInput, SelectionSetLabel } from "@vp/swan";
import { DesignReview } from "@design-stack-vista/smart-validations-ui";
import { useAppSelector, useAppDispatch, setNextStepUrl, setShowValidations } from "@shared/redux";
import { getNextStep } from "@shared/utils/SiteFlow";
import { useStudio5AvailablePremiumFinishesOnCanvas } from "@utilities";
import ContinueButtonStudio5 from "./ContinueButtonStudio5";
import { FullBleedPremiumFinishReview } from "../../shared/features/DesignReview/FullBleedPremiumFinishReview";
import { useFullBleedPremiumFinish } from "../hooks/useFullBleedPremiumFinish";
import { useTriggerCanvasChange } from "../hooks/useTriggerCanvasChange";
import { useValidations } from "./Validation/ValidationProvider";
import "./reviewStep.scss";

const messages = defineMessages({
    reviewStepModalHeader: {
        id: "studio.components.reviewStep.reviewStepModalHeader",
        defaultMessage: "Design Review"
    },
    confirmationQuestion: {
        id: "studio.components.reviewStep.confirmationQuestion",
        defaultMessage: "All set? Let’s double check:"
    },
    checkListItem_text: {
        id: "studio.components.reviewStep.checkListText",
        defaultMessage: "Text: Is it clear and legible?"
    },
    checkListItem_margin: {
        id: "studio.components.reviewStep.checkListMargin",
        defaultMessage: "Margin: Is everything within the space?"
    },
    checkListItem_info: {
        id: "studio.components.reviewStep.checkListItem_info",
        defaultMessage: "Info: Everything accurate? Spelled correctly?"
    },
    checkListItem_images: {
        id: "studio.components.reviewStep.checkListItem_images",
        defaultMessage: "Images: Is everything clear (no blur)?"
    },
    placeholderWarningText: {
        id: "studio.components.reviewStep.placeholderWarningText",
        defaultMessage: "Your design has unedited placeholder text"
    },
    placeholderWarningDescriptionFirst: {
        id: "studio.components.reviewStep.placeholderWarningDescriptionFirst",
        defaultMessage: "Placeholder text that have not been changed will not print."
    },
    placeholderWarningDescriptionSecond: {
        id: "studio.components.reviewStep.placeholderWarningDescriptionSecond",
        defaultMessage: "Please review your design before completing your order."
    },
    editDesignButton: {
        id: "studio.components.reviewStep.editDesignButton",
        defaultMessage: "Edit my design"
    },
    approvalMessage: {
        id: "studio.components.reviewStep.approvalMessage",
        defaultMessage: "I've reviewed and approve my design."
    },
    authorizationApprovalMessage: {
        id: "studio.components.reviewStep.authorizationApprovalMessage",
        defaultMessage: "I have authorization to use the design, I have reviewed and approve it."
    },
    confirmDesignAriaLabel: {
        id: "studio.components.reviewStep.confirmDesignAriaLabel",
        defaultMessage: "Confirm design review"
    },
    identifiedErrorSubtitle: {
        id: "studio.components.reviewStep.identifiedErrorSubtitle",
        defaultMessage: "We’ve identified possible issues with your design that need your attention."
    },
    identifiedErrorCountSingular: {
        id: "studio.components.reviewStep.identifiedErrorCountSingular",
        defaultMessage: "[count] issue found"
    },
    identifiedErrorCountPlural: {
        id: "studio.components.reviewStep.identifiedErrorCountPlural",
        defaultMessage: "[count] issues found"
    },
    showErrorsButton: {
        id: "studio.components.reviewStep.showErrorsButton",
        defaultMessage: "Show issues on design"
    },
    continueButton: {
        id: "studio.components.reviewStep.continueButton",
        defaultMessage: "Continue"
    }
});

interface Props {
    customerReviewed: boolean;
    setCustomerReviewed: (b: boolean) => void;
    handleContinueEditingClick: () => void;
}

export default function ReviewStep({ customerReviewed, setCustomerReviewed, handleContinueEditingClick }: Props) {
    const { t } = useTranslationSSR();
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const locale = useAppSelector(state => state.locale);
    const nextStepUrl = useAppSelector(state => state.nextStepUrl?.url);
    const dispatch = useAppDispatch();
    const { isFullBleedPremiumFinish } = useFullBleedPremiumFinish();
    const { hasPremiumFinishesCurrentCanvas } = useStudio5AvailablePremiumFinishesOnCanvas();
    const triggerCanvasChange = useTriggerCanvasChange();
    const { getReducedIssues } = useValidations();
    const issues = getReducedIssues();

    useEffect(() => {
        async function loadNextStep() {
            if (productKey && productVersion && locale) {
                const siteFlowNextStepUrl = await getNextStep(
                    productKey,
                    productVersion,
                    studioSelectedProductOptions,
                    locale
                );
                const parsedNextStepUrl = siteFlowNextStepUrl.url;
                const predictedStepsFlow = siteFlowNextStepUrl.predictedFlow;
                const context = siteFlowNextStepUrl.context || [];
                dispatch(
                    setNextStepUrl({ nextStepUrl: parsedNextStepUrl, predictedFlow: predictedStepsFlow, context })
                );
            }
        }
        loadNextStep();
    }, [productKey, productVersion, studioSelectedProductOptions, locale, dispatch]);

    const localization = {
        title: t(messages.reviewStepModalHeader.id),
        withoutIssues: {
            subtitle: t(messages.confirmationQuestion.id),
            points: [
                t(messages.checkListItem_text.id),
                t(messages.checkListItem_margin.id),
                t(messages.checkListItem_info.id),
                t(messages.checkListItem_images.id)
            ],
            editButton: t(messages.editDesignButton.id)
        },
        withIssues: {
            subtitle: t(messages.identifiedErrorSubtitle.id),
            showErrorsButton: t(messages.showErrorsButton.id)
        },
        confirmationCheckboxLabel: t(messages.confirmationQuestion.id),
        continueButton: t(messages.continueButton.id),
        problemCountSingular: t(messages.identifiedErrorCountSingular.id),
        problemCountPlural: t(messages.identifiedErrorCountPlural.id)
    };

    return (
        <div className="review-container">
            <DesignReview
                onGoBack={() => {
                    handleContinueEditingClick();
                    if (issues.length) {
                        dispatch(setShowValidations({ showPanel: true, isContinueToCheckout: true }));
                    }
                }}
                issues={issues}
                localization={localization}
            />
            <Divider className="mobile-divider" />
            <div className="review-text">
                {isFullBleedPremiumFinish && (
                    <FullBleedPremiumFinishReview
                        hasPremiumFinishesCurrentCanvas={hasPremiumFinishesCurrentCanvas}
                        triggerCanvasChange={triggerCanvasChange}
                    />
                )}

                <SelectionSet
                    variant="multi-select"
                    skin="simple-column"
                    onSelectedValuesChange={newSelectedValues =>
                        setCustomerReviewed(newSelectedValues.customerReviewed)
                    }
                    selectedValues={{ customerReviewed }}
                    aria-label={t(messages.confirmDesignAriaLabel.id)}
                >
                    <SelectionSetInput value="customerReviewed" aria-required="true">
                        {/* You can find the request for this en-in specific message in the Slack thread
                        in this ticket: https://vistaprint.atlassian.net/browse/DT-14711 */}
                        <SelectionSetLabel style={{ wordBreak: "break-word" }}>
                            {t(
                                locale.toLowerCase() === "en-in"
                                    ? messages.authorizationApprovalMessage.id
                                    : messages.approvalMessage.id
                            )}
                        </SelectionSetLabel>
                    </SelectionSetInput>
                </SelectionSet>

                <ContinueButtonStudio5
                    hasBeenReviewed={customerReviewed}
                    widthVariant="full-width"
                    marginRight="0"
                    marginBottom="0"
                />
            </div>
            {/* this anchor is used by fido and is not meant to be visible to or used by the user */}
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a
                href={nextStepUrl ?? undefined}
                data-fidoprefetchhint="EAGER"
                aria-hidden
                tabIndex={-1}
                style={{ visibility: "hidden" }}
            />
        </div>
    );
}
ReviewStep.displayName = "ReviewStep";
