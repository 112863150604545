import React, { ReactElement, useMemo } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Button } from "@vp/swan";
import { designPanelMessages } from "../designPanelMessages";
import { DesignPanelType, useDesignDialog } from "../DesignDialogProvider";
import { DesignPanelDialogBody } from "../components/DesignPanelDialogBody";

type UseGetDesignVariableContentsParams = {
    NextStepButton: React.ComponentProps<typeof DesignPanelDialogBody>["NextStepButton"];
    closePanel: () => void;
};

type UseGetDesignVariableContentsResponse = {
    FooterComponent: ReactElement;
    title: string;
    instructions: string;
};

const {
    designPanelChangeTitle,
    designPanelChangeInstructions,
    designPanelCancel,
    designPanelContinueNoSide,
    designPanelChangeInstructionsWithoutTemplate,
    designPanelContinueWithBlank,
    designPanelAddTitle,
    designPanelAddInstructions
} = designPanelMessages;

export function useGetDesignPanelVariableContents({
    NextStepButton,
    closePanel
}: UseGetDesignVariableContentsParams): UseGetDesignVariableContentsResponse {
    const { t } = useTranslationSSR();
    const { designPanelType, dialogCanvasTitle, isAddDialog } = useDesignDialog();
    const isAdd = isAddDialog();

    let titleKey;
    let instructionsKey;
    if (isAdd) {
        titleKey = designPanelAddTitle;
        instructionsKey = designPanelAddInstructions;
    }
    if (
        designPanelType === DesignPanelType.ChangeAutoShow ||
        designPanelType === DesignPanelType.ChangeAutoShowFromNext
    ) {
        titleKey = designPanelChangeTitle;
        instructionsKey = designPanelChangeInstructionsWithoutTemplate;
    } else {
        titleKey = designPanelChangeTitle;
        instructionsKey = designPanelChangeInstructions;
    }
    const title = titleKey && t(titleKey.id, { sideName: dialogCanvasTitle });
    const instructions = instructionsKey && t(instructionsKey.id, { sideName: dialogCanvasTitle });

    const FooterComponent = useMemo(() => {
        switch (designPanelType) {
            case DesignPanelType.CheckoutAdd:
                return (
                    <NextStepButton
                        margin={0}
                        width="full-width"
                        customLabel={t(designPanelContinueNoSide.id, {
                            sideName: dialogCanvasTitle
                        })}
                        data-testid="designPanelNextStep"
                    />
                );
            case DesignPanelType.ChangeAutoShowFromNext:
                return (
                    <NextStepButton
                        margin={0}
                        width="full-width"
                        customLabel={t(designPanelContinueWithBlank.id, {
                            sideName: dialogCanvasTitle
                        })}
                        data-testid="designPanelNextStep"
                    />
                );
            default:
                return (
                    <Button width="full-width" skin="secondary" size="mini" onClick={closePanel} margin={0}>
                        {t(designPanelCancel.id)}
                    </Button>
                );
        }
    }, [NextStepButton, closePanel, designPanelType, dialogCanvasTitle, t]);

    const rtn = useMemo(
        () => ({
            FooterComponent,
            title,
            instructions
        }),
        [FooterComponent, instructions, title]
    );

    return rtn;
}
