import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Button } from "@vp/swan";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { MobileExperience } from "@shared/features/ResponsiveDesign";
import { AdjustFiltersIcon } from "@shared/features/StudioChrome";
import * as styles from "./AdjustFiltersButton.module.scss";

const messages = defineMessages({
    adjustFilters: {
        id: "studio.components.changeTemplate.adjustFilters",
        defaultMessage: "Adjust filters",
        description: {
            note: "Text for a button that opens the filter panel on mobile"
        }
    }
});

export function AdjustFiltersButton() {
    const { t } = useTranslationSSR();
    const { setCurrentActiveDialog } = useActiveDialog();

    const showFilters = () => {
        setCurrentActiveDialog(DialogType.Filters);
    };

    return (
        <MobileExperience>
            <Button
                size="mini"
                width="full-width"
                iconPosition="left"
                className={styles.filtersButton}
                onClick={showFilters}
            >
                {t(messages.adjustFilters.id)}
                <AdjustFiltersIcon />
            </Button>
        </MobileExperience>
    );
}

AdjustFiltersButton.displayName = "AdjustFiltersButton";
