import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { ValidationBrick, useBrickState } from "@design-stack-vista/smart-validations-ui";
import { getRootClassNames, Link } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useDesigner } from "@designer-suite";
import ReactDOM from "react-dom";
import { useAppDispatch, setShowValidations } from "@shared/redux";
import type { DSS } from "@vp/types-ddif";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";
import { convertStudioValidationToBrickValidation } from "./ValidationUtils";
import { useValidations } from "./ValidationProvider";
import messages from "./messages";
import { PanelOpenedActionOrigin, SmartValidationEvents, trackSmartValidationEvent } from "./trackingClient";
import { useLayoutManager } from "../StudioLayout/LayoutManager";

interface Props {
    data: DSS.StudioValidation;
}

// Sum of the width and x margins of .validation-badge class from ValidationOverlay
// Used to set the StudioValidationCanvasBrick on the opposite side without covering the validation badge
const VALIDATION_BADGE_BOX_WIDTH = 48;
// Top/Bottom margin of .validation-badge class from ValidationOverlay
// Used to increse the badges' hover area; Should be accounted when positioning the brick container
const VALIDATION_BADGE_BOX_Y_MARGIN = 16;

const initialBadgePosition = { x: 0, y: 0 };
export function StudioValidationCanvasBrick(props: Props) {
    const { data } = props;
    const designer = useDesigner();
    const dispatch = useAppDispatch();
    const { t } = useTranslationSSR();
    const { updateSmartFixedItems } = useValidations();
    const { setCurrentActiveDialog } = useActiveDialog();
    const [badgePos, setBadgePos] = useState<Point | undefined>();
    const validationBrickRef = useRef<HTMLDivElement>(null);
    const { setHighlightedBrickId, setSelectedBrickId } = useBrickState();
    const [badgeRect, setBadgeRect] = useState<DOMRect | undefined>(undefined);
    const {
        bottomRect: { height: bottomRectHeight }
    } = useLayoutManager();

    const updateBadgeRect = useCallback(
        node => {
            if (node !== null) {
                setBadgeRect(node.getBoundingClientRect());
            }
        },
        [setBadgeRect]
    );

    useEffect(() => {
        if (window && validationBrickRef?.current && badgeRect) {
            const brickRect = validationBrickRef?.current?.getBoundingClientRect();
            if (
                badgeRect.right + brickRect.width >= window.innerWidth &&
                badgeRect?.bottom + brickRect.height >= window.innerHeight - bottomRectHeight
            ) {
                setBadgePos({ x: -1 * brickRect.width - VALIDATION_BADGE_BOX_WIDTH, y: -90 });
                return;
            }
            if (badgeRect.right + brickRect.width >= window.innerWidth) {
                setBadgePos({ x: -1 * brickRect.width - VALIDATION_BADGE_BOX_WIDTH, y: 0 });
                return;
            }
            if (badgeRect?.bottom + brickRect.height >= window.innerHeight - bottomRectHeight) {
                setBadgePos({ x: 0, y: -97 });
                return;
            }
            setBadgePos(initialBadgePosition);
        }
    }, [validationBrickRef, badgeRect, bottomRectHeight]);

    const openPanel = useCallback(() => {
        dispatch(setShowValidations({ showPanel: true, isContinueToCheckout: false }));
        // close the left panel
        setCurrentActiveDialog(DialogType.None);
    }, [dispatch, setCurrentActiveDialog]);

    const onBrickClick = useCallback(() => {
        openPanel();
        setSelectedBrickId(`${data.itemId}-${data.validationName}`);
    }, [data, setSelectedBrickId, openPanel]);

    const onBrickHover = useCallback(() => {
        setHighlightedBrickId(undefined);
    }, [setHighlightedBrickId]);

    const onBrickUnhover = useCallback(() => {
        setHighlightedBrickId(undefined);
    }, [setHighlightedBrickId]);

    if (!designer) {
        return null;
    }

    const brickValidation = convertStudioValidationToBrickValidation({
        studioValidation: data,
        gotoPanelButton: (
            <Link
                className="canvas-brick-go-to-panel-link"
                skin="cta"
                component="button"
                onClick={() => {
                    openPanel();
                    trackSmartValidationEvent(SmartValidationEvents.PanelOpened, {
                        extraData: { trigger: PanelOpenedActionOrigin.Popover }
                    });
                }}
            >
                {t(messages.validationBrickButtonShowAllIssues.id)}
            </Link>
        ),
        updateSmartFixedItems,
        designer,
        onSelect: onBrickClick,
        onHover: onBrickHover,
        onUnhover: onBrickUnhover,
        t
    });

    const left = (badgeRect?.left ?? 0) + (badgePos || initialBadgePosition).x;
    const top = (badgeRect?.top ?? 0) + (badgePos || initialBadgePosition).y + VALIDATION_BADGE_BOX_Y_MARGIN;

    const swanRootClassNames = getRootClassNames();

    return (
        <div ref={updateBadgeRect}>
            {ReactDOM.createPortal(
                <div
                    className={classNames("canvas-validation-brick-container", swanRootClassNames)}
                    ref={validationBrickRef}
                    data-dcl-prevent-canvas-items-deselection
                    style={{
                        left: `${left}px`,
                        top: `${top}px`,
                        visibility: `${badgePos ? "visible" : "hidden"}`
                    }}
                >
                    <ValidationBrick
                        validation={brickValidation}
                        showIcon={false}
                        className="canvas-validation-brick"
                    />
                </div>,
                document.body
            )}
        </div>
    );
}

StudioValidationCanvasBrick.displayName = "StudioValidationCanvasBrick";
