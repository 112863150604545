import React, { useEffect, useState } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";

import { useAppSelector } from "@shared/redux";
import { DUPLICATE_FIRST_PANEL_TEMPLATE } from "@shared/utils/Templates";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { usePreviewsContext } from "@shared/features/Previews";
import { getTransientPreviewURL } from "@shared/utils/Previews";
import { useDesignDialog } from "@shared/features/DesignPanel";
import { isHorizontalArrow } from "@shared/utils/Shapes";
import { designPanelMessages } from "../../designPanelMessages";
import { DesignTileButtonImage } from "../DesignTileButtonImage";
import { DesignTileButton } from "../DesignTileButton";
import { DesignTileButtonLabel } from "../DesignTileButtonLabel";
import { useShouldShowDuplicateFirstCanvasButton } from "../../hooks/useShouldShowDuplicateFirstCanvasButton";

interface Props {
    className?: string;
}

/**
 * Tile to add to the Design Tiles Panel that allows the user to duplicate the front to their canvas.
 */
export function DuplicateDesignTile({ className }: Props) {
    const [previewUrl, setPreviewUrl] = useState<string>("");
    const { auth } = useIdentityContext();
    const { t } = useTranslationSSR();
    const { canvasSelectorUrls } = usePreviewsContext();
    const { dialogCanvasName, getDocument } = useDesignDialog();
    const { shouldFlipArrows } = useAppSelector(state => state.studioConfiguration);

    useEffect(() => {
        const getUrl = async () => {
            const existingDocument = await getDocument();
            const firstPanel = existingDocument.document.panels[0];
            if (!firstPanel) {
                return undefined;
            }
            if (shouldFlipArrows) {
                const arrows = firstPanel?.shapes?.filter((shape: any) => isHorizontalArrow(shape, existingDocument));
                arrows?.forEach((arrow: any) => {
                    let rotationAngle = arrow.rotationAngle || 0;
                    if (arrow) {
                        rotationAngle += 180;
                        if (rotationAngle >= 360) {
                            rotationAngle -= 360;
                        }
                    }
                    // eslint-disable-next-line no-param-reassign
                    arrow.rotationAngle = rotationAngle;
                });
                const authToken = auth.getToken();
                const url = await getTransientPreviewURL({
                    designDocument: existingDocument,
                    width: 400,
                    page: 1,
                    authToken
                });
                return url;
            }

            const front: any = canvasSelectorUrls.find(
                (selector: any) => selector.name.toLowerCase() === firstPanel.name?.toLowerCase()
            );

            return Promise.resolve(front?.src);
        };
        getUrl().then(url => setPreviewUrl(url));
    }, [canvasSelectorUrls, shouldFlipArrows, auth, getDocument]);

    const label = t(designPanelMessages.designTileDuplicate.id);

    const show = useShouldShowDuplicateFirstCanvasButton(dialogCanvasName);

    return (
        <>
            {show && (
                <DesignTileButton
                    selectedOption={DUPLICATE_FIRST_PANEL_TEMPLATE}
                    key={DUPLICATE_FIRST_PANEL_TEMPLATE}
                    ariaLabel={label}
                    className={className}
                >
                    <DesignTileButtonImage url={previewUrl} />
                    <DesignTileButtonLabel label={label} />
                </DesignTileButton>
            )}
        </>
    );
}
DuplicateDesignTile.displayName = "DuplicateDesignTile";
