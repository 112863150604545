import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
}
export function ShortcutDownArrowIcon(props: Props) {
    return (
        <svg viewBox="0 0 10 14" fill="none" {...props}>
            <path
                // eslint-disable-next-line max-len
                d="M4.99658 0.878418C4.80518 0.878418 4.65023 0.937663 4.53174 1.05615C4.41325 1.17464 4.354 1.32959 4.354 1.521V9.77881L4.4292 11.8022L4.81885 11.6313L2.59033 9.15674L1.12061 7.71436C1.06136 7.65511 0.993001 7.61182 0.915527 7.58447C0.838053 7.55257 0.753743 7.53662 0.662598 7.53662C0.480306 7.53662 0.329915 7.59587 0.211426 7.71436C0.0974935 7.83285 0.0405273 7.98096 0.0405273 8.15869C0.0405273 8.33187 0.106608 8.4891 0.23877 8.63037L4.51807 12.9097C4.58643 12.9826 4.66162 13.035 4.74365 13.0669C4.82568 13.1034 4.90999 13.1216 4.99658 13.1216C5.08773 13.1216 5.17432 13.1034 5.25635 13.0669C5.33838 13.035 5.41357 12.9826 5.48193 12.9097L9.75439 8.63037C9.89111 8.4891 9.95947 8.33187 9.95947 8.15869C9.95947 7.98096 9.90023 7.83285 9.78174 7.71436C9.66325 7.59587 9.51514 7.53662 9.3374 7.53662C9.24626 7.53662 9.16195 7.55257 9.08447 7.58447C9.007 7.61182 8.93864 7.65511 8.87939 7.71436L7.40967 9.15674L5.16748 11.6313L5.5708 11.8022L5.646 9.77881V1.521C5.646 1.32959 5.58447 1.17464 5.46143 1.05615C5.34294 0.937663 5.18799 0.878418 4.99658 0.878418Z"
                fill="currentColor"
            />
        </svg>
    );
}
ShortcutDownArrowIcon.displayName = "ShortcutDownArrowIcon";
