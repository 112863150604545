import React, { useEffect } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import { RedesignedUploadsPanelMessages, UploadsPanelMessages } from "@shared/features/UploadsAndAssets";
import { useAppSelector } from "@shared/redux";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { ReplaceDrawerPanelDisplay } from "@shared/features/ContextualToolbar/Images/ReplaceImage/ReplaceDrawerPanelDisplay";
import { useUploadQRABTest } from "@shared/features/QRUrlUploads";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import {
    PlacementStrategy,
    UploadButton,
    useActiveCanvas,
    useDesigner,
    useHandleImagePlacement,
    useSimpleSelection
} from "../../..";
import { addImageCallToAction } from "../../../../studioFive/components/Panels/Images/DrawerPanel/utils";
import { isImageUnreplacedPlaceholder, isLocked } from "../../../utilities";
import { useAssetsOnCanvas } from "../../../../studioFive/components/Panels/Images/DrawerPanel/useAssetsOnCanvas";
import { useUploadToDesignerSuccessCallback } from "../../../../studioFive/components/Panels/Images/DrawerPanel/useUploadToDesignerSuccessCallback";

interface Props {
    /**
     * An object with an onClick and onUpload behavior specified
     * Determines the strategy used for placing images on tap and on upload completed
     * */
    placementStrategy?: PlacementStrategy;

    /**
     * A callback to be executed when an upload is added to the canvas
     * */
    onUploadAdded?: () => void;

    /**
     * Optional. Boolean to control whether or not to open this when a placeholder has been selected
     * */
    openOnPlaceholderSelected?: boolean;

    /**
     * Optional. Class name for the div
     */
    className?: string;

    /**
     * Callback for how the user wants to open this panel
     */
    openPanel?: () => void;

    sideName?: string;
}

export function ReplaceDrawerPanel({
    placementStrategy,
    onUploadAdded,
    openPanel,
    openOnPlaceholderSelected = false,
    ...rest
}: Props) {
    const { onImageClicked, onImageDoubleClicked, onImageUploaded } = useHandleImagePlacement({
        placementStrategy,
        onUploadAdded
    });
    const { usedAssets } = useAssetsOnCanvas();
    const designer = useDesigner();
    const canvas = useActiveCanvas();
    const activeCanvasName = canvas && canvas.name;
    const designRequirements = useDesignRequirementsContext();
    const isUploadModalAutoLoaded = useAppSelector(state => state.isUploadModalAutoLoaded);
    const { isSmall, isMedium } = useStudioLayout();
    const activePanelId = activeCanvasName ? designRequirements?.getPanelByName(activeCanvasName)?.id : undefined;

    const onDoubleClick = (asset: VistaAsset, pageNumber: number) => {
        addImageCallToAction({ designer, asset, pageNumber, callback: onImageDoubleClicked });
    };

    const onClick = (asset: VistaAsset, pageNumber: number) => {
        // Fire tracking event for when a recently uploaded image is added to the canvas
        // from the auto loaded replace image modal
        isUploadModalAutoLoaded &&
            fireDesignToolTrackingEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.CLICK_RECENTLY_UPLOADED_IMAGE.replace(
                    "[CanvasSide]",
                    activeCanvasName || ""
                ),
                label: "General",
                extraData: () => ({
                    productSurfaceId: activePanelId,
                    activeCanvas: activeCanvasName
                })
            } as any);
        addImageCallToAction({ designer, asset, pageNumber, callback: onImageClicked });
    };
    const { t } = useTranslationSSR();
    const isReplaceOpenOnCanvasChange = useAppSelector(state => state.isReplaceOpenOnCanvasChange);
    const { isQRCodeImageUploadABEnabled } = useUploadQRABTest();
    const addRemoveSelection = useSimpleSelection("add remove");

    useUploadToDesignerSuccessCallback(onImageUploaded, onUploadAdded);

    useEffect(() => {
        // We need to check to make sure there is only one element selected,
        // and that the element is an image that is a placeholder and that has not been replaced.
        if (
            addRemoveSelection.length === 1 &&
            isImageUnreplacedPlaceholder(addRemoveSelection[0]) &&
            openOnPlaceholderSelected === true &&
            openPanel &&
            isLocked(addRemoveSelection[0])
        ) {
            openPanel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addRemoveSelection]);

    let qrEnabledUploadButtonText = t(UploadsPanelMessages.uploadButtonWithExperiment.id);
    if (isSmall) {
        qrEnabledUploadButtonText = t(UploadsPanelMessages.uploadButtonMobile.id);
    } else if (isMedium) {
        qrEnabledUploadButtonText = t(UploadsPanelMessages.uploadButtonTablet.id);
    }

    let uploadButtonText = !isReplaceOpenOnCanvasChange
        ? t(RedesignedUploadsPanelMessages.uploadButton.id)
        : t(RedesignedUploadsPanelMessages.uploadImageButton.id);

    if (isQRCodeImageUploadABEnabled) {
        uploadButtonText = qrEnabledUploadButtonText;
    }

    const uploadButton = (
        <UploadButton
            isUploadModalAutoLoaded={isUploadModalAutoLoaded}
            isImageUpload={false}
            aria-describedby="upload-image-allowed-formats"
        >
            {uploadButtonText}
        </UploadButton>
    );

    return (
        <ReplaceDrawerPanelDisplay
            onAssetClick={onClick}
            onAssetDoubleClick={onDoubleClick}
            uploadButton={uploadButton}
            usedAssets={usedAssets}
            activeCanvasName={activeCanvasName}
            {...rest}
        />
    );
}
ReplaceDrawerPanel.displayName = "ReplaceDrawerPanel";
