import React, { useEffect, useState } from "react";
import { Typography } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useAppSelector } from "@shared/redux";
import { mapDesignAttributeToProductOption, mapDesignAttributeValueToProductOptionValue } from "@shared/utils/Product";
import { LoadingSpinner, SpinnerVariant } from "@shared/features/StudioChrome";
import { previewLoadingMessages } from "@shared/utils/Previews";
import { StudioPreview, type StudioVortexPreviewProps } from "@shared/features/Previews";
import loadable from "@loadable/component";
import { loadableRetry } from "@shared/utils/Network";
import { useFlexibilityOptionPanel } from "./FlexibilityOptionPanelProvider";
import { type DesignDocumentDataRecord } from "../hooks/useFlexibilityDesignDocuments";
import * as styles from "./FlexibilityOptionPreview.module.scss";

const StudioVortexPreview = loadable<
    StudioVortexPreviewProps,
    { StudioVortexPreview: React.ComponentType<StudioVortexPreviewProps> }
>(() => loadableRetry(() => import("@shared/features/Previews")), {
    resolveComponent: components => components.StudioVortexPreview
});

export function FlexibilityOptionPreview({ designDocuments }: { designDocuments: DesignDocumentDataRecord }) {
    const [previewInstructionsUrl, setPreviewInstructionsUrl] = useState<string>();

    const designAttributeMappings = useAppSelector(state => state.designAttributeMappings);
    const { currentDesignAttributeValue, designAttributeName, forcePreviewFallback } = useFlexibilityOptionPanel();
    const { t } = useTranslationSSR();

    const previewsObject =
        designDocuments[currentDesignAttributeValue] || designDocuments[Object.keys(designDocuments)[0]];
    const previews = previewsObject?.previewUrls || [];

    const productOptionName = mapDesignAttributeToProductOption(designAttributeMappings, designAttributeName);
    const currentOptionValue = mapDesignAttributeValueToProductOptionValue(
        designAttributeMappings,
        designAttributeName,
        currentDesignAttributeValue
    );

    useEffect(() => {
        // This is just holding on to the old preview.
        // Otherwise we can lose access to an instructions url when changing options with the preview showing
        // and that tends to break the preview so that it doesn't come back
        // this way we can show the old preview while the new one is being generated
        setPreviewInstructionsUrl(previewsObject?.instructionsUrl);
    }, [previewsObject]);

    return (
        <>
            {previewInstructionsUrl && (
                <StudioVortexPreview
                    vortexClassName={styles.vortexPreviewContainer}
                    fallbackComponent={(initialSelection: number) => (
                        <StudioPreview
                            className={styles.studioPreviewContainer}
                            initialSelection={initialSelection}
                            previewUrls={previews}
                            isLoading={Object.keys(designDocuments).length === 0}
                            buttonBarControls={true}
                            loadingSpinnerComponent={
                                <div>
                                    <LoadingSpinner variant={SpinnerVariant.Large} />
                                    <Typography fontSize="3">{t(previewLoadingMessages.loadingPreview.id)}</Typography>
                                </div>
                            }
                        />
                    )}
                    previewInstructionsUrl={previewInstructionsUrl}
                    previewCount={previews.length}
                    previewIndex={0}
                    controlsClassName={styles.vortexPreviewControls}
                    forceFallback={forcePreviewFallback}
                    {...(currentOptionValue ? { settingsOveride: { [productOptionName]: currentOptionValue } } : {})}
                />
            )}
        </>
    );
}

FlexibilityOptionPreview.displayName = "FlexibilityOptionPreview";
