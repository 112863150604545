import React, { useState } from "react";
import { DesignTile, DesignColorSwatchPicker } from "@design-stack-vista/gallery-ui";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import type { DSS } from "@vp/types-ddif";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { Typography } from "@vp/swan";
import classNames from "classnames";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppSelector } from "@shared/redux";
import type { ColorSwatch, Design } from "@shared/utils/Gallery";
import { changeTemplateMessages } from "./messages";
import type { GenerateDocumentForNewTemplateReturn } from "./changeTemplateClient";
import * as styles from "./DesignTileContainer.module.scss";

const messages = defineMessages({
    defaultChangeColorOptionLabel: {
        id: "studio.components.changeTemplateColor.defaultChangeColorOptionLabel",
        defaultMessage: "Color Option",
        description: {
            note: "default label for color swatch"
        }
    }
});

interface DesignTileContainerProps {
    design: Design;
    changeTemplate: (
        template: string,
        studioSelectedProductOptions: Record<string, string>,
        designDocument: DSS.DesignDocument
    ) => Promise<boolean>;
    generateNewDocument: (
        template: string,
        studioSelectedProductOptions: Record<string, string>,
        removeBackground?: boolean
    ) => Promise<GenerateDocumentForNewTemplateReturn>;
    setConfirmationModalData: (data: {
        selectedDesign: Design;
        selectedSwatch: ColorSwatch;
        designDocument: DSS.DesignDocument;
    }) => void;
    setIsConfirmationModalOpen: (value: React.SetStateAction<boolean>) => void;
    setCurrentTemplateColorSwatch: (value: React.SetStateAction<ColorSwatch>) => void;
    onRequestDismiss: (changeTemplateInitiated?: boolean) => void;
    isSelectedDesign: boolean;
}

export const DesignTileContainer = ({
    design,
    generateNewDocument,
    setIsConfirmationModalOpen,
    setCurrentTemplateColorSwatch,
    setConfirmationModalData,
    changeTemplate,
    onRequestDismiss,
    isSelectedDesign
}: DesignTileContainerProps) => {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const { colorSwatches, selectedColorSwatchDesignId, previewUrls, previewInfo } = design;
    const filteredColorSwatches = colorSwatches.filter((v, i, a) => a.findIndex(v2 => v2.color === v.color) === i);
    const template = useAppSelector(state => state.template);
    const locale = useAppSelector(state => state.locale);
    const [selectedColorSwatch, setSelectedColorSwatch] = useState<ColorSwatch>(
        colorSwatches.find(({ designId, previewInfo: { templateToken } }: ColorSwatch) =>
            isSelectedDesign ? templateToken === template : designId === selectedColorSwatchDesignId
        ) as ColorSwatch
    );

    const currentPreviewUrls = selectedColorSwatch?.previewUrls || previewUrls;
    const previewUrl = currentPreviewUrls.size2x;
    const { aspectRatio } = previewInfo;

    // hardcoding in a maximum height for now since we only need to worry about business cards
    const maxHeight = aspectRatio >= 1 ? 254 : 300;

    return (
        <DesignTile
            key={design.designId}
            className={classNames(styles.designTile, {
                [styles.designTileSelected]: isSelectedDesign
            })}
            onClick={async () => {
                if (!isSelectedDesign) {
                    fireDesignToolTrackingEvent({
                        eventDetail: STUDIO_TRACKING_EVENTS.CLICK_CHANGE_TEMPLATE,
                        label: "Click a template in change template dialog"
                    });
                    const selectedTemplateSource = selectedColorSwatch || design;
                    const selectedTemplate = selectedTemplateSource.previewInfo.templateToken;

                    const { designDocument, requiresConfirmation } = await generateNewDocument(
                        selectedTemplate,
                        design.fullProductOptions,
                        true
                    );
                    if (designDocument) {
                        if (requiresConfirmation) {
                            setIsConfirmationModalOpen(true);
                            setConfirmationModalData({
                                selectedDesign: design,
                                selectedSwatch: selectedColorSwatch,
                                designDocument
                            });
                        } else {
                            changeTemplate(selectedTemplate, design.fullProductOptions, designDocument);
                            onRequestDismiss();
                        }
                    }
                }
            }}
            previewHeight={maxHeight}
            imageProps={{
                alt: t(changeTemplateMessages.designTilePreviewAlt.id),
                // @ts-ignore Check with gallery ui package owners
                aspectRatio,
                maxPreviewHeight: [maxHeight, false],
                srcSet: previewUrl
            }}
        >
            {isSelectedDesign && (
                <Typography fontSize={1} className={styles.badge}>
                    {t(changeTemplateMessages.currentTemplate.id)}
                </Typography>
            )}
            <DesignColorSwatchPicker
                designColorSwatches={filteredColorSwatches}
                colorSwatchesProps={{
                    sizeVariant: isSmall ? "standard" : "super"
                }}
                selectedDesignId={selectedColorSwatch?.designId || selectedColorSwatchDesignId}
                onDesignSelectionChange={colorSwatch => {
                    fireDesignToolTrackingEvent({
                        eventDetail: STUDIO_TRACKING_EVENTS.CLICK_GALLERY_TEMPLATE_COLOR,
                        label: "Click a color swatch in change template dialog"
                    });
                    setSelectedColorSwatch(colorSwatch);
                    if (isSelectedDesign) {
                        setCurrentTemplateColorSwatch(colorSwatch);
                    }
                }}
                collapse={true}
                maxColorSwatches={isSmall ? 4 : 9}
                locale={locale}
                defaultColorLabel={t(messages.defaultChangeColorOptionLabel.id)}
            />
        </DesignTile>
    );
};
DesignTileContainer.displayName = "DesignTileContainer";
