import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { CUSTOM_SELECTED_TEMPLATE } from "@shared/utils/Templates";
import { useIsPaidUpsellContext } from "@shared/features/Product";
import { designPanelMessages } from "../../designPanelMessages";
import { DesignTileButton } from "../DesignTileButton";
import { DesignTileButtonLabel } from "../DesignTileButtonLabel";
import { useDesignDialog } from "../../DesignDialogProvider";
import * as styles from "./StartFromScratchDesignTile.module.scss";

interface Props {
    imageHeight?: number;
    className?: string;
}

/**+
 * Tile to add to the Design Tiles Panel that allows the user to start their design from scratch
 */
export function StartFromScratchDesignTile({ imageHeight, className }: Props) {
    const { t } = useTranslationSSR();
    const { dialogCanvasName, isAddDialog } = useDesignDialog();
    const isPaidUpsell = useIsPaidUpsellContext();
    const label =
        isAddDialog() && isPaidUpsell(dialogCanvasName)
            ? t(designPanelMessages.designStartFromBlank.id)
            : t(designPanelMessages.designChangeToBlank.id);

    return (
        <DesignTileButton
            selectedOption={CUSTOM_SELECTED_TEMPLATE}
            key={CUSTOM_SELECTED_TEMPLATE}
            ariaLabel={label}
            className={className}
        >
            <div className={styles.customIcon} style={{ paddingBottom: `${imageHeight}%` }} />

            <DesignTileButtonLabel label={label} />
        </DesignTileButton>
    );
}
StartFromScratchDesignTile.displayName = "StartFromScratchDesignTile";
