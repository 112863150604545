import React from "react";
import { useDocumentImages } from "../../../easel/designer-suite/PanelComponents/Uploads/useDocumentImages";
import { DesignerProcessingSpinner } from "./DesignerProcessingSpinner";

export const LoadingSpinnerLayer = () => {
    const imagesOnPanel = useDocumentImages(true);

    return (
        <>
            {imagesOnPanel.map(image => (
                <DesignerProcessingSpinner
                    key={`spinner-${image.id}`}
                    imageViewModel={image._itemViewModel as ImageViewModel}
                />
            ))}
        </>
    );
};

LoadingSpinnerLayer.displayName = "LoadingSpinnerLayer";
