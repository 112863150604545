import React from "react";
import { useTranslation } from "@vp/i18n-helper";
import classNames from "classnames";
import {
    Typography,
    Button,
    Divider,
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogButtons,
    LegacyModalDialogTitle,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton,
    LegacyModalDialogFooter,
    LegacyModalDialogNav,
    BasicCollapsible
} from "@vp/swan";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { POSTCARD_PRDS, useMailingServicesContext } from "@shared/features/MailingServices";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppDispatch, autoClosableAlert, setAlerts } from "@shared/redux";
import { getDefaultProductOptions, useProductOptions } from "../../hooks/useProductOptions";
import { mailingServicesMessages } from "./messages";
import { useSwitchProducts } from "../contexts/SwitchProductsContext";
import "./MailingServicesFAQModal.scss";

const MailingServicesFAQContent = [
    {
        option: "one",
        header: mailingServicesMessages.mailingServiceFaqHeaderOne.id,
        content: mailingServicesMessages.mailingServiceFaqContentOne.id
    },
    {
        option: "two",
        header: mailingServicesMessages.mailingServiceFaqHeaderTwo.id,
        content: mailingServicesMessages.mailingServiceFaqContentTwo.id
    },
    {
        option: "three",
        header: mailingServicesMessages.mailingServiceFaqHeaderThree.id,
        content: mailingServicesMessages.mailingServiceFaqContentThree.id
    }
];
export function MailingServicesFAQModal() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isMailingServicesFAQModalOpen, setIsMailingServicesFAQModalOpen, isMailingPostCard } =
        useMailingServicesContext();
    const { customerSelectedProductOptions, locale } = useProductOptions();
    const { switchMSProduct } = useSwitchProducts();
    const { isSmall } = useStudioLayout();

    const mailingServicesFAQModalClose = () => {
        setIsMailingServicesFAQModalOpen(false);
    };

    const removeMailingServices = async () => {
        mailingServicesFAQModalClose();
        const productOptions = await getDefaultProductOptions({
            productKey: POSTCARD_PRDS.NON_MAILING,
            customerSelectedProductOptions,
            locale
        });
        productOptions && switchMSProduct({ productOptions, isMailingPostCard });
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_REMOVE_MAILING_SERVICES_BUTTON,
            label: "mailing services is removed for Postcard"
        } as any);
        dispatch(
            setAlerts({
                alerts: [
                    {
                        key: "studio.components.Toast.mailingServicesRemoved"
                    }
                ]
            })
        );
        dispatch(autoClosableAlert());
    };

    return (
        <LegacyModalDialog
            variant={isSmall ? "panel-bottom" : "panel-right"}
            bodyWidth="standard"
            isOpen={isMailingServicesFAQModalOpen}
            onRequestDismiss={mailingServicesFAQModalClose}
            className="mailing-services-faq-modal"
            takeOver={isSmall}
        >
            <LegacyModalDialogContent aria-label={t(mailingServicesMessages.faqDialogAriaLabel.id)}>
                <LegacyModalDialogNav>
                    <LegacyModalDialogCloseButton visuallyHiddenLabel={t(mailingServicesMessages.close.id)} />
                </LegacyModalDialogNav>

                <LegacyModalDialogHeader className="mailing-services-faq-modal-header">
                    <LegacyModalDialogTitle>
                        {" "}
                        {t(mailingServicesMessages.mailingServiceFaqTitleOne.id)}
                    </LegacyModalDialogTitle>
                    <Typography fontWeight="bold" mb={5} mt={6} fontSize="3">
                        {t(mailingServicesMessages.mailingServiceFaqTitleTwo.id)}
                    </Typography>
                </LegacyModalDialogHeader>
                {MailingServicesFAQContent.map((mailingFAQContentOptions, index) => (
                    <div key={mailingFAQContentOptions.option}>
                        <Divider
                            className={classNames({ "faq-modal-divider": index === 0 }, "faq-modal-sub-divider")}
                        />
                        <BasicCollapsible
                            heading={t(mailingFAQContentOptions.header)}
                            className="mailing-services-faq-modal-contents"
                        >
                            {t(mailingFAQContentOptions.content)}
                        </BasicCollapsible>
                    </div>
                ))}

                <LegacyModalDialogFooter pinned className="mailing-services-faq-modal-footer">
                    <LegacyModalDialogButtons>
                        <Button
                            className="remove-mailing-services-remove-button"
                            skin="secondary"
                            width="full-width"
                            onClick={removeMailingServices}
                        >
                            {t(mailingServicesMessages.removeMailingServices.id)}
                        </Button>
                        <Button skin="secondary" width="full-width" onClick={mailingServicesFAQModalClose}>
                            {t(mailingServicesMessages.cancel.id)}
                        </Button>
                    </LegacyModalDialogButtons>
                </LegacyModalDialogFooter>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}
MailingServicesFAQModal.displayName = "MailingServicesFAQModal";
