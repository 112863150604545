import { VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import classNames from "classnames";
import React, { ReactNode } from "react";
import { useDrop, XYCoord } from "react-dnd";
import { DropTargetIcon } from "@shared/features/StudioChrome";
import { DraggableTypes } from "./Constants";

export interface DropType {
    asset: VistaAsset;
    pageNumber: number;
}

interface CollectedType {
    isOver: boolean;
    isOverCurrent: boolean;
    canDrop: boolean;
}

interface DroppableTargetProps {
    onDrop?: (item: DropType, position?: XYCoord | null) => void;
    className?: string;
    dropTargetElement?: JSX.Element;
    children?: ReactNode | ReactNode[];
}

export const DroppableTarget = (props: DroppableTargetProps) => {
    const { onDrop, className, dropTargetElement, children } = props;
    const [{ isOver, canDrop, isOverCurrent }, drop] = useDrop<DropType, unknown, CollectedType>({
        // Accept will make sure only these element type can be droppable on this element
        accept: DraggableTypes.UploadedImage,
        drop(item, monitor) {
            if (isOverCurrent) {
                const offset = monitor.getClientOffset();
                onDrop?.(item, offset);
            }
        },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
            isOverCurrent: monitor.isOver({ shallow: true })
        })
    });
    const isActive = isOver && canDrop;
    let suffix;
    if (isActive) {
        if (isOverCurrent) {
            suffix = "active-current";
        } else {
            suffix = "active";
        }
    } else if (canDrop) {
        suffix = "can-drop";
    } else {
        return null;
    }
    return (
        <div ref={drop} className={classNames("drop-target", `${className}-${suffix}`)}>
            {children}
            {dropTargetElement || <DropTargetIcon className="drop-target-image" />}
        </div>
    );
};

DroppableTarget.displayName = "DroppableTarget";
