import React, { useCallback, useEffect, useMemo, useState, forwardRef, Ref, useRef } from "react";
import ReactDOM from "react-dom";
import { Typography } from "@vp/swan";
import { Severity, useBrickState } from "@design-stack-vista/smart-validations-ui";
import { useOnKeyDown } from "@shared/features/Accessibility";
import { ValidationIcon } from "@shared/features/StudioChrome";
import type { DSS } from "@vp/types-ddif";
import { useDesigner } from "@designer-suite";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useAppSelector } from "@shared/redux";
import { ItemPosition, getRotatedPoints, areVerticesOnCanvas, getRectangleVertices } from "./PointUtilities";
import { StudioValidationCanvasBrick } from "./StudioValidationCanvasBrick";
import { findPolygonIntersections, getPositions } from "./ValidationUtils";
import "./validationOverlay.scss";

export function getPositionAttributes(viewModel: ItemViewModel): ItemPosition {
    let rotation = viewModel.model.get("rotation");
    rotation = ((rotation % 360) + 360) % 360;
    return {
        left: viewModel.model.get("left"),
        top: viewModel.model.get("top"),
        width: viewModel.model.get("width"),
        height: viewModel.model.get("height"),
        rotation
    };
}

type Props = {
    data: DSS.StudioValidation[];
    showBrick: boolean;
    setSelectedItemIdHovered: React.Dispatch<React.SetStateAction<string>>;
    setSelectedItemIdClicked: React.Dispatch<React.SetStateAction<string>>;
};

const BADGE_WIDTH_IN_PX = 38;
const BADGE_HEIGHT_IN_PX = 24 - 4;
// These is padding that is specified by the UX designer
const BADGE_PADDING_OUTSIDE_BOX = 14;
const BADGE_PADDING_ALONG_EDGE = 10;

/**
 * This function calculates the intersection between the item and the canvas.
 * @canvasItem item that is checking the intersection
 * @canvas canvas to check the intersection on
 * @returns the intersection rectangle.
 */
const calculateIntersections = (canvasItem: CanvasItem | undefined, canvas: Canvas | undefined) => {
    if (!canvasItem || !canvas) {
        return null;
    }
    const itemPosition = getPositionAttributes(canvasItem._itemViewModel);
    const itemPoints = getPositions(getRotatedPoints(itemPosition));
    const { mmDimensions } = canvas;
    const canvasPoints = getPositions(getRectangleVertices({ x: 0, y: 0, ...mmDimensions }));
    return findPolygonIntersections(itemPoints, canvasPoints);
};

const convertToTwoDecimalPlaces = (f: number) => Math.round(f * 100) / 100;

/**
 * Find the next point from the starting point in the point array
 * @param points list of point to search through
 * @param startingPoint point to start from
 * @param forward go forward or backward from the starting point
 * @returns Point the neighboringPoint.
 */
const findNextPoint = (points: Point[], startingPoint: Point, forward: boolean) => {
    // Since the poitns are a ring, the first point is the same as the last one.
    // we want to take out the last one so we wrap properly.
    const pts = points.slice(0, points.length - 1);
    let index = pts.findIndex(
        pt =>
            convertToTwoDecimalPlaces(pt.x) === convertToTwoDecimalPlaces(startingPoint.x) &&
            convertToTwoDecimalPlaces(pt.y) === convertToTwoDecimalPlaces(startingPoint.y)
    );
    if (forward) {
        index += 1;
    } else {
        index -= 1;
    }
    if (index >= pts.length) {
        index = 0;
    } else if (index < 0) {
        index = pts.length - 1;
    }
    return pts[index];
};

enum OffsetDir {
    x,
    y,
    none
}

const shouldUseCornerPosition = (cornerPoint: Point, targetPoint: Point, zoomFactor: number, currentZoom: number) => {
    const xDiff = Math.abs(cornerPoint.x - targetPoint.x) * zoomFactor;
    const yDiff = Math.abs(cornerPoint.y - targetPoint.y) * zoomFactor;
    // The extra 4 is to take into account the selection corner.
    const padding = BADGE_PADDING_OUTSIDE_BOX + 10 * currentZoom;
    if (xDiff > yDiff) {
        if (xDiff < BADGE_WIDTH_IN_PX + padding) {
            return OffsetDir.x;
        }
    } else if (yDiff < BADGE_HEIGHT_IN_PX + padding) {
        return OffsetDir.y;
    }
    return OffsetDir.none;
};

const calculateEdgePoint = (
    intersectingVerticies: Point[],
    cornerPoint: Point,
    forward: boolean,
    zoomFactor: number,
    currentZoom: number
) => {
    let offsetPx = BADGE_WIDTH_IN_PX / 2 + BADGE_PADDING_OUTSIDE_BOX;
    const point = findNextPoint(intersectingVerticies, cornerPoint, forward);
    const useEdgePoint = shouldUseCornerPosition(cornerPoint, point, zoomFactor, currentZoom);
    if (useEdgePoint === OffsetDir.x) {
        if (cornerPoint.x - point.x < 0) {
            offsetPx *= -1;
        }
        return { ...cornerPoint, x: cornerPoint.x + offsetPx / zoomFactor };
    }
    if (useEdgePoint === OffsetDir.y) {
        const otherPoint = findNextPoint(intersectingVerticies, cornerPoint, !forward);
        if (otherPoint.x - cornerPoint.x > 0) {
            offsetPx *= -1;
        }
        return { ...cornerPoint, x: cornerPoint.x + offsetPx / zoomFactor };
    }
    return point;
};

/**
 * Get the point to position a badge at
 * @param controlElement The HTML element that the badge is attaching to
 * @param canvasItem The canvas item to be used
 * @param canvas The canvas the item is on
 * @returns Point - currently defaults to center when it doesn't know what it should be
 */
const calculateBadgePosition = (
    controlElement: HTMLElement | null,
    canvasItem: CanvasItem | undefined,
    canvas: Canvas | undefined
) => {
    if (!canvas || !canvasItem || !controlElement) {
        return { x: 0, y: 0 };
    }
    const itemPosition = getPositionAttributes(canvasItem._itemViewModel);
    const vertices = getRotatedPoints(itemPosition);
    const { topLeft, topRight, topMiddle, bottomLeft, bottomRight, bottomMiddle } = vertices;
    const { mmDimensions } = canvas;
    const {
        topLeftOnCanvas,
        topRightOnCanvas,
        bottomLeftOnCanvas,
        bottomRightOnCanvas,
        topMiddleOnCanvas,
        bottomMiddleOnCanvas
    } = areVerticesOnCanvas(vertices, mmDimensions);

    if (topMiddleOnCanvas) {
        return topMiddle;
    }
    if (bottomMiddleOnCanvas) {
        return bottomMiddle;
    }
    const intersectingVerticies = calculateIntersections(canvasItem, canvas);
    const zoomFactor = canvas?._canvasViewModel.get("zoomFactor") || 1;
    const currentZoom = canvas?._canvasViewModel.get("currentZoom") || 1;
    if (intersectingVerticies) {
        if (topRightOnCanvas) {
            return calculateEdgePoint(intersectingVerticies, topRight, false, zoomFactor, currentZoom);
        }
        if (topLeftOnCanvas) {
            return calculateEdgePoint(intersectingVerticies, topLeft, true, zoomFactor, currentZoom);
        }
        if (bottomRightOnCanvas) {
            return calculateEdgePoint(intersectingVerticies, bottomRight, true, zoomFactor, currentZoom);
        }
        if (bottomLeftOnCanvas) {
            return calculateEdgePoint(intersectingVerticies, bottomLeft, false, zoomFactor, currentZoom);
        }
        return topMiddle;
    }
    return { x: mmDimensions.width / 2, y: mmDimensions.height / 2 };
};

const calculateBadgePositionInPixels = (
    controlElement: HTMLElement | null,
    canvasItem: CanvasItem | undefined,
    canvas: Canvas | undefined
) => {
    const pos = calculateBadgePosition(controlElement, canvasItem, canvas);
    const zoomFactor = canvas?._canvasViewModel.get("zoomFactor") || 1;
    const currentZoom = canvas?._canvasViewModel.get("currentZoom") || 1;
    const padding = BADGE_PADDING_ALONG_EDGE * currentZoom;
    const pointInPx = { x: pos.x * zoomFactor, y: pos.y * zoomFactor };
    if (pointInPx.x <= BADGE_WIDTH_IN_PX / 2 + padding) {
        pointInPx.x = BADGE_WIDTH_IN_PX / 2 + padding;
    }
    if (pointInPx.y <= BADGE_HEIGHT_IN_PX / 2 + padding) {
        pointInPx.y = BADGE_HEIGHT_IN_PX / 2 + padding;
    }
    if (canvas) {
        const {
            pxDimensions: { width, height }
        } = canvas;
        if (pointInPx.x > width - (BADGE_WIDTH_IN_PX / 2 + padding)) {
            pointInPx.x = width - (BADGE_WIDTH_IN_PX / 2 + padding);
        }
        if (pointInPx.y > height - (BADGE_HEIGHT_IN_PX / 2 + padding)) {
            pointInPx.y = height - (BADGE_HEIGHT_IN_PX / 2 + padding);
        }
    }
    return pointInPx;
};

export const ValidationOverlay = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
    const { data, showBrick, setSelectedItemIdHovered, setSelectedItemIdClicked } = props;
    const { itemId, canvasId, validationName } = data[0];
    const highlightableBrickId = `${itemId}-${validationName}`;
    const controlElement = document.getElementById(`${canvasId}`);
    const designer = useDesigner();
    const { isSmall } = useStudioLayout();
    const showDesignIssues = useAppSelector(state => state.showDesignIssues);
    const [badgePos, setBadgePos] = useState<Point>({ x: 0, y: 0 });
    const { canvas, canvasItem } = useMemo(() => {
        if (!designer || !controlElement) {
            return { canvas: undefined, canvasItem: undefined };
        }
        const canvas = designer.api.design.canvases.find(item => item.id === canvasId);
        const canvasItem = canvas?.items.find(item => item.id === itemId);

        const pos = calculateBadgePositionInPixels(controlElement, canvasItem, canvas);
        setBadgePos(pos);

        return {
            canvas,
            canvasItem
        };
    }, [designer, itemId, canvasId, controlElement]);
    const { setHighlightedBrickId } = useBrickState();
    // Keep track of hover on validation icon
    const hasBrickHoverRef = useRef(false);

    const calculatePos = useCallback(() => {
        const pos = calculateBadgePositionInPixels(controlElement, canvasItem, canvas);
        setBadgePos(pos);
    }, [canvas, canvasItem, controlElement]);

    useEffect(() => {
        const events =
            "change:temporaryRotation change:handleBoundingBox change:selecting change:rotating change:resizing change:dragging";
        canvasItem?._itemViewModel.on(events, calculatePos);
        return () => {
            canvasItem?._itemViewModel.off(events, calculatePos);
        };
    }, [canvasItem, calculatePos]);

    const toggleHighlihtedBrickId = useCallback(
        event => {
            if (event.type === "mouseenter") {
                setHighlightedBrickId(highlightableBrickId);
                // This event triggers before the event on onMouseEnter/onMouseLeave events on the validation icon,
                // so we need to check if there was a hover there, and only if NOT we can reset the highlight
            } else if (!hasBrickHoverRef.current) {
                setHighlightedBrickId(undefined);
            }
        },
        [setHighlightedBrickId, highlightableBrickId]
    );

    // It is necessary to use DOM events, because attaching a Backbone model event to `change:over` gets into a loop state when the panel brick was being hovered.
    useEffect(() => {
        // @ts-ignore
        if (!window || !window.$) {
            return;
        }
        // @ts-ignore
        window.$(document).on("mouseenter mouseleave", `#${itemId}-handle`, toggleHighlihtedBrickId);
        // eslint-disable-next-line consistent-return
        return () => {
            // @ts-ignore
            window.$(document).off("mouseenter mouseleave", `#${itemId}-handle`, toggleHighlihtedBrickId);
        };
    }, [toggleHighlihtedBrickId, itemId]);

    const handleClick = () => {
        designer?.selectionManager.select([]);
        setSelectedItemIdClicked(current => {
            return current === itemId ? "" : itemId;
        });
    };

    const handleHover = () => {
        setSelectedItemIdHovered(itemId);
        setHighlightedBrickId(highlightableBrickId);
        hasBrickHoverRef.current = true;
    };

    const handleUnhover = () => {
        setSelectedItemIdHovered("");
        setHighlightedBrickId(undefined);
        hasBrickHoverRef.current = false;
    };

    const onKeyDown = useOnKeyDown(handleClick);
    if (!controlElement || !data.length || !canvasItem) {
        return null;
    }

    const isError = data.some(validation => validation.severity === Severity.ERROR);
    const suffix = isError ? Severity.ERROR : Severity.WARNING;
    const temporaryPositionDelta = canvasItem._itemViewModel.get("temporaryPositionDelta");
    const temporaryRotation = canvasItem._itemViewModel.get("temporaryRotation") !== undefined;
    // Don't show the badge if the item is being rotated or moved.
    if (temporaryPositionDelta || temporaryRotation) {
        return null;
    }

    // Sets the z-index for the validation-badge-brick-container progressively over 10, based on the canvas item z-index.
    // A 10 base sum secures that the badge is on top of all elements of the canvas
    // In case the user hovers on the validation badge, it sets the z-index on top of all defined badges (items.length + 11, based on the previously set 10 based)
    const zIndex =
        canvasItem._itemViewModel.model.get("zIndex") + (showBrick && canvas ? canvas.items.length + 11 : 10);

    return (
        <>
            {(showDesignIssues || isError) &&
                ReactDOM.createPortal(
                    <div
                        className="validation-badge-brick-container"
                        onClick={handleClick}
                        onMouseLeave={handleUnhover}
                        onBlur={handleUnhover}
                        role="button"
                        onKeyDown={onKeyDown}
                        ref={ref}
                        tabIndex={0}
                        style={{
                            top: `${badgePos.y}px`,
                            left: `${badgePos.x}px`,
                            zIndex
                        }}
                        data-dcl-prevent-canvas-items-deselection
                    >
                        <div
                            className="validation-badge"
                            role="button"
                            tabIndex={0}
                            onMouseEnter={handleHover}
                            onFocus={handleHover}
                        >
                            <ValidationIcon className={`validation-badge-icon validation-badge-icon-${suffix}`} />
                            {data.length > 1 && (
                                <Typography
                                    fontSize="-1"
                                    className={`validation-badge-text validation-badge-text-${suffix}`}
                                >
                                    {data.length}
                                </Typography>
                            )}
                        </div>
                        {!isSmall && showBrick && <StudioValidationCanvasBrick data={data[0]} />}
                    </div>,
                    controlElement
                )}
        </>
    );
});

ValidationOverlay.displayName = "ValidationOverlay";
