import React, { useState, useEffect } from "react";
import { Typography, FlexBox } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { LoadingSpinner, SpinnerVariant } from "@shared/features/StudioChrome";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppSelector } from "@shared/redux";
import type { DSS } from "@vp/types-ddif";
import type { Save } from "@shared/features/Save";
import type { Design } from "@shared/utils/Gallery";
import { DesignTileContainer } from "./DesignTileContainer";
import { useChangeTemplate } from "./useChangeTemplate";
import { useGallery } from "./GalleryProvider";
import { changeTemplateMessages } from "./messages";
import { ConfirmationModal } from "./ConfirmationModal";
import * as styles from "./ChangeTemplateGallery.module.scss";
import type { LoadNewDesign } from "./changeTemplateClient";

interface ChangeTemplateGalleryProps {
    designs: Design[];
    onRequestDismiss: (changeTemplateInitiated?: boolean) => void;
    onTemplateColorChange: (hasTemplateColorChanged: boolean) => void;
    getDocument: () => Promise<DSS.DesignDocument>;
    loadNewDesign: LoadNewDesign;
    save: Save;
}

export const ChangeTemplateGallery = ({
    designs,
    onRequestDismiss,
    onTemplateColorChange,
    getDocument,
    loadNewDesign,
    save
}: ChangeTemplateGalleryProps) => {
    const {
        loading,
        changeTemplateSearchTerm,
        currentDesign,
        currentTemplateColorSwatch,
        setCurrentDesign,
        setCurrentTemplateColorSwatch
    } = useGallery();
    const { changeTemplate, generateNewDocument } = useChangeTemplate({ getDocument, loadNewDesign, save });
    const { t } = useTranslationSSR();
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [confirmationModalData, setConfirmationModalData] = useState<any>({});
    const currentTemplate = useAppSelector(state => state.template);

    useEffect(() => {
        if (isConfirmationModalOpen) {
            fireDesignToolTrackingEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.CHANGE_TEMPLATE_CONFIRMATION_SHOWN,
                label: "Change template confirmation modal shown"
            });
        }
    }, [isConfirmationModalOpen]);

    useEffect(() => {
        if (
            designs.length > 0 &&
            !isConfirmationModalOpen &&
            !loading &&
            currentDesign &&
            currentTemplateColorSwatch?.previewInfo?.designId === currentDesign?.designId
        ) {
            onTemplateColorChange(currentTemplateColorSwatch?.previewInfo?.templateToken !== currentTemplate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConfirmationModalOpen, loading, currentDesign, currentTemplateColorSwatch]);

    if (loading) {
        return (
            <FlexBox flexDirection="column">
                <LoadingSpinner className="my-3" variant={SpinnerVariant.Large} centering />
            </FlexBox>
        );
    }

    return (
        <>
            {changeTemplateSearchTerm && designs.length === 0 && (
                <Typography textAlign="center" fontSize="1">
                    {t(changeTemplateMessages.changeTemplateNoSearchResults.id)}
                </Typography>
            )}
            <ul className={styles.templateGallery}>
                {designs.map((design: Design) => {
                    const { designId } = design;
                    return (
                        <DesignTileContainer
                            key={design.designId}
                            design={design}
                            generateNewDocument={generateNewDocument}
                            setIsConfirmationModalOpen={setIsConfirmationModalOpen}
                            setCurrentTemplateColorSwatch={setCurrentTemplateColorSwatch}
                            setConfirmationModalData={setConfirmationModalData}
                            changeTemplate={changeTemplate}
                            onRequestDismiss={onRequestDismiss}
                            isSelectedDesign={designId === currentDesign?.designId}
                        />
                    );
                })}
            </ul>
            <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                designDocument={confirmationModalData?.designDocument}
                onRequestDismiss={() => {
                    setIsConfirmationModalOpen(false);
                    setConfirmationModalData({});
                }}
                onAccept={() => {
                    fireDesignToolTrackingEvent({
                        eventDetail: STUDIO_TRACKING_EVENTS.CLICK_CHANGE_TEMPLATE_CONFIRMATION,
                        label: "Click change template confirmation"
                    });
                    const { selectedDesign, selectedSwatch, designDocument } = confirmationModalData;
                    if (selectedDesign && designDocument) {
                        const templateSrc = selectedSwatch || selectedDesign;
                        const selectedTemplate = templateSrc.previewInfo.templateToken;
                        setCurrentDesign(selectedDesign);
                        changeTemplate(selectedTemplate, selectedDesign.fullProductOptions, designDocument);
                        onTemplateColorChange(false);
                        onRequestDismiss();
                    }
                }}
            />
        </>
    );
};
ChangeTemplateGallery.displayName = "changeTemplateGallery";
