import React, { useState } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { loadableRetry } from "@shared/utils/Network";
import loadable from "@loadable/component";
import { Typography, Button, Divider } from "@vp/swan";
import { useAppDispatch, setLoadingMessage } from "@shared/redux";
import { BLANK_SELECTED_TEMPLATE } from "@shared/utils/Templates";
import { TriggerCanvasChange } from "@shared/utils/DesignPanel";
import { useDesignRequirementsContext, useGetCanvasUpsellContext } from "@shared/features/Product";
import { type DifferentialPricingProps } from "@shared/features/Pricing";
import { useDesignDialog } from "../DesignDialogProvider";
import { designPanelMessages } from "../designPanelMessages";
import { DeleteConfirmationDialog } from "./DeleteConfirmationDialog";
import * as styles from "./DeleteSideButton.module.scss";
import { useSelectBlankCallback } from "../hooks/useSelectBlankCallback";

const DifferentialPricingDisplay = loadable(() => loadableRetry(() => import("@shared/features/Pricing")), {
    resolveComponent: components => components.DifferentialPricingDisplay as React.FC<DifferentialPricingProps>
});
const { designButtonDelete, designButtonDeleteInstructions } = designPanelMessages;

interface Props {
    triggerCanvasChange: TriggerCanvasChange;
}

/**
 * A Delete button for deleting a side (set it to be blank)
 * @returns Component
 */
export function DeleteSideButton({ triggerCanvasChange }: Props) {
    const { t } = useTranslationSSR();
    const { changeCanvas, dialogCanvasTitle, dialogCanvasName } = useDesignDialog();
    const getUpsellsByCanvasName = useGetCanvasUpsellContext();
    const dialogPricing = getUpsellsByCanvasName(dialogCanvasName);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const dispatch = useAppDispatch();
    const designRequirements = useDesignRequirementsContext();

    const activeCanvasColorMode = dialogCanvasName
        ? designRequirements?.getPanelByName(dialogCanvasName)?.colorMode
        : "";
    const isGrayScale = activeCanvasColorMode === "grayscale";
    const selectBlank = useSelectBlankCallback(true);

    const selectDelete = () => {
        if (!designRequirements) {
            return;
        }

        dispatch(setLoadingMessage(t(designPanelMessages.deletingSide.id, { sideName: dialogCanvasTitle })));
        changeCanvas({
            selectedOption: BLANK_SELECTED_TEMPLATE,
            callback: selectBlank
        });
        // The canvas being "deleted" is the active canvas, so switch to front instead
        triggerCanvasChange(designRequirements.getDefaultPanelName());
    };

    const pricing = dialogPricing && dialogPricing.listPrice !== 0 ? dialogPricing : undefined;

    return (
        <>
            <Button
                skin="secondary"
                size="mini"
                width="full-width"
                onClick={() => setShowConfirmation(true)}
                className={styles.deleteSideButton}
                aria-describedby="delete-help"
            >
                {t(designButtonDelete.id, { sideName: dialogCanvasTitle })}
                {pricing && !isGrayScale && (
                    <>
                        (
                        <DifferentialPricingDisplay
                            pricing={pricing}
                            hideQuantity={true}
                            hideVatMessage={true}
                            negatePricingValues={true}
                        />
                        )
                    </>
                )}
            </Button>
            <Typography as="p" fontSize="1" textColor="dark-grey" className={styles.instructions} id="delete-help">
                {t(designButtonDeleteInstructions.id, { sideName: dialogCanvasTitle.toLowerCase() })}
            </Typography>
            <Divider className={styles.deleteDivider} />
            <DeleteConfirmationDialog
                show={showConfirmation}
                sideName={dialogCanvasTitle}
                onDelete={selectDelete}
                onClose={() => setShowConfirmation(false)}
            />
        </>
    );
}
DeleteSideButton.displayName = "DeleteSideButton";
