import React, { useCallback } from "react";
import { type LoadProjectCallback, MyProjectsPanel } from "@shared/features/MyProjects";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { useAppDispatch, useAppSelector } from "@shared/redux";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { loadProjectInStudio } from "../../clients/loadProjectClient";

export function MyProjectsPanelWrapper() {
    const { isXerox } = useXerox();
    const showMyProjects = useAppSelector(state => state.showMyProjects.show) && !isXerox;
    const locale = useAppSelector(state => state.locale);
    const dispatch = useAppDispatch();
    const { auth, identity } = useIdentityContext();
    const { useColorGenericBacksides } = useAppSelector(state => state.studioConfiguration);
    const { isSmall } = useStudioLayout();

    const loadProject = useCallback<LoadProjectCallback>(
        async params => {
            return loadProjectInStudio({
                ...params,
                locale,
                dispatch,
                auth,
                identity,
                isColorMatchingBacksideEnabled: useColorGenericBacksides,
                useDraggablePlaceholders: !isSmall
            });
        },
        [locale, dispatch, auth, identity, useColorGenericBacksides, isSmall]
    );

    return <MyProjectsPanel isOpen={showMyProjects} loadProject={loadProject} />;
}

MyProjectsPanelWrapper.displayName = "MyProjectsPanelWrapper";
