import React, { useCallback, useMemo } from "react";
import { useRichTextSet, useSelection } from "@designer-suite";
import { useRecentFontsContext } from "@presentational";
import {
    getTrackingDataForSelection,
    isTable,
    isTeamsPlaceholder,
    isWordArt,
    isWordArtBold,
    isWordArtItalic,
    textFilter,
    teamsPlaceholderFilter,
    updateSelectedItems,
    updateTeamsItemData,
    useDecorationTechnology,
    isTableBold,
    isTableItalic,
    updateTableFontFamily
} from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { FontBrowserModal } from "@shared/features/FontBrowser";
import { DecorationTechnologiesSimple } from "@shared/utils/CimDoc";
import { FontFlavor } from "@design-stack-ct/font-sdk";
import { onSelectWordArt, useOnFontSelect } from "../../designer-suite/dropdowns/onFontSelect";
import { useDesigner } from "../../designer-suite/designer/DesignerProvider";
import { useSelectedFonts } from "../../designer-suite/ToolSheets/FontSelector/useSelectedFonts";
import { useTableSelectionFilter } from "../../designer-suite/hooks/useTableSelectionFilter";
import { setTextWeight, setWordArtWeight, setTableWeight } from "./FontBrowserUtils";
import { useIsActive } from "../../designer-suite/buttons/FontStyleButton";

const listenEvents = "model:change:content";

export function FontBrowserModalStudio5() {
    const onSelect = useOnFontSelect();

    const decorationTechnology = useDecorationTechnology();
    const { recentFonts, updateLastSelectedFont, updateRecentFonts, lastSelectedFont } = useRecentFontsContext();

    const selection = useSelection(listenEvents);
    const designer = useDesigner();
    const { wordArtItems, otherItems, selectedFontFamily } = useSelectedFonts();
    const { matchingItems: textItems } = textFilter(designer, otherItems);
    const { tableItems } = useTableSelectionFilter(selection);
    const { teamsPlaceholderItems } = teamsPlaceholderFilter(selection);
    const { setWithSideEffects } = useRichTextSet();
    const isBoldActive = useIsActive("bold");
    const isItalicActive = useIsActive("italic");

    const appliedWeights = useMemo(() => {
        const result = {
            bold:
                isBoldActive ||
                (wordArtItems.length > 0 && isWordArtBold(wordArtItems[0])) ||
                (tableItems.length > 0 && isTableBold(tableItems[0])),
            italic:
                isItalicActive ||
                (wordArtItems.length > 0 && isWordArtItalic(wordArtItems[0])) ||
                (tableItems.length > 0 && isTableItalic(tableItems[0]))
        };
        return result;
    }, [isBoldActive, isItalicActive, tableItems, wordArtItems]);

    const handleUpdateFont = useCallback(
        (fontFamily: string, weight?: string) => {
            if (textItems.length) {
                try {
                    onSelect(textItems, fontFamily);
                    if (weight) {
                        setTextWeight(textItems, weight, setWithSideEffects);
                    }
                    // this sometimes blows up in designer trying to check font sizes but doesn't cause any real issues
                    // eslint-disable-next-line no-empty
                } catch {}
            }
            if (wordArtItems.length) {
                onSelectWordArt(designer, wordArtItems, fontFamily);

                if (weight) {
                    setWordArtWeight(designer, wordArtItems, weight);
                }
            }
            if (tableItems.length) {
                updateSelectedItems(designer, tableItems, table => {
                    updateTableFontFamily(table, fontFamily);
                });
                if (weight) {
                    setTableWeight(designer, tableItems, weight);
                }
            }
            if (teamsPlaceholderItems.length) {
                updateSelectedItems(designer, teamsPlaceholderItems, teamsPlaceholder => {
                    updateTeamsItemData(teamsPlaceholder, { fontFamily });
                });
            }
            designer &&
                designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_FONT_CHOICE, {
                    ...getTrackingDataForSelection([...wordArtItems, ...textItems, ...tableItems]),
                    fontFamily,
                    inRecentFonts: recentFonts.includes(fontFamily),
                    inFontBrowser: true
                });
        },
        [
            designer,
            onSelect,
            recentFonts,
            setWithSideEffects,
            tableItems,
            teamsPlaceholderItems,
            textItems,
            wordArtItems
        ]
    );

    const handleDismiss = useCallback(
        (openTime: number | null) => {
            designer &&
                designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_FONT_CLOSE_BROWSER, {
                    ...getTrackingDataForSelection([...wordArtItems, ...textItems, ...tableItems]),
                    timeUsed: openTime ? performance.now() - openTime : null
                });
        },
        [designer, tableItems, textItems, wordArtItems]
    );

    const handleCategoryClick = useCallback(
        (categoryValue: string) => {
            designer &&
                designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_FONT_CATEGORY, {
                    fontCategory: categoryValue,
                    inFontBrowser: true
                });
        },
        [designer]
    );

    const getFontVariant = useCallback(
        (fontFamily: string) => {
            return designer?.clients?.font?.fontMapping[fontFamily.toLowerCase()]?.Variants;
        },
        [designer]
    );

    const getSelectedText = useCallback(
        (pangram: string): string | undefined => {
            let selectedText: string | undefined;
            if (selection) {
                const selectedItem = selection.find(
                    item => item.itemType === "TEXT" || isWordArt(item) || isTable(item) || isTeamsPlaceholder(item)
                ) as WordArtItem | TextItem | TableItem | undefined;
                if (selectedItem) {
                    if (isWordArt(selectedItem)) {
                        selectedText = (selectedItem as WordArtItem).data.content;
                    } else if (isTable(selectedItem)) {
                        // I don't know if lumping table cells together is the right decision.  Tables could contain weird info (random strings, numbers)
                        // just going to show the pangram
                        selectedText = pangram;
                    } else if (isTeamsPlaceholder(selectedItem)) {
                        // will just show the pangram because the placeholder will eventually be replaced by user input text
                        selectedText = pangram;
                    } else {
                        selectedText = (selectedItem as TextItem).value.join(" ").replace(/\n/gm, "").trim();
                    }
                    if (!selectedText || selectedText.length < 4) {
                        selectedText = pangram;
                    }
                }
            }
            return selectedText;
        },
        [selection]
    );

    return (
        <FontBrowserModal
            onDismiss={handleDismiss}
            onUpdateRecentFonts={updateRecentFonts}
            lastSelectedFontFamily={lastSelectedFont}
            onCategoryClick={handleCategoryClick}
            recentFonts={recentFonts}
            getFontVariant={getFontVariant}
            getSelectedText={getSelectedText}
            appliedWeights={appliedWeights}
            onUpdateFont={handleUpdateFont}
            selectedFontFamily={selectedFontFamily}
            updateLastSelectedFont={updateLastSelectedFont}
            fontFlavor={
                // Maps legacy types to FontFlavor
                decorationTechnology === DecorationTechnologiesSimple.PRINT ? FontFlavor.PRINT : FontFlavor.EMBROIDERY
            }
        />
    );
}

FontBrowserModalStudio5.displayName = "FontBrowserModalStudio5";
