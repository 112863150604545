import React, { ReactNode, useCallback } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { XYCoord } from "react-dnd";
import { UploadStrategies, useDesigner, useHandleImagePlacement } from "../../../easel";
import { addImageCallToAction } from "../Panels/Images/DrawerPanel/utils";
import { DroppableTarget, DropType } from "./DroppableTarget";

const messages = defineMessages({
    canvasDropTargetText: {
        id: "studioFive.components.dragAndDrop.content.canvasDropTargetText",
        defaultMessage: "Drop here to place item.",
        description: {
            note: "Text that appears on an overlay when an item is dragged over the canvas. "
        }
    }
});

interface DroppableTargetLayerCanvasElementProps {
    children: ReactNode | ReactNode[];
}

export const DroppableTargetLayerCanvasElement = (props: DroppableTargetLayerCanvasElementProps) => {
    const { children } = props;
    const { onImageClicked } = useHandleImagePlacement({ placementStrategy: UploadStrategies.PlaceOnCanvas });
    const { t } = useTranslationSSR();
    const designer = useDesigner();

    const getPositionFromPageCoordinates = useCallback(
        (position: XYCoord | null) => {
            if (!designer || !position) {
                return undefined;
            }
            const canvasViewModel = designer.documentRepository.getActiveCanvasViewModel();
            if (!canvasViewModel) {
                return undefined;
            }
            const canvasElement = document.getElementById(canvasViewModel.id);
            if (!canvasElement) {
                return undefined;
            }
            const canvasZoomFactor = canvasViewModel.get("zoomFactor");
            const canvasRect = canvasElement.getBoundingClientRect();

            return {
                top: (position.y - canvasRect.top) / canvasZoomFactor,
                left: (position.x - canvasRect.left) / canvasZoomFactor
            };
        },
        [designer]
    );

    const onDrop = (item: DropType, position?: XYCoord | null) => {
        let mmPosition: CanvasPosition | undefined;
        if (position) {
            mmPosition = getPositionFromPageCoordinates(position);
        }
        addImageCallToAction({ designer, ...item, callback: onImageClicked, mmPosition });
    };

    return (
        <DroppableTarget
            className="drop-target-canvas"
            onDrop={onDrop}
            dropTargetElement={<div className="drop-target-canvas-text">{t(messages.canvasDropTargetText.id)}</div>}
        >
            {children}
        </DroppableTarget>
    );
};

DroppableTargetLayerCanvasElement.displayName = "DroppableTargetLayerCanvasElement";
