import { useCallback } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { FlexibilityOptionData } from "../common/components/FlexibilityOption";

const messages = defineMessages({
    StandardDescription: {
        id: "studio.components.Standard.description",
        defaultMessage: "Traditional square corner for a crisp and clean look",
        description: {
            note: "Description for Standard trim"
        }
    },
    RoundedDescription: {
        id: "studio.components.Rounded.description",
        defaultMessage: "Rounded corners add a smooth, modern touch",
        description: {
            note: "Description for Rounded trim"
        }
    },
    ScallopedDescription: {
        id: "studio.components.Scalloped.description",
        defaultMessage: "A decorative edge creates a unique texture and shape",
        description: {
            note: "Description for Scalloped trim"
        }
    }
});

export const useTrimData = () => {
    const { t } = useTranslationSSR();

    const constructData = useCallback(
        (designAttributeValue: string): FlexibilityOptionData => ({
            description: t(messages[`${designAttributeValue}Description`].id),
            designAttributeValue,
            useIcon: true
        }),
        [t]
    );

    const getTrimData = useCallback(
        (trims: string[]) => {
            return trims.map(constructData);
        },
        [constructData]
    );

    return getTrimData;
};
