import React, { useEffect, useRef } from "react";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import type { DSS } from "@vp/types-ddif";
import type { Save } from "@shared/features/Save";
import { ChangeTemplateModal } from "./ChangeTemplateModal";
import { FiltersModal } from "./Filters/FiltersModal";
import type { LoadNewDesign } from "./changeTemplateClient";

interface Props {
    getDocument: () => Promise<DSS.DesignDocument>;
    loadNewDesign: LoadNewDesign;
    save: Save;
}

export function ChangeTemplateExperienceContainer({ getDocument, loadNewDesign, save }: Props) {
    const { isSmall } = useStudioLayout();
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const previousContentType = useRef(currentActiveDialog);

    const isChangeTemplateModalOpen = currentActiveDialog === DialogType.ChangeTemplate;
    const isFiltersModalOpen = currentActiveDialog === DialogType.Filters;

    const onRequestDismiss = (changeTemplateInitiated: boolean = false) => {
        let changeToContentType = previousContentType.current || DialogType.Text;
        if (isSmall) {
            changeToContentType = DialogType.None;
        } else if (changeTemplateInitiated) {
            changeToContentType = DialogType.Text;
        }
        setCurrentActiveDialog(changeToContentType);
    };

    useEffect(() => {
        if (currentActiveDialog !== DialogType.ChangeTemplate && currentActiveDialog !== DialogType.Filters) {
            previousContentType.current = currentActiveDialog;
        }
    }, [currentActiveDialog]);

    return (
        <>
            <ChangeTemplateModal
                onRequestDismiss={onRequestDismiss}
                isOpen={isChangeTemplateModalOpen}
                getDocument={getDocument}
                loadNewDesign={loadNewDesign}
                save={save}
            />
            <FiltersModal onRequestDismiss={onRequestDismiss} isOpen={isFiltersModalOpen} />
        </>
    );
}
ChangeTemplateExperienceContainer.displayName = "ChangeTemplateExperienceContainer";
