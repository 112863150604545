import React from "react";
import {
    LegacyModalDialog,
    LegacyModalDialogBody,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton,
    Box
} from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useAppSelector, useAppDispatch, setShowSpecsAndTemplateModal } from "@shared/redux";
import { useSurfaceImage } from "./useSurfaceImage";
import { SurfaceDataContainer } from "./SurfaceDataContainer";
import * as styles from "./SpecsAndTemplatesModal.module.scss";

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: "studio.components.specsAndTemplatePanel.closeButtonAriaLabel",
        defaultMessage: "Close specs and template modal",
        description: {
            note: "aria label for close button of specs and template dialog"
        }
    },
    modalAriaLabel: {
        id: "studio.components.specsAndTemplatePanel.modalAriaLabel",
        defaultMessage: "Specs and template modal",
        description: {
            note: "aria label for specs and template dialog"
        }
    },
    imageAltText: {
        id: "studio.components.specsAndTemplatePanel.imageAltText",
        defaultMessage: "Template surface preview",
        description: {
            note: "hidden label for template preview image"
        }
    }
});

export function SpecsAndTemplatesModal() {
    const dispatch = useAppDispatch();
    const { t } = useTranslationSSR();
    const specsTemplateModal = useAppSelector(state => state.showSpecsAndTemplate);

    const modalClose = () => {
        dispatch(setShowSpecsAndTemplateModal(false));
    };

    const surfacePreview = useSurfaceImage();

    return (
        <LegacyModalDialog
            variant="panel-right"
            isOpen={specsTemplateModal}
            onRequestDismiss={modalClose}
            className={styles.specsAndTemplatesModalDialog}
        >
            <LegacyModalDialogContent aria-label={t(messages.modalAriaLabel.id)} fullBleed>
                <LegacyModalDialogCloseButton visuallyHiddenLabel={t(messages.closeButtonAriaLabel.id)} />
                <LegacyModalDialogBody>
                    <Box padding={5} className={styles.specsAndTemplatesPreviewImageContainer}>
                        <img src={surfacePreview} alt={t(messages.imageAltText.id)} />
                    </Box>
                    <SurfaceDataContainer />
                </LegacyModalDialogBody>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}
SpecsAndTemplatesModal.displayName = "SpecsAndTemplatesModal";
