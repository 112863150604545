import React from "react";
import { Typography } from "@vp/swan";
import classNames from "classnames";
import * as styles from "./DesignTileButtonLabel.module.scss";

type Props = {
    label: string;
};

/**
 * Text that appears on the DesignTileButton
 */

export const DesignTileButtonLabel = (props: Props) => {
    const { label } = props;

    return (
        <Typography className={classNames(styles.buttonLabel, "design-tile-button-label")} fontSize="-1">
            {label}
        </Typography>
    );
};

DesignTileButtonLabel.displayName = "DesignTileButtonLabel";
