import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useTrimData } from "./useTrimData";
import { FlexibilityOptionPanel } from "../common/components/FlexibilityOptionPanel";
import { FlexibilityOptionPanelProvider } from "../common/components/FlexibilityOptionPanelProvider";
import { useFlexibilityPreviewScenes } from "../common/hooks/useFlexibilityPreviewScenes";
import { FlexibilityDesignAttributes } from "../constants";

export const messages = defineMessages({
    modalCloseButton: {
        id: "studio.components.changeTrim.modalCloseButton",
        defaultMessage: "Close",
        description: {
            note: "Text message for change product trim modal close button"
        }
    },
    modalHeader: {
        id: "studio.components.changeTrim.modalHeader",
        defaultMessage: "Change the trim",
        description: {
            note: "Text message for change product trim modal header"
        }
    },
    modalDescription: {
        id: "studio.components.changeTrim.description",
        defaultMessage: "Select the cut and shape of corners",
        description: {
            note: "Description text message for change product trim modal"
        }
    },
    modalChangeText: {
        id: "studio.components.changeTrim.changeText",
        defaultMessage: "Save changes",
        description: {
            note: "Change product trim button text message in modal"
        }
    }
});

export function ChangeTrimModal() {
    const { t } = useTranslationSSR();
    const getTrimData = useTrimData();
    const trimScenes = useFlexibilityPreviewScenes(FlexibilityDesignAttributes.Trim);

    return (
        <FlexibilityOptionPanelProvider
            designAttributeName={FlexibilityDesignAttributes.Trim}
            getOptionsData={getTrimData}
            previewScenes={trimScenes}
        >
            <FlexibilityOptionPanel
                closeButtonLabel={t(messages.modalCloseButton.id)}
                headerText={t(messages.modalHeader.id)}
                descriptionText={t(messages.modalDescription.id)}
                changeOptionText={t(messages.modalChangeText.id)}
                loadingText={t(messages.modalChangeText.id)}
                changeAlertKey="studio.components.Toast.changeTrimToast"
            />
        </FlexibilityOptionPanelProvider>
    );
}
ChangeTrimModal.displayName = "ChangeTrimModal";
