import { useDesignRequirementsContext } from "@shared/features/Product";
import { useMemo } from "react";

export function useShouldShowDuplicateFirstCanvasButton(panelName: string) {
    const designRequirements = useDesignRequirementsContext();

    return useMemo(() => {
        if (!panelName || !designRequirements) {
            return false;
        }

        const firstPanel = designRequirements.panels[0];
        const currentPanel = designRequirements.getPanelByName(panelName);
        if (!currentPanel || currentPanel.id === firstPanel.id) {
            return false;
        }

        if (currentPanel.width !== firstPanel.width || currentPanel.height !== firstPanel.height) {
            return false;
        }

        return true;
    }, [designRequirements, panelName]);
}
