import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { isMobile } from "react-device-detect";
import { Button } from "@shared/features/StudioChrome";

import { ControlIconToIconMap, Icon, Typography } from "@vp/swan";
import React from "react";
import classNames from "classnames";

interface Props {
    label?: string;
    onClick?: () => void;
    className?: string;
}

export const FlexibilityBackButton = ({ label, onClick, className }: Props) => {
    const { setCurrentActiveDialog } = useActiveDialog();

    const onBackClick = () => {
        isMobile && setCurrentActiveDialog(DialogType.Flexibility);
        onClick?.();
    };

    return (
        <Button className={classNames("flexibility-option-panel-back-btn", className)} onClick={onBackClick}>
            <Icon {...ControlIconToIconMap["arrow-l"]} />
            {label && <Typography className="button-label">{label}</Typography>}
        </Button>
    );
};

FlexibilityBackButton.displayName = "FlexibilityBackButton";
