import React from "react";
import {
    Typography,
    Button,
    Icon,
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogBody,
    LegacyModalDialogContent,
    LegacyModalDialogFooter,
    LegacyModalDialogNav
} from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";
import { ChangeTemplateFilters } from "./ChangeTemplateFilters";
import { ClearFiltersButton } from "./ClearFiltersButton";
import * as styles from "./FiltersModal.module.scss";

const messages = defineMessages({
    applyFilters: {
        id: "studio.components.changeTemplate.applyFilters",
        defaultMessage: "Apply filters",
        description: {
            note: "Text for a button that applies filter selections to the template browser"
        }
    },
    mobileFilterModalHeader: {
        id: "studio.components.changeTemplate.filters",
        defaultMessage: "Filters",
        description: {
            note: "This is a header for the mobile template filter sheet"
        }
    },
    filterModalAriaLabel: {
        id: "studio.components.changeTemplate.filterModalAriaLabel",
        defaultMessage: "Filters Modal",
        description: {
            note: "This is aria label text for filter modal dialog content"
        }
    }
});

interface FiltersModalProps {
    isOpen: boolean;
    onRequestDismiss: () => void;
}

export function FiltersModal({ isOpen, onRequestDismiss }: FiltersModalProps) {
    const { t } = useTranslationSSR();
    const { setCurrentActiveDialog } = useActiveDialog();

    const handleBackClick = () => {
        setCurrentActiveDialog(DialogType.ChangeTemplate);
    };

    return (
        <LegacyModalDialog
            isOpen={isOpen}
            onRequestDismiss={onRequestDismiss}
            className={styles.modal}
            variant="panel-bottom"
            data-dcl-prevent-canvas-items-deselection
        >
            <LegacyModalDialogContent aria-label={t(messages.filterModalAriaLabel.id)} fullBleed={true}>
                <LegacyModalDialogNav>
                    <Icon iconType="chevronLeft" onClick={handleBackClick} />
                    <LegacyModalDialogHeader ml={4}>
                        <Typography fontSize="3" fontWeight="bold">
                            {t(messages.mobileFilterModalHeader.id)}
                        </Typography>
                    </LegacyModalDialogHeader>
                    <ClearFiltersButton />
                </LegacyModalDialogNav>
                <LegacyModalDialogBody className={styles.body} paddingX={4}>
                    <ChangeTemplateFilters />
                </LegacyModalDialogBody>
                <LegacyModalDialogFooter pinned className={styles.footer}>
                    <Button skin="primary" size="mini" width="full-width" onClick={handleBackClick}>
                        {t(messages.applyFilters.id)}
                    </Button>
                </LegacyModalDialogFooter>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}
FiltersModal.displayName = "FiltersModal";
