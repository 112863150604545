import React, { forwardRef, Ref, useCallback } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Button } from "@vp/swan";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppSelector } from "@shared/redux";
import { patchWorkName } from "@shared/utils/Work";
import { type Save } from "./useSave";

type TextButtonProps = React.ComponentProps<typeof Button>;
interface props extends TextButtonProps {
    newWorkName: string;
    setStartTime: (startTime: number) => void;
    save: Save;
}

const messages = defineMessages({
    saveButton: {
        id: "studio.components.saveButton.save",
        defaultMessage: "Save"
    }
});

export const SaveButton = forwardRef((props: props, ref: Ref<HTMLButtonElement>) => {
    const { newWorkName, skin, setStartTime, save, ...rest } = props;
    const isRenamingProject = useAppSelector(state => state.isRenamingProject);
    const workId = useAppSelector(state => state.workId);
    const workName = useAppSelector(state => state.workName);
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const { auth } = useIdentityContext();

    const handleClick = useCallback(async () => {
        const startTime = performance.now();
        setStartTime(startTime);
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_SAVE_BUTTON,
            label: "Save Button"
        });
        if (isRenamingProject && workId) {
            const authToken = auth.getToken();
            if (newWorkName !== workName) {
                await patchWorkName(workId, authToken, newWorkName);
            }
            save();
        } else {
            save(newWorkName);
        }
    }, [setStartTime, isRenamingProject, auth, newWorkName, workName, save, workId]);

    return (
        <Button
            skin={skin || "primary"}
            width={isSmall ? "full-width" : "standard"}
            size="mini"
            onClick={handleClick}
            ref={ref}
            {...rest}
        >
            {t(messages.saveButton.id)}
        </Button>
    );
});

SaveButton.displayName = "SaveButton";
