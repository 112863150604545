import { useCallback } from "react";
import {
    BLANK_SELECTED_TEMPLATE,
    CUSTOM_SELECTED_TEMPLATE,
    DUPLICATE_FIRST_PANEL_TEMPLATE,
    FULLBLEED_SELECTED_TEMPLATE
} from "@shared/utils/Templates";

import { DesignTileCallback, DesignTileCallbackProps } from "@shared/utils/DesignPanel";
import { useAppSelector } from "@shared/redux";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";
import { useGetDesignTileTemplates } from "./useGetDesignTileTemplates";
import { useSelectBlankCallback } from "./useSelectBlankCallback";
import { useSelectDuplicateCallback } from "./useSelectDuplicateCallback";
import { useFullbleedCallback } from "./useSelectFullbleedCallback";
import { useSelectStartFromScratchCallback } from "./useSelectStartFromScratchCallback";
import { useSelectTemplateCallback } from "./useSelectTemplateCallback";

export const useGetSelectionCallback = (selectedOption: string | undefined) => {
    const selectBlank = useSelectBlankCallback();
    const selectDuplicate = useSelectDuplicateCallback();
    const selectFullbleed = useFullbleedCallback();
    const selectScratch = useSelectStartFromScratchCallback();
    const selectTemplate = useSelectTemplateCallback();
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const isDesignAssistantTabEnabled = useAppSelector(state => state.isDesignAssistantTabEnabled);
    const { validTemplates } = useGetDesignTileTemplates();

    const getSelectionCallBack = useCallback(
        (callBack: DesignTileCallback) => {
            if (isDesignAssistantTabEnabled && currentActiveDialog !== DialogType.IdeaPanel) {
                return (props: DesignTileCallbackProps) => {
                    setCurrentActiveDialog(DialogType.IdeaPanel);
                    return callBack(props);
                };
            }
            return callBack;
        },
        [currentActiveDialog, isDesignAssistantTabEnabled, setCurrentActiveDialog]
    );

    switch (selectedOption) {
        case BLANK_SELECTED_TEMPLATE:
            return selectBlank;
        case DUPLICATE_FIRST_PANEL_TEMPLATE:
            return getSelectionCallBack(selectDuplicate);
        case FULLBLEED_SELECTED_TEMPLATE:
            return getSelectionCallBack(selectFullbleed);
        case CUSTOM_SELECTED_TEMPLATE:
            return getSelectionCallBack(selectScratch);
        default: {
            // We need to assume this is a template iD;
            const validTemplate = validTemplates.find(template => template.templateToken === selectedOption);
            if (validTemplate) {
                return (params: DesignTileCallbackProps) => {
                    return selectTemplate({ ...params, ...validTemplate });
                };
            }
            return undefined;
        }
    }
};
