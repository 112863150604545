import { addModelsToSelection } from "@designer-suite";
import type { Designer, ItemSelection } from "src/easel/designer-suite/@types/designer";
import {
    FontStyle,
    updateFontStyle,
    updateSelectedItems,
    updateTableBoldStyle,
    updateTableItalicStyle
} from "@utilities";

export const setTextWeight = (
    selection: ItemSelection,
    weight: string,
    setWithSideEffects: (viewModels: ItemViewModel[], attributeName: string, value: string | number | boolean) => void
) => {
    switch (weight) {
        case "bold":
            setWithSideEffects(addModelsToSelection(selection), "bold", true);
            setWithSideEffects(addModelsToSelection(selection), "italic", false);
            break;
        case "italic":
            setWithSideEffects(addModelsToSelection(selection), "bold", false);
            setWithSideEffects(addModelsToSelection(selection), "italic", true);
            break;
        case "bolditalic":
            setWithSideEffects(addModelsToSelection(selection), "bold", true);
            setWithSideEffects(addModelsToSelection(selection), "italic", true);
            break;
        default:
            setWithSideEffects(addModelsToSelection(selection), "bold", false);
            setWithSideEffects(addModelsToSelection(selection), "italic", false);
            break;
    }
};

export const setWordArtWeight = (designer: Designer | undefined, wordArtItems: ItemSelection, weight: string) => {
    switch (weight) {
        case "bold":
            updateSelectedItems(designer, wordArtItems, updateFontStyle(false, FontStyle.NORMAL));
            updateSelectedItems(designer, wordArtItems, updateFontStyle(true, FontStyle.BOLD));
            break;
        case "italic":
            updateSelectedItems(designer, wordArtItems, updateFontStyle(true, FontStyle.NORMAL));
            updateSelectedItems(designer, wordArtItems, updateFontStyle(false, FontStyle.ITALIC));
            break;
        case "bolditalic":
            updateSelectedItems(designer, wordArtItems, updateFontStyle(true, FontStyle.BOLD));
            updateSelectedItems(designer, wordArtItems, updateFontStyle(false, FontStyle.ITALIC));
            break;
        default:
            updateSelectedItems(designer, wordArtItems, updateFontStyle(false, FontStyle.NORMAL));
            updateSelectedItems(designer, wordArtItems, updateFontStyle(true, FontStyle.NORMAL));
            break;
    }
};

export const setTableWeight = (designer: Designer | undefined, tableItems: ItemSelection, weight: string) => {
    switch (weight) {
        case "bold":
            updateSelectedItems(designer, tableItems, updateTableItalicStyle(false));
            updateSelectedItems(designer, tableItems, updateTableBoldStyle(true));
            break;
        case "italic":
            updateSelectedItems(designer, tableItems, updateTableItalicStyle(true));
            updateSelectedItems(designer, tableItems, updateTableBoldStyle(false));
            break;
        case "bolditalic":
            updateSelectedItems(designer, tableItems, updateTableItalicStyle(true));
            updateSelectedItems(designer, tableItems, updateTableBoldStyle(true));
            break;
        default:
            updateSelectedItems(designer, tableItems, updateTableItalicStyle(false));
            updateSelectedItems(designer, tableItems, updateTableBoldStyle(false));
            break;
    }
};
