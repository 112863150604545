import { useCallback, useEffect, useState } from "react";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { getStudioConfigFromCalciferV2, getSecondaryConfigFromCalciferV2 } from "@shared/utils/Calcifer";
import { useAppSelector } from "@shared/redux";
import { mapDesignAttributeToProductOption, mapDesignAttributeValueToProductOptionValue } from "@shared/utils/Product";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { getResizedTransformedDocument } from "@shared/utils/ProductTransformation";
import cloneDeep from "lodash/cloneDeep";
import { DSS } from "@vp/types-ddif";
import { PreviewData, useGenerateFlexibilityPreviewUrl } from "./useGenerateFlexibilityPreviewUrl";
import { useActiveFlexibilityOptions, useDebouncedDesignDocument } from "../../ActiveFlexibilityProvider";
import { FlexibilityDesignAttributes } from "../../constants";
import { useFlexibilityOptionPanel } from "../components/FlexibilityOptionPanelProvider";
import { convertPremiumFinishInDocument } from "../../PremiumFinish/PremiumFinishConversionUtils";
import { getResizedDocument } from "../../../../utils/ProductTransformation/productTransformationUtilites";

interface DesignDocumentData extends PreviewData {
    // TODO: This was previously a string, but when set it was actually set to a document,
    // and when read it was checked to see if it was present, and if present, it was read like a document
    targetDocument?: any;
}

export type DesignDocumentDataRecord = Record<string, DesignDocumentData>;

export function useFlexibilityDesignDocuments() {
    const { optionData, designAttributeName, availableDesignAttributes } = useFlexibilityOptionPanel();
    const { displayedDesignAttributeName, getCurrentDesignAttributeValue, getDocument } = useActiveFlexibilityOptions();
    const debouncedDesignDocument = useDebouncedDesignDocument();
    const generatePreviewUrls = useGenerateFlexibilityPreviewUrl();
    const designAttributeMappings = useAppSelector(state => state.designAttributeMappings);
    const [isCanvasUpdated, setIsCanvasUpdated] = useState(false);

    const [designDocuments, setDesignDocuments] = useState<DesignDocumentDataRecord>({});
    const { auth } = useIdentityContext();
    const locale = useAppSelector(state => state.locale);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const quantity = useAppSelector(state => state.quantity);
    const template = useAppSelector(state => state.template);
    const currentTemplateColor = useAppSelector(state => state.currentTemplateColor);
    const { useColorGenericBacksides } = useAppSelector(state => state.studioConfiguration);
    const designRequirements = useDesignRequirementsContext();
    const numberOfPanels = designRequirements?.numberOfPanels;

    const updateDocumentState = useCallback((documents: DesignDocumentDataRecord[]) => {
        setDesignDocuments(current => {
            documents.forEach(data => {
                // eslint-disable-next-line no-param-reassign
                current = { ...current, ...data };
            });
            return current;
        });
    }, []);

    const generateDesignDocument = useCallback(
        async (designAttributeName: string, designAttributeValue: string): Promise<DesignDocumentDataRecord> => {
            const productOptionName = mapDesignAttributeToProductOption(designAttributeMappings, designAttributeName);
            const productOptionValue = mapDesignAttributeValueToProductOptionValue(
                designAttributeMappings,
                designAttributeName,
                designAttributeValue
            );
            let targetDocument = null;
            const newStudioSelectedProductOptions = { ...studioSelectedProductOptions };
            newStudioSelectedProductOptions[productOptionName] = productOptionValue;
            const productConfigs = await getStudioConfigFromCalciferV2(
                productKey,
                undefined,
                productVersion,
                newStudioSelectedProductOptions,
                undefined,
                quantity,
                locale,
                null,
                false,
                template
            );
            const { selectedOptions } = productConfigs;

            const designDocument = await getDocument();

            if (
                studioSelectedProductOptions &&
                studioSelectedProductOptions[productOptionName] !== productOptionValue
            ) {
                if (productVersion === null) {
                    throw Error("Product version is not defined");
                }
                const authToken = auth.getToken();
                // For trim, we want to create a new resized document based off the current one.
                // We then take the dimensions of the new document and change the size of the current document to be the new one
                // This is to preserve the users document, and all the elements on it.
                // In some locales, different trim options have different sized surfaces. (We're unsure why)
                if (designAttributeName === FlexibilityDesignAttributes.Trim) {
                    const scratchDocument: DSS.DesignDocument = await getResizedDocument({
                        designDocument,
                        authToken,
                        productKey,
                        productVersion,
                        selectedOptions,
                        locale
                    });
                    const designDocumentPanels = designDocument.document.panels;
                    const targetDocumentPanels = scratchDocument.document.panels;
                    designDocument.document.panels = designDocumentPanels.map((panel, index) => {
                        return {
                            ...panel,
                            width: targetDocumentPanels[index].width,
                            height: targetDocumentPanels[index].height
                        };
                    });
                    targetDocument = designDocument;
                } else {
                    const { designVariations } = await getSecondaryConfigFromCalciferV2(
                        productKey,
                        productVersion,
                        newStudioSelectedProductOptions,
                        locale,
                        false,
                        template,
                        useColorGenericBacksides
                    );
                    const document = await getResizedTransformedDocument({
                        originalDocument: designDocument,
                        authToken,
                        productKey,
                        productVersion,
                        selectedOptions,
                        designVariations,
                        locale,
                        currentTemplateColor,
                        isColorMatchingBacksideEnabled: useColorGenericBacksides
                    });
                    if (document?.targetDocument) {
                        // eslint-disable-next-line prefer-destructuring
                        targetDocument = document.targetDocument;
                    }
                }
                const previewData = await generatePreviewUrls(targetDocument, designAttributeValue);
                return { [designAttributeValue]: { targetDocument, ...previewData } };
            }
            targetDocument = designDocument;
            return {};
        },
        [
            designAttributeMappings,
            studioSelectedProductOptions,
            productKey,
            productVersion,
            quantity,
            locale,
            template,
            useColorGenericBacksides,
            auth,
            currentTemplateColor,
            generatePreviewUrls,
            getDocument
        ]
    );

    useEffect(() => {
        if (debouncedDesignDocument) {
            (async () => {
                setIsCanvasUpdated(true);
                const currentlySelectedAttributeValue = getCurrentDesignAttributeValue(designAttributeName);
                let currentDocument = cloneDeep(debouncedDesignDocument);
                const previewData = await generatePreviewUrls(currentDocument, currentlySelectedAttributeValue);
                if (designAttributeName === FlexibilityDesignAttributes.PremiumFinish) {
                    currentDocument = convertPremiumFinishInDocument(currentDocument, currentlySelectedAttributeValue);
                }
                updateDocumentState([
                    { [currentlySelectedAttributeValue]: { targetDocument: currentDocument, ...previewData } }
                ]);
            })();
        }
    }, [
        debouncedDesignDocument,
        designAttributeName,
        generatePreviewUrls,
        getCurrentDesignAttributeValue,
        updateDocumentState
    ]);

    useEffect(() => {
        if (!optionData?.length || !displayedDesignAttributeName || !isCanvasUpdated) {
            return;
        }
        setIsCanvasUpdated(false);

        (async () => {
            if (designAttributeName === FlexibilityDesignAttributes.PremiumFinish) {
                const sourceDocument = await getDocument();
                const documents = availableDesignAttributes.map(async designAttributeValue => {
                    const targetDocument = convertPremiumFinishInDocument(sourceDocument, designAttributeValue);
                    const previewData = await generatePreviewUrls(targetDocument, designAttributeValue);
                    return { [designAttributeValue]: { targetDocument, ...previewData } };
                });
                Promise.all(documents).then(updateDocumentState);
                return;
            }

            Promise.all(
                optionData?.map(({ designAttributeValue }) =>
                    generateDesignDocument(designAttributeName, designAttributeValue)
                )
            )
                .then(updateDocumentState)
                .catch(e => {
                    // eslint-disable-next-line no-console
                    console.warn(e);
                    setDesignDocuments({});
                });
        })();
    }, [
        getDocument,
        generateDesignDocument,
        optionData,
        displayedDesignAttributeName,
        designAttributeName,
        availableDesignAttributes,
        isCanvasUpdated,
        auth,
        numberOfPanels,
        generatePreviewUrls,
        updateDocumentState,
        currentTemplateColor
    ]);

    return { designDocuments };
}
