import React from "react";
import { Typography, Button, LegacyModalDialog, LegacyModalDialogHeader, LegacyModalDialogContent } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";

import { useAppSelector, useAppDispatch, setChangeDesignData } from "@shared/redux";

import classNames from "classnames";
import { designPanelMessages } from "../designPanelMessages";
import { CanvasUpdateStatus, useDesignDialog } from "../DesignDialogProvider";

import * as styles from "./ChangeConfirmationDialog.module.scss";
import { useGetSelectionCallback } from "../hooks/useGetSelectionCallback";

export function ChangeConfirmationModal() {
    const { t } = useTranslationSSR();
    const { changeCanvas, dialogCanvasTitle: sideName, setCanvasUpdateStatus } = useDesignDialog();
    const changeDesignData = useAppSelector(state => state.changeDesignData);
    const dispatch = useAppDispatch();
    const { isSmall } = useStudioLayout();
    const callback = useGetSelectionCallback(changeDesignData?.selectedOption);

    const onClickCancel = () => {
        dispatch(setChangeDesignData(null));
    };

    const onClickChange = () => {
        if (changeDesignData && callback) {
            const { ...rest } = changeDesignData;
            changeCanvas({
                ...rest,
                callback,
                reset: true
            });
            onClickCancel();
            setCanvasUpdateStatus(CanvasUpdateStatus.UPDATED);
        }
    };

    return (
        <LegacyModalDialog
            className="change-confirmation"
            isOpen={changeDesignData !== null}
            onRequestDismiss={onClickCancel}
            data-dcl-prevent-canvas-items-deselection
            bodyWidth="capped"
            variant={isSmall ? "panel-bottom" : "standard"}
        >
            <LegacyModalDialogContent
                className={classNames("modal-dialog-content", styles.dialogContent)}
                aria-label={t(designPanelMessages.changeConfirmationAriaLabel.id)}
            >
                <LegacyModalDialogHeader data-dcl-prevent-canvas-items-deselection marginBottom={3}>
                    <Typography fontSize="4">
                        {t(designPanelMessages.designConfirmChangeTitle.id, { sideName })}
                    </Typography>
                </LegacyModalDialogHeader>

                <Typography fontSize="1" marginBottom={5}>
                    {t(designPanelMessages.designConfirmChangeText.id, { sideName })}
                </Typography>
                <Button skin="secondary" width={"standard"} size="mini" onClick={onClickCancel} marginRight={4}>
                    {t(designPanelMessages.designConfirmChangeCancel.id)}
                </Button>
                <Button
                    className="change-confirm-button"
                    skin="secondary"
                    width={"standard"}
                    size="mini"
                    onClick={onClickChange}
                >
                    {t(designPanelMessages.designConfirmChangeConfirm.id)}
                </Button>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}

ChangeConfirmationModal.displayName = "ChangeConfirmationModal";
