import { updateItems } from "@designer-suite";
import { isWordArt } from "@utilities";
import type { Designer } from "src/easel/designer-suite/@types/designer";
import { convertPxToMmWithZoomFactor } from "../../../../easel/utilities/Utilities";
import { converSurfaceToSvgPath } from "../../../../easel/utilities/ItemValidation/surfaceSpecificationConverter";
import { calculateOverlap } from "../../../../easel/utilities/ItemValidation/overlapDetection";

export const isWithinPostalArea = (designer: Designer, surface: any, item: Item): boolean => {
    // word art is behaving weird during validation. skipping validation for wordart here.
    if (isWordArt(item)) {
        return false;
    }

    const RED_COLOR = "rgba(255, 0, 0, 1)";
    const SCALE_PATH = 10;
    const activeCanvas = designer.documentRepository?.getActiveCanvas();
    const currentZoomFactor = designer.documentRepository.getActiveCanvasViewModel()?.get("zoomFactor");
    // activeCanvas and currentZoomFactor can be null during transition to a different project
    if (!activeCanvas || !currentZoomFactor) {
        return false;
    }
    const convertPxToMmWithCurrentZoomFactor = convertPxToMmWithZoomFactor(currentZoomFactor);
    const { width: canvasWidth, height: canvasHeight } = activeCanvas?.attributes;

    const canvasSize = { canvasWidth, canvasHeight };
    const outerboundsPath = `M 0,0 H ${canvasSize.canvasWidth} V ${canvasSize.canvasHeight} H ${
        -1 * canvasSize.canvasWidth
    } Z`;
    /**
     * This function converts our surface mask to an svg path.
     * Almost entirely taken from Designer
     */
    const maskPath = converSurfaceToSvgPath(surface, true);
    const reusableCanvas = document.createElement("canvas");
    const reusableCanvasContext = reusableCanvas.getContext("2d");
    reusableCanvasContext?.save();

    /**
     * call overlap data
     * This function renders the space between the panel and the mask in red
     * Then it returns the pixels of a given box represented by the item
     * This item data should be the preprocessed to account for rotation
     * For text, use "previewBoundingBox" then apply opaquePixelOffsets as seen in
     * app/core/validations/strategies/outsideMarginStrategy/outsideMarginStrategy.js
     * For others, use "previewBoundingBox"
     */
    const {
        left: pixelOffsetsLeft,
        top: pixelOffsetsTop,
        right: pixelOffsetsRight,
        bottom: pixelOffsetsBottom
    } = item._itemViewModel.get("opaquePixelOffsets") || {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    };

    // adding this check as items do not have previewBoundingBox property right after adding it.
    if (!item._itemViewModel?.attributes?.previewBoundingBox) {
        return false;
    }
    let { left, top, width, height } = item._itemViewModel?.attributes?.previewBoundingBox;
    if (!left || !top) {
        return false;
    }

    if (!width || !height) {
        // cannot render on canvas if item height is 0 or null;
        return false;
    }

    let pixelOffsets = [pixelOffsetsLeft, pixelOffsetsTop, pixelOffsetsRight, pixelOffsetsBottom];
    [...pixelOffsets] = pixelOffsets.map(convertPxToMmWithCurrentZoomFactor);

    [width, height] = [width, height].map(convertPxToMmWithCurrentZoomFactor);
    left = convertPxToMmWithCurrentZoomFactor(left) + pixelOffsetsLeft;
    top = convertPxToMmWithCurrentZoomFactor(top) + pixelOffsetsTop;

    const right = left + width - pixelOffsetsRight;
    const bottom = top + height - pixelOffsetsBottom;

    const itemDimensions = { left, top, width, height, right, bottom };

    if (!reusableCanvasContext) {
        throw Error("Canvas context not set");
    }

    const { hasHits: hasHitsWithNonPostal } = calculateOverlap(
        itemDimensions,
        canvasSize.canvasWidth,
        canvasSize.canvasHeight,
        reusableCanvasContext,
        RED_COLOR,
        new Path2D(`${maskPath} ${outerboundsPath}`),
        SCALE_PATH
    );

    reusableCanvasContext?.restore();

    const { hasHits: hasHitsWithPostal } = calculateOverlap(
        itemDimensions,
        canvasSize.canvasWidth,
        canvasSize.canvasHeight,
        reusableCanvasContext,
        RED_COLOR,
        new Path2D(`${maskPath}`),
        SCALE_PATH
    );

    if (hasHitsWithNonPostal) {
        return false;
    }

    if (hasHitsWithPostal) {
        return true;
    }
    return false;
};

// added bounding box multiple factor to stop wrapping text field forcefully.
const textBoundingBoxMultipleFactor = 1.05;

// shrinking size of text item bounding box for cases when text item occupies complete width of canvas.
const shrinkText = (item: Item, zoomFactor: number) => {
    if (item.itemType === "TEXT") {
        const width = item._itemViewModel?.attributes?.previewBoundingBox?.width;
        const convertPxToMmWithCurrentZoomFactor = convertPxToMmWithZoomFactor(zoomFactor);
        if (!width) {
            return;
        }
        const textNewBoundingBox = {
            ...item.mmDimensions,
            width: convertPxToMmWithCurrentZoomFactor(Math.ceil(width * textBoundingBoxMultipleFactor))
        };
        item._itemViewModel.model.set(textNewBoundingBox);
    }
};

/**
 * Takes a list of items and direction in which the items will be moved.
 * @param {Designer} designer - designer
 * * @param {Item[]} items - array of items to move
 * * @param {string} direction - either of "left" or "top"
 * * @param {number} spacingFromEdge - spacing from "left" or "top" edge
 */
export const moveItemsOnCanvas = (designer: Designer, items: Item[], direction: string, spacingFromEdge: number) => {
    if (!designer || !["left", "top"].includes(direction) || items.length === 0) {
        return;
    }
    const zoomFactor = designer.documentRepository.getActiveCanvasViewModel().get("zoomFactor");
    const updateSelectedItems = (item: Item) => {
        const { model } = item._itemViewModel;
        shrinkText(item, zoomFactor);
        model.set("left", spacingFromEdge);
    };
    updateItems(designer, items, updateSelectedItems);
};
