import React from "react";
import {
    Typography,
    Button,
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogTitle,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton
} from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppDispatch, autoClosableAlert, setAlerts } from "@shared/redux";
import { useMailingServicesContext } from "@shared/features/MailingServices";
import { mailingServicesMessages } from "./messages";
import "./MailingConfirmationModal.scss";

export function MailingConfirmationModal() {
    const { t } = useTranslationSSR();
    const dispatch = useAppDispatch();
    const { isMailingServicesConfirmationModalOpen, setIsMailingServicesConfirmationModalOpen } =
        useMailingServicesContext();

    const handleOnClickContinueBtn = () => {
        setIsMailingServicesConfirmationModalOpen(false);
        dispatch(
            setAlerts({
                alerts: [
                    {
                        key: "studio.components.Toast.mailingServicesAdded",
                        skin: "positive"
                    }
                ]
            })
        );
        dispatch(autoClosableAlert());
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.SHOW_ADDED_MAILING_SERVICES_TO_POSTCARD,
            label: "Mailing services is added and backside is blank"
        } as any);
    };

    return (
        <LegacyModalDialog
            isOpen={isMailingServicesConfirmationModalOpen}
            variant="standard"
            onRequestDismiss={() => {
                setIsMailingServicesConfirmationModalOpen(false);
            }}
            className="mailing-confirmation-modal"
        >
            <LegacyModalDialogContent aria-label={t(mailingServicesMessages.confirmationDialogAriaLabel.id)}>
                <LegacyModalDialogCloseButton visuallyHiddenLabel={t(mailingServicesMessages.close.id)} />
                <LegacyModalDialogHeader className="confirmation-modal-header-text">
                    <LegacyModalDialogTitle>
                        {t(mailingServicesMessages.mailingConfirmationModalHeader.id)}
                    </LegacyModalDialogTitle>
                </LegacyModalDialogHeader>
                <Typography mb={5}>{t(mailingServicesMessages.mailingConfirmationModalMessage.id)}</Typography>
                <Button skin="primary" size="mini" onClick={handleOnClickContinueBtn}>
                    {t(mailingServicesMessages.continue.id)}
                </Button>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}

MailingConfirmationModal.displayName = "MailingConfirmationModal";
