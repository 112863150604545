import { useState, useCallback } from "react";
import { isCareAgent } from "@shared/utils/Care";
import { handleError, ERROR_CODES } from "@shared/utils/Errors";
import { fireDesignToolTrackingEvent, fireUserInteractionTrackingEvent } from "@shared/utils/Tracking";
import { useAppDispatch, setProcessingPDF, useAppSelector } from "@shared/redux";
import { getPdfProof } from "@design-stack-ct/prepress-client";
import { retry } from "@shared/utils/Network";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { saveTransientDocument } from "@shared/utils/DocumentStorage";
import { type GetDocument } from "@shared/utils/CimDoc";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { convertToAuthHeaders } from "@design-stack-ct/utility-core";
import { useGenerateProofABTest } from "./useGenerateProofABTest";

interface Props {
    filename: string;
    getDocument: GetDocument;
}

const useGeneratePdf = ({ filename, getDocument }: Props) => {
    const dispatch = useAppDispatch();
    const { auth } = useIdentityContext();
    const [hasError, setHasError] = useState(false);
    const { isProofGenerationABEnabled, trackImpression: trackABTestImpression } = useGenerateProofABTest();
    const productKey = useAppSelector(state => state.productKey);
    const designRequirements = useDesignRequirementsContext();
    const authToken = auth?.getToken();

    const onClickGenerate = useCallback(() => {
        (async function getProof() {
            trackABTestImpression();
            const generateStart = performance.now();
            dispatch(setProcessingPDF(true));
            setHasError(false);
            try {
                const fileSaver = await retry(() => import("file-saver"), { name: "import" });
                const {
                    default: { saveAs }
                } = fileSaver;
                fireDesignToolTrackingEvent({
                    eventDetail: "Generate PDF Proof Button Clicked",
                    label: "Generate PDF Proof Button",
                    pageNameBase: undefined,
                    extraData: undefined
                });

                let blob;
                const documentUrls = await (await saveTransientDocument(await getDocument(), authToken))._links;

                if (isProofGenerationABEnabled && !isCareAgent()) {
                    const renderingInstructionsUrl = documentUrls.previewInstructions?.href;

                    blob = await fetch(
                        `https://rendering.documents.cimpress.io/v1/pdf?instructions_uri=${encodeURIComponent(
                            renderingInstructionsUrl
                        )}`,
                        {
                            headers: {
                                Authorization: `Bearer ${auth.getToken()}`
                            }
                        }
                    ).then(res => res.blob());
                } else {
                    const prepressInstructionsUrl = documentUrls.drawingInstructions.href;
                    const authHeaders = convertToAuthHeaders({ value: authToken, authType: "bearer" });
                    const { PdfUrl } = await getPdfProof({
                        prepressInstructionsUrl,
                        headers: authHeaders,
                        watermarkText: isCareAgent() ? "" : "PROOF"
                    });

                    const response = await fetch(PdfUrl);
                    blob = await response.blob();
                }
                saveAs(blob, filename);
                const generateEnd = performance.now();
                const timeElapsed = generateEnd - generateStart;
                fireDesignToolTrackingEvent({
                    eventDetail: "Generate PDF Proof Processed",
                    label: "Generate PDF Proof Button",
                    pageNameBase: undefined,
                    extraData: () => ({ timeToProcess: timeElapsed })
                });

                const extraData = {
                    productKey,
                    panelsCount: designRequirements?.panels.length
                };
                fireUserInteractionTrackingEvent("Click Download PDF Button", timeElapsed, extraData);

                dispatch(setProcessingPDF(false));
            } catch (error) {
                let loggableError = error;
                // Designer promise handling is not correct for network issues and does not throw an error
                if (!error.message) {
                    loggableError = new Error("Unknown Exception from Prepress Client");
                }
                dispatch(setProcessingPDF(false));
                setHasError(true);
                const generateEnd = performance.now();
                fireDesignToolTrackingEvent({
                    eventDetail: "Generate PDF Proof Failed",
                    label: "Generate PDF Proof Button",
                    pageNameBase: undefined,
                    extraData: () => ({ timeToProcess: generateEnd - generateStart })
                });
                handleError(loggableError, ERROR_CODES.PDF_PROOF_PROCESSING, false, false);
            }
        })();
    }, [
        dispatch,
        getDocument,
        authToken,
        isProofGenerationABEnabled,
        filename,
        trackABTestImpression,
        productKey,
        designRequirements?.panels.length,
        auth
    ]);

    return { onClickGenerate, hasError };
};

export { useGeneratePdf };
