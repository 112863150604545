import qs from "qs";
import { tryFetch } from "@shared/utils/Network";

export async function getProductSurfacePreview(
    productKey: string,
    productVersion: number | undefined,
    productOptions: Record<string, string>,
    authToken: string
): Promise<{ url: string }> {
    const surfacesQueryString = qs.stringify({
        optionSelections: productOptions,
        requestor: REQUESTOR
    });
    const imageWidth = 250;
    const url = `${SURFACE_PRODUCT_URL}/surfaces/${productKey}${
        productVersion ? `/${productVersion}` : ""
    }/sampleimage/${imageWidth}?${surfacesQueryString}`;
    const response = await tryFetch({
        url,
        options: {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "uploadPrintClient:getProductSurfacePreview",
        friendlyDescription: "get product surface preview from product key"
    });
    return response;
}
