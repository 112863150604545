import React, { Fragment } from "react";
import {
    Typography,
    Box,
    Divider,
    SelectionSet,
    SelectionSetInput,
    SelectionSetLabel,
    LegacyModalDialogHeader,
    LegacyModalDialogBody,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton,
    LegacyModalDialogNav,
    Hidden,
    Visible,
    LegacyListbox,
    LegacyListboxButton,
    LegacyListboxOption,
    LegacyListboxPopover
} from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { FontPreview } from "@shared/features/ContextualToolbar";
import { getFontCategories } from "@shared/utils/Fonts";
import { fontBrowserMessages } from "./fontBrowserMessages";
import { FontWeightDropdown } from "./FontWeightDropdown";
import { FontBrowserButton } from "./FontBrowserButton";
import { type FontBrowserModalProps } from "./FontBrowserModal";
import { type FontSimpleStyle } from "./FontSimpleStyle";

const fontCategoryOptions = [
    {
        value: "recentlyUsed",
        label: fontBrowserMessages.recentlyUsed.id,
        family: "Graphik",
        renderClientside: true
    },
    {
        value: "traditional",
        label: fontBrowserMessages.traditional.id,
        family: "EB Garamond",
        height: 15
    },
    {
        value: "modern",
        label: fontBrowserMessages.modern.id,
        family: "Poppins",
        height: 16,
        fontStyle: "bold"
    },
    {
        value: "script",
        label: fontBrowserMessages.script.id,
        family: "Sacramento",
        height: 25
    },
    {
        value: "playful",
        label: fontBrowserMessages.playful.id,
        family: "Roger",
        height: 17
    },
    {
        value: "decorative",
        label: fontBrowserMessages.decorative.id,
        family: "EloquentJF",
        height: 15,
        fontStyle: "bold"
    }
];
interface FontBrowserModalContentProps
    extends Omit<FontBrowserModalProps, "onDismiss" | "onUpdateRecentFonts" | "lastSelectedFontFamily"> {
    focusedIndex: number | null;
    setFocusedIndex: React.Dispatch<React.SetStateAction<number | null>>;
}
export function FontBrowserModalContent(props: FontBrowserModalContentProps) {
    const {
        onCategoryClick,
        recentFonts,
        getFontVariant,
        getSelectedText,
        appliedWeights,
        updateLastSelectedFont,
        onUpdateFont,
        selectedFontFamily,
        fontFlavor,
        focusedIndex,
        setFocusedIndex
    } = props;

    const mainBrowserRef = React.createRef<HTMLDivElement>();
    const { t } = useTranslationSSR();

    const fontCategories = getFontCategories()[fontFlavor].slice(1);

    const fontCategoryRefs = fontCategoryOptions.reduce((acc, category) => {
        acc[category.value.toLowerCase()] = React.createRef();
        return acc;
    }, {});

    const selectedText = getSelectedText(t(fontBrowserMessages.fontBrowserPangram.id));

    const handleCategoryClick = (categoryValue: string) => {
        onCategoryClick?.(categoryValue);

        if (mainBrowserRef?.current) {
            mainBrowserRef.current.scrollTo({
                // Add 1 to offset to scroll past the divider
                top: fontCategoryRefs[categoryValue.toLowerCase()]?.current?.offsetTop + 1,
                behavior: "smooth"
            });
        }
    };

    const handleClick = (fontFamily: string, index: number, style?: FontSimpleStyle) => {
        onUpdateFont(fontFamily, style);

        updateLastSelectedFont(fontFamily);
        if (!style) {
            setFocusedIndex(index);
        }
    };

    let runningFontCount: number;
    const totalFontCount = fontCategories.reduce((acc, curr) => {
        return acc + curr.fontFamilies.length;
    }, recentFonts.length);

    const handleKeyDown = (event: React.KeyboardEvent<Element>) => {
        if (event.key === "Enter") {
            event.stopPropagation(); // allows enter to select font for word art
        }
        if (event.key === "ArrowDown") {
            setFocusedIndex(currentFocusedIndex => {
                return currentFocusedIndex === totalFontCount - 1 ? 0 : (currentFocusedIndex || 0) + 1;
            });
        }
        if (event.key === "ArrowUp") {
            setFocusedIndex(currentFocusedIndex => {
                return currentFocusedIndex === 0 || !currentFocusedIndex ? totalFontCount - 1 : currentFocusedIndex - 1;
            });
        }
    };

    return (
        <LegacyModalDialogContent
            className="font-browser"
            aria-label={t(fontBrowserMessages.ariaModalLabel.id)}
            data-testid="font-browser-modal"
        >
            <LegacyModalDialogNav>
                <LegacyModalDialogHeader>
                    <Typography fontSize="3" textAlign="center">
                        {t(fontBrowserMessages.fontBrowserHeader.id)}
                    </Typography>
                </LegacyModalDialogHeader>
                <LegacyModalDialogCloseButton visuallyHiddenLabel={t(fontBrowserMessages.ariaCloseLabel.id)} />
                <Visible sm className="font-browser__listbox-container">
                    <LegacyListbox fullWidth size="mini">
                        <LegacyListboxButton mt={5} aria-label={t(fontBrowserMessages.fontCategoriesAriaLabel.id)} />
                        <LegacyListboxPopover inModal className="font-browser__listbox-popover">
                            {fontCategoryOptions.map((fontCategory, index) => (
                                <Fragment key={fontCategory.value}>
                                    <LegacyListboxOption
                                        className="font-browser__listbox-option"
                                        value={fontCategory.value}
                                        label={t(fontCategory.label)}
                                        mb={2}
                                        mx={0}
                                        // @ts-expect-error: Issue with SWAN types: https://vistaprint.slack.com/archives/CB9FVD94J/p1670967014616639
                                        onClick={() => handleCategoryClick(fontCategory.value)}
                                        data-testid={`category-${fontCategory.value}`}
                                        data-dcl-prevent-canvas-items-deselection
                                    >
                                        <FontPreview
                                            text={t(fontCategory.label)}
                                            fontFamily={fontCategory.family}
                                            altText={t(fontCategory.label)}
                                            renderClientside={fontCategory.renderClientside}
                                            height={fontCategory.height}
                                            fontStyle={fontCategory.fontStyle}
                                        />
                                    </LegacyListboxOption>
                                    {index === 0 ? <Divider mb={2} /> : null}
                                </Fragment>
                            ))}
                        </LegacyListboxPopover>
                    </LegacyListbox>
                </Visible>
            </LegacyModalDialogNav>
            <Divider />
            <LegacyModalDialogBody>
                <Hidden sm className="font-browser__sidebar">
                    <SelectionSet
                        className="font-browser__selection-set"
                        variant="single-select"
                        skin="buttons"
                        margin={3}
                        aria-label={t(fontBrowserMessages.fontCategoriesAriaLabel.id)}
                    >
                        {fontCategoryOptions.map((fontCategory, index) => (
                            <Fragment key={fontCategory.value}>
                                <SelectionSetInput
                                    value={fontCategory.value}
                                    onClick={() => handleCategoryClick(fontCategory.value)}
                                >
                                    <SelectionSetLabel mb={2}>
                                        <FontPreview
                                            text={t(fontCategory.label)}
                                            fontFamily={fontCategory.family}
                                            altText={t(fontCategory.label)}
                                            renderClientside={fontCategory.renderClientside}
                                            height={fontCategory.height}
                                            fontStyle={fontCategory.fontStyle}
                                        />
                                    </SelectionSetLabel>
                                </SelectionSetInput>
                                {index === 0 ? <Divider mb={2} /> : null}
                            </Fragment>
                        ))}
                    </SelectionSet>
                </Hidden>
                <Box className="font-browser__main" px={3} pb={3} ref={mainBrowserRef}>
                    <Box ref={fontCategoryRefs[0]}>
                        <Typography
                            py={3}
                            fontSize="1"
                            fontWeight="bold"
                            // eslint-disable-next-line dot-notation
                            ref={fontCategoryRefs["recentlyused"]}
                            className="font-browser__font-category"
                        >
                            {t(fontCategoryOptions[0].label)}
                        </Typography>
                        {recentFonts.map((fontFamily: string, index) => {
                            const fontVariants = getFontVariant(fontFamily);
                            const hasFontVariants = fontVariants && Object.values(fontVariants).filter(Boolean).length;

                            return hasFontVariants ? (
                                <FontWeightDropdown
                                    key={fontFamily}
                                    dropdownIndex={index}
                                    selectedText={selectedText || ""}
                                    fontFamily={fontFamily}
                                    appliedWeights={appliedWeights}
                                    handleClick={handleClick}
                                    setFocusedIndex={setFocusedIndex}
                                    isSelected={selectedFontFamily === fontFamily}
                                    handleKeyDown={handleKeyDown}
                                    isFocused={index === focusedIndex}
                                    recentFonts={recentFonts}
                                    fontVariants={fontVariants}
                                />
                            ) : (
                                <FontBrowserButton
                                    key={fontFamily}
                                    index={index}
                                    selectedText={selectedText || ""}
                                    fontFamily={fontFamily}
                                    handleClick={handleClick}
                                    isSelected={selectedFontFamily === fontFamily}
                                    handleKeyDown={handleKeyDown}
                                    isFocused={index === focusedIndex}
                                    recentFonts={recentFonts}
                                />
                            );
                        })}
                    </Box>

                    {fontCategories.map((fontCategory, index) => {
                        runningFontCount =
                            index === 0
                                ? recentFonts.length
                                : (runningFontCount += fontCategories[index - 1].fontFamilies.length);
                        return (
                            <Box key={fontCategory.name}>
                                <Divider mt={3} ref={fontCategoryRefs[fontCategory.name.toLowerCase()]} />
                                <Typography
                                    py={3}
                                    className="font-browser__font-category"
                                    fontSize="1"
                                    fontWeight="bold"
                                >
                                    {t(fontCategoryOptions[index + 1].label)}
                                </Typography>
                                {fontCategory.fontFamilies.map((fontFamily: string, index) => {
                                    const calculatedIndex = index + runningFontCount;

                                    const fontVariants = getFontVariant(fontFamily);
                                    const hasFontVariants =
                                        fontVariants && Object.values(fontVariants).filter(Boolean).length;

                                    return hasFontVariants ? (
                                        <FontWeightDropdown
                                            key={fontFamily}
                                            dropdownIndex={calculatedIndex}
                                            selectedText={selectedText || ""}
                                            fontFamily={fontFamily}
                                            appliedWeights={appliedWeights}
                                            handleClick={handleClick}
                                            setFocusedIndex={setFocusedIndex}
                                            isSelected={selectedFontFamily === fontFamily}
                                            handleKeyDown={handleKeyDown}
                                            isFocused={calculatedIndex === focusedIndex}
                                            recentFonts={recentFonts}
                                            fontVariants={fontVariants}
                                        />
                                    ) : (
                                        <FontBrowserButton
                                            key={fontFamily}
                                            index={calculatedIndex}
                                            selectedText={selectedText || ""}
                                            fontFamily={fontFamily}
                                            handleClick={handleClick}
                                            isSelected={selectedFontFamily === fontFamily}
                                            handleKeyDown={handleKeyDown}
                                            isFocused={calculatedIndex === focusedIndex}
                                            recentFonts={recentFonts}
                                        />
                                    );
                                })}
                            </Box>
                        );
                    })}
                </Box>
            </LegacyModalDialogBody>
        </LegacyModalDialogContent>
    );
}
FontBrowserModalContent.displayName = "FontBrowserModalContent";
