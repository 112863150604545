import React from "react";
import { Pagination, PaginationButton, PaginationEllipses, PaginationStep, FlexBox } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useGallery, TemplatePaginationActionTypes } from "../GalleryProvider";
import {
    MAX_PAGE_LINKS,
    STARTING_PAGE_NUMBER,
    generatePageNumbers,
    scrollToTopOfElement
} from "./templatePaginationUtilities";

const messages = defineMessages({
    paginationAriaLabel: {
        id: "studio.components.changeTemplate.pagination",
        defaultMessage: "Pagination",
        description: {
            note: "aria-label for pagination"
        }
    },
    pageNumberText: {
        id: "studio.components.changeTemplate.pageNumberText",
        defaultMessage: "Page [[pageNumber]]",
        description: {
            note: "page number for aria-label"
        }
    },
    previousPage: {
        id: "studio.components.changeTemplate.previousPage",
        defaultMessage: "Previous Page",
        description: {
            note: "aria-label for previous page button"
        }
    },
    nextPage: {
        id: "studio.components.changeTemplate.nextPage",
        defaultMessage: "Next Page",
        description: {
            note: "aria-label for next page button"
        }
    }
});

interface TemplatePaginationProps {
    scrollableContent?: string;
}

export function TemplatePagination({ scrollableContent }: TemplatePaginationProps) {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const { numberOfPages, currentPage, dispatchPagination } = useGallery();
    const pageNumbersList = generatePageNumbers(currentPage, MAX_PAGE_LINKS, numberOfPages, STARTING_PAGE_NUMBER);

    const trackPageChange = () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_GALLERY_CHANGE_PAGE,
            label: "Click template gallery change page"
        });
    };

    const handlePrevClick = () => {
        if (currentPage > 1) {
            trackPageChange();
            dispatchPagination({ type: TemplatePaginationActionTypes.PREV_PAGE });
            if (scrollableContent) {
                scrollToTopOfElement(scrollableContent);
            }
        }
    };
    const handleNextClick = () => {
        if (currentPage < numberOfPages) {
            trackPageChange();
            dispatchPagination({ type: TemplatePaginationActionTypes.NEXT_PAGE });
            if (scrollableContent) {
                scrollToTopOfElement(scrollableContent);
            }
        }
    };
    const handlePageNumberClick = (pageNumber: number) => {
        trackPageChange();
        dispatchPagination({ type: TemplatePaginationActionTypes.GO_TO_PAGE, pageNumber });
        if (scrollableContent) {
            scrollToTopOfElement(scrollableContent);
        }
    };

    return (
        <FlexBox justifyContent="center" pb={isSmall ? 2 : undefined}>
            {numberOfPages > 1 && (
                <Pagination visuallyHiddenText={t(messages.paginationAriaLabel.id)}>
                    <PaginationButton
                        variant="previous"
                        visuallyHiddenText={t(messages.previousPage.id)}
                        disabled={numberOfPages === 0 || currentPage === STARTING_PAGE_NUMBER}
                        onClick={handlePrevClick}
                    />
                    {/* First Page */}
                    <PaginationStep
                        key={STARTING_PAGE_NUMBER}
                        active={currentPage === STARTING_PAGE_NUMBER}
                        visuallyHiddenText={t(messages.pageNumberText.id, { STARTING_PAGE_NUMBER })}
                        onClick={() => handlePageNumberClick(STARTING_PAGE_NUMBER)}
                    >
                        {STARTING_PAGE_NUMBER}
                    </PaginationStep>
                    {pageNumbersList[0] > STARTING_PAGE_NUMBER + 1 && <PaginationEllipses key={-1} />}
                    {/* Remaining Pages */}
                    {numberOfPages > STARTING_PAGE_NUMBER &&
                        pageNumbersList.map(pageNumber => (
                            <PaginationStep
                                key={pageNumber}
                                active={currentPage === pageNumber}
                                visuallyHiddenText={t(messages.pageNumberText.id, { pageNumber })}
                                onClick={() => handlePageNumberClick(pageNumber)}
                            >
                                {pageNumber}
                            </PaginationStep>
                        ))}
                    {pageNumbersList[pageNumbersList.length - 1] < numberOfPages - 1 && <PaginationEllipses key={-2} />}
                    {/* Last Page */}
                    <PaginationStep
                        key={numberOfPages}
                        active={currentPage === numberOfPages}
                        visuallyHiddenText={t(messages.pageNumberText.id, { numberOfPages })}
                        onClick={() => handlePageNumberClick(numberOfPages)}
                    >
                        {numberOfPages}
                    </PaginationStep>
                    <PaginationButton
                        variant="next"
                        visuallyHiddenText={t(messages.nextPage.id)}
                        disabled={numberOfPages === 0 || currentPage === numberOfPages}
                        onClick={handleNextClick}
                    />
                </Pagination>
            )}
        </FlexBox>
    );
}

TemplatePagination.displayName = "TemplatePagination";
