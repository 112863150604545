import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
    className?: string;
}

export function SafetyLineIndicatorIcon(props: Props) {
    return (
        <svg viewBox="0 0 22 2" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M1 1H21" stroke="#a5a5a5" strokeWidth="2" strokeLinecap="round" strokeDasharray="3 3" />
        </svg>
    );
}
SafetyLineIndicatorIcon.displayName = "SafetyLineIndicatorIcon";
