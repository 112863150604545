import React, { useEffect, useState, useCallback } from "react";
import classNames from "classnames";
import { Typography, FlexBox } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useAppSelector } from "@shared/redux";
import { LoadingSpinner } from "@shared/features/StudioChrome";
import { useSelection } from "../../designer/useSelection";
import { useDesigner } from "../../designer/DesignerProvider";
import { embroideryMessages } from "./embroideryMessages";
import { getPreviewBackgroundStyle } from "../../util";
import "./embroideryImageColorModal.scss";

interface Props {
    isUpdating: boolean;
    setIsUpdating: (isUpdating: boolean) => void;
}
export function EmbroideryImageColorPreview({ isUpdating, setIsUpdating }: Props) {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const isOpen = useAppSelector(state => state.embroideryImageColorModalOpen);
    const selection = useSelection("model:change:colorOverrides");
    const [imageUrl, setImageUrl] = useState();
    const [background, setBackground] = useState({ backgroundImage: "", backgroundSize: "", backgroundPosition: "" });
    const [previewImageHeight, setPreviewImageHeight] = useState(0);
    const [previewImageWidth, setPreviewImageWidth] = useState(0);

    const imageContainerRef = useCallback(node => {
        if (node !== null) {
            setPreviewImageHeight(node.getBoundingClientRect().height);
            setPreviewImageWidth(node.getBoundingClientRect().width);
        }
    }, []);

    useEffect(() => {
        if (!isOpen || !designer) {
            return;
        }

        const underlayScene = designer.documentRepository?.getActiveCanvasViewModel()?.getUnderlayScene();
        if (underlayScene) {
            const background = getPreviewBackgroundStyle(underlayScene, previewImageWidth, previewImageHeight);
            setBackground(background);
        }
    }, [previewImageWidth, previewImageHeight, designer, isOpen]);

    useEffect(() => {
        const getImageUrl = async () => {
            const imageUrl = await selection[0]?._itemViewModel.get("url");
            setImageUrl(imageUrl);
        };

        if (isOpen) {
            getImageUrl();
        }
    }, [selection, isOpen]);

    return (
        <FlexBox
            className="embroidery-image-color-modal__image-container"
            ref={imageContainerRef}
            justifyContent="center"
            style={background}
        >
            <FlexBox
                className={classNames("embroidery-image-color-modal__updating-message", {
                    "embroidery-image-color-modal__updating-message--visible": isUpdating,
                    "embroidery-image-color-modal__updating-message--hidden": !isUpdating
                })}
                alignItems="center"
                justifyContent="center"
            >
                <LoadingSpinner className="embroidery-image-color-modal__loading-spinner" />
                <Typography marginLeft={2} fontSize="-1">
                    {t(embroideryMessages.updatingColor.id)}
                </Typography>
            </FlexBox>
            <img
                onLoad={() => setIsUpdating(false)}
                className="embroidery-image-color-modal__image"
                src={imageUrl}
                alt={t(embroideryMessages.previewColor.id)}
            />
        </FlexBox>
    );
}

EmbroideryImageColorPreview.displayName = "EmbroideryImageColorPreview";
