interface SurfacePoint {
    x: string | number;
    y: string | number;
}

interface SurfacePathData {
    anchorX: number;
    anchorY: number;
    pathPoints: SurfacePathPointData[];
}

interface SurfacePathPointData {
    endPointX: number;
    endPointY: number;
    firstControlPointX?: number;
    firstControlPointY?: number;
    secondControlPointX?: number;
    secondControlPointY?: number;
}

interface Coordinates {
    firstCurvePoint: Partial<SurfacePoint>;
    secondCurvePoint: Partial<SurfacePoint>;
    end: SurfacePoint;
}

const svgDirections = {
    moveTo: "M",
    lineTo: "L",
    curve: "C",
    closePath: "Z"
};

const createCoordinateInstructions = ({ x, y }: SurfacePoint) => `${x},${y}`;

/**
 * Takes a direction and array of coordinates and returns a string (a direction segment) that the svg
 * can use to draw a line
 * @param {string} direction - direction for how the path should be drawn
 * @param {Object[]} coordinates
 * @param {number} coordinates[].x
 * @param {number} coordinates[].y
 */
const createInstructions = (direction: string, coordinates: SurfacePoint[]) => {
    const coordStrings = coordinates.map(createCoordinateInstructions);
    return `${direction} ${coordStrings.join(" ")}`;
};

const mapCoordinates = (data: SurfacePathPointData): Coordinates => ({
    end: {
        x: data.endPointX,
        y: data.endPointY
    },
    firstCurvePoint: {
        x: data.firstControlPointX,
        y: data.firstControlPointY
    },
    secondCurvePoint: {
        x: data.secondControlPointX,
        y: data.secondControlPointY
    }
});

/**
 * If added to the beginning of an svg path, this will create a rectangle of the specified width and height
 * This can be used to create a border around the edges of the canvas, which can then be filled in
 */
const createBorder = () => {
    const topLeft = { x: 0, y: 0 };
    const borderAroundCanvas = [
        topLeft,
        { x: "{width}", y: 0 },
        { x: "{width}", y: "{height}" },
        { x: 0, y: "{height}" }
    ];

    return `${createInstructions(svgDirections.moveTo, [topLeft])} ${createInstructions(
        svgDirections.lineTo,
        borderAroundCanvas
    )} ${svgDirections.closePath}`;
};

interface CurveCoordinates {
    firstCurvePoint: SurfacePoint;
    secondCurvePoint: SurfacePoint;
    end: SurfacePoint;
}

const isCurve = (coordinates: Coordinates): coordinates is CurveCoordinates =>
    coordinates.firstCurvePoint.x !== undefined &&
    coordinates.firstCurvePoint.y !== undefined &&
    coordinates.secondCurvePoint.x !== undefined &&
    coordinates.secondCurvePoint.y !== undefined;

/**
 * Takes the data for a path and an object that has the path data in strings
 * @param {Object} data
 * @param {number} data.anchorX - x coordinate for starting point of directions
 * @param {number} data.anchorY - y coordinate for starting point of directions
 * @param {Object[]} data.pathPoints - array of directions for where the path will go
 * @param {Boolean} shouldClosePath - true if svg string should contain a direction saying to close the shape
 * @returns {Object} - a string of where the path will start, an array of strings that stipulate the directions
 * for where the path will go, a boolean saying whether the shape should be closed, and a border that may be used to
 * style the area around the svg rather than the svg itself
 */
const createPaths = (data: SurfacePathData, shouldClosePath: boolean) => {
    const start = createInstructions(svgDirections.moveTo, [{ x: data.anchorX, y: data.anchorY }]);

    const directions = data.pathPoints.map(p => {
        const coordinateObject = mapCoordinates(p);
        return isCurve(coordinateObject)
            ? createInstructions(svgDirections.curve, [
                  coordinateObject.firstCurvePoint,
                  coordinateObject.secondCurvePoint,
                  coordinateObject.end
              ])
            : createInstructions(svgDirections.lineTo, [coordinateObject.end]);
    });

    const closePath = shouldClosePath ? svgDirections.closePath : "";

    const border = createBorder();

    return {
        start,
        directions,
        closePath,
        border
    };
};

/**
 * Take surface data that has directions for svg paths and returns an array of objects that include the path information transformed to strings
 * that can be added together to create svgs
 * @param {Object} data - contains pathType and an array of paths
 * @param {Boolean} closePath - true if svg should be a closed shape
 */
export default function convertSurfaceData(data: SurfaceSpecificationPath, closePath: boolean) {
    const paths = data.paths.map(path => createPaths(path, closePath));
    const type = data.pathType.toLowerCase();

    return { paths, type };
}

/**
 * I added this new to just give a full path
 */
const createPathDescription = (instructions: ReturnType<typeof createPaths>) =>
    `${instructions.start} ${instructions.directions.join(" ")} ${instructions.closePath}`;

export const converSurfaceToSvgPath = (data: SurfaceSpecificationPath, closePath: boolean) => {
    return convertSurfaceData(data, closePath).paths.reduce((acc, path) => `${acc} ${createPathDescription(path)}`, "");
};

interface SurfaceSpecificationPath {
    pathType: string;
    paths: SurfacePathData[];
}
