import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { Button, Icon } from "@vp/swan";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
    handleClose: () => void;
    closeButtonAriaLabel: string;
    dialogIsOpen: boolean;
}

export function ModelessDialogCloseButton({ handleClose, closeButtonAriaLabel, className, dialogIsOpen }: Props) {
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        let returnFn;
        if (dialogIsOpen) {
            // focus on close button after the panel has finished animating in
            const timer = setTimeout(() => {
                buttonRef?.current?.focus();
            }, 250);
            returnFn = () => clearTimeout(timer);
        }
        return returnFn;
    }, [dialogIsOpen]);

    return (
        <Button
            ref={buttonRef}
            className={classNames("modeless-dialog-close-button", className)}
            buttonShape="round"
            skin="unstyled"
            aria-label={closeButtonAriaLabel}
            onClick={handleClose}
        >
            <Icon iconType="close" />
        </Button>
    );
}

ModelessDialogCloseButton.displayName = "ModelessDialogCloseButton";
