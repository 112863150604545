import React, { useCallback } from "react";

/**
 * Provide a callback for keyboard handling
 * This should only be used on buttons that specifically need to overcome RTF focus stealing selection behavior
 * Default usage is intended for when you want to have
 * "Enter" or " " invoke the passed in onClick handler
 * @param action Handler to invoke
 * @param keys Keyboard keys that should trigger the action
 */
export function useOnKeyDownRTFFocus(action: () => void, keys = ["Enter", " "]) {
    return useCallback(
        (e: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement>) => {
            const { key } = e;

            if (key && keys.includes(key)) {
                e.preventDefault();
                e.stopPropagation();

                action();

                // specifically RTM has code like the following that causes reselections asynchronously
                // setTimeout(() => {
                //     const newPos = quillInstance.getSelection().index;
                //     if (newPos === oldPos) {
                //         quillInstance.setSelection(quillInstance.getSelection().index + 1, 0);
                //     }
                // }, 30);
                // so we need to set it back.  This only needs to live as long as the quill hack lives or move to graphite
                setTimeout(() => (e.nativeEvent.target as HTMLElement)?.focus(), 200);

                (e.nativeEvent.target as HTMLElement)?.focus();
            }
        },
        [action, keys]
    );
}
