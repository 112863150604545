import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
    className?: string;
}

export function TrimLineIndicatorIcon(props: Props) {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="10" cy="10" r="10" fill="#7cc5e4" />
        </svg>
    );
}
TrimLineIndicatorIcon.displayName = "TrimLineIndicatorIcon";
