import React, { useState } from "react";
import {
    Typography,
    Box,
    Divider,
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogBody,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton,
    Button,
    LegacyModalDialogFooter
} from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useStudioLayout, DesktopExperience } from "@shared/features/ResponsiveDesign";
import classNames from "classnames";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import type { DSS } from "@vp/types-ddif";
import type { Save } from "@shared/features/Save";
import { ChangeTemplateHeader } from "./ChangeTemplateHeader";
import { ChangeTemplateFilters } from "./Filters/ChangeTemplateFilters";
import { ChangeTemplateGallery } from "./ChangeTemplateGallery";
import { AdjustFiltersButton } from "./Filters/AdjustFiltersButton";
import { TemplatePagination } from "./Pagination/TemplatePagination";
import { useGallery } from "./GalleryProvider";
import { ClearFiltersButton } from "./Filters/ClearFiltersButton";
import { changeTemplateMessages } from "./messages";
import { useChangeTemplate } from "./useChangeTemplate";
import * as styles from "./ChangeTemplateModal.module.scss";
import type { LoadNewDesign } from "./changeTemplateClient";

interface ChangeTemplateModalProps {
    isOpen: boolean;
    onRequestDismiss: () => void;
    getDocument: () => Promise<DSS.DesignDocument>;
    loadNewDesign: LoadNewDesign;
    save: Save;
}

const MOBILE_SCROLLABLE_CONTENT = "mobile-scrollable-content";
const DESKTOP_SCROLLABLE_CONTENT = "desktop-scrollable-content";

export function ChangeTemplateModal({
    isOpen,
    onRequestDismiss,
    getDocument,
    loadNewDesign,
    save
}: ChangeTemplateModalProps) {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const { results, currentDesign, currentTemplateColorSwatch, designs, defaultDesigns } = useGallery();
    const [showApplyChangesBtn, setShowApplyChangesBtn] = useState<boolean>(false);
    const { changeTemplate, generateNewDocument } = useChangeTemplate({ getDocument, loadNewDesign, save });
    const noResults = results === 0;

    const handleTemplateColorChange = (hasTemplateColorChanged: boolean) => {
        setShowApplyChangesBtn(hasTemplateColorChanged);
    };

    const handleDismiss = () => {
        onRequestDismiss();
        setShowApplyChangesBtn(false);
    };

    const handleApplyTemplateChanges = async () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_APPLY_TEMPLATE_COLOR_BUTTON,
            label: "Click on apply template color update in change template dialog"
        });
        const selectedTemplateSource = currentTemplateColorSwatch || currentDesign;
        const selectedTemplate = selectedTemplateSource?.previewInfo?.templateToken;
        if (selectedTemplate && currentDesign) {
            const { designDocument } = await generateNewDocument(
                selectedTemplate,
                currentDesign.fullProductOptions,
                true
            );
            if (designDocument) {
                changeTemplate(selectedTemplate, currentDesign.fullProductOptions, designDocument);
                onRequestDismiss();
                setShowApplyChangesBtn(false);
            }
        }
    };

    return (
        <LegacyModalDialog
            isOpen={isOpen}
            onRequestDismiss={handleDismiss}
            className={styles.modal}
            variant={isSmall ? "panel-bottom" : "panel-right"}
            data-dcl-prevent-canvas-items-deselection
        >
            <LegacyModalDialogContent
                id={isSmall ? MOBILE_SCROLLABLE_CONTENT : ""}
                aria-label={t(changeTemplateMessages.desktopModalHeader.id)}
                fullBleed={true}
            >
                <LegacyModalDialogBody
                    className={classNames(styles.modalBody, {
                        [styles.applyButtonVisible]: showApplyChangesBtn
                    })}
                    paddingX={4}
                >
                    <DesktopExperience>
                        <Box px={5} className={styles.filtersContainer}>
                            <Box className={styles.clearFiltersButtonContainer} pt={2} pb={4}>
                                <ClearFiltersButton />
                            </Box>
                            <ChangeTemplateFilters />
                        </Box>
                    </DesktopExperience>
                    <Box id={!isSmall ? DESKTOP_SCROLLABLE_CONTENT : ""} className={styles.galleryContainer}>
                        <div className={styles.stickyNav}>
                            <LegacyModalDialogCloseButton
                                visuallyHiddenLabel={t(changeTemplateMessages.closeModal.id)}
                                className="modal-close-button"
                            />
                            <LegacyModalDialogHeader paddingX={isSmall ? 0 : 4} marginBottom={0} marginX={0}>
                                <ChangeTemplateHeader />
                                <AdjustFiltersButton />
                            </LegacyModalDialogHeader>
                        </div>
                        {noResults ? (
                            <>
                                <Box px={isSmall ? undefined : 4} ml={isSmall ? undefined : 5}>
                                    <Typography
                                        textAlign="center"
                                        fontSize="1"
                                        mt={5}
                                        mb={isSmall ? 4 : 5}
                                        px={isSmall ? 4 : undefined}
                                    >
                                        {t(changeTemplateMessages.noFilterResults.id)}
                                    </Typography>
                                    <Divider mb={isSmall ? 6 : 7} />
                                    <Typography textAlign="center" fontSize="1" mb={5} mt={isSmall ? 4 : 5}>
                                        {t(changeTemplateMessages.exploreUnfiltered.id)}
                                    </Typography>
                                </Box>
                                <ChangeTemplateGallery
                                    designs={defaultDesigns}
                                    onRequestDismiss={onRequestDismiss}
                                    onTemplateColorChange={handleTemplateColorChange}
                                    getDocument={getDocument}
                                    loadNewDesign={loadNewDesign}
                                    save={save}
                                />
                            </>
                        ) : (
                            <ChangeTemplateGallery
                                designs={designs}
                                onRequestDismiss={onRequestDismiss}
                                onTemplateColorChange={handleTemplateColorChange}
                                getDocument={getDocument}
                                loadNewDesign={loadNewDesign}
                                save={save}
                            />
                        )}
                        <LegacyModalDialogFooter m={5}>
                            <TemplatePagination
                                scrollableContent={isSmall ? MOBILE_SCROLLABLE_CONTENT : DESKTOP_SCROLLABLE_CONTENT}
                            />
                        </LegacyModalDialogFooter>
                    </Box>
                    <div
                        className={classNames(styles.applyChangeButton, {
                            [styles.applyChangeButtonVisible]: showApplyChangesBtn
                        })}
                    >
                        <Button
                            skin="primary"
                            size={isSmall ? "mini" : "standard"}
                            width={isSmall ? "full-width" : "standard"}
                            onClick={handleApplyTemplateChanges}
                        >
                            {t(changeTemplateMessages.applyTemplateChangeButtonLabel.id)}
                        </Button>
                    </div>
                </LegacyModalDialogBody>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}
ChangeTemplateModal.displayName = "ChangeTemplateModal";
