import React from "react";

interface Props extends React.HTMLAttributes<SVGSVGElement> {}

export function MailingWarningIcon(props: Props) {
    return (
        <svg height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M10.516 3.66942L3.23068 16.6045C2.55336 17.794 3.39588 19.2973 4.71748
                19.2973H19.2716C20.6097 19.2973 21.4357 17.794 20.7584 16.6045L13.4896 3.66942C12.8288 2.47998 11.1768 2.47998 10.516 3.66942Z"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M12.0015 8.49219V12.7544" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M12.002 16.1049C11.783 16.1049 11.5769 15.9165 11.5769
                15.6467C11.5769 15.3992 11.7775 15.2051 12.002 15.2051C12.221 15.2051 12.4271 15.3934 12.4271 15.6632C12.4271 15.9108 12.2266 16.1049 12.002 16.1049Z"
                fill="currentColor"
                stroke="black"
            />
        </svg>
    );
}
MailingWarningIcon.displayName = "MailingWarningIcon";
