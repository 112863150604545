import { formatUpload } from "@shared/features/UploadsAndAssets";
import { SIMTAG_PREFIX } from "@five/xerox/features/Elements";
import { Designer } from "../../../../easel/designer-suite/@types/designer";

export interface ClipartItem {
    id: string;
    src: string;
    width: number;
    height: number;
    previewUrl: string;
    printUrl: string;
    printPixelHeight: number;
    printPixelWidth: number;
    svgUrl: string;
}

export const createModel = (imageResult: ClipartItem, designer: Designer) => {
    if (!designer) {
        return undefined;
    }
    const extension = "png";
    const type = "image";
    const upload = {
        ...imageResult,
        originalFileContentType: `${type}/${extension}`,
        previewUrl: imageResult.svgUrl, // cimdoc previewUrl
        uploadId: `${SIMTAG_PREFIX}${imageResult.id}`,
        url: imageResult.printUrl, // cimdoc originalSourceUrl
        status: "50",
        analysisIsLogo: "False",
        analysisIsPhoto: "True",
        analysisIsVector: "False",
        analysisLineartness: "1",
        extension,
        type
    };
    const formatedUpload = formatUpload(upload);
    const model = designer.uploadManager.createModels(formatedUpload);
    return model;
};
