import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import {
    Button,
    Divider,
    LegacyModalDialog,
    LegacyModalDialogBody,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton,
    LegacyModalDialogFooter
} from "@vp/swan";
import { UploadStrategies, useHandleImagePlacement } from "@designer-suite";
import { useTrackEvents } from "@shared/features/Tracking";
import { imageLibraryPanelMessages } from "./imageLibraryMessages";
import { DP_IMAGE_PREFIX, updateRecentlyUsedList } from "./ImagePanelUtils";
import { useImageGalleryPreview } from "./ImageGalleryPreviewProvider";

import "./imageGalleryPreview.scss";

export function ImageGalleryPreview() {
    const { trackEvent } = useTrackEvents();
    const { t } = useTranslationSSR();
    const { imageGalleryPreviewData: preview, setImageGalleryPreviewData } = useImageGalleryPreview();

    const { isSmall } = useStudioLayout();

    const onCancel = () => {
        const previewUrl = preview?.previewUrl;
        trackEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.IMAGE_GALLERY_IMAGE_CLOSE,
            extraData: () => ({
                previewUrl
            })
        });
        setImageGalleryPreviewData(undefined);
    };
    const { onImageDoubleClicked } = useHandleImagePlacement({
        placementStrategy: UploadStrategies.AutofillSinglePlaceholder,
        onUploadAdded: onCancel
    });

    const addToDesign = () => {
        if (preview) {
            const { upload, page, previewUrl } = preview;

            if (upload.id?.startsWith(DP_IMAGE_PREFIX)) {
                trackEvent({
                    eventDetail: STUDIO_TRACKING_EVENTS.IMAGE_GALLERY_IMAGE_ADD_TO_DESIGN,
                    extraData: () => ({
                        previewUrl
                    })
                });
                updateRecentlyUsedList(upload.id ?? "");
            }

            onImageDoubleClicked({ upload, page });
        }
    };

    return (
        <LegacyModalDialog
            isOpen={Boolean(preview)}
            onRequestDismiss={onCancel}
            paddingTop={isSmall ? undefined : 4}
            variant="standard"
            data-dialog-enable-browser-history="xs-only"
            onClick={(event: React.MouseEvent) => {
                event.preventDefault();
                setImageGalleryPreviewData(undefined);
            }}
            data-dcl-prevent-canvas-items-deselection
        >
            <LegacyModalDialogContent
                fullBleed={isSmall}
                className="image-gallery-preview-content"
                pb={4}
                pt={9}
                px={0}
                aria-label={t(imageLibraryPanelMessages.imageGalleryModalAriaLabel.id)}
            >
                <Divider />
                <LegacyModalDialogCloseButton
                    visuallyHiddenLabel={t(imageLibraryPanelMessages.closeModalAriaLabel.id)}
                />
                <LegacyModalDialogBody>
                    <div
                        className="image-gallery-preview-image"
                        style={{ backgroundImage: preview?.previewUrl ? `url(${preview?.previewUrl})` : "" }}
                    />
                </LegacyModalDialogBody>
                <LegacyModalDialogFooter mt={4}>
                    <Button skin="primary" size="mini" onClick={addToDesign}>
                        {t(imageLibraryPanelMessages.addToDesignButtonLabel.id)}
                    </Button>
                </LegacyModalDialogFooter>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}
ImageGalleryPreview.displayName = "ImageGalleryPreview";
