import { type EmbroiderySelectableColor, type SelectableColor, ColorSwatch } from "@shared/features/ColorPicker";
import { Typography, FlexBox, Divider } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import React, { useEffect, useRef, useState } from "react";
import { scrollToRef } from "@shared/utils/WebBrowser";
import { HorizontalScroller } from "@shared/features/StudioChrome";
import { embroideryMessages } from "./embroideryMessages";
import { ResetButton } from "./ResetButton";
import { isInViewport } from "../../../utilities/Utilities";

interface Props {
    paletteColors: SelectableColor[];
    currentColors: EmbroiderySelectableColor[] | undefined;
    onChange: (color: string) => void;
    resetColors: () => void;
    setOrdinalToUpdate: (ordinal: number) => void;
    ordinalToUpdate: number;
}
export function MobileEmbroideryColorPalette({
    paletteColors,
    currentColors,
    onChange,
    resetColors,
    setOrdinalToUpdate,
    ordinalToUpdate
}: Props) {
    const { t } = useTranslationSSR();
    const [currentSelection, setCurrentSelection] = useState<undefined | SelectableColor>();
    const currentSwatchRef = useRef<HTMLElement>(null);
    const parentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (ordinalToUpdate) {
            const selectedColor = currentColors?.find(color => color.ordinal === ordinalToUpdate);
            setCurrentSelection(selectedColor);
        }
    }, [currentColors, ordinalToUpdate, setOrdinalToUpdate]);

    useEffect(() => {
        if (currentSwatchRef && currentSwatchRef.current && currentSelection) {
            if (!isInViewport(currentSwatchRef.current)) {
                scrollToRef(parentRef, currentSwatchRef, 0, false, "smooth");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelection, currentSwatchRef, currentSwatchRef.current]);

    return (
        <>
            <FlexBox
                alignItems="center"
                justifyContent="space-between"
                className="embroidery-image-color-modal__container embroidery-image-color-modal__container--short"
            >
                <Typography fontSize="-1">{t(embroideryMessages.currentColors.id)}</Typography>
                <ResetButton resetColors={resetColors} />
            </FlexBox>
            <div className="embroidery-image-color-modal__mobile-current-swatches">
                <HorizontalScroller>
                    {currentColors?.map(color => {
                        return (
                            <ColorSwatch
                                className="embroidery-image-color-modal__mobile-color-swatch"
                                key={color.ordinal}
                                isSelected={color.ordinal === ordinalToUpdate}
                                color={color}
                                toggleColor={(color: EmbroiderySelectableColor) => {
                                    setCurrentSelection(color);
                                    setOrdinalToUpdate(color.ordinal);
                                }}
                            />
                        );
                    })}
                </HorizontalScroller>
            </div>
            <Divider marginTop={4} />
            <div className="embroidery-image-color-modal__container embroidery-image-color-modal__container--short">
                <Typography fontSize="-1">{t(embroideryMessages.chooseColors.id)}</Typography>
            </div>
            <div className="embroidery-image-color-modal__mobile-palette-swatches">
                <HorizontalScroller ref={parentRef}>
                    {paletteColors?.map(color => {
                        return (
                            <ColorSwatch
                                ref={color.value === currentSelection?.value ? currentSwatchRef : undefined}
                                className="embroidery-image-color-modal__mobile-color-swatch"
                                key={color.value}
                                isSelected={color.value === currentSelection?.value}
                                color={color}
                                toggleColor={(color: SelectableColor) => {
                                    setCurrentSelection(color);
                                    onChange(color.value);
                                }}
                            />
                        );
                    })}
                </HorizontalScroller>
            </div>
        </>
    );
}

MobileEmbroideryColorPalette.displayName = "MobileEmbroideryColorPalette";
