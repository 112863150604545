import { getProductDefaultsFromCalcifer } from "@shared/utils/Calcifer";
import { useAppSelector } from "@shared/redux";
import { getCompatibleOptions } from "@shared/utils/Product";

type Props = {
    productKey: string;
    locale: string;
    customerSelectedProductOptions: Record<string, string>;
};

export async function getDefaultProductOptions({ productKey, locale, customerSelectedProductOptions }: Props) {
    const { productVersion, selectedOptions, quantity } = await getProductDefaultsFromCalcifer(productKey, locale);

    const compatibleOptions = await getCompatibleOptions(productKey, selectedOptions, productVersion);

    const compatibleCustomerSelectedProductOptions = {};

    Object.entries(customerSelectedProductOptions).forEach(([key, value]) => {
        const compatibleItem = compatibleOptions.find(
            (compatibleOption: { name: string; values: Array<any> }) => compatibleOption.name === key
        );
        if (compatibleItem) {
            if (compatibleItem.values.includes(value)) {
                compatibleCustomerSelectedProductOptions[key] = value;
            } else {
                compatibleCustomerSelectedProductOptions[key] = selectedOptions[key];
            }
        }
    });

    const productOptions = {
        productKey,
        locale,
        productVersion,
        quantity,
        customerSelectedProductOptions: compatibleCustomerSelectedProductOptions
    };
    return productOptions;
}

export function useProductOptions() {
    const customerSelectedProductOptions = useAppSelector(state => state.customerSelectedProductOptions);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const locale = useAppSelector(state => state.locale);
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const quantity = useAppSelector(state => state.quantity);
    const template = useAppSelector(state => state.template);
    const workId = useAppSelector(state => state.workId);

    return {
        customerSelectedProductOptions,
        locale,
        productKey,
        productVersion,
        quantity,
        studioSelectedProductOptions,
        template,
        workId
    };
}
