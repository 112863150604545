import React, { useEffect } from "react";
import {
    Typography,
    Button,
    FlexBox,
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogBody,
    LegacyModalDialogContent,
    LegacyModalDialogNav
} from "@vp/swan";
import { useTranslationSSR, Trans } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { fireUpdateCartTrackingEvent } from "@shared/utils/Tracking";
import { useAppSelector, useAppDispatch, showEditInCartModal } from "@shared/redux";
import { EDIT_FROM_CART_MODAL_TYPE } from "@shared/utils/Cart";
import { useCartContext, editFromCartMessages } from "@shared/features/Cart";
import * as styles from "./EditFromCartModal.module.scss";

const title = {
    [EDIT_FROM_CART_MODAL_TYPE.LOADING]: editFromCartMessages.alreadyEditingHeader.id,
    [EDIT_FROM_CART_MODAL_TYPE.SWITCH_PROJECT]: editFromCartMessages.designUpdatedHeader.id,
    [EDIT_FROM_CART_MODAL_TYPE.CHANGES_UPDATED]: editFromCartMessages.changesUpdatedHeader.id
};

const body = {
    [EDIT_FROM_CART_MODAL_TYPE.LOADING]: editFromCartMessages.alreadyEditingBody.id,
    [EDIT_FROM_CART_MODAL_TYPE.SWITCH_PROJECT]: editFromCartMessages.designUpdatedBody.id,
    [EDIT_FROM_CART_MODAL_TYPE.CHANGES_UPDATED]: editFromCartMessages.changesUpdatedBody.id
};

const primaryButton = {
    [EDIT_FROM_CART_MODAL_TYPE.LOADING]: editFromCartMessages.alreadyEditingContinue.id,
    [EDIT_FROM_CART_MODAL_TYPE.SWITCH_PROJECT]: editFromCartMessages.designUpdatedOk.id,
    [EDIT_FROM_CART_MODAL_TYPE.CHANGES_UPDATED]: editFromCartMessages.changesUpdatedContinue.id
};

const secondaryButton = {
    [EDIT_FROM_CART_MODAL_TYPE.CHANGES_UPDATED]: editFromCartMessages.changesUpdatedKeepEditing.id
};

export function EditFromCartModal() {
    const dispatch = useAppDispatch();
    const { isItemInCart } = useCartContext();
    const { isSmall } = useStudioLayout();
    const { t } = useTranslationSSR();
    const { showModal, modalType, callback } = useAppSelector(state => state.editInCartModal);

    const handleClose = () => {
        if (callback) {
            callback();
        }
        dispatch(showEditInCartModal({ showModal: false }));
    };

    const secondaryBtnOnClick = () => {
        dispatch(showEditInCartModal({ showModal: false }));
    };

    useEffect(() => {
        if (isItemInCart) {
            dispatch(showEditInCartModal({ showModal: true, modalType: EDIT_FROM_CART_MODAL_TYPE.LOADING }));
            fireUpdateCartTrackingEvent({
                extraData: () => ({
                    editFromCartFlow: isItemInCart
                })
            });
        }
    }, [isItemInCart, dispatch]);

    if (!modalType) {
        return null;
    }

    return (
        <LegacyModalDialog
            isOpen={showModal}
            onRequestDismiss={() => {}} // don't close modal on click away
            className={styles.editFromCartModal}
            data-dcl-prevent-canvas-items-deselection
            bodyWidth="capped"
            variant={isSmall ? "panel-bottom" : "standard"}
        >
            <LegacyModalDialogContent
                aria-label={t(editFromCartMessages.editFromCartModalAriaLabel.id)}
                className="modal-dialog-content"
                paddingX={isSmall ? 6 : undefined}
                paddingTop={isSmall ? 7 : undefined}
            >
                <LegacyModalDialogNav marginBottom={3}>
                    <LegacyModalDialogHeader>
                        <Typography textSize={isSmall ? 2 : 3}>{t(title[modalType])}</Typography>
                    </LegacyModalDialogHeader>
                </LegacyModalDialogNav>
                <LegacyModalDialogBody>
                    <Typography fontSize="2">
                        <Trans t={t} i18nKey={t(body[modalType])} components={{ bold: <strong /> }} />
                    </Typography>
                    <FlexBox marginTop={isSmall ? 6 : 8} flexDirection={isSmall ? "column" : "row"}>
                        <Button
                            skin="primary"
                            width={isSmall ? "full-width" : "standard"}
                            size={isSmall ? "mini" : "standard"}
                            onClick={handleClose}
                        >
                            <Typography>{t(primaryButton[modalType])}</Typography>
                        </Button>
                        {secondaryButton[modalType] && (
                            <Button
                                className={isSmall ? styles.editFromCartModalMobileSecondaryButton : undefined}
                                marginLeft={isSmall ? undefined : 4}
                                width={isSmall ? "full-width" : "standard"}
                                size={isSmall ? "mini" : "standard"}
                                onClick={secondaryBtnOnClick}
                            >
                                <Typography>{t(secondaryButton[modalType])}</Typography>
                            </Button>
                        )}
                    </FlexBox>
                </LegacyModalDialogBody>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}

EditFromCartModal.displayName = "EditFromCartModal";
