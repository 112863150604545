import React from "react";
import classNames from "classnames";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Typography, Link } from "@vp/swan";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useGallery } from "../GalleryProvider";
import { ChangeTemplateFilterActionTypes } from "./changeTemplateFiltersReducer";
import * as styles from "./ClearFiltersButton.module.scss";

const messages = defineMessages({
    clearFilters: {
        id: "studio.components.changeTemplate.clearFilters",
        defaultMessage: "Clear all filters",
        description: {
            note: "Text for button to clear all selected filters when browsing templates"
        }
    }
});

export function ClearFiltersButton() {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const { dispatchTemplateFilters, templateFilters } = useGallery();

    return (
        <Link
            className={classNames({
                [styles.hidden]: Object.entries(templateFilters).length === 0
            })}
            component="button"
            onClick={() => {
                fireDesignToolTrackingEvent({
                    eventDetail: STUDIO_TRACKING_EVENTS.CLICK_CLEAR_GALLERY_FILTERS,
                    label: "Click clear all filters in change template dialog"
                });
                dispatchTemplateFilters({
                    type: ChangeTemplateFilterActionTypes.CLEAR_FILTERS
                });
            }}
        >
            <Typography textSize={isSmall ? 6 : 7} weight={isSmall ? "standard" : "bold"}>
                {t(messages.clearFilters.id)}
            </Typography>
        </Link>
    );
}

ClearFiltersButton.displayName = "ClearFiltersButton";
