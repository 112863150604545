import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Panel, SmartValidationPanel, CheckIcon, useBrickState } from "@design-stack-vista/smart-validations-ui";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useActiveCanvas, useDesigner } from "@designer-suite";
import { LoadingSpinner, SpinnerVariant } from "@shared/features/StudioChrome";
import { useAppSelector, useAppDispatch, setShowValidations, showDesignReview } from "@shared/redux";
import { useSmartValidations } from "@shared/features/SmartValidations";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";
import { useCanvasSelectorPreviews } from "@shared/features/CanvasSelectors";
import { saveStudio5Design } from "@five/clients/saveClient";
import { useHandleNextStepClick } from "@shared/features/GlobalHeader";
import { getDesignDocumentFromDesigner } from "@utilities";
import { VALIDATION_DISMISS_DELAY } from "../../utilities/constants";
import { useValidations } from "./ValidationProvider";
import { brickOnSelect, brickOnUnhover, constructValidationPanels, getNextItemValidation } from "./ValidationUtils";
import { ModelessDialog } from "../ModelessDialog/ModelessDialog";
import { ModelessDialogNav } from "../ModelessDialog/ModelessDialogNav";
import { ModelessDialogCloseButton } from "../ModelessDialog/ModelessDialogCloseButton";
import { useTriggerCanvasChange } from "../../hooks/useTriggerCanvasChange";
import { getCanvasOrdinal } from "../../utilities/canvasUtilities";
import { useCheckValidationsForNextStep } from "../nextStepHelpers";
import "./smartValidationsPanel.scss";
import messages from "./messages";

const delayValidationDismissal = () => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(null);
        }, VALIDATION_DISMISS_DELAY);
    });
};

export function SmartValidationsPanel() {
    const designer = useDesigner();
    const activeCanvas = useActiveCanvas();
    const isOpen = useAppSelector(state => state.showValidations.showPanel);
    const { currentActiveDialog } = useActiveDialog();
    const isChangeTemplateModalOpen = currentActiveDialog === DialogType.ChangeTemplate;
    const isContinueToCheckout = useAppSelector(state => state.showValidations.isContinueToCheckout);
    const { t } = useTranslationSSR();
    const dispatch = useAppDispatch();
    const { isSmartValidationsEnabled, elementToFocusOnCloseRef } = useSmartValidations();

    const {
        canvasValidations,
        validationCount,
        updateSmartFixedItems,
        dismissValidation,
        startDismissValidation,
        lastResolvedItemId,
        hasPanelBeenOpen,
        setHasPanelBeenOpen,
        containsErrors,
        dismissAllValidations
    } = useValidations();
    const [validationPanels, setValidationPanels] = useState<Panel[]>([]);
    const panelSubtitle = useMemo(
        () =>
            containsErrors
                ? t(messages.validationsPanelSubTitleReviewStepWithErrors.id)
                : t(messages.validationsPanelSubTitleReviewStepValidationsOnly.id),
        [containsErrors, t]
    );
    const [isLoading, setIsLoading] = useState(false);
    const triggerCanvasChange = useTriggerCanvasChange();
    const { highlightedBrickId, selectedBrickId, setSelectedBrickId } = useBrickState();
    const canvasSelectorPreviewData = useCanvasSelectorPreviews();

    const handleClose = () => {
        dispatch(setShowValidations({ showPanel: false }));
        setValidationPanels([]);
        elementToFocusOnCloseRef?.current?.focus();
    };

    // In case the template gallery is open, close the validations panel.
    // Reason: when template is being applied, there are numerous product content changes, during which
    // the product briefly has no content and therefore no issues, causing the Smart Validation Panel
    // to show the "all good" state; switching again to showing the issues once the content has been set.
    // For details see https://gitlab.com/Cimpress-Technology/artwork-design/lemonade-squad/lemonade-stand/-/issues/83
    useEffect(() => {
        if (isChangeTemplateModalOpen && isOpen) {
            dispatch(setShowValidations({ showPanel: false }));
        }
    }, [dispatch, isChangeTemplateModalOpen, isOpen]);

    useEffect(() => {
        if (isOpen && designer) {
            !hasPanelBeenOpen && setHasPanelBeenOpen(true);
            const constructedValidationPanels = constructValidationPanels(
                designer,
                canvasValidations,
                triggerCanvasChange,
                updateSmartFixedItems,
                validationToDismiss => {
                    startDismissValidation(validationToDismiss);
                    delayValidationDismissal().then(() => {
                        dismissValidation(validationToDismiss);
                    });
                },
                canvasSelectorPreviewData?.canvasSelectorUrls,
                t
            );
            setValidationPanels(constructedValidationPanels);

            // Next block is responsible to calculate and select next validation in the SmartValidationPanel after resolving an issue
            if (lastResolvedItemId) {
                const nextItemValidation = getNextItemValidation(constructedValidationPanels, lastResolvedItemId);
                if (nextItemValidation && activeCanvas) {
                    setSelectedBrickId(`${nextItemValidation.itemId}-${nextItemValidation.validation}`);
                    const canvasOrdinal = getCanvasOrdinal(activeCanvas!);
                    brickOnSelect(nextItemValidation.itemId, designer, canvasOrdinal);
                    brickOnUnhover(lastResolvedItemId, canvasOrdinal - 1, designer);
                }
            }
        }
    }, [
        isOpen,
        designer,
        canvasValidations,
        triggerCanvasChange,
        updateSmartFixedItems,
        startDismissValidation,
        dismissValidation,
        canvasSelectorPreviewData,
        lastResolvedItemId,
        setSelectedBrickId,
        activeCanvas,
        hasPanelBeenOpen,
        setHasPanelBeenOpen,
        setValidationPanels,
        t
    ]);

    const getDocument = useCallback(async () => getDesignDocumentFromDesigner(), []);

    const checkValidations = useCheckValidationsForNextStep();
    const nextClickHandler = useHandleNextStepClick({
        checkValidations,
        activeCanvasName: activeCanvas?.name,
        triggerCanvasChange,
        getDocument,
        save: saveStudio5Design
    });

    useEffect(() => {
        if (isOpen) {
            setIsLoading(true);
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
    }, [isOpen, containsErrors]);

    const noValidationsData = {
        title: t(messages.noValidationsTitle.id),
        subtitle: t(messages.noValidationsSubtitle.id),
        icon: CheckIcon(),
        buttonData: {
            text: isContinueToCheckout ? t(messages.continueToCheckoutButton.id) : t(messages.noValidationsButton.id),
            onClick: isContinueToCheckout
                ? () => dispatch(showDesignReview({ show: true, startTime: performance.now() }))
                : handleClose
        }
    };

    const smartValidationFooter = {
        hasErrors: containsErrors,
        contactSupportData: {
            heading: t(messages.careMessagingTitle.id),
            actionText: t(messages.careMessagingButton.id),
            onClick: () => {}
        },
        dismissAllData: isContinueToCheckout
            ? {
                  onClick: () => {
                      dismissAllValidations();
                      dispatch(setShowValidations({ showPanel: false }));
                      nextClickHandler();
                  },
                  title: t(messages.continueToCheckoutButton.id)
              }
            : undefined
    };

    const loaderOverlayData = {
        children: LoadingSpinner({
            variant: SpinnerVariant.Large
        }),
        description: t(messages.loaderOverlayDescription.id)
    };

    return (
        <ModelessDialog
            className="smart-validation-panel__container"
            isOpen={isSmartValidationsEnabled && isOpen}
            dialogAriaLabel={t(messages.validationsPanelTitle.id)}
            handleClose={handleClose}
            onMouseDown={event => {
                event.stopPropagation();
                return false;
            }}
        >
            <ModelessDialogNav>
                <ModelessDialogCloseButton
                    closeButtonAriaLabel={t(messages.closeModalAriaLabel.id)}
                    handleClose={handleClose}
                    dialogIsOpen={isOpen}
                />
            </ModelessDialogNav>
            <SmartValidationPanel
                className="smart-validation-panel"
                title={t(messages.validationsPanelTitle.id)}
                subtitle={panelSubtitle}
                panelValidations={validationPanels}
                validationCount={validationCount}
                noValidationsData={noValidationsData}
                highlightBrickId={highlightedBrickId}
                selectBrickId={selectedBrickId}
                smartValidationFooter={smartValidationFooter}
                isLoading={isLoading}
                loaderOverlayData={loaderOverlayData}
            />
        </ModelessDialog>
    );
}

SmartValidationsPanel.displayName = "SmartValidationsPanel";
