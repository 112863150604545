import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { SOURCE_COLORS } from "@vp/swan";
import { DSS } from "@vp/types-ddif";
import { useCallback } from "react";
import { getTemporaryPreviewInstructionsUrl, getTransientPreviewURL } from "@shared/utils/Previews";
import { useFlexibilityOptionPanel } from "../components/FlexibilityOptionPanelProvider";

export interface PreviewData {
    previewUrls: string[];
    instructionsUrl: string;
}

const previewWidth = 800;
const getUrl = async (document: DSS.DesignDocument, page: number, scene: string | undefined, authToken: string) => {
    const url = document
        ? await getTransientPreviewURL({ designDocument: document, width: previewWidth, page, scene, authToken })
        : "";
    return `${url}&bgColor=${SOURCE_COLORS.grey100.replace("#", "")}`;
};
export const useGenerateFlexibilityPreviewUrl = () => {
    const { auth } = useIdentityContext();
    const designRequirements = useDesignRequirementsContext();
    const numberOfPanels = designRequirements?.numberOfPanels;
    const { previewScenes } = useFlexibilityOptionPanel();

    const generatePreviewUrls = useCallback(
        async (targetDocument: DSS.DesignDocument, designAttributeValue: string): Promise<PreviewData> => {
            const scenes = previewScenes?.find(
                previewScene => previewScene.designAttributeValue === designAttributeValue
            )?.sceneUrls;
            const authToken = auth.getToken();
            const promises = [...Array(numberOfPanels)].map((_: any, index: number) => {
                const scene = scenes ? scenes[index] : undefined;
                return getUrl(targetDocument, index + 1, scene, authToken);
            });
            const instructionsUrl = await getTemporaryPreviewInstructionsUrl({
                designDocument: targetDocument,
                authToken
            });
            const previewUrls = await Promise.all(promises);
            return { previewUrls, instructionsUrl };
        },
        [auth, numberOfPanels, previewScenes]
    );

    return generatePreviewUrls;
};
