// This file is the current version of the review step. It is pending to be replaced by the updated version
// (hence some code duplication) using a component from `smart-validations-ui` package,
// currently implemented in `../ReviewStep.jsx`; visible when smart validations flag is turned on.
import React, { ReactNode, useEffect } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Typography, Button, Divider, SelectionSet, SelectionSetInput, SelectionSetLabel, H2, FlexBox } from "@vp/swan";
import { useAppSelector, useAppDispatch, setNextStepUrl } from "@shared/redux";
import { getNextStep } from "@shared/utils/SiteFlow";
import { WarningErrorIcon } from "@shared/features/StudioChrome";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { FullBleedPremiumFinishReview } from "@shared/features/DesignReview";
import type { TriggerCanvasChange } from "@shared/utils/DesignPanel";
import * as styles from "./DesignReview.module.scss";

const messages = defineMessages({
    reviewStepModalHeader: {
        id: "studio.components.reviewStep.reviewStepModalHeader",
        defaultMessage: "Design Review"
    },
    confirmationQuestion: {
        id: "studio.components.reviewStep.confirmationQuestion",
        defaultMessage: "All set? Let’s double check:"
    },
    checkListItem_text: {
        id: "studio.components.reviewStep.checkListText",
        defaultMessage: "Text: Is it clear and legible?"
    },
    checkListItem_margin: {
        id: "studio.components.reviewStep.checkListMargin",
        defaultMessage: "Margin: Is everything within the space?"
    },
    checkListItem_info: {
        id: "studio.components.reviewStep.checkListItem_info",
        defaultMessage: "Info: Everything accurate? Spelled correctly?"
    },
    checkListItem_images: {
        id: "studio.components.reviewStep.checkListItem_images",
        defaultMessage: "Images: Is everything clear (no blur)?"
    },
    placeholderWarningText: {
        id: "studio.components.reviewStep.placeholderWarningText",
        defaultMessage: "Your design has unedited placeholder text"
    },
    placeholderWarningDescriptionFirst: {
        id: "studio.components.reviewStep.placeholderWarningDescriptionFirst",
        defaultMessage: "Placeholder text that have not been changed will not print."
    },
    placeholderWarningDescriptionSecond: {
        id: "studio.components.reviewStep.placeholderWarningDescriptionSecond",
        defaultMessage: "Please review your design before completing your order."
    },
    editDesignButton: {
        id: "studio.components.reviewStep.editDesignButton",
        defaultMessage: "Edit my design"
    },
    authorizationApprovalMessage: {
        id: "studio.components.reviewStep.authorizationApprovalMessage",
        defaultMessage: "I have authorization to use the design, I have reviewed and approve it."
    },
    approvalMessage: {
        id: "studio.components.reviewStep.approvalMessage",
        defaultMessage: "YES. Everything looks great. I’ve reviewed and approved."
    },
    confirmDesignAriaLabel: {
        id: "studio.components.reviewStep.confirmDesignAriaLabel",
        defaultMessage: "Confirm design review"
    }
});

interface Props {
    customerReviewed: boolean;
    setCustomerReviewed: (b: boolean) => void;
    handleContinueEditingClick: () => void;
    onChatButtonClick?: () => void;
    placeholderTextPresent: boolean;
    isFullBleedPremiumFinish: boolean;
    hasPremiumFinishesCurrentCanvas: boolean;
    triggerCanvasChange: TriggerCanvasChange;
    continueButton: ReactNode;
}

export function DesignReview({
    customerReviewed,
    setCustomerReviewed,
    handleContinueEditingClick,
    placeholderTextPresent,
    isFullBleedPremiumFinish,
    hasPremiumFinishesCurrentCanvas,
    triggerCanvasChange,
    continueButton,
    onChatButtonClick
}: Props) {
    const { t } = useTranslationSSR();
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const locale = useAppSelector(state => state.locale);
    const nextStepUrl = useAppSelector(state => state.nextStepUrl?.url);
    const dispatch = useAppDispatch();
    const { isSmall } = useStudioLayout();

    useEffect(() => {
        async function loadNextStep() {
            if (productKey && productVersion && locale) {
                const siteFlowNextStepUrl = await getNextStep(
                    productKey,
                    productVersion,
                    studioSelectedProductOptions,
                    locale
                );
                const parsedNextStepUrl = siteFlowNextStepUrl.url;
                const predictedStepsFlow = siteFlowNextStepUrl.predictedFlow;
                const context = siteFlowNextStepUrl.context || [];
                dispatch(
                    setNextStepUrl({ nextStepUrl: parsedNextStepUrl, predictedFlow: predictedStepsFlow, context })
                );
            }
        }
        loadNextStep();
    }, [productKey, productVersion, studioSelectedProductOptions, locale, dispatch]);

    return (
        <>
            <div className={styles.designReviewText}>
                <span className="swan-hidden-xs">
                    <H2 marginBottom={6} id="reviewStepModalHeader">
                        {t(messages.reviewStepModalHeader.id)}
                    </H2>

                    <Typography fontWeight="bold" marginBottom={4}>
                        {t(messages.confirmationQuestion.id)}
                    </Typography>
                    {/* TODO: use Swan `List` component here */}
                    <ul className="swan-list swan-list-skin-checkmark">
                        <li>{t(messages.checkListItem_text.id)}</li>
                        <li>{t(messages.checkListItem_margin.id)}</li>
                        <li>{t(messages.checkListItem_info.id)}</li>
                        <li>{t(messages.checkListItem_images.id)}</li>
                    </ul>
                </span>
                {placeholderTextPresent && (
                    <FlexBox mb={isSmall ? 4 : 3}>
                        <WarningErrorIcon className={styles.warningErrorIcon} aria-hidden="true" />
                        <div className="placeholder-warning-message-icon-text">
                            <Typography textAlign="left" textColor="error" pl={1}>
                                {t(messages.placeholderWarningText.id)}
                            </Typography>
                            <Typography fontSize="1" textAlign="left" textColor="error" pl={1} pt={2}>
                                {t(messages.placeholderWarningDescriptionFirst.id)}
                            </Typography>
                            <Typography fontSize="1" textAlign="left" textColor="error" pl={1}>
                                {t(messages.placeholderWarningDescriptionSecond.id)}
                            </Typography>
                        </div>
                    </FlexBox>
                )}

                <Button
                    skin="secondary"
                    width="full-width"
                    size="mini"
                    onClick={handleContinueEditingClick}
                    data-testid="continueEditingButton"
                    mt={isSmall ? 2 : 3}
                >
                    {t(messages.editDesignButton.id)}
                </Button>

                {isFullBleedPremiumFinish && (
                    <FullBleedPremiumFinishReview
                        hasPremiumFinishesCurrentCanvas={hasPremiumFinishesCurrentCanvas}
                        triggerCanvasChange={triggerCanvasChange}
                    />
                )}

                {!isFullBleedPremiumFinish && <Divider mt={isSmall ? 5 : 7} />}

                <SelectionSet
                    mt={isSmall ? 5 : 7}
                    variant="multi-select"
                    skin="simple-column"
                    onSelectedValuesChange={newSelectedValues =>
                        setCustomerReviewed(newSelectedValues.customerReviewed)
                    }
                    selectedValues={{ customerReviewed }}
                    aria-label={t(messages.confirmDesignAriaLabel.id)}
                >
                    <SelectionSetInput value="customerReviewed" aria-required="true">
                        {/* You can find the request for this en-in specific message in the Slack thread
                        in this ticket: https://vistaprint.atlassian.net/browse/DT-14711 */}
                        <SelectionSetLabel style={{ wordBreak: "break-word" }}>
                            {t(
                                locale.toLowerCase() === "en-in"
                                    ? messages.authorizationApprovalMessage.id
                                    : messages.approvalMessage.id
                            )}
                        </SelectionSetLabel>
                    </SelectionSetInput>
                </SelectionSet>

                {continueButton}
            </div>
            {/* this anchor is used by fido and is not meant to be visible to or used by the user */}
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a
                href={nextStepUrl ?? undefined}
                data-fidoprefetchhint="EAGER"
                aria-hidden
                tabIndex={-1}
                style={{ visibility: "hidden" }}
            />
        </>
    );
}
DesignReview.displayName = "DesignReview";
