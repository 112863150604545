import React from "react";
import { Box } from "@vp/swan";
import { SpecsAndTemplatesHeader } from "./SpecsAndTemplatesHeader";
import { SpecsAndTemplatesSurfaceData } from "./SpecsAndTemplatesSurfaceData";
import { UploadDesignsTemplates } from "./UploadDesignsTemplates";
import { useStudioLayout } from "../ResponsiveDesign";
import { useTemplateUrls } from "./useTemplateUrls";

export const SurfaceDataContainer = () => {
    const { pdfUrl, svgUrl, aiUrl } = useTemplateUrls();
    const { isSmall } = useStudioLayout();
    return (
        <Box paddingY={4} paddingX={isSmall ? 4 : 6}>
            <SpecsAndTemplatesHeader />
            <SpecsAndTemplatesSurfaceData />
            <UploadDesignsTemplates pdfUrl={pdfUrl} svgUrl={svgUrl} aiUrl={aiUrl} />
        </Box>
    );
};
SurfaceDataContainer.displayName = "SurfaceDataContainer";
