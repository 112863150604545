import { Column as ReactTableColumn } from "react-table";
import { v4 as uuidv4 } from "uuid";
import { TableCell, TableColumn, TableItemReferenceData, TableRow } from "@shared/utils/Tables";
import { ReactTableRowData } from "./types";

export function convertToReactTable(tableItemData: TableItemReferenceData): {
    reactTableColumns: ReactTableColumn<ReactTableRowData>[];
    reactTableData: ReactTableRowData[];
} {
    const { rows, columns } = tableItemData;
    const columnCount = rows[0].cells.length;
    const reactTableColumns: ReactTableColumn<ReactTableRowData>[] = [];

    for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
        const { width, alignment } = columns[columnIndex];
        const newColumnId = uuidv4();

        // @ts-ignore alignment is being passed through so that we can get it on the other side.
        reactTableColumns.push({ Header: `Column ${newColumnId}`, accessor: newColumnId, width, alignment });
    }

    const reactTableData = rows.map(row => {
        const reactTableRowData: ReactTableRowData = {};

        row.cells.forEach((cell, index) => {
            const id = reactTableColumns[index].accessor as string;
            reactTableRowData[id] = cell.text;
        });

        return reactTableRowData;
    });

    return { reactTableColumns, reactTableData };
}

export function convertFromReactTable(
    reactTableData: ReactTableRowData[],
    reactTableColumns: ReactTableColumn<ReactTableRowData>[]
): TableItemReferenceData {
    const tableItemRows: TableRow[] = reactTableData.map(rowData => {
        return {
            cells: reactTableColumns.map<TableCell>(column => {
                return { text: rowData[column.accessor as string] };
            })
        };
    });

    const tableItemColumns: TableColumn[] = reactTableColumns.map(column => {
        // @ts-ignore alignment is not part of the type
        const { alignment, width } = column;

        return { alignment, width: width as string };
    });

    return { rows: tableItemRows, columns: tableItemColumns };
}
