import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { SelectedCheckIcon } from "@shared/features/StudioChrome";
import { Typography, Button, Icon, Box, FlexBox } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { FontPreview } from "@shared/features/ContextualToolbar";
import { HorizontalDivider } from "@shared/features/StudioChrome/HorizontalDivider";
import { FontBrowserButton } from "./FontBrowserButton";
import { fontBrowserMessages } from "./fontBrowserMessages";
import { fontWeightTranslation } from "./fontWeightTranslation";
import type { FontSimpleStyle } from "./FontSimpleStyle";

interface Props {
    selectedText: string;
    fontFamily: string;
    appliedWeights: { bold: boolean; italic: boolean };
    handleClick: (fontFamily: string, index: number, style?: FontSimpleStyle) => void;
    setFocusedIndex: (index: number) => void;
    isSelected: boolean;
    handleKeyDown: (event: React.KeyboardEvent) => void;
    isFocused: boolean;
    dropdownIndex: number;
    recentFonts: string[];
    fontVariants: {
        Bold: boolean;
        Italic: boolean;
        BoldItalic: boolean;
    };
}

export function FontWeightDropdown(props: Props) {
    const {
        selectedText,
        fontFamily,
        appliedWeights,
        handleClick,
        setFocusedIndex,
        isSelected,
        handleKeyDown,
        isFocused,
        dropdownIndex,
        recentFonts,
        fontVariants
    } = props;
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [expanded, setExpanded] = useState(false);
    const [focusedSubIndex, setFocusedSubIndex] = useState<number | null>(null);
    const { t } = useTranslationSSR();

    const totalFontCount = Object.keys(fontVariants).filter(key => fontVariants[key]).length;
    const cimDocStyle =
        Object.keys(appliedWeights)
            .filter(key => appliedWeights[key])
            .join() || "normal";

    const handleWeightKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === "Enter") {
            event.stopPropagation(); // allows enter to select font for word art
        }
        if (event.key === "ArrowDown") {
            if (focusedSubIndex === totalFontCount) {
                setFocusedIndex(dropdownIndex + 1);
                setExpanded(false);
            } else {
                setFocusedSubIndex(currentFocusedIndex => {
                    return (currentFocusedIndex || 0) + 1;
                });
            }
        }
        if (event.key === "ArrowUp") {
            if (focusedSubIndex === 0) {
                setFocusedIndex(dropdownIndex - 1);
                setExpanded(false);
            } else {
                setFocusedSubIndex(currentFocusedIndex => {
                    return !currentFocusedIndex ? totalFontCount - 1 : currentFocusedIndex - 1;
                });
            }
        }
    };

    const handleFontStyleClick = (fontFamily: string, index: number, style?: FontSimpleStyle) => {
        setFocusedSubIndex(index);
        handleClick(fontFamily, index, style);
    };

    useEffect(() => {
        if (isFocused) {
            buttonRef.current?.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFocused]);

    useEffect(() => {
        if (expanded)
            setFocusedSubIndex(currentFocusedIndex => {
                return currentFocusedIndex || 0;
            });
    }, [expanded]);

    useEffect(() => {
        if (
            isSelected &&
            !recentFonts.includes(fontFamily) &&
            buttonRef.current?.parentElement?.parentElement?.scrollTo
        ) {
            // Remove 45 from the scroll to account for category label covering the button
            buttonRef.current.parentElement.parentElement.scrollTo({
                top: buttonRef.current?.offsetTop - 45
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return expanded ? (
        <Box ref={buttonRef} className={classNames("font-browser__weight-button-expanded")} mb={2}>
            <FlexBox
                justifyContent="space-between"
                onClick={() => {
                    setExpanded(false);
                }}
                className="font-browser__weight-button__toggle"
            >
                <Typography fontSize="-1" textColor="dark-grey" mb={2}>
                    {fontFamily}
                </Typography>
                <Typography fontSize="-1">
                    {fontVariants &&
                        t(fontBrowserMessages.fontBrowserWeights.id, {
                            numberOfWeights: Object.values(fontVariants).filter(Boolean).length + 1
                        })}
                    <Icon skin="standard" iconType="caretDown" ml={1} />
                </Typography>
            </FlexBox>
            <HorizontalDivider />
            <FontBrowserButton
                key={`fontFamily ${"regular"}`}
                fontStyle={"normal"}
                appliedWeights={appliedWeights}
                index={0}
                selectedText={selectedText || ""}
                fontFamily={fontFamily}
                handleClick={handleFontStyleClick}
                isSelected={isSelected}
                handleKeyDown={handleWeightKeyDown}
                isFocused={focusedSubIndex === 0}
                recentFonts={recentFonts}
            />
            {fontVariants &&
                Object.keys(fontVariants)
                    .filter(key => fontVariants[key])
                    .map((weight, weightIndex) => (
                        <FontBrowserButton
                            key={`fontFamily ${weight}`}
                            fontStyle={weight.toLowerCase() as FontSimpleStyle}
                            appliedWeights={appliedWeights}
                            index={weightIndex + 1}
                            selectedText={selectedText || ""}
                            fontFamily={fontFamily}
                            handleClick={handleFontStyleClick}
                            isSelected={isSelected}
                            handleKeyDown={handleWeightKeyDown}
                            isFocused={weightIndex + 1 === focusedSubIndex}
                            recentFonts={recentFonts}
                        />
                    ))}
        </Box>
    ) : (
        <Button
            ref={buttonRef}
            skin="unstyled"
            className={classNames("font-browser__button", "font-browser__weight-button", {
                "font-browser__button--selected": isSelected
            })}
            mb={2}
            onClick={() => {
                setExpanded(true);
                setFocusedIndex(dropdownIndex);
            }}
            onKeyDown={handleKeyDown}
        >
            <FlexBox justifyContent="space-between">
                <Typography fontSize="-1" textColor="dark-grey" mb={2}>
                    {fontFamily}
                </Typography>
                <Typography fontSize="-1">
                    {fontVariants &&
                        t(fontBrowserMessages.fontBrowserWeights.id, {
                            numberOfWeights: Object.values(fontVariants).filter(Boolean).length + 1
                        })}
                    <Icon skin="standard" iconType="caretUp" ml={1} />
                </Typography>
            </FlexBox>

            <FlexBox alignItems="center" justifyContent="space-between">
                <FontPreview
                    text={selectedText || ""}
                    fontFamily={fontFamily}
                    altText=""
                    fontStyle={isSelected ? cimDocStyle : "normal"}
                />
                <FlexBox alignItems="center" className="font-browser__right-aligned-items">
                    <div className="font-browser__preview-image-blur" />
                    <FlexBox>
                        {isSelected && (
                            <Typography fontSize="-1" className="font-browser__weight-label">
                                {t(
                                    fontWeightTranslation(
                                        Object.keys(appliedWeights)
                                            .filter(key => appliedWeights[key])
                                            .join("")
                                    )
                                )}
                            </Typography>
                        )}
                        <SelectedCheckIcon className="font-browser__icon" />
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </Button>
    );
}

FontWeightDropdown.displayName = "FontWeightDropdown";
