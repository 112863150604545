import React from "react";
import { UploadStrategies, useDesigner, useHandleImagePlacement } from "../../../easel";
import { addImageCallToAction } from "../Panels/Images/DrawerPanel/utils";
import { getPositionAttributes } from "../Validation/ValidationOverlay";
import { DroppableTarget, DropType } from "./DroppableTarget";

type DroppableTargetLayerImageElementProps = {
    image: ImageItem;
};

export const DroppableTargetLayerImageElement = (props: DroppableTargetLayerImageElementProps) => {
    const { image } = props;
    const imageViewModel = image._itemViewModel;
    const { onImageDropped } = useHandleImagePlacement({ placementStrategy: UploadStrategies.ReplaceSelected });
    const designer = useDesigner();

    if (!designer) {
        return null;
    }

    const onDrop = (item: DropType) => {
        addImageCallToAction({ designer, ...item, callback: onImageDropped, image });
    };

    const { top, left, width, height } = getPositionAttributes(imageViewModel);
    const canvasViewModel = designer.documentRepository.getActiveCanvasViewModel();
    const zoomFactor = canvasViewModel.get("zoomFactor") || 1;

    return (
        <div
            style={{
                position: "absolute",
                top: top * zoomFactor,
                left: left * zoomFactor,
                width: width * zoomFactor,
                height: height * zoomFactor
            }}
        >
            <DroppableTarget className="drop-target-placeholder" onDrop={onDrop} />
        </div>
    );
};

DroppableTargetLayerImageElement.displayName = "DroppableTargetLayerElement";
