import React, { useCallback } from "react";
import { UploadsPanelRedesign as UploadsPanel, UploadStrategies } from "@designer-suite";
import { useAppSelector, useAppDispatch, setShowReplaceImageModal } from "@shared/redux";
import { usePreviewsContext } from "@shared/features/Previews";
import { MobileReplaceDrawerPanelDisplay } from "@shared/features/ContextualToolbar";
import { useUploadComponents } from "@shared/features/UploadsAndAssets";
import { useIsUnreplacedFullbleedPlaceholder } from "../../../../studioFive/components/UploadDesigns/useIsUnreplacedFullbleedPlaceholder";
import { getActiveCanvasOrdinal } from "../../../../studioFive/utilities/canvasUtilities";
import { ReplaceDrawerPanel } from "./ReplaceDrawerPanel";

interface Props {
    openOnPlaceholderSelected?: boolean;
}

const ReplacePanel = ({ openOnPlaceholderSelected }: Props) => {
    const showReplaceImageModal = useAppSelector(state => state.showReplaceImageModal);
    const isReplaceOpenOnCanvasChange = useAppSelector(state => state.isReplaceOpenOnCanvasChange);
    const isFullBleed = useAppSelector(state => state.isFullBleed);
    const dispatch = useAppDispatch();
    useIsUnreplacedFullbleedPlaceholder();
    const uploadComponents = useUploadComponents();

    const handleModalClose = useCallback(() => {
        dispatch(
            setShowReplaceImageModal({
                showReplaceImageModal: false,
                isReplaceOpenOnCanvasChange: false,
                isUploadModalAutoLoaded: false
            })
        );
    }, [dispatch]);

    const handleModalOpen = useCallback(() => {
        dispatch(
            setShowReplaceImageModal({
                showReplaceImageModal: true,
                isReplaceOpenOnCanvasChange: false,
                isUploadModalAutoLoaded: false
            })
        );
    }, [dispatch]);

    const { canvasSelectorUrls } = usePreviewsContext();
    const canvasOrdinal = getActiveCanvasOrdinal() ?? 1;
    const sideName = canvasSelectorUrls[canvasOrdinal - 1]?.title;

    return (
        <MobileReplaceDrawerPanelDisplay
            handleModalClose={handleModalClose}
            isReplaceOpenOnCanvasChange={isReplaceOpenOnCanvasChange}
            showReplaceImageModal={showReplaceImageModal}
        >
            {uploadComponents ? (
                <ReplaceDrawerPanel
                    placementStrategy={
                        isFullBleed && isReplaceOpenOnCanvasChange
                            ? UploadStrategies.AutofillSinglePlaceholder
                            : UploadStrategies.ReplaceSelected
                    }
                    onUploadAdded={handleModalClose}
                    openOnPlaceholderSelected={openOnPlaceholderSelected}
                    openPanel={handleModalOpen}
                    sideName={sideName}
                />
            ) : (
                <UploadsPanel
                    placementStrategy={
                        isFullBleed && isReplaceOpenOnCanvasChange
                            ? UploadStrategies.AutofillSinglePlaceholder
                            : UploadStrategies.ReplaceSelected
                    }
                    className="easel-replace-panel image-panel-refresh upload-print-replace-panel"
                    key="ImagePanel"
                    onUploadAdded={handleModalClose}
                    openOnPlaceholderSelected={openOnPlaceholderSelected}
                    openPanel={handleModalOpen}
                    columnCount={3}
                    isReplacePanel
                    allowHiding={false}
                    sideName={sideName}
                />
            )}
        </MobileReplaceDrawerPanelDisplay>
    );
};
ReplacePanel.displayName = "ReplacePanel";

export { ReplacePanel };
