import React, { useCallback, useEffect, useState } from "react";
import {
    LegacyModalDialog,
    LegacyModalDialogBody,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton
} from "@vp/swan";
import { RootStateOrAny, useSelector } from "react-redux";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppSelector } from "@shared/redux";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { SaveDesignWithSaveDocumentOmitted } from "@shared/features/Save";
import { QuantityOptionPanelContent } from "./QuantityOptionPanelContent";
import { useActiveFlexibilityOptions } from "../ActiveFlexibilityProvider";
import { useFlexibilityOptionPanel } from "../common/components/FlexibilityOptionPanelProvider";
import * as styles from "./QuantityOptionPanel.module.scss";
import { useStudioFlexibility } from "../StudioFlexibilityABProvider";

interface QuantityOptionPanelProps {
    className?: string;
    headerText: string;
    closeButtonLabel: string;
    changeOptionText: string;
    saveDesign: SaveDesignWithSaveDocumentOmitted;
}

export function QuantityOptionPanel(props: QuantityOptionPanelProps) {
    const { className, headerText, closeButtonLabel, changeOptionText, saveDesign } = props;
    const { designAttributeName } = useFlexibilityOptionPanel();
    const { displayedDesignAttributeName, setDisplayedDesignAttributeName } = useActiveFlexibilityOptions();
    const productQuantity = useSelector((state: RootStateOrAny) => state.quantity);
    const [currentQuantitySelection, setCurrentQuantitySelection] = useState(productQuantity);
    const showPreviewState = useAppSelector(state => state.previewOpen);
    const { isMileStone2Enabled } = useStudioFlexibility();
    const { isSmall } = useStudioLayout();
    const isBackButtonEnabled = isSmall && isMileStone2Enabled;

    useEffect(() => {
        setCurrentQuantitySelection(productQuantity);
    }, [productQuantity]);

    const closePanel = useCallback(() => {
        setDisplayedDesignAttributeName(undefined);
        setCurrentQuantitySelection(productQuantity);
    }, [setDisplayedDesignAttributeName, setCurrentQuantitySelection, productQuantity]);

    const onDismiss = useCallback(() => {
        // Tracking Event for closing the modal by clicking outside the modal or clicking on “x” button.
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLOSE_CHANGE_DESIGN_ATTRIBUTE_MODAL,
            label: "close change design attribute modal",
            extraData: () => ({
                designAttributeName
            })
        });
        closePanel();
    }, [closePanel, designAttributeName]);

    return (
        <LegacyModalDialog
            isOpen={displayedDesignAttributeName === designAttributeName && !showPreviewState}
            onRequestDismiss={onDismiss}
            className={className}
            variant="panel-right"
        >
            <LegacyModalDialogContent className={styles.panelContent} aria-label={headerText} fullBleed={true}>
                <LegacyModalDialogCloseButton visuallyHiddenLabel={closeButtonLabel} />
                <LegacyModalDialogBody className={styles.panelBody}>
                    <QuantityOptionPanelContent
                        headerText={headerText}
                        changeOptionText={changeOptionText}
                        currentQuantitySelection={currentQuantitySelection}
                        setCurrentQuantitySelection={setCurrentQuantitySelection}
                        onClose={closePanel}
                        isBackButtonEnabled={isBackButtonEnabled}
                        saveDesign={saveDesign}
                    />
                </LegacyModalDialogBody>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}
QuantityOptionPanel.displayName = "QuantityOptionPanel";
