import React from "react";

export function DownArrowIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 20 20" fill="none" style={{ transform: `matrix(0, -1, 1, 0, 0, 0)` }} {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.75 10a.75.75 0 0 0-.75-.75H4a.75.75 0 0 0 0 1.5h12a.75.75 0 0 0 .75-.75Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.53 14.53a.75.75 0 0 0 0-1.06L5.06 10l3.47-3.47a.75.75 0 0 0-1.06-1.06l-4 4a.751.751 0 0 0 0 1.06l4 4a.75.75 0 0 0 1.06 0Z"
                fill="currentColor"
            />
        </svg>
    );
}
DownArrowIcon.displayName = "DownArrowIcon";
