import React, { useEffect } from "react";
import { Filter } from "@design-stack-vista/gallery-ui";
import { Accordion } from "@vp/swan";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { AttributeFilter, CategoryFilter } from "@shared/utils/Gallery";
import { useGallery } from "../GalleryProvider";
import { ChangeTemplateFilterActionTypes } from "./changeTemplateFiltersReducer";

export function ChangeTemplateFilters() {
    const {
        filters,
        dispatchTemplateFilters,
        expandedCollapsibles,
        setDefaultExpandedCollapsibles,
        templateFilters,
        updateExpandedCollapsibles
    } = useGallery();

    useEffect(() => {
        if (filters.length > 0 && Object.keys(expandedCollapsibles).length === 0) {
            setDefaultExpandedCollapsibles();
        }
    }, [expandedCollapsibles, filters, setDefaultExpandedCollapsibles]);

    const onExpandChange = (collapsibleId: string, expanded: boolean) => {
        updateExpandedCollapsibles(collapsibleId, expanded);
    };

    const setSelectedValues = (selectedValues: any, filterIdentifier: string | number) => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_GALLERY_FILTER,
            label: "Gallery filter selected",
            extraData: () => ({ filterIdentifier })
        });
        dispatchTemplateFilters({
            type: ChangeTemplateFilterActionTypes.SET_FILTERS,
            payload: { [filterIdentifier]: selectedValues }
        });
    };

    return (
        <Accordion expandedCollapsibles={expandedCollapsibles} onRequestExpandedChange={onExpandChange}>
            {filters.map(f => {
                const filterIdentier = (f as CategoryFilter).categoryId || (f as AttributeFilter).id;
                return (
                    <Filter
                        filter={f}
                        key={f.name}
                        // @ts-ignore Check with gallery ui package owners
                        onSelectChange={(selectedValues: any) => setSelectedValues(selectedValues, filterIdentier)}
                        selected={templateFilters[filterIdentier] || null} // set to null to clear filters
                    />
                );
            })}
        </Accordion>
    );
}

ChangeTemplateFilters.displayName = "ChangeTemplateFilters";
