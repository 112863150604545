import React from "react";
import classNames from "classnames";
import { FlexBox } from "@vp/swan";

export function ModelessDialogNav({ children, className }: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <FlexBox justifyContent="flex-end" className={classNames("modeless-dialog-nav", className)}>
            {children}
        </FlexBox>
    );
}

ModelessDialogNav.displayName = "ModelessDialogNav";
