import { useContext } from "react";
import { generateProofABcontext } from "./GenerateProofABProvider";

export function useGenerateProofABTest() {
    const result = useContext(generateProofABcontext);
    if (!result) {
        throw Error("Missing context.  This must be called within a GenerateProofABProvider");
    }
    return result;
}
