import React from "react";
import { ButtonProps } from "@vp/swan";
import { ContinueButton } from "@shared/features/DesignReview";
import { useProductHasSpecsAndTemplates } from "@shared/features/SpecsAndTemplates";
import { saveStudio5Design, saveStudio5Document } from "../clients/saveClient";

type ContinueButtonProps = Omit<
    ButtonProps,
    "data-testid" | "onClick" | "id" | "data-translationid" | "skin" | "ref"
> & {
    hasBeenReviewed: boolean;
};

function ContinueButtonStudio5({ hasBeenReviewed, ...rest }: ContinueButtonProps) {
    const hasSpecsAndTemplate = useProductHasSpecsAndTemplates();

    return (
        <ContinueButton
            hasBeenReviewed={hasBeenReviewed}
            hasSpecsAndTemplate={hasSpecsAndTemplate}
            saveDocument={saveStudio5Document}
            saveDesign={saveStudio5Design}
            {...rest}
        />
    );
}
ContinueButtonStudio5.displayName = "ContinueButtonStudio5";

export default ContinueButtonStudio5;
