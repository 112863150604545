import { useState, useEffect } from "react";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useAppSelector } from "@shared/redux";

const useIsWorkRecoverable = () => {
    const { identity } = useIdentityContext();

    const [workRecoverable, setWorkRecoverable] = useState(false);
    const workId = useAppSelector(state => state.workId);
    useEffect(() => {
        if (identity && !identity.isAnonymousUser && workId && !workRecoverable) {
            setWorkRecoverable(true);
        }
    }, [identity, workId, workRecoverable]);

    return workRecoverable;
};

export default useIsWorkRecoverable;
