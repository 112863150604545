import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import {
    Button,
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogBody,
    LegacyModalDialogButtons,
    LegacyModalDialogTitle,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton,
    LegacyModalDialogFooter
} from "@vp/swan";
import { withPrefix } from "gatsby";
import { useDesigner } from "@designer-suite";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useMailingServicesContext } from "@shared/features/MailingServices";
import { mailingServicesMessages } from "./messages";
import "./mailingServicesGuideModal.scss";

export function MailingServicesGuideModal() {
    const { t } = useTranslationSSR();
    const { isMailingServicesGuideModalOpen, setIsMailingServicesGuideModalOpen, isShowingGuideModalFromOptionsModal } =
        useMailingServicesContext();
    const designer = useDesigner();

    const MailingServicesGuideModalClose = () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.SHOW_GUIDE_MODAL_OF_MAILING_SERVICES,
            label: "Mailing services is added and backside have template"
        } as any);
        setIsMailingServicesGuideModalOpen(false);
    };
    if (!designer) {
        return null;
    }

    return (
        <LegacyModalDialog
            isOpen={isMailingServicesGuideModalOpen}
            onRequestDismiss={MailingServicesGuideModalClose}
            className="mailing-services-guide-modal"
        >
            <LegacyModalDialogContent fullBleed aria-label={t(mailingServicesMessages.guideDialogAriaLabel.id)}>
                <LegacyModalDialogCloseButton
                    visuallyHiddenLabel={t(mailingServicesMessages.closeModalAriaLabel.id)}
                    className="mailing-services-guide-modal__close"
                />
                <LegacyModalDialogBody className="mailing-services-guide-modal__image-wrapper">
                    <div className="mailing-services-guide-modal__image">
                        <video autoPlay muted loop playsInline>
                            <source
                                // @ts-ignore
                                src={`${ASSET_PREFIX + withPrefix(`/videos/mailing-service-animation.mp4`)}`}
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </LegacyModalDialogBody>
                <div className="mailing-services-guide-modal-description">
                    <LegacyModalDialogHeader className="mailing-services-guide-modal-description__header">
                        <LegacyModalDialogTitle>
                            {isShowingGuideModalFromOptionsModal
                                ? t(mailingServicesMessages.mailingConfirmationModalHeader.id)
                                : t(mailingServicesMessages.mailingGuideTitle.id)}
                        </LegacyModalDialogTitle>
                    </LegacyModalDialogHeader>
                    <LegacyModalDialogBody>
                        {t(mailingServicesMessages.mailingGuideBodyInformation.id)}
                    </LegacyModalDialogBody>
                    <LegacyModalDialogFooter>
                        <LegacyModalDialogButtons>
                            <Button skin="primary" size="mini" onClick={MailingServicesGuideModalClose}>
                                {t(mailingServicesMessages.mailingGuideButtonTitle.id)}
                            </Button>
                        </LegacyModalDialogButtons>
                    </LegacyModalDialogFooter>
                </div>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}
MailingServicesGuideModal.displayName = "MailingServicesGuideModal";
