import { defineMessages } from "@vp/i18n-helper";

const spinnerMessages = defineMessages({
    updatingThreadColor: {
        id: "studio.components.spinners.updatingThreadColor",
        defaultMessage: "Updating thread color...",
        description: {
            note: "Spinner text shown when the thread color is being updated in the embroidery image thread color modal dialog"
        }
    },
    loadingChat: {
        id: "studio.components.spinners.loadingChat",
        defaultMessage: "Loading chat...",
        description: {
            note: "Spinner text shown on start chat button while chat is starting up"
        }
    },
    changingSize: {
        id: "studio.components.spinners.changingSize",
        defaultMessage: "Changing size...",
        description: {
            note: "Spinner text shown on change size button in change size panel while size is being changed to the new value"
        }
    },
    processingImage: {
        id: "studio.components.spinners.processingImage",
        defaultMessage: "Processing image...",
        description: {
            note: "Spinner text for while some kind of image processing is in progress, e.g. image being turned into a single-color"
        }
    },
    updatingColor: {
        id: "studio.components.spinners.updatingColor",
        defaultMessage: "Updating color...",
        description: {
            note: "Spinner text shown when color of a single-color image is being updated"
        }
    },
    removingBackground: {
        id: "studio.components.spinners.removingBackground",
        defaultMessage: "Removing background...",
        description: {
            note: "Spinner text shown over image while removing background"
        }
    },
    restoringBackground: {
        id: "studio.components.spinners.restoringBackground",
        defaultMessage: "Restoring background...",
        description: {
            note: "Spinner text shown over image while restoring background on an image where background was previously removed"
        }
    },
    sharpeningImage: {
        id: "studio.components.spinners.sharpeningImage",
        defaultMessage: "Sharpening image...",
        description: {
            note: "Spinner text shown over image while sharpening"
        }
    },
    convertingToStitches: {
        id: "studio.components.spinners.convertingToStitches",
        defaultMessage: "Converting your image to stitches...",
        description: {
            note: "Spinner text shown over image while converting to embroidery"
        }
    }
});

export default spinnerMessages;
