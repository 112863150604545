import { ShapeTypes } from "@cimpress-technology/svg-path";
import type { ShapeItem } from "@design-stack-ct/cdif-types";
import type { DSS } from "@vp/types-ddif";

export const isHorizontalArrow = (shape: ShapeItem, document: DSS.DesignDocument) => {
    const studioMetadata = document?.metadata?.studioMetadata;
    if (!studioMetadata || !shape) {
        return false;
    }
    const { id, rotationAngle } = shape;
    const metadata = studioMetadata[id];
    return (
        metadata?.shapeMetadata?.type === ShapeTypes.Arrow &&
        // A rotation angle of undefined is considered to be 0.
        // cdif-types defines rotation as a string but it can be a number
        (!rotationAngle || (rotationAngle as unknown as number) === 180 || rotationAngle === "180")
    );
};
