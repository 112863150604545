import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
}
export function ShiftIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 14" fill="none" {...props}>
            <path
                // eslint-disable-next-line max-len
                d="M5.97998 13.0532H10.0132C10.4826 13.0532 10.8381 12.9256 11.0796 12.6704C11.3257 12.4152 11.4487 12.0688 11.4487 11.6313V8.76709H14.0327C14.3107 8.76709 14.5431 8.69189 14.73 8.5415C14.9168 8.38656 15.0103 8.18148 15.0103 7.92627C15.0103 7.76676 14.9738 7.62549 14.9009 7.50244C14.828 7.37939 14.7277 7.25407 14.6001 7.12646L8.81689 1.3501C8.69385 1.21794 8.56396 1.11768 8.42725 1.04932C8.29053 0.980957 8.14697 0.946777 7.99658 0.946777C7.84619 0.946777 7.70264 0.980957 7.56592 1.04932C7.43376 1.11768 7.30387 1.21794 7.17627 1.3501L1.3999 7.12646C1.26318 7.25863 1.16064 7.38395 1.09229 7.50244C1.02393 7.62093 0.989746 7.76221 0.989746 7.92627C0.989746 8.18148 1.08317 8.38656 1.27002 8.5415C1.45687 8.69189 1.68929 8.76709 1.96729 8.76709H4.54443V11.6313C4.54443 12.0688 4.66748 12.4152 4.91357 12.6704C5.15967 12.9256 5.51514 13.0532 5.97998 13.0532ZM6.15771 11.9185C6.03923 11.9185 5.94352 11.8797 5.87061 11.8022C5.79769 11.7293 5.76123 11.6359 5.76123 11.522V7.9126C5.76123 7.76676 5.69059 7.69385 5.54932 7.69385H2.64404C2.59847 7.69385 2.57568 7.6779 2.57568 7.646C2.57568 7.62321 2.58936 7.59814 2.6167 7.5708L7.8667 2.34814C7.89404 2.32536 7.91683 2.30941 7.93506 2.30029C7.95329 2.28662 7.9738 2.27979 7.99658 2.27979C8.01937 2.27979 8.03988 2.28662 8.05811 2.30029C8.08089 2.30941 8.10368 2.32536 8.12646 2.34814L13.3833 7.5708C13.4061 7.59814 13.4175 7.62321 13.4175 7.646C13.4175 7.6779 13.3947 7.69385 13.3491 7.69385H10.4507C10.3049 7.69385 10.2319 7.76676 10.2319 7.9126V11.522C10.2319 11.6359 10.1932 11.7293 10.1157 11.8022C10.0428 11.8797 9.94938 11.9185 9.83545 11.9185H6.15771Z"
                fill="currentColor"
            />
        </svg>
    );
}
ShiftIcon.displayName = "ShiftIcon";
