import React, { ReactNode, useCallback } from "react";
import classnames from "classnames";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useOnKeyDown } from "@shared/features/Accessibility";
import { useAppDispatch, setChangeDesignData, setLoadingMessage } from "@shared/redux";
import { useDesignDialog, DesignPanelType } from "@shared/features/DesignPanel";
import { useIsMailingPostCard, useMailingServicesContext } from "@shared/features/MailingServices";
import { designPanelMessages } from "../designPanelMessages";
import { CanvasUpdateStatus } from "../DesignDialogProvider";
import * as styles from "./DesignTileButton.module.scss";
import { useGetSelectionCallback } from "../hooks/useGetSelectionCallback";

type Props = {
    selectedOption: string;
    ariaLabel: string;
    children: ReactNode | ReactNode[];
    className?: string;
};

/**
 * Button that allows the user to change the design of their canvas
 */
export const DesignTileButton = (props: Props) => {
    const { selectedOption, ariaLabel, children, className } = props;
    const { t } = useTranslationSSR();
    const { dialogSelectionOption, changeCanvas, designPanelType, dialogCanvasTitle, setCanvasUpdateStatus } =
        useDesignDialog();
    const selected = dialogSelectionOption === selectedOption;
    const dispatch = useAppDispatch();
    const isMailingPostCard = useIsMailingPostCard();
    const { setIsMailingServicesGuideModalOpen, setIsShowingGuideModalFromOptionsModal } = useMailingServicesContext();
    const callback = useGetSelectionCallback(selectedOption);

    const onClick = useCallback(() => {
        if (designPanelType === DesignPanelType.Change) {
            dispatch(setLoadingMessage(t(designPanelMessages.changingSide.id, { sideName: dialogCanvasTitle })));
            dispatch(setChangeDesignData({ selectedOption }));
            return;
        }
        dispatch(setLoadingMessage(t(designPanelMessages.addingSide.id, { sideName: dialogCanvasTitle })));
        if (callback) {
            changeCanvas({ selectedOption, callback });
        }
        if (
            isMailingPostCard &&
            (designPanelType === DesignPanelType.Add || designPanelType === DesignPanelType.CheckoutAdd)
        ) {
            setIsShowingGuideModalFromOptionsModal(false);
            setIsMailingServicesGuideModalOpen(true);
        }
        setCanvasUpdateStatus(CanvasUpdateStatus.UPDATED);
    }, [
        designPanelType,
        callback,
        dialogCanvasTitle,
        changeCanvas,
        selectedOption,
        isMailingPostCard,
        dispatch,
        setIsMailingServicesGuideModalOpen,
        setIsShowingGuideModalFromOptionsModal,
        setCanvasUpdateStatus,
        t
    ]);

    return (
        <button
            className={classnames(className, styles.designTileButton, "design-tile-button", {
                selected
            })}
            id={`canvas_selector_${selectedOption}`}
            onClick={onClick}
            onKeyDown={useOnKeyDown(onClick)}
            aria-checked={selected}
            aria-label={ariaLabel}
            role="radio"
        >
            {children}
        </button>
    );
};
DesignTileButton.displayName = "DesignTileButton";
