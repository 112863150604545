function isAValidDimension(value: number | null | undefined) {
    if (value === null || value === undefined || value <= 0) {
        return false;
    }
    return true;
}

export const getItemOverlapData = (
    item: ItemDimensions,
    domWidth: number,
    domHeight: number,
    ctx: CanvasRenderingContext2D,
    fillStyle: string,
    path: Path2D,
    scalePath = 1.0
) => {
    // Get the item dimensions
    const { left, top, right, bottom } = item;
    let width = right - left;
    let height = bottom - top;

    // rounding off width and height as ctx.getImageData() takes a value > 1 else throws error.
    if (height < 1) {
        height = 1;
    }

    if (width <= 1) {
        width = 1;
    }

    // cannot process if width/height is non positive or null/undefined.
    if (!isAValidDimension(width) || !isAValidDimension(height)) {
        return { data: [] };
    }
    // Clear the context with fefefe which is the color we are checking for.
    // following logic will clear out the "safe areas";
    ctx.fillStyle = fillStyle;
    ctx.fillRect(0, 0, domWidth, domHeight);

    // Draw the "Allowed space and clear back to white"
    ctx.scale(scalePath, scalePath);
    ctx.fillStyle = "#fefefe";
    ctx.fill(path, "evenodd");
    ctx.scale(1, 1);
    // Get the image data from the context for the item's adjusted dimensions
    return ctx.getImageData(left, top, width, height);
};

/**
 * Calculates overlap of an item over a mask rendered by path2D.
 * SVGs rendered by mask and path2D are filled with red in evenodd style.
 * If item is completely inside the path SVG then all the pixels
 * within that item will be filled with red else only a part of the pixels overlapped will be red.
 */
export const calculateOverlap = (
    itemDimensions: ItemDimensions,
    canvasWidth: number,
    canvasHeight: number,
    canvasContext: CanvasRenderingContext2D,
    RED_COLOR: string,
    path2D: Path2D,
    SCALE_PATH: number
) => {
    const overlapDataWithNonPostal = getItemOverlapData(
        itemDimensions,
        canvasWidth,
        canvasHeight,
        canvasContext,
        RED_COLOR,
        path2D,
        SCALE_PATH
    );
    const { data } = overlapDataWithNonPostal;
    // checking for the pixels owned by the item to be in red color.
    let hasHits = false;
    for (let i = 0; i < data.length; i += 4) {
        if (data[i] === 255 && data[i + 1] === 0 && data[i + 2] === 0) {
            hasHits = true;
        }
    }
    return {
        hasHits
    };
};
