import { FluidImage } from "@vp/swan";
import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import classNames from "classnames";
import * as styles from "./DesignTileButtonImage.module.scss";

type Props = {
    url: string;
    style?: any;
};

const messages = defineMessages({
    chooseTemplateConfirmationImage: {
        id: "studio.components.designTileButton.chooseTemplateConfirmationImage",
        defaultMessage: "Choose template confirmation preview image",
        description: {
            note: "Descriptive alt label for preview of choose template confirmation Image"
        }
    }
});

/**
 * Image that appears on the DesignTileButton
 */
export const DesignTileButtonImage = (props: Props) => {
    const { url, style } = props;
    const { t } = useTranslationSSR();

    return (
        <FluidImage
            className={classNames(styles.buttonImage, "design-tile-button-image")}
            src={url}
            style={style}
            alt={t(messages.chooseTemplateConfirmationImage.id)}
        />
    );
};

DesignTileButtonImage.displayName = "DesignTileButtonImage";
