import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
}
export function WindowsControlIcon(props: Props) {
    return (
        <svg viewBox="0 0 12 8" fill="none" {...props}>
            <path
                // eslint-disable-next-line max-len
                d="M0.640625 6.19775C0.590495 6.24788 0.549479 6.30485 0.517578 6.36865C0.490234 6.43245 0.476562 6.50309 0.476562 6.58057C0.476562 6.68083 0.499349 6.77197 0.544922 6.854C0.595052 6.93148 0.658854 6.99528 0.736328 7.04541C0.818359 7.09098 0.909505 7.11377 1.00977 7.11377C1.1556 7.11377 1.28092 7.06364 1.38574 6.96338L6.32812 1.86377H5.67871L10.6074 6.96338C10.7168 7.06364 10.8444 7.11377 10.9902 7.11377C11.0905 7.11377 11.1816 7.09098 11.2637 7.04541C11.3457 6.99528 11.4095 6.93148 11.4551 6.854C11.5007 6.77197 11.5234 6.68083 11.5234 6.58057C11.5234 6.43473 11.471 6.30941 11.3662 6.20459L6.39648 1.06396C6.34635 1.00928 6.28711 0.965983 6.21875 0.934082C6.15039 0.902181 6.07747 0.88623 6 0.88623C5.84961 0.88623 5.71517 0.945475 5.59668 1.06396L0.640625 6.19775Z"
                fill="currentColor"
            />
        </svg>
    );
}
WindowsControlIcon.displayName = "WindowsControlIcon";
