import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { Box, SelectionSetInput, SelectionSetLabel, GridContainer, Row, Column } from "@vp/swan";
import { useSelector, RootStateOrAny } from "react-redux";
import { RawPrice, PricePerUnit } from "@vp/vp-tokenized-fragment";
import * as styles from "./QuantityCard.module.scss";

const messages = defineMessages({
    quantityLabel: {
        id: "studio.components.quantityCard.quantityLabel",
        defaultMessage: "Quantity ",
        description: {
            note: "Text message for change product quantity"
        }
    },
    priceLabel: {
        id: "studio.components.quantityCard.priceLabel",
        defaultMessage: "Price information",
        description: {
            note: "Placeholder text for product price"
        }
    },
    pricePerUnitLabel: {
        id: "studio.components.quantityCard.pricePerUnitLabel",
        defaultMessage: "Unit Price information",
        description: {
            note: "Placeholder text for product price per unit"
        }
    }
});

interface QuantityCardProps {
    priceQuantityData: any;
    quantity: string;
    vatInc: boolean;
    defaultFractionDigits: number;
}
export function QuantityCard(props: QuantityCardProps) {
    const { priceQuantityData, quantity, vatInc, defaultFractionDigits } = props;
    const estimatedPriceQuantity = priceQuantityData.estimatedPrices && priceQuantityData.estimatedPrices[quantity];
    const { t } = useTranslationSSR();
    const key = `quantity-${quantity}`;
    const quantityLabelId = `${key}-quantity-label`;
    const priceId = `${key}-price`;
    const unitPriceId = `${key}-unitPrice`;
    const priceLabelId = `${priceId}-label`;
    const unitPriceLabelId = `${unitPriceId}-label`;
    const listPrice = vatInc
        ? estimatedPriceQuantity.totalListPrice.taxed
        : estimatedPriceQuantity.totalListPrice.untaxed;
    const discountPrice = vatInc
        ? estimatedPriceQuantity.totalDiscountedPrice.taxed
        : estimatedPriceQuantity.totalDiscountedPrice.untaxed;
    const unitListPrice = vatInc
        ? estimatedPriceQuantity.unitListPrice.taxed
        : estimatedPriceQuantity.unitListPrice.untaxed;
    const unitDiscountPrice = vatInc
        ? estimatedPriceQuantity.unitDiscountedPrice.taxed
        : estimatedPriceQuantity.unitDiscountedPrice.untaxed;
    const { currency } = useSelector((state: { currency: string }) => state);
    const locale = useSelector((state: RootStateOrAny) => state.locale);

    return (
        <SelectionSetInput
            key={key}
            aria-labelledby={`${quantityLabelId} ${priceLabelId} ${priceId} ${unitPriceLabelId} ${unitPriceId}`}
            value={quantity}
        >
            <SelectionSetLabel>
                <GridContainer className={styles.quantityPricingRow}>
                    <Row>
                        <Column className={styles.quantityPricingColumn} span={4} spanXs={4}>
                            <Box aria-label={quantityLabelId} id={quantityLabelId}>
                                {quantity}
                            </Box>
                        </Column>
                        <Column className={styles.quantityPricingColumn} span={4} spanXs={4}>
                            <Box aria-labelledby={`${priceLabelId} ${priceId}`}>
                                <RawPrice
                                    placeHolderText={t(messages.priceLabel.id)}
                                    pricingInfo={{
                                        listPrice,
                                        discountPrice,
                                        vatInc,
                                        minimumFractionDigits:
                                            priceQuantityData.fractionDigits || defaultFractionDigits,
                                        culture: locale,
                                        currency: priceQuantityData.currency || currency,
                                        hideVatMessage: true
                                    }}
                                />
                            </Box>
                        </Column>
                        <Column className={styles.quantityPricingColumn} span={4} spanXs={4}>
                            <Box aria-labelledby={`${unitPriceLabelId} ${unitPriceId}`}>
                                <PricePerUnit
                                    placeHolderText={t(messages.pricePerUnitLabel.id)}
                                    pricingInfo={{
                                        unitDiscountPrice,
                                        unitListPrice,
                                        vatInc,
                                        minimumFractionDigits:
                                            priceQuantityData.fractionDigits || defaultFractionDigits,
                                        culture: locale,
                                        currency: priceQuantityData.currency || currency,
                                        hideVatMessage: true
                                    }}
                                />
                            </Box>
                        </Column>
                    </Row>
                </GridContainer>
            </SelectionSetLabel>
        </SelectionSetInput>
    );
}
QuantityCard.displayName = "QuantityCard";
