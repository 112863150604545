import React, { useCallback } from "react";
import { useAppDispatch, showStudioErrorDialog } from "@shared/redux";
import { DesignErrorModal } from "@shared/features/Modals";
import { Errors } from "@shared/features/Validations";

interface Props {
    /** Collection of error messages to be displayed in the modal */
    designErrors: Errors[];
    /** Whether it has Premium Finishes in Current Canvas. false by default */
    hasPremiumFinishesCurrentCanvas: boolean;
    /** Whether the modal should be shown. false by default */
    showDialog: boolean;
}

export function StudioDesignErrorModal({
    designErrors = [],
    hasPremiumFinishesCurrentCanvas = false,
    showDialog = false
}: Props) {
    const [firstError] = designErrors;
    const headerText = firstError?.header || "";
    const bodyText = firstError?.body || "";
    const buttonText = firstError?.cta || "";
    const dispatch = useAppDispatch();

    const onClose = useCallback(() => {
        dispatch(showStudioErrorDialog(false));
    }, [dispatch]);

    return (
        <DesignErrorModal
            show={showDialog}
            onClose={onClose}
            headerText={headerText}
            bodyText={bodyText}
            buttonText={buttonText}
            hasPremiumFinishesCurrentCanvas={hasPremiumFinishesCurrentCanvas}
        />
    );
}

StudioDesignErrorModal.displayName = "StudioDesignErrorModal";
