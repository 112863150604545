import React from "react";
import {
    ShiftIcon,
    ShortcutDownArrowIcon,
    LeftArrowIcon,
    ShortcutUpArrowIcon,
    MacOptionIcon,
    CommandIcon,
    ShortcutRightArrowIcon
} from "@shared/features/StudioChrome";
import { keyboardShortcutMessages } from "./messages";
import { KeyBoardShortcutsIcon } from "./KeyboardShortcutsIcon";

export enum modifierKeyName {
    alt = "alt",
    control = "control",
    option = "option",
    command = "command"
}
export const KeyboardShortcutsTabs = {
    viewZoom: {
        tabTitle: keyboardShortcutMessages.viewAndZoomTabTitle.id,
        columns: [
            // {
            //     header: "View",
            //     content: [
            //         {
            //             title: messages.guide.id,
            //             icons: [
            //                 <React.Fragment key={messages.guide.id}>
            //                     <KeyBoardShortcutsIcon>
            //                         <ShiftIcon />
            //                     </KeyBoardShortcutsIcon>
            //                     <KeyBoardShortcutsIcon alphabet="G" />
            //                 </React.Fragment>
            //             ]
            //         },
            //         {
            //             title: messages.rulers.id,
            //             icons: [
            //                 <React.Fragment key={messages.rulers.id}>
            //                     <KeyBoardShortcutsIcon>
            //                         <ShiftIcon />
            //                     </KeyBoardShortcutsIcon>
            //                     <KeyBoardShortcutsIcon alphabet="R" />
            //                 </React.Fragment>
            //             ]
            //         },
            //         {
            //             title: messages.safety.id,
            //             icons: [
            //                 <React.Fragment key={messages.safety.id}>
            //                     <KeyBoardShortcutsIcon>
            //                         <ShiftIcon />
            //                     </KeyBoardShortcutsIcon>
            //                     <KeyBoardShortcutsIcon alphabet="B" />
            //                 </React.Fragment>
            //             ]
            //         },
            //         {
            //             title: messages.preview.id,
            //             icons: [
            //                 <React.Fragment key={messages.preview.id}>
            //                     <KeyBoardShortcutsIcon>
            //                         <ShiftIcon />
            //                     </KeyBoardShortcutsIcon>
            //                     <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
            //                         <CommandIcon />
            //                     </KeyBoardShortcutsIcon>
            //                     <KeyBoardShortcutsIcon alphabet="P" />
            //                 </React.Fragment>
            //             ]
            //         }
            //     ]
            // },
            {
                header: "Zoom",
                content: [
                    {
                        title: keyboardShortcutMessages.zoomIn.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.zoomIn.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
                                    <MacOptionIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="+" />
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.zoomOut.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.zoomOut.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
                                    <MacOptionIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="-" />
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.zoomToFit.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.zoomToFit.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="0" />
                            </React.Fragment>
                        ]
                    }
                ]
            }
        ]
    },
    text: {
        tabTitle: keyboardShortcutMessages.textTabTitle.id,
        columns: [
            {
                header: "",
                content: [
                    // {
                    //     title: messages.leftAlignText.id,
                    //     icons: [
                    //         <React.Fragment key={messages.leftAlignText.id}>
                    //             <KeyBoardShortcutsIcon>
                    //                 <ShiftIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                    //                 <CommandIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon alphabet="L" />
                    //         </React.Fragment>
                    //     ]
                    // },
                    // {
                    //     title: messages.centerAlignText.id,
                    //     icons: [
                    //         <React.Fragment key={messages.centerAlignText.id}>
                    //             <KeyBoardShortcutsIcon>
                    //                 <ShiftIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                    //                 <CommandIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon alphabet="C" />
                    //         </React.Fragment>
                    //     ]
                    // },
                    // {
                    //     title: messages.rightAlignText.id,
                    //     icons: [
                    //         <React.Fragment key={messages.rightAlignText.id}>
                    //             <KeyBoardShortcutsIcon>
                    //                 <ShiftIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                    //                 <CommandIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon alphabet="R" />
                    //         </React.Fragment>
                    //     ]
                    // },
                    {
                        title: keyboardShortcutMessages.bold.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.bold.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="B" />
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.italic.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.italic.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="I" />
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.underline.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.underline.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="U" />
                            </React.Fragment>
                        ]
                    },
                    // {
                    //     title: messages.strikethrough.id,
                    //     icons: [
                    //         <React.Fragment key={messages.strikethrough.id}>
                    //             <KeyBoardShortcutsIcon>
                    //                 <ShiftIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                    //                 <CommandIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon alphabet="S" />
                    //         </React.Fragment>
                    //     ]
                    // },
                    {
                        title: keyboardShortcutMessages.increaseFont.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.increaseFont.id}>
                                <KeyBoardShortcutsIcon>
                                    <ShiftIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="." />
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.decreaseFont.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.decreaseFont.id}>
                                <KeyBoardShortcutsIcon>
                                    <ShiftIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="," />
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.openFontBrowser.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.openFontBrowser.id}>
                                <KeyBoardShortcutsIcon>
                                    <ShiftIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="F" />
                            </React.Fragment>
                        ]
                    }
                ]
            }
        ]
    },
    shape: {
        tabTitle: keyboardShortcutMessages.shapeTabTitle.id,
        columns: [
            {
                header: "",
                content: [
                    {
                        title: keyboardShortcutMessages.addText.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.shapeTabTitle.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
                                    <MacOptionIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="T" />
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.addRectangle.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.addRectangle.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
                                    <MacOptionIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="R" />
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.addLine.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.addLine.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
                                    <MacOptionIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="L" />
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.addCircle.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.addCircle.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
                                    <MacOptionIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="C" />
                            </React.Fragment>
                        ]
                    }
                ]
            }
        ]
    },
    edit: {
        tabTitle: keyboardShortcutMessages.editTabTitle.id,
        columns: [
            {
                header: "",
                content: [
                    // {
                    //     title: messages.copyStyle.id,
                    //     icons: [
                    //         <React.Fragment key={messages.copyStyle.id}>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
                    //                 <MacOptionIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                    //                 <CommandIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon alphabet="C" />
                    //         </React.Fragment>
                    //     ]
                    // },
                    // {
                    //     title: messages.pasteStyle.id,
                    //     icons: [
                    //         <React.Fragment key={messages.pasteStyle.id}>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
                    //                 <MacOptionIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                    //                 <CommandIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon alphabet="V" />
                    //         </React.Fragment>
                    //     ]
                    // },
                    {
                        title: keyboardShortcutMessages.toggleLock.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.toggleLock.id}>
                                <KeyBoardShortcutsIcon>
                                    <ShiftIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="L" />
                            </React.Fragment>
                        ]
                    },
                    // {
                    //     title: messages.groupElements.id,
                    //     icons: [
                    //         <React.Fragment key={messages.groupElements.id}>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                    //                 <CommandIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon alphabet="G" />
                    //         </React.Fragment>
                    //     ]
                    // },
                    // {
                    //     title: messages.ungroupElements.id,
                    //     icons: [
                    //         <React.Fragment key={messages.ungroupElements.id}>
                    //             <KeyBoardShortcutsIcon>
                    //                 <ShiftIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                    //                 <CommandIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon alphabet="G" />
                    //         </React.Fragment>
                    //     ]
                    // },
                    {
                        title: keyboardShortcutMessages.save.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.save.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="S" />
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.deleteSelectedElements.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.deleteSelectedElements.id}>
                                <KeyBoardShortcutsIcon alphabet="Delete" />
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.selectAllElements.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.selectAllElements.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="A" />
                            </React.Fragment>
                        ]
                    },
                    // {
                    //     title: messages.deselectAllElements.id,
                    //     icons: [
                    //         <React.Fragment key={messages.deselectAllElements.id}>
                    //             <KeyBoardShortcutsIcon>
                    //                 <ShiftIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                    //                 <CommandIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon alphabet="A" />
                    //         </React.Fragment>
                    //     ]
                    // },
                    {
                        title: keyboardShortcutMessages.duplicateSelectedElements.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.duplicateSelectedElements.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="D" />
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.undo.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.undo.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="Z" />
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.redo.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.redo.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="Y" />
                            </React.Fragment>
                        ]
                    },
                    // {
                    //     title: messages.colorPicker.id,
                    //     icons: [
                    //         <React.Fragment key={messages.colorPicker.id}>
                    //             <KeyBoardShortcutsIcon>
                    //                 <ShiftIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                    //                 <CommandIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon alphabet="I" />
                    //         </React.Fragment>
                    //     ]
                    // },
                    {
                        title: keyboardShortcutMessages.copy.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.copy.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="C" />
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.paste.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.paste.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="V" />
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.cut.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.cut.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="X" />
                            </React.Fragment>
                        ]
                    }
                ]
            }
        ]
    },
    transform: {
        tabTitle: keyboardShortcutMessages.transformTabTitle.id,
        columns: [
            {
                header: "",
                content: [
                    // {
                    //     title: messages.flipHorizontally.id,
                    //     icons: [
                    //         <React.Fragment key={messages.flipHorizontally.id}>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
                    //                 <MacOptionIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon alphabet="H" />
                    //         </React.Fragment>
                    //     ]
                    // },
                    // {
                    //     title: messages.flipVertically.id,
                    //     icons: [
                    //         <React.Fragment key={messages.flipVertically.id}>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
                    //                 <MacOptionIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon alphabet="V" />
                    //         </React.Fragment>
                    //     ]
                    // },
                    // {
                    //     title: messages.alignElementsToTop.id,
                    //     icons: [
                    //         <React.Fragment key={messages.alignElementsToTop.id}>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
                    //                 <MacOptionIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon alphabet="W" />
                    //         </React.Fragment>
                    //     ]
                    // },
                    // {
                    //     title: messages.alignElementsToRight.id,
                    //     icons: [
                    //         <React.Fragment key={messages.alignElementsToRight.id}>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
                    //                 <MacOptionIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon alphabet="D" />
                    //         </React.Fragment>
                    //     ]
                    // },
                    // {
                    //     title: messages.alignElementsToBottom.id,
                    //     icons: [
                    //         <React.Fragment key={messages.alignElementsToBottom.id}>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
                    //                 <MacOptionIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon alphabet="S" />
                    //         </React.Fragment>
                    //     ]
                    // },
                    // {
                    //     title: messages.alignElementsToLeft.id,
                    //     icons: [
                    //         <React.Fragment key={messages.alignElementsToLeft.id}>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
                    //                 <MacOptionIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon alphabet="A" />
                    //         </React.Fragment>
                    //     ]
                    // },
                    // {
                    //     title: messages.alignElementsVertically.id,
                    //     icons: [
                    //         <React.Fragment key={messages.alignElementsVertically.id}>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
                    //                 <MacOptionIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon alphabet="V" />
                    //         </React.Fragment>
                    //     ]
                    // },
                    // {
                    //     title: messages.alignElementsHoriztonally.id,
                    //     icons: [
                    //         <React.Fragment key={messages.alignElementsHoriztonally.id}>
                    //             <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
                    //                 <MacOptionIcon />
                    //             </KeyBoardShortcutsIcon>
                    //             <KeyBoardShortcutsIcon alphabet="H" />
                    //         </React.Fragment>
                    //     ]
                    // },
                    {
                        title: keyboardShortcutMessages.moveSelectionUpBy10px.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.moveSelectionUpBy10px.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon>
                                    <ShortcutUpArrowIcon />
                                </KeyBoardShortcutsIcon>
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.moveSelectionRightBy10px.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.moveSelectionRightBy10px.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon>
                                    <ShortcutRightArrowIcon />
                                </KeyBoardShortcutsIcon>
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.moveSelectionDownBy10px.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.moveSelectionDownBy10px.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon>
                                    <ShortcutDownArrowIcon />
                                </KeyBoardShortcutsIcon>
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.moveSelectionLeftBy10px.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.moveSelectionLeftBy10px.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon>
                                    <LeftArrowIcon />
                                </KeyBoardShortcutsIcon>
                            </React.Fragment>
                        ]
                    }
                ]
            }
        ]
    },
    arrange: {
        tabTitle: keyboardShortcutMessages.arrangeTabTitle.id,
        columns: [
            {
                header: "",
                content: [
                    {
                        title: keyboardShortcutMessages.arrangeElementsForward.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.arrangeElementsForward.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="]" />
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.arrangeElementsBackward.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.arrangeElementsBackward.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="[" />
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.arrangeElementsToFront.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.arrangeElementsToFront.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
                                    <MacOptionIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="]" />
                            </React.Fragment>
                        ]
                    },
                    {
                        title: keyboardShortcutMessages.arrangeElementsToBack.id,
                        icons: [
                            <React.Fragment key={keyboardShortcutMessages.arrangeElementsToBack.id}>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
                                    <MacOptionIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
                                    <CommandIcon />
                                </KeyBoardShortcutsIcon>
                                <KeyBoardShortcutsIcon alphabet="[" />
                            </React.Fragment>
                        ]
                    }
                ]
            }
        ]
    }
    // selection: {
    //     tabTitle: messages.selectionTabTitle.id,
    //     columns: [
    //         {
    //             header: "",
    //             content: [
    //                 {
    //                     title: messages.duplicateSelection.id,
    //                     icons: [
    //                         <React.Fragment key={messages.duplicateSelection.id}>
    //                             <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
    //                                 <MacOptionIcon />
    //                             </KeyBoardShortcutsIcon>
    //                             <KeyBoardShortcutsIcon alphabet="Drag" />
    //                         </React.Fragment>
    //                     ]
    //                 },
    //                 {
    //                     title: messages.incrementalRotationOnly.id,
    //                     icons: [
    //                         <React.Fragment key={messages.incrementalRotationOnly.id}>
    //                             <KeyBoardShortcutsIcon>
    //                                 <ShiftIcon />
    //                             </KeyBoardShortcutsIcon>
    //                             <KeyBoardShortcutsIcon alphabet="Rotate" />
    //                         </React.Fragment>
    //                     ]
    //                 },
    //                 {
    //                     title: messages.ignoreSnapping.id,
    //                     icons: [
    //                         <React.Fragment key={messages.ignoreSnapping.id}>
    //                             <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
    //                                 <CommandIcon />
    //                             </KeyBoardShortcutsIcon>
    //                             <KeyBoardShortcutsIcon alphabet="Rotate" />
    //                         </React.Fragment>
    //                     ]
    //                 },
    //                 {
    //                     title: messages.ignoreResizeSnapping.id,
    //                     icons: [
    //                         <React.Fragment key={messages.ignoreResizeSnapping.id}>
    //                             <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
    //                                 <MacOptionIcon />
    //                             </KeyBoardShortcutsIcon>
    //                             <KeyBoardShortcutsIcon alphabet="Resize" />
    //                         </React.Fragment>
    //                     ]
    //                 },
    //                 {
    //                     title: messages.resizeFromMiddle.id,
    //                     icons: [
    //                         <React.Fragment key={messages.resizeFromMiddle.id}>
    //                             <KeyBoardShortcutsIcon>
    //                                 <ShiftIcon />
    //                             </KeyBoardShortcutsIcon>
    //                             <KeyBoardShortcutsIcon iconName={modifierKeyName.alt}>
    //                                 <MacOptionIcon />
    //                             </KeyBoardShortcutsIcon>
    //                             <KeyBoardShortcutsIcon alphabet="Resize" />
    //                         </React.Fragment>
    //                     ]
    //                 },
    //                 {
    //                     title: messages.resizeFromOpposite.id,
    //                     icons: [
    //                         <React.Fragment key={messages.resizeFromOpposite.id}>
    //                             <KeyBoardShortcutsIcon>
    //                                 <ShiftIcon />
    //                             </KeyBoardShortcutsIcon>
    //                             <KeyBoardShortcutsIcon alphabet="Resize" />
    //                         </React.Fragment>
    //                     ]
    //                 },
    //                 {
    //                     title: messages.pan.id,
    //                     icons: [
    //                         <React.Fragment key={messages.pan.id}>
    //                             <KeyBoardShortcutsIcon alphabet="Space" />
    //                         </React.Fragment>
    //                     ]
    //                 },
    //                 {
    //                     title: messages.holdHorizontalAndVerticalPosition.id,
    //                     icons: [
    //                         <React.Fragment key={messages.holdHorizontalAndVerticalPosition.id}>
    //                             <KeyBoardShortcutsIcon>
    //                                 <ShiftIcon />
    //                             </KeyBoardShortcutsIcon>
    //                             <KeyBoardShortcutsIcon alphabet="Drag" />
    //                         </React.Fragment>
    //                     ]
    //                 },
    //                 {
    //                     title: messages.ignoreDragSnapping.id,
    //                     icons: [
    //                         <React.Fragment key={messages.ignoreDragSnapping.id}>
    //                             <KeyBoardShortcutsIcon iconName={modifierKeyName.control}>
    //                                 <CommandIcon />
    //                             </KeyBoardShortcutsIcon>
    //                             <KeyBoardShortcutsIcon alphabet="Drag" />
    //                         </React.Fragment>
    //                     ]
    //                 }
    //             ]
    //         }
    //     ]
    // }
};
