import { useCallback } from "react";
import { FlexibilityOptionData } from "../common/components/FlexibilityOption";

export const useSizeData = () => {
    const constructData = useCallback(
        (designAttributeValue: string): FlexibilityOptionData => ({
            designAttributeValue
        }),
        []
    );

    const getSizeData = useCallback(
        (sizes: string[]) => {
            return sizes.map(constructData);
        },
        [constructData]
    );

    return getSizeData;
};
