import React from "react";
import { setShowTableEditorModal, useAppDispatch, useAppSelector } from "@shared/redux";
import { TableEditorDialog } from "@shared/features/Tables";
import { updateTableItemData as updateDataInTableItem } from "@utilities";
import { useDesigner } from "../designer/DesignerProvider";
import { useSelection } from "../designer/useSelection";
import { useTableSelectionFilter } from "../hooks/useTableSelectionFilter";

export function TableEditorModal() {
    const designer = useDesigner();
    const dispatch = useAppDispatch();
    const showTableEditorModal = useAppSelector(state => state.showTableEditorModal);
    const selection = useSelection();
    const { tableItems } = useTableSelectionFilter(selection);

    if (tableItems.length !== 1) {
        return null;
    }

    const [tableItem] = tableItems;
    const { data: tableData } = tableItem;

    const closeModal = () => {
        dispatch(setShowTableEditorModal(false));
    };

    const onApply = (newData: TableItemData) => {
        if (!designer) {
            return;
        }

        designer.api.design.updateItem<TableItem>(tableItem.id, mutableItem => {
            updateDataInTableItem(mutableItem, newData);
        });

        closeModal();
    };

    return (
        <TableEditorDialog
            isOpen={showTableEditorModal}
            onClose={closeModal}
            onApplyChanges={onApply}
            tableData={tableData}
        />
    );
}

TableEditorModal.displayName = "TableEditorModal";
