import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import {
    Button,
    Divider,
    LegacyModalDialog,
    LegacyModalDialogBody,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton,
    LegacyModalDialogFooter
} from "@vp/swan";
import { UploadStrategies, useDesigner, useHandleImagePlacement } from "@designer-suite";
import { useAppSelector, useAppDispatch, setClipartGalleryPreview } from "@shared/redux";
import { updateRecentlyUsedElements } from "@five/xerox/features/Elements";
import { createModel } from "./ClipArtPanelUtils";
import { ClipartMessages } from "./ClipartMessages";
import "./clipartPanel.scss";

export function ClipartPreview() {
    const designer = useDesigner();
    const preview = useAppSelector(state => state.clipartGalleryPreview);
    const dispatch = useAppDispatch();
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();

    const onCancel = () => {
        dispatch(setClipartGalleryPreview(null));
    };
    const { onImageDoubleClicked } = useHandleImagePlacement({
        placementStrategy: UploadStrategies.AutofillSinglePlaceholder,
        onUploadAdded: onCancel
    });

    const addToDesign = () => {
        if (!designer) {
            return;
        }
        if (preview) {
            const { id, src, previewUrl, width, height, printUrl, printPixelHeight, printPixelWidth } = preview;

            const img = {
                id,
                src,
                previewUrl,
                width,
                height,
                printUrl,
                svgUrl: previewUrl,
                printPixelHeight,
                printPixelWidth,
                key: id
            };

            const upload = createModel(img, designer);
            const pages = upload.get("pages");

            updateRecentlyUsedElements(img);
            onImageDoubleClicked({ upload, page: pages.at(0) });
        }
    };

    return (
        <LegacyModalDialog
            isOpen={Boolean(preview)}
            onRequestDismiss={onCancel}
            paddingTop={isSmall ? undefined : 4}
            variant="standard"
            data-dialog-enable-browser-history="xs-only"
            onClick={(event: React.MouseEvent) => {
                event.preventDefault();
                dispatch(setClipartGalleryPreview(null));
            }}
            data-dcl-prevent-canvas-items-deselection
        >
            <LegacyModalDialogContent
                fullBleed={isSmall}
                className="clipart-preview-content"
                pb={4}
                pt={9}
                px={0}
                aria-label={t(ClipartMessages.clipartPreviewModalAriaLabel.id)}
            >
                <Divider />
                <LegacyModalDialogCloseButton visuallyHiddenLabel={t(ClipartMessages.closeModalAriaLabel.id)} />
                <LegacyModalDialogBody>
                    <div
                        className="clipart-preview-image"
                        style={{ backgroundImage: preview?.previewUrl ? `url(${preview?.previewUrl})` : "" }}
                    />
                </LegacyModalDialogBody>
                <LegacyModalDialogFooter mt={4}>
                    <Button skin="primary" size="mini" onClick={addToDesign}>
                        {t(ClipartMessages.addToDesignButtonLabel.id)}
                    </Button>
                </LegacyModalDialogFooter>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}
ClipartPreview.displayName = "ClipartPreview";
