import React from "react";
import { Typography, Button, LegacyModalDialog, LegacyModalDialogHeader, LegacyModalDialogContent } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useIsMailingPostCard } from "@shared/features/MailingServices";
import classNames from "classnames";
import { designPanelMessages } from "../designPanelMessages";

import * as styles from "./DeleteConfirmationDialog.module.scss";

interface Props {
    /**
     * Whether to show the modal or not
     * @default false
     */
    show: boolean;
    /** Name of side that is being deleted (already localized) */
    sideName: string;
    /** Optional callback when modal closes */
    onClose: () => void;
    /** Callback to when delete is clicked (dialog will close itself once callback is complete) */
    onDelete: () => void;
}

export function DeleteConfirmationDialog(props: Props) {
    const { show, sideName, onClose, onDelete } = props;
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const isMailingPostCard = useIsMailingPostCard();

    const onClickCancel = () => {
        onClose();
    };

    const onClickDelete = () => {
        onDelete();
        onClose();
    };

    return (
        <LegacyModalDialog
            className="delete-confirmation"
            isOpen={show}
            onRequestDismiss={onClickCancel}
            data-dcl-prevent-canvas-items-deselection
            bodyWidth="capped"
            variant={isSmall ? "panel-bottom" : "standard"}
        >
            <LegacyModalDialogContent
                className={classNames("modal-dialog-content", styles.dialogContent)}
                aria-label={t(designPanelMessages.deleteConfirmationAriaLabel.id)}
            >
                <LegacyModalDialogHeader data-dcl-prevent-canvas-items-deselection marginX={0} marginBottom={3}>
                    <Typography fontSize="4">
                        {t(designPanelMessages.designConfirmDeleteTitle.id, { sideName })}
                    </Typography>
                </LegacyModalDialogHeader>

                <Typography fontSize="1" marginBottom={5}>
                    {t(designPanelMessages.designConfirmDeleteText.id, { sideName })}
                    {isMailingPostCard && (
                        <span>{t(designPanelMessages.designConfirmationMailingText.id, { sideName })}</span>
                    )}
                </Typography>
                <Button skin="secondary" width={"standard"} size="mini" onClick={onClickCancel} marginRight={4}>
                    {t(designPanelMessages.designConfirmDeleteCancel.id)}
                </Button>
                <Button
                    className={styles.confirmButton}
                    skin="primary"
                    width={"standard"}
                    size="mini"
                    onClick={onClickDelete}
                >
                    {t(designPanelMessages.designConfirmDeleteConfirm.id)}
                </Button>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}

DeleteConfirmationDialog.displayName = "DeleteConfirmationDialog";
