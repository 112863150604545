import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { Typography } from "@vp/swan";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useAppSelector } from "@shared/redux";

const messages = defineMessages({
    headerTextOfPanel: {
        id: "studio.components.specsAndTemplatePanel.headerTextOfPanel",
        defaultMessage: "Design Specs",
        description: {
            note: "header text for specs and template panel"
        }
    },
    descriptionTextOne: {
        id: "studio.components.specsAndTemplatePanel.descriptionTextOne",
        defaultMessage: `To avoid white edges, extend your design to the bleed line `,
        description: {
            note: "first line description text of specs and template dailog"
        }
    },
    descriptionTextTwo: {
        id: "studio.components.specsAndTemplatePanel.descriptionTextTwo",
        defaultMessage: `– but keep text and images within the safety area.`,
        description: {
            note: "second line description text of specs and template dailog"
        }
    }
});

export function SpecsAndTemplatesHeader() {
    const { isSmall } = useStudioLayout();
    const { t } = useTranslationSSR();
    const productName = useAppSelector(state => state.productName);

    return (
        <>
            <Typography fontSize="4" mt={1} mb={4} fontWeight="bold">
                {t(messages.headerTextOfPanel.id)}
            </Typography>
            <Typography fontSize="3" fontWeight="bold" mb={4}>
                {productName}
            </Typography>
            {isSmall ? (
                <Typography fontSize="1" mb={5}>
                    {t(messages.descriptionTextOne.id)}
                    {t(messages.descriptionTextTwo.id)}
                </Typography>
            ) : (
                <>
                    <Typography fontSize="1">{t(messages.descriptionTextOne.id)}</Typography>
                    <Typography fontSize="1" mb={5}>
                        {t(messages.descriptionTextTwo.id)}
                    </Typography>
                </>
            )}
        </>
    );
}
SpecsAndTemplatesHeader.displayName = "SpecsAndTemplatesHeader";
