import React, { useState, useEffect } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useActiveCanvasPremiumFinishItems, useStudio5AvailablePremiumFinishesOnCanvas } from "@utilities";
import { FinishMessages } from "@shared/features/PremiumFinish";
import { useAppSelector, useAppDispatch, setPremiumFinishWarning } from "@shared/redux";
import { usePrevious } from "@design-stack-ct/utility-react";
import { DesignErrorModal } from "@shared/features/Modals";

const messages = defineMessages({
    keepEditing: {
        id: "easel.ui.lastPremiumFinishWarningPanel.button.keepEditing",
        defaultMessage: "Keep Editing"
    }
});

export function LastPremiumFinishWarningModal() {
    const { t } = useTranslationSSR();
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(state => state.isPremiumFinishModalOpen);
    const { hasPremiumFinishesCurrentCanvas, enabledCanvasFinish } = useStudio5AvailablePremiumFinishesOnCanvas();
    const { premiumFinishItems, fetchingPremiumFinishItems } = useActiveCanvasPremiumFinishItems();
    const previousPremiumFinishItems = usePrevious(premiumFinishItems);
    const [show, setShow] = useState(false);
    const finishType = enabledCanvasFinish.length > 0 ? enabledCanvasFinish[0] : null;

    useEffect(() => {
        if (!hasPremiumFinishesCurrentCanvas) return;
        if (
            !fetchingPremiumFinishItems.length &&
            !premiumFinishItems.length &&
            previousPremiumFinishItems &&
            previousPremiumFinishItems.length &&
            !isOpen
        ) {
            setShow(true);
            // dispatch a redux action telling every one that no item left with premium finish now.
            dispatch(setPremiumFinishWarning(true));
        } else {
            dispatch(setPremiumFinishWarning(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [premiumFinishItems, fetchingPremiumFinishItems]);

    if (!finishType) {
        return null;
    }

    return (
        <DesignErrorModal
            onClose={() => {
                setShow(false);
            }}
            show={show}
            className="last-premium-finish"
            headerText={t(FinishMessages.noPremiumFinishHeader.id, {
                finishType: t(FinishMessages[finishType].id)
            })}
            buttonText={t(messages.keepEditing.id)}
            bodyText={t(FinishMessages.noPremiumFinishBody.id, { finishType: t(FinishMessages[finishType].id) })}
            hasPremiumFinishesCurrentCanvas={hasPremiumFinishesCurrentCanvas}
        />
    );
}
LastPremiumFinishWarningModal.displayName = "LastPremiumFinishWarningModal";
