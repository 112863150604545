import { defineMessages } from "@vp/i18n-helper";

export const embroideryMessages = defineMessages({
    modalTitle: {
        id: "easel.ui.components.embroideryImageColorModal.modalTitle",
        defaultMessage: "Update thread colors"
    },
    currentColors: {
        id: "easel.ui.components.embroideryImageColorModal.currentColors",
        defaultMessage: "Your current thread colors"
    },
    resetColors: {
        id: "easel.ui.components.embroideryImageColorModal.resetColors",
        defaultMessage: "Reset colors"
    },
    chooseColors: {
        id: "easel.ui.components.embroideryImageColorModal.chooseColors",
        defaultMessage: "Choose different colors"
    },
    cancel: {
        id: "easel.ui.components.embroideryImageColorModal.cancel",
        defaultMessage: "Cancel"
    },
    apply: {
        id: "easel.ui.components.embroideryImageColorModal.apply",
        defaultMessage: "Apply"
    },
    closeModalAriaLabel: {
        id: "easel.ui.components.embroideryImageColorModal.closeModalAriaLabel",
        defaultMessage: "Close",
        description: {
            note: "Accessibility label for the modal close button"
        }
    },
    chooseColorAriaLabel: {
        id: "easel.ui.components.embroideryImageColorModal.chooseColorAriaLabel",
        defaultMessage: "Choose a different color",
        description: {
            note: "Accessibility label for the button to open the dropdown to choose a new color"
        }
    },
    updatingColor: {
        id: "easel.ui.components.embroideryImageColorModal.updatingColor",
        defaultMessage: "Updating thread color"
    },
    previewColor: {
        id: "easel.ui.components.embroideryImageColorModal.preview",
        defaultMessage: "Embroidery color preview",
        description: {
            note: "Descriptive alt label for preview of embroidery color image"
        }
    }
});
