import { TextAreaContent, TextInline, TextList, TextListItem } from "@design-stack-ct/cdif-types";

function isTextListItem(data: TextListItem | TextInline | TextList): data is TextListItem {
    return (data as TextListItem).type === "listItem";
}

function isTextList(data: TextListItem | TextInline | TextList): data is TextList {
    return (data as TextList).type === "list";
}

// Acts like Array.map but over the text content in a cimdoc
export function mapTextContent(
    contentArray: TextAreaContent[] | TextListItem[] | TextInline[],
    mappingFunction: (arg0: TextAreaContent | TextListItem | TextInline) => TextAreaContent | TextListItem | TextInline
) {
    return contentArray.map(item => {
        const result = mappingFunction(item);
        if (isTextListItem(result)) {
            result.content = mapTextContent(result.content, mappingFunction) as TextInline[];
        }
        if (isTextList(result)) {
            result.content = mapTextContent(result.content, mappingFunction) as TextListItem[];
        }
        return result;
    });
}
