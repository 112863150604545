// extracted by mini-css-extract-plugin
export var advanceOptionHeader = "KeyboardShortcutsTabContent-module--advance-option-header--c265f";
export var closeButton = "KeyboardShortcutsTabContent-module--close-button--ce69c";
export var closeButtonContainer = "KeyboardShortcutsTabContent-module--close-button-container--1730d";
export var header = "KeyboardShortcutsTabContent-module--header--0ccaa";
export var keyAndIconsContent = "KeyboardShortcutsTabContent-module--key-and-icons-content--f705c";
export var keyboardShortcutModal = "KeyboardShortcutsTabContent-module--keyboard-shortcut-modal--0dc94";
export var keyboardShortcutsTabContainer = "KeyboardShortcutsTabContent-module--keyboard-shortcuts-tab-container--c9345";
export var keyboardShortcutsTabContents = "KeyboardShortcutsTabContent-module--keyboard-shortcuts-tab-contents--a0ea8";
export var keyboardShortcutsTabTitle = "KeyboardShortcutsTabContent-module--keyboard-shortcuts-tab-title--9a366";
export var keyboardShortcutsTabsHeaders = "KeyboardShortcutsTabContent-module--keyboard-shortcuts-tabs-headers--b6500";
export var osSelectionHeader = "KeyboardShortcutsTabContent-module--os-selection-header--13572";
export var swanDropdown = "KeyboardShortcutsTabContent-module--swan-dropdown--14cff";
export var tabsColumnContent = "KeyboardShortcutsTabContent-module--tabs-column-content--b3721";