import React, { useCallback, useEffect, useState } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import loadable from "@loadable/component";
import { loadableRetry } from "@shared/utils/Network";
import { Divider } from "@vp/swan";
import { useDocumentItems } from "@designer-suite";
import {
    fireDesignToolTrackingEvent,
    fireStudioTrackingEvent,
    fireUserInteractionTrackingEvent
} from "@shared/utils/Tracking";
import { useAppSelector, useAppDispatch, showDesignReview } from "@shared/redux";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { useSmartValidations } from "@shared/features/SmartValidations";
import { type ReviewPrevewProps } from "@shared/features/Previews";
import { DesignReview } from "@shared/features/DesignReview";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useStudio5AvailablePremiumFinishesOnCanvas } from "@utilities";
import { MobilePanel } from "@shared/features/StudioChrome";
import ReviewStep from "../ReviewStep";
import { useTriggerCanvasChange } from "../../hooks/useTriggerCanvasChange";
import { useFullBleedPremiumFinish } from "../../hooks/useFullBleedPremiumFinish";
import { useValidations } from "../Validation/ValidationProvider";
import { isPlaceholderTextPresent } from "../Redesign/PremiumFinish/usePremiumFinishFilter";
import {
    PanelOpenedActionOrigin,
    SmartValidationEvents,
    trackSmartValidationEvent
} from "../Validation/trackingClient";
import ContinueButtonStudio5 from "../ContinueButtonStudio5";
import "./reviewPanel.scss";

const ReviewPreviews = loadable<ReviewPrevewProps, any>(
    () => loadableRetry(() => import("@shared/features/Previews")),
    {
        resolveComponent: components => components.ReviewPreviews
    }
);

const messages = defineMessages({
    ariaLabel: {
        id: "studio.components.reviewStep.reviewStepModalHeader",
        defaultMessage: "Review Step Modal Header",
        description: {
            note: "Aria label for Review your final design with the Modal Header"
        }
    },
    ariaCloseLabel: {
        id: "studio.components.nextStep.reviewCloseLabel",
        defaultMessage: "Close Modal",
        description: {
            note: "Aria label for close button of review modal"
        }
    }
});

export const ReviewPanel = () => {
    const showDesignReviewState = useAppSelector(state => state.designReviewState.show);
    const startTime = useAppSelector(state => state.designReviewState.startTime);
    const [customerReviewed, setCustomerReviewed] = useState<boolean>(false);
    const { t } = useTranslationSSR();
    const dispatch = useAppDispatch();
    const { isSmartValidationsEnabled } = useSmartValidations();
    const designRequirements = useDesignRequirementsContext();
    const items = useDocumentItems(false);
    const placeholderTextPresent = isPlaceholderTextPresent(items);
    const { isFullBleedPremiumFinish } = useFullBleedPremiumFinish();
    const { hasPremiumFinishesCurrentCanvas } = useStudio5AvailablePremiumFinishesOnCanvas();
    const { isSmall } = useStudioLayout();

    const triggerCanvasChange = useTriggerCanvasChange();
    const { canvasValidations, itemValidations } = useValidations();

    useEffect(() => {
        if (showDesignReviewState && startTime) {
            const endTime = performance.now();
            fireUserInteractionTrackingEvent("Click Next Button To Review", endTime - startTime);
        }
    }, [showDesignReviewState, startTime]);

    const handleMobilePanelCloseClick = useCallback(() => {
        dispatch(showDesignReview({ show: false }));
        fireStudioTrackingEvent();
        setCustomerReviewed(false);
    }, [dispatch]);

    const onChatButtonClick = () => {
        if (!(window as any).$Lightning) {
            (window as any).caLoader && (window as any).caLoader();
        }
        handleMobilePanelCloseClick();
    };

    // This will switch the design view to the first canvas with visible, non-dismissed issues
    const switchToFirstPanelWithIssues = useCallback(() => {
        if (!designRequirements) {
            return;
        }
        // Get the list of all issues and flatten them into a single array
        const canvasValidationKeys = Object.keys(canvasValidations);
        const itemValidationKeys = Object.keys(itemValidations);
        const canvasValidationsArray = canvasValidationKeys
            .map(key => canvasValidations[key])
            .reduce((prev, record) => {
                const keys = Object.keys(record);
                return [...prev, ...keys.map(key => record[key])];
            }, [])
            .reduce((prev, curr) => [...prev, ...curr], []);
        const itemValidationArray = itemValidationKeys
            .map(key => itemValidations[key])
            .reduce((prev, curr) => [...prev, ...curr], []);
        // Filter out dismissed, dismissing and fixed validations
        const allValidations = [...canvasValidationsArray, ...itemValidationArray].filter(
            validation => !validation.dismissed && !validation.dismissing && !validation.smartFixed
        );
        // If there are no actionable issues, select the fist canvas
        if (allValidations.length === 0) {
            triggerCanvasChange(designRequirements.getDefaultPanelName());
            return;
        }
        // Reduce to a list of canvases that have issues on them
        const allAffectedCanvases = allValidations.reduce((prev, curr) => {
            if (!prev.includes(curr.canvasId)) {
                prev.push(curr.canvasId);
            }
            return prev;
        }, [] as string[]);
        // Pick the first one affected
        const canvasName = designRequirements.panels.reduce((prev, curr) => {
            if (!prev && allAffectedCanvases.includes(curr.id)) return curr.name;
            return prev;
        }, "");

        triggerCanvasChange(canvasName);
    }, [canvasValidations, itemValidations, triggerCanvasChange, designRequirements]);

    const onBackButtonEvent = useCallback(
        (e: PopStateEvent) => {
            if (showDesignReviewState) {
                e.preventDefault();
                fireDesignToolTrackingEvent({
                    eventDetail: "click:closeReviewPage",
                    label: "Review",
                    extraData: () => ({ closeMethod: "Back Button" })
                } as any);
                handleMobilePanelCloseClick();
            }
        },
        [handleMobilePanelCloseClick, showDesignReviewState]
    );

    useEffect(() => {
        window.addEventListener("popstate", onBackButtonEvent);
        return () => {
            window.removeEventListener("popstate", onBackButtonEvent);
        };
    }, [onBackButtonEvent]);

    return (
        <MobilePanel
            title={""}
            isOpen={showDesignReviewState}
            fullBleed={true}
            onRequestDismiss={() => {
                handleMobilePanelCloseClick();
                fireDesignToolTrackingEvent({
                    eventDetail: "click:closeReviewPage",
                    label: "Review",
                    extraData: () => ({ closeMethod: "Request Dismiss" })
                } as any);
            }}
            ariaLabel={t(messages.ariaLabel.id)}
            ariaCloseLabel={t(messages.ariaCloseLabel.id)}
            ariaLabelledBy="reviewStepModal"
            takeOver={true}
            testId={"reviewStepModalHeader"}
            className={"review-panel"}
        >
            <div className="review-window" id="reviewStepModal">
                <ReviewPreviews showPreview={showDesignReviewState} isReviewPage={true} />
                <Divider className="mobile-divider" />
                {isSmartValidationsEnabled ? (
                    <ReviewStep
                        customerReviewed={customerReviewed}
                        setCustomerReviewed={setCustomerReviewed}
                        handleContinueEditingClick={() => {
                            handleMobilePanelCloseClick();
                            switchToFirstPanelWithIssues();
                            fireDesignToolTrackingEvent({
                                eventDetail: "click:editMyDesign",
                                label: "Review"
                            } as any);
                            trackSmartValidationEvent(SmartValidationEvents.PanelOpened, {
                                extraData: {
                                    trigger: PanelOpenedActionOrigin.DesignReview
                                }
                            });
                        }}
                    />
                ) : (
                    <DesignReview
                        customerReviewed={customerReviewed}
                        setCustomerReviewed={setCustomerReviewed}
                        handleContinueEditingClick={() => {
                            handleMobilePanelCloseClick();
                            fireDesignToolTrackingEvent({ eventDetail: "click:editMyDesign", label: "Review" } as any);
                        }}
                        onChatButtonClick={onChatButtonClick}
                        placeholderTextPresent={!!placeholderTextPresent}
                        isFullBleedPremiumFinish={isFullBleedPremiumFinish}
                        hasPremiumFinishesCurrentCanvas={hasPremiumFinishesCurrentCanvas}
                        triggerCanvasChange={triggerCanvasChange}
                        continueButton={
                            <ContinueButtonStudio5 hasBeenReviewed={customerReviewed} mt={isSmall ? 0 : 3} />
                        }
                    />
                )}
            </div>
        </MobilePanel>
    );
};

ReviewPanel.displayName = "ReviewPanel";
