import React, { useEffect, useState } from "react";
import {
    Typography,
    Button,
    Icon,
    FlexBox,
    Divider,
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogBody,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton,
    LegacyModalDialogNav
} from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useDesigner, useSelection } from "@designer-suite";
import { useAppSelector, useAppDispatch, showEmbroideryImageColorModal } from "@shared/redux";
import type { SelectableColor, EmbroiderySelectableColor } from "@shared/features/ColorPicker";
import { embroideryMessages } from "./embroideryMessages";
import { DesktopEmbroideryColorPalette } from "./DesktopEmbroideryColorPalette";
import { MobileEmbroideryColorPalette } from "./MobileEmbroideryColorPalette";
import "./embroideryImageColorModal.scss";
import { EmbroideryImageColorPreview } from "./EmbroideryImageColorPreview";
import { useRecentColorsContext } from "../../../presentational/ColorPicker/RecentColorsProvider";
import {
    getColorPalette,
    getCurrentEmbroideryImageColors,
    getUpdatedEmbroideryOverrideColors,
    updateSelectedItems
} from "../../util";

export function EmbroideryImageColorModal() {
    const dispatch = useAppDispatch();
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const isOpen = useAppSelector(state => state.embroideryImageColorModalOpen);

    const selection = useSelection("model:change:colorOverrides");
    const designer = useDesigner();
    const { recentColors } = useRecentColorsContext();
    const [originalOverrides, setOriginalOverrides] = useState<ColorSpecification[] | undefined>();
    const [currentColors, setCurrentColors] = useState<EmbroiderySelectableColor[] | undefined>();
    const [paletteColors, setPaletteColors] = useState<SelectableColor[]>([]);
    const [colorOverrides, setColorOverrides] = useState<ColorSpecification[] | undefined>();
    const [ordinalToUpdate, setOrdinalToUpdate] = useState<number>(1);
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        if (designer && selection && isOpen) {
            const selectedItem = selection[0];

            // these are thread ids
            const paletteColors = getColorPalette(designer, selectedItem);
            setPaletteColors(paletteColors);
            // originalColors are thread ids, but the overrides are hex
            const { originalColors, colorOverrides } = selectedItem?._itemViewModel.model.attributes;
            setColorOverrides(colorOverrides);
            if (!originalOverrides && colorOverrides) {
                setOriginalOverrides(colorOverrides);
            }
            const currentEmbroideryImageColors = getCurrentEmbroideryImageColors(
                originalColors,
                colorOverrides,
                paletteColors
            );
            setCurrentColors(currentEmbroideryImageColors);
        }
    }, [designer, originalOverrides, selection, isOpen]);

    if (!designer || selection?.length !== 1) {
        return null;
    }

    const handleClose = () => {
        dispatch(showEmbroideryImageColorModal(false));
        setOriginalOverrides(undefined);
        setCurrentColors(undefined);
        setPaletteColors([]);
        setColorOverrides(undefined);
        setOrdinalToUpdate(1);
    };

    const resetColors = () => {
        updateSelectedItems(designer, selection, (item: Item) =>
            item._itemViewModel.model.set("colorOverrides", originalOverrides)
        );
    };

    // if we close in any way besides clicking apply, reset to the original overrides before closing
    const resetAndClose = () => {
        resetColors();
        handleClose();
    };

    // when apply is clicked, keep the changes that have been made and close
    const applyColors = () => {
        handleClose();
    };

    const onChange = (color: string) => {
        if (colorOverrides && ordinalToUpdate) {
            const currentOverride = colorOverrides.find(colorOverride => colorOverride.ordinal === ordinalToUpdate);
            if (currentOverride?.color === color) {
                return;
            }

            setIsUpdating(true);
            const newOverride = { ordinal: ordinalToUpdate, color };
            const { originalColors } = selection[0]._itemViewModel.model.attributes;
            // @ts-ignore Fixed when we migrate to Design Stack
            const newOverrides = getUpdatedEmbroideryOverrideColors(originalColors, colorOverrides, newOverride);
            updateSelectedItems(designer, selection, (item: Item) =>
                item._itemViewModel.model.set("colorOverrides", newOverrides)
            );
        }
    };

    return (
        <LegacyModalDialog
            isOpen={isOpen}
            onRequestDismiss={resetAndClose}
            className="embroidery-image-color-modal"
            data-dcl-prevent-canvas-items-deselection
        >
            <LegacyModalDialogContent aria-label={t(embroideryMessages.modalTitle.id)}>
                <LegacyModalDialogNav>
                    {isSmall && (
                        <Button
                            aria-label={t(embroideryMessages.closeModalAriaLabel.id)}
                            skin="unstyled"
                            marginRight={3}
                            onClick={resetAndClose}
                        >
                            <Icon skin="standard" iconType="chevronLeft" />
                        </Button>
                    )}
                    <LegacyModalDialogHeader>
                        <Typography textSize={isSmall ? 6 : 5} textAlign={isSmall ? "left" : "center"}>
                            {t(embroideryMessages.modalTitle.id)}
                        </Typography>
                    </LegacyModalDialogHeader>
                    {!isSmall && (
                        <LegacyModalDialogCloseButton
                            visuallyHiddenLabel={t(embroideryMessages.closeModalAriaLabel.id)}
                            onClick={resetAndClose}
                        />
                    )}
                </LegacyModalDialogNav>
                {!isSmall && <Divider />}
                <LegacyModalDialogBody>
                    {!isSmall && (
                        <DesktopEmbroideryColorPalette
                            recentColors={recentColors}
                            paletteColors={paletteColors}
                            currentColors={currentColors}
                            onChange={onChange}
                            resetColors={resetColors}
                            setOrdinalToUpdate={setOrdinalToUpdate}
                            ordinalToUpdate={ordinalToUpdate}
                        />
                    )}
                    <EmbroideryImageColorPreview isUpdating={isUpdating} setIsUpdating={setIsUpdating} />
                    {isSmall && (
                        <MobileEmbroideryColorPalette
                            paletteColors={paletteColors}
                            currentColors={currentColors}
                            onChange={onChange}
                            resetColors={resetColors}
                            setOrdinalToUpdate={setOrdinalToUpdate}
                            ordinalToUpdate={ordinalToUpdate}
                        />
                    )}
                    <FlexBox
                        className="embroidery-image-color-modal__container"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Button
                            size="mini"
                            width={isSmall ? "full-width" : "standard"}
                            className="embroidery-image-color-modal__button"
                            onClick={resetAndClose}
                        >
                            <Typography fontSize="-1">{t(embroideryMessages.cancel.id)}</Typography>
                        </Button>
                        <Button
                            skin="primary"
                            size="mini"
                            width={isSmall ? "full-width" : "standard"}
                            className="embroidery-image-color-modal__button"
                            onClick={applyColors}
                        >
                            <Typography fontSize="-1">{t(embroideryMessages.apply.id)}</Typography>
                        </Button>
                    </FlexBox>
                </LegacyModalDialogBody>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}

EmbroideryImageColorModal.displayName = "EmbroideryImageColorModal";
