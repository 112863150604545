import React from "react";
import { StudioDesignErrorModal } from "@shared/features/Modals/StudioDesignErrorModal";
import { useAppSelector } from "@shared/redux";
import { useStudio5AvailablePremiumFinishesOnCanvas } from "@utilities";
import { useDesignErrors } from "../hooks/useDesignErrors";

export default function StudioDesignErrorModalWrapper() {
    const showDialog = useAppSelector(state => state.studioErrorDialogOpen);
    const designErrors = useDesignErrors();
    const { hasPremiumFinishesCurrentCanvas } = useStudio5AvailablePremiumFinishesOnCanvas();

    return (
        <StudioDesignErrorModal
            designErrors={designErrors}
            hasPremiumFinishesCurrentCanvas={hasPremiumFinishesCurrentCanvas}
            showDialog={showDialog}
        />
    );
}

StudioDesignErrorModalWrapper.displayName = "StudioDesignErrorModalWrapper";
