import React from "react";
import ReactDOM from "react-dom";
import { Typography } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { LoadingSpinner, SpinnerVariant } from "@shared/features/StudioChrome";
import { useLoadingState } from "../../hooks/useLoadingState";
import "./designerProcessingSpinner.scss";
import spinnerMessages from "./spinnerMessages";

type Props = {
    imageViewModel: ImageViewModel;
};

const MIN_IMAGE_SIZE_TO_SHOW_SPINNER_TEXT = 160; // 160px

export const DesignerProcessingSpinner = (props: Props) => {
    const { t } = useTranslationSSR();
    const { imageViewModel } = props;
    const { isLoading } = useLoadingState(imageViewModel);
    const portalElement = document.getElementById(`${imageViewModel.id}-decoration`);

    if (!isLoading || !portalElement) {
        return null;
    }

    let processingMessage = "";
    if (imageViewModel.get("processesInProgress")?.includes("sharpen")) {
        processingMessage = t(spinnerMessages.sharpeningImage.id);
    } else if (imageViewModel.get("processesInProgress")?.includes("backgroundRemove")) {
        // not sure if we need to account for possibility of reverse action here and in sharpening
        if (imageViewModel.model?.get("appliedProcesses")?.includes("backgroundRemove")) {
            processingMessage = t(spinnerMessages.restoringBackground.id);
        } else {
            processingMessage = t(spinnerMessages.removingBackground.id);
        }
    } else if (imageViewModel.get("digitizationNeeded")) {
        processingMessage = t(spinnerMessages.convertingToStitches.id);
    } else if (imageViewModel.get("segmentationNeeded")) {
        processingMessage = t(spinnerMessages.processingImage.id);
    } else if (imageViewModel.get("fetchingPremiumFinishOverlay")) {
        processingMessage = t(spinnerMessages.processingImage.id);
    } else {
        processingMessage = t(spinnerMessages.processingImage.id);
    }

    const { width, height } = portalElement.getBoundingClientRect();
    const shouldShowText = height > MIN_IMAGE_SIZE_TO_SHOW_SPINNER_TEXT || width > MIN_IMAGE_SIZE_TO_SHOW_SPINNER_TEXT;

    return (
        <>
            {ReactDOM.createPortal(
                <div className="designer-spinner-container" data-dcl-prevent-canvas-items-deselection>
                    <div className="spinner-wrapper">
                        <LoadingSpinner variant={SpinnerVariant.Small} centering={true} />
                        {processingMessage && shouldShowText && (
                            <Typography fontSize="-1" textAlign="center" fontWeight="normal" textColor="black">
                                {processingMessage}
                            </Typography>
                        )}
                    </div>
                </div>,
                portalElement
            )}
        </>
    );
};

DesignerProcessingSpinner.displayName = "DesignerProcessingSpinner";
