import { useEffect, useState } from "react";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useAppSelector } from "@shared/redux";
import { fetchTemplateUrls } from "./specsTemplatesClientUtil";

interface UrlData {
    pdfUrl: string;
    svgUrl: string;
    aiUrl: string;
}

export const useTemplateUrls = () => {
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const productOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const { auth } = useIdentityContext();
    const [templateUrls, setTemplateUrls] = useState<UrlData>({ pdfUrl: "", svgUrl: "", aiUrl: "" });

    useEffect(() => {
        if (!auth) {
            return;
        }
        const authToken = auth.getToken();
        async function getTemplateUrls() {
            if (authToken && productVersion) {
                const res = await fetchTemplateUrls(productKey, productOptions, productVersion, authToken);
                setTemplateUrls(res);
            }
        }
        if (productKey) {
            getTemplateUrls();
        }
    }, [productKey, productOptions, productVersion, auth]);
    return templateUrls;
};
