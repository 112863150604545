import { defineMessages } from "@vp/i18n-helper";

export const fontBrowserMessages = defineMessages({
    ariaModalLabel: {
        id: "studio.fontBrowserModal.ariaModalLabel",
        defaultMessage: "Browse for fonts"
    },
    ariaCloseLabel: {
        id: "studio.fontBrowserModal.ariaCloseLabel",
        defaultMessage: "Close"
    },
    fontBrowserHeader: {
        id: "studio.fontBrowserModal.fontBrowserHeader",
        defaultMessage: "Font Browser"
    },
    fontBrowserPangram: {
        id: "studio.fontBrowserModal.fontBrowserPangram",
        defaultMessage: "The quick brown fox jumps over the lazy dog.",
        description: {
            note: "This is a pangram that uses every letter in the alphabet used for previewing fonts"
        }
    },
    fontBrowserWeights: {
        id: "studio.fontBrowserModal.fontBrowserWeights",
        defaultMessage: "[[numberOfWeights]] weights"
    },
    weightRegular: {
        id: "studio.fontBrowserModal.weightRegular",
        defaultMessage: "Regular"
    },
    weightBold: {
        id: "studio.fontBrowserModal.weightBold",
        defaultMessage: "Bold"
    },
    weightBlack: {
        id: "studio.fontBrowserModal.weightBlack",
        defaultMessage: "Black"
    },
    weightItalic: {
        id: "studio.fontBrowserModal.weightItalic",
        defaultMessage: "Italic"
    },
    weightBoldItalic: {
        id: "studio.fontBrowserModal.weightBoldItalic",
        defaultMessage: "Bold Italic"
    },
    fontCategoriesAriaLabel: {
        id: "studio.fontBrowserModal.fontCategoriesAriaLabel",
        defaultMessage: "Font Categories"
    },
    recentlyUsed: {
        id: "studio.fontBrowserModal.recentlyUsed",
        defaultMessage: "Recently used"
    },
    traditional: {
        id: "studio.fontBrowserModal.traditional",
        defaultMessage: "Traditional"
    },
    modern: {
        id: "studio.fontBrowserModal.modern",
        defaultMessage: "Modern"
    },
    script: {
        id: "studio.fontBrowserModal.script",
        defaultMessage: "Script"
    },
    playful: {
        id: "studio.fontBrowserModal.playful",
        defaultMessage: "Playful"
    },
    decorative: {
        id: "studio.fontBrowserModal.decorative",
        defaultMessage: "Decorative"
    }
});
