import React, { useCallback } from "react";
import classnames from "classnames";
import {
    LegacyModalDialog,
    LegacyModalDialogBody,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton
} from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppSelector } from "@shared/redux";
import { FlexibilityOptionPanelContent } from "./FlexibilityOptionPanelContent";
import { FlexibilityOptionPreview } from "./FlexibilityOptionPreview";
import { useFlexibilityOptionPanel } from "./FlexibilityOptionPanelProvider";
import * as styles from "./FlexibilityOptionPanel.module.scss";
import { useActiveFlexibilityOptions } from "../../ActiveFlexibilityProvider";
import { useFlexibilityDesignDocuments } from "../hooks/useFlexibilityDesignDocuments";

export const messages = defineMessages({
    flexibilityBackButton: {
        id: "studio.components.flexibility.back.button",
        defaultMessage: "Back to product options",
        description: {
            note: "Text message for back to product options panel"
        }
    }
});

interface FlexibilityOptionPanelProps {
    className?: string;
    onContinueClick?: () => void;
    headerText: string;
    descriptionText: string;
    closeButtonLabel: string;
    loadingText: string;
    changeOptionText: string;
    changeAlertKey?: string;
}

export function FlexibilityOptionPanel(props: FlexibilityOptionPanelProps) {
    const {
        className,
        headerText,
        descriptionText,
        closeButtonLabel,
        loadingText,
        changeOptionText,
        changeAlertKey,
        onContinueClick
    } = props;
    const { designAttributeName } = useFlexibilityOptionPanel();
    const { designDocuments } = useFlexibilityDesignDocuments();
    const { displayedDesignAttributeName, setDisplayedDesignAttributeName } = useActiveFlexibilityOptions();
    const showPreviewState = useAppSelector(state => state.previewOpen);
    const { t } = useTranslationSSR();

    const closePanel = useCallback(() => {
        setDisplayedDesignAttributeName(undefined);
    }, [setDisplayedDesignAttributeName]);

    const onDismiss = useCallback(() => {
        // Tracking Event for closing the modal by clicking outside the modal or clicking on “x” button.
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLOSE_CHANGE_DESIGN_ATTRIBUTE_MODAL,
            label: "close change design attribute modal",
            extraData: () => ({
                designAttributeName
            })
        });
        closePanel();
    }, [closePanel, designAttributeName]);

    return (
        <LegacyModalDialog
            isOpen={displayedDesignAttributeName === designAttributeName && !showPreviewState}
            onRequestDismiss={onDismiss}
            className={className}
            variant="panel-right"
        >
            <LegacyModalDialogContent className={styles.modalDialog} aria-label={headerText} fullBleed={true}>
                <LegacyModalDialogCloseButton visuallyHiddenLabel={closeButtonLabel} />
                <div className={classnames("flexibility-option-panel-content-preview", styles.contentPreview)}>
                    <FlexibilityOptionPreview designDocuments={designDocuments} />
                </div>
                <LegacyModalDialogBody className={styles.panelBody}>
                    <FlexibilityOptionPanelContent
                        onContinueClick={onContinueClick}
                        headerText={headerText}
                        descriptionText={descriptionText}
                        loadingText={loadingText}
                        changeOptionText={changeOptionText}
                        changeAlertKey={changeAlertKey}
                        backButtonLabel={t(messages.flexibilityBackButton.id)}
                    />
                </LegacyModalDialogBody>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}
FlexibilityOptionPanel.displayName = "FlexibilityOptionPanel";
