import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import type { CimDoc } from "@design-stack-ct/cdif-types";
import { DesignTileButtonImage } from "../DesignTileButtonImage";
import { DesignTileButton } from "../DesignTileButton";
import { designPanelMessages } from "../../designPanelMessages";

type Template = {
    templateToken: string;
    previewUrl: string;
    designType: string;
    colorMatchedDocument: CimDoc;
};

type Props = {
    template: Template;
    overridePreviewUrl?: string;
    className?: string;
};

/**
 * Tile to add to the Design Tiles Panel that allows the user to change their canvas to the selected template design.
 */
export function TemplateDesignTile(props: Props) {
    const { t } = useTranslationSSR();
    const {
        template: { templateToken, previewUrl },
        className
    } = props;
    return (
        <DesignTileButton
            selectedOption={templateToken}
            key={`${templateToken}`}
            ariaLabel={t(designPanelMessages.changeTemplateAriaLabel.id)}
            className={className}
        >
            <DesignTileButtonImage url={previewUrl} />
        </DesignTileButton>
    );
}
TemplateDesignTile.displayName = "TemplateDesignTile";
