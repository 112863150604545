import React, { ReactNode } from "react";
import * as styles from "./KeyboardShortcutsContent.module.scss";

interface Props {
    keyboardShortcutsTitle?: string;
    keyboardShortcutsKeysIcons?: string | ReactNode | ReactNode[];
}

export function KeyboardShortcutsContent({ keyboardShortcutsTitle, keyboardShortcutsKeysIcons }: Props) {
    return (
        <div className={styles.tabContentsTitle}>
            {keyboardShortcutsTitle}
            <div className={styles.tabContentsIcons}>{keyboardShortcutsKeysIcons}</div>
        </div>
    );
}
KeyboardShortcutsContent.displayName = "KeyboardShortcutsContent";
