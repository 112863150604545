import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
}
export function MacOptionIcon(props: Props) {
    return (
        <svg viewBox="0 0 14 12" fill="none" {...props}>
            <path
                // eslint-disable-next-line max-len
                d="M4.12891 0.0698242H1.03906C0.847656 0.0698242 0.685872 0.133626 0.553711 0.26123C0.426107 0.384277 0.362305 0.536947 0.362305 0.719238C0.362305 0.90153 0.426107 1.0542 0.553711 1.17725C0.685872 1.30029 0.847656 1.36182 1.03906 1.36182H3.82812C3.94206 1.36182 4.03776 1.38916 4.11523 1.44385C4.19727 1.49398 4.26335 1.56917 4.31348 1.66943L8.54492 11.0962C8.67253 11.3833 8.84115 11.5929 9.05078 11.7251C9.26042 11.8618 9.52246 11.9302 9.83691 11.9302H12.9609C13.1478 11.9302 13.3073 11.8664 13.4395 11.7388C13.5716 11.6157 13.6377 11.4631 13.6377 11.2808C13.6377 11.103 13.5716 10.9504 13.4395 10.8228C13.3073 10.6997 13.1478 10.6382 12.9609 10.6382H10.1514C9.90983 10.6382 9.74121 10.5356 9.64551 10.3306L5.43457 0.91748C5.31152 0.653158 5.13607 0.445801 4.9082 0.29541C4.68034 0.14502 4.42057 0.0698242 4.12891 0.0698242ZM12.9609 0.0698242H8.83203C8.64518 0.0698242 8.48568 0.131348 8.35352 0.254395C8.22591 0.377441 8.16211 0.530111 8.16211 0.712402C8.16211 0.894694 8.22591 1.04736 8.35352 1.17041C8.48112 1.29346 8.64062 1.35498 8.83203 1.35498H12.9609C13.1478 1.35498 13.305 1.29346 13.4326 1.17041C13.5648 1.04736 13.6309 0.894694 13.6309 0.712402C13.6309 0.530111 13.5648 0.377441 13.4326 0.254395C13.305 0.131348 13.1478 0.0698242 12.9609 0.0698242Z"
                fill="black"
            />
        </svg>
    );
}
MacOptionIcon.displayName = "MacOptionIcon";
