import React, { useState } from "react";
import { Typography, Box, FluidImage, ProgressiveImage } from "@vp/swan";
import Skeleton from "react-loading-skeleton";
import classNames from "classnames";
import { encodeForRenderingApis } from "@shared/utils/DocumentStorage";
import { useAsyncEffect } from "@design-stack-ct/utility-react";
import { FONT_REPOSITORY_URL } from "@shared/utils/Fonts";
import * as styles from "./FontPreview.module.scss";

const IMAGE_HEIGHT = 25;
const IMAGE_QUALITY = 4;

async function createItemPreview(
    content: string,
    fontFamily: string,
    fontStyle: string,
    height: number
): Promise<string> {
    const document = {
        version: "2",
        deleted: false,
        document: {
            panels: [
                {
                    id: "Item",
                    name: "Item",
                    width: "2000mm",
                    height: "0mm",
                    textAreas: [
                        {
                            id: "8075c824-0b07-413b-bd6e-db5607455cea",
                            position: {
                                x: "0mm",
                                y: "0mm",
                                width: "2000mm",
                                height: "0mm"
                            },
                            horizontalAlignment: "left",
                            verticalAlignment: "top",
                            blockFlowDirection: "horizontal-tb",
                            textOrientation: "natural",
                            zIndex: 0,
                            content: [
                                {
                                    content,
                                    type: "inline",
                                    fontFamily,
                                    fontStyle,
                                    fontSize: "14pt",
                                    color: "rgb(#000000)"
                                }
                            ]
                        }
                    ],
                    decorationTechnology: "offsetOrDigital",
                    colorMode: "color"
                }
            ]
        },
        fontRepositoryUrl: FONT_REPOSITORY_URL
    };
    const base64Document = await encodeForRenderingApis(document);
    // @ts-ignore
    const itemPreviewDoc = `${UDS_INSTRUCTIONS_V3_URL}/instructions:item?documentUri=${encodeURIComponent(
        base64Document
    )}`;
    // these images are pretty small and for fancy fonts you can't fit much detail
    // we multiply height by 2 to get slightly crisper rendering
    // @ts-ignore
    const renderingUrl = `${CIMPRESS_RENDERING_URL}/v1/dcl/preview?height=${
        height * IMAGE_QUALITY
    }&format=png&category=studio&instructions_uri=${encodeURIComponent(itemPreviewDoc)}`;
    return renderingUrl;
}

interface FontPreviewProps {
    text: string;
    fontFamily: string;
    altText: string;
    className?: string;
    renderClientside?: boolean;
    height?: number;
    fontStyle?: string;
}

export function FontPreview({
    text,
    fontFamily,
    altText,
    className = "",
    renderClientside = false,
    height = IMAGE_HEIGHT,
    fontStyle = "normal"
}: FontPreviewProps) {
    const [renderingUrl, setRenderingUrl] = useState<string | undefined>();

    useAsyncEffect(
        helpers => {
            function getRenderingUrl() {
                helpers.runIfMounted(async () =>
                    setRenderingUrl(
                        await createItemPreview(
                            // 100 likely is enough characters to extend past the edge of the modal, but hopefully not enough to wrap
                            text.length < 100 ? text : text.substring(0, 100),
                            fontFamily,
                            fontStyle,
                            height
                        )
                    )
                );
            }
            if (!renderClientside) {
                getRenderingUrl();
            }
        },
        [fontFamily, fontStyle, height, renderClientside, text]
    );

    return renderClientside ? (
        <Typography
            className={classNames(styles.clientSideText, "rendered-preview-text", className)}
            style={{ fontFamily }}
            data-testid="font-preview"
        >
            {text}
        </Typography>
    ) : (
        <ProgressiveImage
            src={renderingUrl}
            trigger="inView"
            className={classNames(styles.progressiveImage, "font-preview")}
            data-testid="font-preview"
        >
            {(image, isLoading) =>
                isLoading ? (
                    <Skeleton width={"100%"} height={height} />
                ) : (
                    <Box
                        className={classNames(styles.container, "rendered-preview-image-container", className)}
                        style={{ height }}
                    >
                        <FluidImage
                            className={classNames(styles.image, "rendered-preview-image")}
                            alt={altText}
                            src={image.src}
                            style={{ maxHeight: height }}
                        />
                    </Box>
                )
            }
        </ProgressiveImage>
    );
}

FontPreview.displayName = "FontPreview";

export function FontPreviewBackgroundImage(props: React.HTMLProps<HTMLDivElement>) {
    return <div {...props} data-testid="font-preview" />;
}

FontPreviewBackgroundImage.displayName = "FontPreviewBackgroundImage";
